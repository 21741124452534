import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import TextBox from '../../../components/ui/TextBox'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import Attachments from '../../../components/ui/Attachments'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import { EDIT_CATEGORY, LOAD_FILE } from '../../../utils/GraphQl/KnowledgeBase'
import { logoutUser } from '../../../store/AuthSlice/AuthSlice'
import Simpledropdown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import FalseAttachments from '../../../components/ui/FalseAttachments'
import ElasticTextbox from '../../../components/ui/Elastic-textbox'
import Button from '../../../components/ui/Buttons/Button'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const EditList = ({
	setIsEditList,
	SelectedData,
	setListSelectedData,
	setData,
	setPageId,
	setLoader
}) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const [Files, setFiles] = useState([])
	const [FalseAttachment, setFalseAttachment] = useState([])
	const dispatch = useDispatch()
	const [editCategory] = useMutation(EDIT_CATEGORY)
	const [loadFile] = useMutation(LOAD_FILE)
	const [Keys, setKeys] = useState([])
	const [Formdata, setFormdata] = useState({
		updated_json_data: '',
		name: SelectedData[0].name,
		keys: '',
		values: '',
		type:
			SelectedData[0].field_type === 'simple_dropdown'
				? { label: 'Simple Dropdown' }
				: SelectedData[0].field_type === 'radio'
					? { label: 'Radio' }
					: SelectedData[0].field_type === 'checkbox'
						? { label: 'Checkbox' }
						: SelectedData[0].field_type === 'multiselect_dropdown'
							? {
									label: 'Multiselect Dropdown',
									value: 'multiselect_dropdown'
								}
							: {
									label: 'Simple Dropdown',
									value: 'simple_dropdown'
								}
	})
	const HANDLEFORMDATA = (field, value) => {
		setFormdata((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}
	const HandleLoadFile = async () => {
		try {
			setLoader(true)
			const response = await loadFile({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					file: Files[0]
				}
			})
			let data = await response.data
			let Bases = data?.loadFile
			if (Bases.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'editcategory_list',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'editcategory_list' }))
				}, 3000)
			} else if (Bases.status !== 200 && Bases.errors.length > 0) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'editcategory_list',
						type: 'danger',
						message: Bases.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (Bases.status === 200) {
				setLoader(false)
				// console.log('KEYS +> ',Bases.data)
				setKeys(Bases.data)
				dispatch(
					addAlerts({
						pageId: 'editcategory_list',
						type: 'success',
						message: Bases.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'editcategory_list',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	useEffect(() => {
		if (Keys.length > 0) {
			const label = Keys.find(
				(item) => item.value === SelectedData[0].label_key
			)
			const value = Keys.find(
				(item) => item.value === SelectedData[0].value_key
			)
			if (label) {
				HANDLEFORMDATA('keys', label)
			}
			if (value) {
				HANDLEFORMDATA('values', value)
			}
		}
	}, [Keys])
	useEffect(() => {
		setPageId('editcategory_list')
		setKeys(SelectedData[0].keys)
		setFalseAttachment([SelectedData[0]?.json_file_path])
		let data = JSON.stringify(SelectedData[0]?.json_data)
		HANDLEFORMDATA('updated_json_data', data)
	}, [])
	const handleAttachmentRemove = (name) => {
		setFalseAttachment([])
		// const attachments = FalseAttachment.filter(
		// 	(attachment) => attachment !== name
		// )
	}

	const HandleEditCategory = async () => {
		try {
			setLoader(true)
			const response = await editCategory({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					listId: SelectedData[0].id,
					input: {
						name: Formdata.name,
						updated_json_data: Formdata.updated_json_data,
						label_key: Formdata.keys.value,
						value_key: Formdata.values.value,
						...(Files.length > 0 && { json: Files[0] }),
						field_type: Formdata.type?.value
					}
				}
			})
			let data = await response.data
			let Bases = data?.editCategoryList
			if (Bases.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'editcategory_list',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'editcategory_list' }))
				}, 3000)
			} else if (Bases.status !== 200 && Bases.errors.length > 0) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'editcategory_list',
						type: 'danger',
						message: Bases.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (Bases.status === 200) {
				setLoader(false)
				let NewField = Bases.data
				let ExistingData = setData
				let Index = ExistingData.findIndex(
					(element) => element.id === SelectedData[0].id
				)
				let UpDatedField = {
					...NewField,
					field: NewField.json_data.length,
					type: NewField.field_type.replace('_', ' ')
				}
				setListSelectedData([UpDatedField])
				ExistingData[Index] = UpDatedField
				dispatch(
					addAlerts({
						pageId: 'editcategory_list',
						type: 'success',
						message: Bases.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					setShowDrawer(false)
					setScrollable(false)
					setScrollableFooter(false)
					setSize('md')
					setOverlay('')
					setBody(null)
					setIsEditList(false)
					dispatch(removeAlerts({ pageId: 'editcategory_list' }))
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'editcategory_list',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const OPTIONS = [
		{ label: 'Simpe Dropdown', value: 'simple_dropdown' },
		{ label: 'Multiselect Dropdown', value: 'multiselect_dropdown' },
		{ label: 'Checkbox', value: 'checkbox' },
		{ label: 'Radio', value: 'radio' }
	]

	const HandleSelectedFile = (file) => {
		if (file[0].name.endsWith('.json')) {
			// console.log('true')
			setFiles([file[0]])
			setFalseAttachment([])
		} else {
			dispatch(
				addAlerts({
					pageId: 'importusers',
					type: 'danger',
					message: 'Please upload a valid JSON file',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const RemoveFIle = (files) => {
		let FIlteredFiles = Files.filter((file) => file.name !== files.name)
		setFiles(FIlteredFiles)
	}

	const FORM = (
		<form
			encType="multipart/form-data"
			className=" flex w-[350px] flex-col justify-start"
		>
			<>
				<div className="flex w-[350px] flex-col justify-start">
					<TextBox
						id={'name'}
						labelName={'Name'}
						value={Formdata.name}
						onChange={(data) => HANDLEFORMDATA('name', data)}
					/>
					<Simpledropdown
						label="Select Type"
						value={Formdata.type.label}
						onChange={(data) => HANDLEFORMDATA('type', data)}
						options={OPTIONS}
					/>
					<div className="relative">
						<Attachments
							accept={'.json'}
							id="edit_jsondata_list"
							labelName={'Select Json File'}
							files={Files}
							onSelect={HandleSelectedFile}
							onRemove={RemoveFIle}
						/>
						{FalseAttachment.length > 0 &&
							FalseAttachment.map((attachment) => {
								return (
									<FalseAttachments
										filename={attachment.split('/')[2]}
										onFileRemove={() =>
											handleAttachmentRemove(attachment)
										}
									/>
								)
							})}
						{Files.length > 0 && (
							<Button
								onClick={HandleLoadFile}
								children="Load File"
								positionClass="!absolute !right-0 !top-0 !mb-2.5 !mt-[30px]"
								className="btn-primary btn-large "
							/>
						)}
					</div>

					<ElasticTextbox
						id="edit_json"
						labelName="Edit JSON data"
						value={Formdata.updated_json_data}
						onChange={(data) =>
							HANDLEFORMDATA('updated_json_data', data)
						}
					/>
				</div>
				<Simpledropdown
					label="Label Mapping"
					value={Formdata.keys?.label}
					onChange={(data) => HANDLEFORMDATA('keys', data)}
					options={Keys}
				/>
				<Simpledropdown
					label="Value Mapping"
					value={Formdata.values?.label}
					onChange={(data) => HANDLEFORMDATA('values', data)}
					options={Keys}
				/>
			</>
		</form>
	)
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setIsEditList(false)
	}

	useEffect(() => {
		setBody(FORM)
	}, [showDrawer, Formdata, Files, FalseAttachment, Keys])

	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "> </h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={HandleEditCategory}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') {
			handleDrawerClose()
		}
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			<Drawer
				label="Edit List"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default EditList
