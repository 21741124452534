import { gql } from '@apollo/client'
export const REGISTER_MUTATION = gql`
	mutation REGISTER($input: RegisterInput!) {
		register(input: $input) {
			status
			message
			data {
				user_id
				role
				email
				active
			}
			errors
		}
	}
`

//   {
//   "input": {
//     "fullname":"vhgvxjgsxvwdhe",
//     "username": "hgjhqgwdwshqjkcgcdhdejkh2uwqd",
//     "password": "123456"
//   }
// }
