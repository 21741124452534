import { createSlice } from '@reduxjs/toolkit'
const InitialState = {
	Fields: []
}
const AdditionalInfoFields = createSlice({
	name: 'additional info slice',
	initialState: InitialState,
	reducers: {
		addFields: (state, action) => {
			state.Fields = action.payload
		}
	}
})

export const { addFields } = AdditionalInfoFields.actions
export const AdditionalInfoFieldsReducer = AdditionalInfoFields.reducer
