import { gql } from '@apollo/client'
export const CONTACT_US_MUTATION = gql`
	mutation Contact($input: contactInput!) {
		contact(input: $input) {
			status
			message
			errors
		}
	}
`
