import { gql } from '@apollo/client'

// Define your GraphQL mutation
export const IMPORT_USER_MUTATION = gql`
	mutation ImportUsers($file: Upload, $skipHeader: Boolean) {
		importUsers(file: $file, skip_header: $skipHeader) {
			status
			message
			data
			errors
		}
	}
`
