import { useState, useEffect } from 'react'
import DataTableWrapper from '../../../components/ui/DataTables'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import React from 'react'
import AddFields from '../AddTreatmentPlans'
import EditTreatmentFields from '../EditTreatmentplans'
import { classNames } from 'primereact/utils'
import Button from '../../../components/ui/Buttons/Button'
import {
	faClose,
	faPlus,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { addAlerts } from '../../../store/AlertSlice'

const TreatmentPlan = ({
	TreatmentPlanData,
	setTreatmentPlanData,
	products,
	setShowAddTreatmentPlan,
	showAddTreatmentplan,
	setShowEditTreatmentPlan,
	showEditTreatmentPlan
}) => {
	const [treatment_products, setTreatment_products] = useState(
		TreatmentPlanData[0].treatment_plan ?? []
	)
	const [selectedData, setSelectedData] = useState([])
	const dispatch = useDispatch()
	const dopdownOptions = ['All']
	const handleFilter = (rowData) => {
		let id = rowData.id
		let filteredItems = treatment_products.filter((item) => item.id !== id)
		TreatmentPlanData[0].treatment_plan = filteredItems
		setTreatment_products(filteredItems)
		let savedProducts = JSON.parse(localStorage.getItem('products'))
		let savedDataidx = savedProducts.findIndex(
			(product) => product.id === TreatmentPlanData[0].id
		)
		savedProducts[savedDataidx] = TreatmentPlanData[0]
		localStorage.setItem('products', JSON.stringify(savedProducts))
	}
	const ActionBodyTemplate = (rowData) => {
		return (
			<div className="flex justify-between">
				<div className="">{rowData.status}</div>
				<IconButton
					type="button"
					className={
						'relative top-0.5 flex h-5 w-6 items-center justify-center rounded-sm border bg-transparent text-sm text-red-500 hover:bg-transparent hover:text-red-700'
					}
					onClick={(e) => {
						e.preventDefault()
						// console.log(rowData)
						handleFilter(rowData)
					}}
					children=""
					Icon={faClose}
					size={'lg'}
				/>
			</div>
		)
	}
	let COLUMNS = [
		{
			selectionMode: 'multiple',
			headerStyle: { width: '20px !important' },
			style: { width: '20px !important' },
			className: 'chk-dt chk-primary',
			id: 'red'
		},
		{
			sortable: true,
			header: 'Id',
			field: 'id',
			style: { width: '4%' },
			headerStyle: { width: '4%' }
		},
		{
			sortable: true,
			header: 'Action',
			field: 'action',
			style: { width: 'auto' },
			headerStyle: { width: 'auto' }
		},
		{
			sortable: true,
			header: 'Responsibility',
			field: 'responsibility',
			style: { width: '12%' },
			headerStyle: { width: '12%' }
		},
		{
			sortable: true,
			header: 'Expiry Date',
			field: 'due_date',
			style: { width: '14%' },
			headerStyle: { width: '14%' }
		},
		{
			sortable: true,
			header: ' Status',
			// field: 'status',
			style: { width: '12%' },
			body: ActionBodyTemplate,
			headerStyle: { width: '12%' }
		}
	]

	const HandleData = (data) => {
		setSelectedData(data)
	}

	const HeaderButtons = ({ exportCSV }) => <></>
	let last_updated_date = ''
	if (treatment_products.length > 0) {
		let last_updated_index = treatment_products.length
		if (last_updated_index > 0) {
			last_updated_date =
				treatment_products[last_updated_index - 1].last_updated
		}
	}

	return (
		<div>
			{showAddTreatmentplan && (
				<AddFields
					originalProducts={products}
					selectedData={TreatmentPlanData}
					setSelectedData={setTreatmentPlanData}
					setShowModal={setShowAddTreatmentPlan}
				/>
			)}
			{showEditTreatmentPlan && selectedData.length > 0 && (
				<EditTreatmentFields
					selectedData={selectedData}
					parentdata={TreatmentPlanData}
					originalProducts={products}
					setTreatment_products={setTreatment_products}
					setSelectedData={setSelectedData}
					setShowModal={setShowEditTreatmentPlan}
				/>
			)}

			<div className="h5 border-b pb-1">
				TREATMENT PLAN Last Updated : {last_updated_date}{' '}
			</div>

			<DataTableWrapper
				showHeader={false}
				paginator={false}
				products={treatment_products}
				HeaderButtons={HeaderButtons}
				options={dopdownOptions}
				columns={COLUMNS}
				onDataSelection={HandleData}
				selectedData={selectedData}
			/>
		</div>
	)
}
export default TreatmentPlan
