import React, { useEffect, useState } from 'react'
import Modal from '../../../components/ui/modals'
import ElasticTextbox from '../../../components/ui/Elastic-textbox'
import Simpledropdown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import DatePicker from '../../../components/ui/Datepicker'
import {
	faSave,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { useDispatch } from 'react-redux'
import { addAlerts } from '../../../store/AlertSlice'
const EditTreatmentFields = ({
	originalProducts,
	selectedData,
	setSelectedData,
	parentdata,
	setShowModal,
	setTreatment_products
}) => {
	const [overLay, setOverLay] = React.useState('')
	const [scrollable, setScrollable] = React.useState(true)
	const [currentDate, setCurrentDate] = useState(selectedData[0].due_date)
	const dispatch = useDispatch()
	const [Formdata, setFormdata] = useState({
		action: selectedData[0].action,
		responsibility: selectedData[0].responsibility,
		status: selectedData[0].status
	})
	const Responsibility = [
		{
			label: 'Tarun',
			value: 'tgupta'
		},
		{
			label: 'Azim',
			value: 'akhan'
		}
	]
	const Status = [
		{ label: 'Active', value: 'active' },
		{ label: 'Inactive', value: 'inactive' }
	]
	useEffect(() => {
		let ResponsibilityIndex = Responsibility.findIndex(
			(product) => product.value === selectedData[0].responsibility
		)
		HandleFormData('responsibility', Responsibility[ResponsibilityIndex])
		let statusIndex = Status.findIndex(
			(product) => product.value === selectedData[0].status
		)
		HandleFormData('status', Status[statusIndex])
	}, [selectedData])
	const HandleFormData = (key, value) => {
		setFormdata((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}
	const HandleDateChange = (date) => {
		setCurrentDate(date)
	}
	const handleModalClose = () => {
		// event.preventDefault()
		setShowModal(false)
		setScrollable(false)
		setOverLay('false')
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		let date = new Date()
		let currentDay = date.getDate()
		let currentMonth = date.getMonth()
		let currentYear = date.getFullYear()
		const monthNames = [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec'
		]
		const final_date = ` ${currentDay}-${monthNames[currentMonth]}-${currentYear}`

		if (
			!Formdata.action ||
			!Formdata.action ||
			!Formdata.responsibility ||
			!Formdata.status
		) {
			dispatch(
				addAlerts({
					pageId: 'riskmanagement_editcolumns',
					type: 'danger',
					message: 'Please fill all the fields',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else {
			let current_data = JSON.parse(JSON.stringify(parentdata))
			let planIndex = current_data[0]?.treatment_plan.findIndex(
				(plan) => plan.id === selectedData[0].id
			)
			let newData = {
				...selectedData[0],
				last_updated: final_date,
				action: Formdata.action,
				responsibility: Formdata.responsibility.value,
				status: Formdata.status.value,
				due_date: currentDate
			}
			setSelectedData([{ ...newData }])
			current_data[0].treatment_plan[planIndex] = newData
			let index = originalProducts.findIndex(
				(product) => product.id === current_data[0].id
			)
			originalProducts[index] = current_data[0]
			setTreatment_products(current_data[0].treatment_plan)
			localStorage.setItem('products', JSON.stringify(originalProducts))
			handleModalClose()
		}
	}
	const FORM = (
		<>
			<ElasticTextbox
				id="action"
				labelName="Action"
				value={Formdata.action}
				onChange={(data) => HandleFormData('action', data)}
			/>
			<Simpledropdown
				label="Responsibility"
				options={Responsibility}
				value={Formdata?.responsibility?.label}
				onChange={(data) => HandleFormData('responsibility', data)}
			/>
			<DatePicker
				labelName="Expiry Date"
				onDateChange={(date) => HandleDateChange(date)}
				currentDate={currentDate}
			/>

			<Simpledropdown
				label="Status"
				options={Status}
				value={Formdata?.status?.label}
				onChange={(data) => HandleFormData('status', data)}
			/>
		</>
	)
	const footer = (
		<>
			<div className="modal-footer flex  items-center justify-between border-t  ">
				<h5 className="invisible font-normal">{'T'}</h5>
				<div className="flex items-center justify-end gap-[3px]">
					<IconButton
						Icon={faSave}
						className="btn-success btn-large flex gap-1.5"
						type="button"
						onClick={handleSubmit}
						children="Save Changes"
					/>
				</div>
			</div>
		</>
	)

	return (
		<div>
			<Modal
				modalSize={'xl'}
				title={'Edit Fields'}
				onClose={handleModalClose}
				body={FORM}
				overLay={overLay}
				showModal={true}
				maxHeight={'404px'}
				minHeight={'404px'}
				scrollable={scrollable}
				Footer={footer}
			/>
		</div>
	)
}

export default EditTreatmentFields
