import React, { useState, useEffect, useRef } from 'react'
import Drawer from '../../../components/ui/drawers'
import TextBox from '../../../components/ui/TextBox'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import ElasticTextBox from '../../../components/ui/Elastic-textbox'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import RichTextbox from '../../../components/ui/Rich-textbox'
import { logoutUser } from '../../../store/AuthSlice/AuthSlice'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import { CREATE_EMAIL_TEMPLATE } from '../../../utils/GraphQl/Templates'
import SimpleDropDown from '../../../components/Dropdowns/Simpledropdown'
import Attachments from '../../../components/ui/Attachments'
import { AddSingleTemplate } from '../../../store/TemplateSlice'
import Button from '../../../components/ui/Buttons/Button'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const AddTemplate = ({ setDrawerstate, setLoader, setPageId }) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [Files, setFiles] = useState([])
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [size, setSize] = useState('40%')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const dispatch = useDispatch()
	const [CreateTemplate] = useMutation(CREATE_EMAIL_TEMPLATE)
	const [editorText, setEditorText] = useState('')
	const [editorRef, setEditorRef] = useState(null)
	const [Formdata, setFormdata] = useState({
		templatename: '',
		from: '',
		textformat: 'HTML',
		sender: '',
		body: '',
		subject: '',
		variable: ''
	})
	const [Text, setText] = useState('')
	const elasticTextBoxRef = useRef(null)

	const HandleCreateTemplate = async () => {
		setPageId('createTemplate')
		try {
			setLoader(true)
			const response = await CreateTemplate({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						template_name: Formdata.templatename,
						subject: Formdata.subject,
						sender_email: Formdata.from,
						sender_name: Formdata.sender,
						is_html: Formdata.textformat === 'HTML' ? true : false,
						body:
							Formdata.textformat === 'HTML'
								? Formdata.body
								: Text
					},
					attachments: Files[0]
				}
			})
			let data = await response.data
			let createTemplate = data.createTemplate
			if (createTemplate.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'createTemplate',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'createTemplate' }))
				}, 3000)
			} else if (createTemplate.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'createTemplate',
						type: 'danger',
						message: createTemplate.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (
				createTemplate.status !== 201 &&
				createTemplate.errors.length > 0
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'createTemplate',
						type: 'danger',
						message: createTemplate.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (createTemplate.status === 201) {
				setLoader(false)
				const TemplateData = createTemplate.data
				dispatch(AddSingleTemplate(TemplateData))
				dispatch(
					addAlerts({
						pageId: 'createTemplate',
						type: 'success',
						message: createTemplate.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					setShowDrawer(false)
					setScrollable(false)
					setScrollableFooter(false)
					setOverlay('')
					setBody(null)
					dispatch(removeAlerts({ pageId: 'createTemplate' }))
				}, 3000)
			}
		} catch (error) {
			console.error('Failed create-template', error)
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'createTemplate',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const HANDLEFORMDATA = (field, value) => {
		setFormdata((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}

	const handleAddVariable = (event) => {
		event.preventDefault()
		let selectedOption = Formdata.variable
		let val = selectedOption.toUpperCase().replaceAll(' ', '_')
		let option = `%%${val}%%`
		if (Formdata.textformat === 'HTML') {
			if (editorRef && editorRef.current) {
				const quillRef = editorRef.current.getQuillRef()
				const editor = quillRef.editor
				if (editor.getLength() === 0) {
					// If the editor is empty, simply insert the selected option
					editor.insertText(0, option, 'user')
				} else {
					const cursorPosition = editor.getSelection(true).index

					// Insert the selected option at the specified cursorPosition
					editor.insertText(cursorPosition, option, 'user')

					// Move the cursor to the end of the newly inserted data
					editor.setSelection(cursorPosition + option.length, 0)
				}
			}
		} else {
			if (elasticTextBoxRef && elasticTextBoxRef.current) {
				// Get the current cursor position
				const cursorPosition = elasticTextBoxRef.current.selectionStart

				// Get the current value in the ElasticTextBox
				const currentValue = elasticTextBoxRef.current.value

				// Construct the new value with the selected option inserted at the cursor position
				const newValue =
					currentValue.substring(0, cursorPosition) +
					option +
					currentValue.substring(cursorPosition)

				// Update the value of the ElasticTextBox
				elasticTextBoxRef.current.value = newValue

				// Set the cursor position after the inserted option
				elasticTextBoxRef.current.setSelectionRange(
					cursorPosition + option.length,
					cursorPosition + option.length
				)

				// Trigger the input event to update any associated state or props
				elasticTextBoxRef.current.dispatchEvent(new Event('input'))
			}
		}
	}
	const HandleSelectedFile = (file) => {
		setFiles([...file])
	}
	const RemoveFIle = (files) => {
		let FIlteredFiles = Files.filter((file) => file.name !== files.name)
		setFiles(FIlteredFiles)
	}
	const HandleEditordata = (data) => {
		setEditorText(data)
	}
	useEffect(() => {
		if (Formdata.textformat === 'TEXT') {
			const text = editorText.replace(
				/<!--[\s\S]*?-->|<[^>]*>|&nbsp;/g,
				''
			)
			HANDLEFORMDATA('body', text)
		} else if (Formdata.textformat === 'HTML') {
			HANDLEFORMDATA('body', editorText)
		}
	}, [editorText, Formdata.textformat, Text])

	const HandleText = (data) => {
		setText(data)
	}
	const FORM = (
		<form
			encType="multipart/form-data"
			className="flex w-full flex-col justify-start"
		>
			<div className="flex w-full flex-col justify-start pr-1">
				<TextBox
					id={'temp_name'}
					name={'template_name'}
					value={Formdata.templatename}
					labelName={'Template Name '}
					onChange={(data) => HANDLEFORMDATA('templatename', data)}
				/>
				<SimpleDropDown
					label="Select Format"
					options={['HTML', 'TEXT']}
					onChange={(data) => HANDLEFORMDATA('textformat', data)}
					value={Formdata.textformat}
				/>

				<TextBox
					id="from"
					name={'from'}
					value={Formdata.from}
					labelName={'From '}
					onChange={(data) => HANDLEFORMDATA('from', data)}
				/>
				<TextBox
					id={'sender'}
					name={'sender'}
					value={Formdata.sender}
					labelName={'Sender'}
					onChange={(data) => HANDLEFORMDATA('sender', data)}
				/>
				<TextBox
					id={'subject'}
					name={'subject'}
					value={Formdata.subject}
					labelName={'Subject'}
					onChange={(data) => HANDLEFORMDATA('subject', data)}
				/>
				{Formdata.textformat === 'HTML' ? (
					<RichTextbox
						value={editorText}
						setEditorRef={setEditorRef}
						//   value={Formdata.body}
						labelName={'Body'}
						onChange={HandleEditordata}
					/>
				) : (
					<ElasticTextBox
						id="plan_text"
						ref={elasticTextBoxRef}
						value={Text}
						onChange={HandleText}
						labelName="Body"
					/>
				)}

				<div className="flex w-full gap-1.5">
					<SimpleDropDown
						className="flex-1"
						label="Add Variable"
						value={Formdata.variable}
						onChange={(data) => HANDLEFORMDATA('variable', data)}
						options={[
							'Fullname',
							'Email',
							'Subject',
							'Message',
							'Login Link',
							'Verify Link',
							'Reset Password Link',
							'Magic Link'
						]}
					/>
					<Button
						onClick={handleAddVariable}
						children="Add"
						className="btn btn-primary btn-large !mb-[10px] !mt-[30px]"
					/>
				</div>

				<Attachments
					id="add-emailtemplate"
					labelName={'Add Attachment'}
					files={Files}
					onSelect={HandleSelectedFile}
					onRemove={RemoveFIle}
				/>
			</div>
		</form>
	)
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('25%')
		setOverlay('')
		setBody(null)
		setDrawerstate(false)
	}

	useEffect(() => {
		setBody(FORM)
	}, [showDrawer, Formdata, editorText, Files])

	const drawerFooterBody = (
		<>
			<h5>{''}</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>
				<IconButton
					//   onClick={() => console.log(Formdata)}
					onClick={HandleCreateTemplate}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			<Drawer
				label="Add Template"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default AddTemplate
