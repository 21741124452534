import React, { useEffect } from 'react'
import Drawer from '../../components/ui/drawers'
import PageAlerts from '../PageAlerts'
import ProgressBar from '../ProgressBar'
const GLobalDrawer = ({
	onClose,
	overlay,
	scrollable,
	scrollableFooter,
	drawerPosition,
	body,
	size,
	label,
	drawerFooterBody,
	loader,
	showDrawer,
	pageId
}) => {
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.remove('scroll-disabled')
		}, 300)
	}, [])

	return (
		<>
			{loader && <ProgressBar />}
			<PageAlerts pageId={pageId} />
			<Drawer
				label={label}
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={onClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={drawerPosition}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default GLobalDrawer
