import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import '../../../assets/css/DropDowns/index.css'
const SimpleDropDown = ({
	label = '',
	options,
	onChange,
	placeholder = 'Select',
	value = '',
	id = '',
	className,
	autoComplete = 'off',
	onFocus,
	onBlur,
	autoFocus
}) => {
	const dropdownRef = useRef()
	const [showItems, setShowItems] = useState(false)
	const [filteredItems, setFilteredItems] = useState([])
	const [cursor, setCursor] = useState(null)
	const ref = useRef()

	const handleShowItems = () => {
		setShowItems((prev) => !prev)
	}

	const handleValue = (name) => {
		// console.log("name =>",name)
		onChange(name)
		setFilteredItems([])
		setShowItems(false)
	}

	const filterNames = (event) => {
		const inputValue = event.target.value
		console.log('inputValue =>', inputValue)
		if (inputValue.length === 0) {
			setFilteredItems([])
		} else {
			setFilteredItems([...options])
		}
	}

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				setShowItems(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		const input = ref.current
		if (input.type !== 'number' && cursor !== null) {
			input.setSelectionRange(cursor, cursor)
		}
	}, [cursor, value])

	const handleInputChange = (e) => {
		let data = {
			label: e.target.value,
			value: e.target.value
		}
		onChange(data)
		filterNames(e)
		setCursor(e.target.selectionStart)
	}

	return (
		<div className={`form-group relative ${className}`}>
			{label && <label className="label">{label}</label>}
			<div className="input-outer">
				<div className="dropsearch" ref={dropdownRef}>
					<div className="dropsearchinput">
						<input
							ref={ref}
							id={id}
							type="text"
							placeholder={placeholder}
							value={value}
							className="form-control searchInput"
							onChange={handleInputChange}
							onKeyDown={filterNames}
							onClick={handleShowItems}
							autoComplete={autoComplete}
							onFocus={onFocus}
							onBlur={onBlur}
							autoFocus={autoFocus}
						/>
						<FontAwesomeIcon
							icon={faAngleDown}
							className="drop-icons"
							onClick={handleShowItems}
						/>
					</div>
					{showItems && (
						<div className="listbox multiple-range absolute">
							<ul>
								{filteredItems.length === 0 &&
									options.map((list) => (
										<li
											key={list.value}
											onClick={() => handleValue(list)}
											className="text-[14px]"
										>
											{list.label}
										</li>
									))}
								{filteredItems.length !== 0 &&
									filteredItems
										.filter((list) =>
											list.label
												.toLowerCase()
												.includes(value.toLowerCase())
										)
										.map((filteredItem) => (
											<li
												key={filteredItem.label}
												onClick={() =>
													handleValue(filteredItem)
												}
												className="text-[14px]"
											>
												{filteredItem.label}
											</li>
										))}
							</ul>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

SimpleDropDown.propTypes = {
	label: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			// value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired
		})
	).isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	// value: PropTypes.string,
	id: PropTypes.string,
	className: PropTypes.string,
	autoComplete: PropTypes.string,
	isDisabled: PropTypes.bool,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	autoFocus: PropTypes.bool
}

export default React.memo(SimpleDropDown)
