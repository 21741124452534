import { useEffect } from 'react'

const useGlobalKeyPress = (callback) => {
	useEffect(() => {
		const handleKeyPress = (event) => {
			if (typeof callback === 'function') {
				callback(event)
			}
		}

		window.addEventListener('keydown', handleKeyPress)

		return () => {
			window.removeEventListener('keydown', handleKeyPress)
		}
	}, [callback])
}

export default useGlobalKeyPress
