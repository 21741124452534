import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { gql } from '@apollo/client'
import client from '../../utils/GraphQl/apolloClient'
export const fetchEmailServerSettings = createAsyncThunk(
	'emailServerSettings/fetchEmailServerSettings',
	async (_, { getState }) => {
		try {
			const authToken = getState().Auth.token
			const query = gql`
				query GetMultipleSettings($keys: [String]) {
					getMultipleSettings(keys: $keys) {
						status
						message
						data
						errors
					}
				}
			`
			const { data } = await client.query({
				query,
				context: { headers: { authorization: authToken } },
				variables: {
					keys: [
						'appsetting_smtp_hostname',
						'appsetting_smtp_port',
						'appsetting_smtp_reply_to_email',
						'appsetting_smtp_sender_email',
						'appsetting_smtp_email',
						'appsetting_smtp_password',
						'appsetting_smtp_username',
						'appsetting_smtp_encryption_check',
						'appsetting_smtp_authentication',
						'appsetting_smtp_sender_display_name'
					]
				},
				fetchPolicy: 'network-only'
			})
			return data
		} catch (error) {
			throw new Error('Failed to fetch Email-Server settings')
		}
	}
)

const emailServerSettings = createSlice({
	name: 'emailServerSettings',
	initialState: {
		data: null,
		loading: false,
		error: null
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchEmailServerSettings.pending, (state) => {
				state.loading = true
			})
			.addCase(fetchEmailServerSettings.fulfilled, (state, action) => {
				state.loading = false
				state.data = action.payload
				state.error = null
			})
			.addCase(fetchEmailServerSettings.rejected, (state, action) => {
				state.loading = false
				state.error = action.error.message
			})
	}
})

export default emailServerSettings.reducer
