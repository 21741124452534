import { gql } from '@apollo/client'
export const DISABLE_USER_MUTATION = gql`
	mutation ($user_id: JSON) {
		disableUser(user_id: $user_id) {
			errors
			status
			message
		}
	}
`
