import React from 'react'

const NotificationList = ({ fillColor, width, height, className }) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		viewBox="0 0 512 512"
		className={className}
		// {...rest}
	>
		<g>
			<g>
				<g>
					<path
						d="M101.134,349.625H19.501C8.749,349.625,0,358.373,0,369.126v81.633c0,10.753,8.749,19.501,19.501,19.501h81.633
                                c10.753,0,19.501-8.749,19.501-19.501v-81.633C120.635,358.373,111.887,349.625,101.134,349.625z M105.634,450.759
                                c0,2.439-2.061,4.5-4.5,4.5H19.501c-2.439,0-4.5-2.061-4.5-4.5v-81.633c0-2.439,2.061-4.5,4.5-4.5h81.633
                                c2.439,0,4.5,2.061,4.5,4.5V450.759z"
					/>
					<path
						d="M482.831,207.263H213.78c-16.084,0-29.169,13.085-29.169,29.169v62.298c0,16.084,13.085,29.169,29.169,29.169h269.051
                                c16.084,0,29.169-13.085,29.169-29.169v-62.298C512,220.348,498.914,207.263,482.831,207.263z M496.999,298.73
                                c0,7.813-6.355,14.168-14.168,14.168H213.78c-7.813,0-14.168-6.355-14.168-14.168v-62.298c0-7.812,6.355-14.168,14.168-14.168
                                h269.051c7.813,0,14.168,6.355,14.168,14.168V298.73z"
					/>
					<path
						d="M228.249,125.22c0,4.142,3.358,7.5,7.5,7.5h225.113c4.142,0,7.5-3.358,7.5-7.5c0-4.142-3.358-7.5-7.5-7.5H235.749
                                C231.607,117.72,228.249,121.077,228.249,125.22z"
					/>
					<path
						d="M460.861,260.081H235.749c-4.142,0-7.5,3.357-7.5,7.5c0,4.143,3.358,7.5,7.5,7.5h225.113c4.142,0,7.5-3.357,7.5-7.5
                                C468.362,263.438,465.004,260.081,460.861,260.081z"
					/>
					<path
						d="M468.361,409.942c0-4.143-3.358-7.5-7.5-7.5H235.749c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h225.113
                                C465.004,417.442,468.361,414.085,468.361,409.942z"
					/>
					<path
						d="M162.859,190.23c-10.706-9.165-26.872-7.911-36.035,2.796l-17.47,20.409c-5.049-3.948-11.301-6.173-17.887-6.173H29.169
                                C13.085,207.263,0,220.348,0,236.432v62.298c0,16.084,13.085,29.169,29.169,29.169h62.297c16.084,0,29.169-13.085,29.169-29.169
                                v-19.869l45.019-52.593c4.439-5.186,6.594-11.791,6.065-18.598C171.191,200.864,168.045,194.671,162.859,190.23z M105.635,298.73
                                L105.635,298.73c-0.001,7.813-6.356,14.168-14.169,14.168H29.169c-7.812,0-14.168-6.355-14.168-14.168v-62.298
                                c0-7.812,6.355-14.168,14.168-14.168c67.693,0,65.532-0.868,70.412,2.587l-16.686,19.494c-5.284-6.868-5.71-7.548-7.482-9.243
                                c-15.104-14.443-40.299-5.5-42.997,15.179c-0.883,6.77,0.924,13.478,5.086,18.888l24.078,31.3
                                c1.179,1.532,2.51,2.909,3.963,4.115c4.752,3.942,10.748,5.851,15.636,5.851c13.875,0,19.268-7.99,24.455-14.05V298.73z
                                M154.258,216.512l-64.403,75.24c-4.341,5.07-12.292,4.894-16.386-0.429l-24.078-31.301c-7.11-9.237,3.9-21.424,13.813-15.466
                                c2.306,1.386,2.392,1.906,13.435,16.261c2.889,3.755,8.532,3.939,11.643,0.304l27.034-31.581
                                c0.001-0.002,0.002-0.003,0.003-0.005l22.899-26.753c3.786-4.421,10.462-4.938,14.885-1.155c2.142,1.834,3.442,4.393,3.66,7.204
                                C156.982,211.642,156.092,214.37,154.258,216.512z"
					/>
					<path
						d="M29.169,185.537h62.297c16.084,0,29.169-13.085,29.169-29.169V136.5l45.019-52.593
                                c9.164-10.706,7.911-26.872-2.795-36.036c-10.707-9.165-26.871-7.91-36.036,2.796l-17.47,20.409
                                c-5.049-3.949-11.302-6.173-17.887-6.173H29.169C13.085,64.903,0,77.987,0,94.071v62.297
                                C0,172.452,13.085,185.537,29.169,185.537z M138.218,60.421c3.786-4.422,10.462-4.94,14.885-1.156
                                c4.421,3.786,4.939,10.464,1.154,14.886L89.855,149.39c-4.314,5.04-12.268,4.926-16.386-0.428l-24.077-31.3
                                c-5.554-7.218,0.068-17.717,9.253-16.938c5.91,0.501,7.122,3.598,17.995,17.731c2.94,3.821,8.596,3.865,11.643,0.304
                                C93.763,112.357,133.148,66.346,138.218,60.421z M15.001,94.071c0-7.813,6.355-14.168,14.168-14.168
                                c67.693,0,65.532-0.868,70.413,2.587l-16.687,19.494c-5.229-6.798-5.799-7.708-7.94-9.659
                                c-15.244-13.88-39.875-4.82-42.539,15.594c-0.883,6.77,0.924,13.478,5.086,18.888l24.077,31.299
                                c9.98,12.974,29.225,13.24,39.672,1.037l4.382-5.12v2.344c0,7.813-6.355,14.168-14.168,14.168H29.169
                                c-7.812,0-14.168-6.355-14.168-14.168V94.071z"
					/>
					<path
						d="M482.831,64.903H365.806c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h117.025c7.813,0,14.168,6.355,14.168,14.168
                                v62.297c0,7.813-6.355,14.168-14.168,14.168H213.78c-7.813,0-14.168-6.355-14.168-14.168V94.071
                                c0-7.813,6.355-14.168,14.168-14.168h117.025c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5H213.78
                                c-16.084,0-29.169,13.085-29.169,29.169v62.297c0,16.084,13.085,29.169,29.169,29.169h269.051
                                c16.084,0,29.169-13.085,29.169-29.169V94.071C512,77.987,498.914,64.903,482.831,64.903z"
					/>
					<path
						d="M482.831,349.625H213.78c-16.084,0-29.169,13.085-29.169,29.169v62.297c0,16.084,13.085,29.17,29.169,29.17h117.025
                                c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5H213.78c-7.813,0-14.168-6.355-14.168-14.169v-62.297
                                c0-7.813,6.355-14.168,14.168-14.168h269.051c7.813,0,14.168,6.355,14.168,14.168v62.297c0,7.813-6.355,14.169-14.168,14.169
                                H365.806c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h117.025c16.084,0,29.169-13.086,29.169-29.17v-62.297
                                C512,362.71,498.914,349.625,482.831,349.625z"
					/>
				</g>
			</g>
		</g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
	</svg>
)
export default NotificationList
