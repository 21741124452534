import image from '../../../assets/img/bg1.jpg'
import { NavLink } from 'react-router-dom'
import '../../../assets/css/FormBanner/index.css'
export default function FormBanner() {
	return (
		<div className="hidden flex-1 lg:block">
			<div className="autho-banner relative h-full">
				{' '}
				<img
					src={image}
					alt="Auth Images"
					className="auth-img h-full w-full object-cover opacity-30"
					style={{ mixBlendMode: 'luminosity' }}
				/>
				<div className="autho-banner-text absolute bottom-0 left-0 max-w-[800px] p-[30px] text-white">
					<h2 className="h2 !text-white ">
						Unlock the Power of Governance, Risk, Compliance, and
						Assurance with Risk Wise.
					</h2>
					<p className="!text-white  ">
						<b>Seamless Integration</b>: Risk Wise seamlessly
						integrates all facets of GRC into a unified platform,
						enhancing collaboration and boosting efficiency across
						your organization.
					</p>
					<p className="text-white  ">
						<b>Real-time Insights</b>: Gain actionable insights with
						our comprehensive analytics, providing the foresight
						needed to make informed decisions.
					</p>
					<p className="text-white  ">
						<b>Assured Compliance</b>: Navigate the complex
						regulatory landscape effortlessly while ensuring
						adherence to industry standards.
					</p>
					<p className="text-white  ">
						<b>Risk Mitigation</b>: Identify and mitigate risks
						proactively, protecting your organization from potential
						threats.
					</p>
					<p className="text-white  ">
						<b>Audits Made Easy</b>: Streamline audit processes and
						achieve compliance with ease.
					</p>
					<p className="text-white  ">
						<b>User-friendly Interface</b>: Our intuitive design
						ensures that everyone in your organization can harness
						the power of Risk Wise.
					</p>
					<p className="text-white  ">
						&copy;2019-2023 All Rights Reserved. Risk Wise &reg; is
						a registered trademark of ThinkFirm.
					</p>
					<ul className="flex gap-2">
						<li>
							<NavLink
								className="mb-0 !text-[14px]/[21px] text-[#fff] underline"
								to="/policy"
							>
								Privacy Policy
							</NavLink>
						</li>
						<li>
							<NavLink
								className="mb-0 !text-[14px]/[21px] text-[#fff] underline"
								to="/"
							>
								Terms and Conditions
							</NavLink>
						</li>
						<li>
							<NavLink
								className="mb-0 !text-[14px]/[21px] text-[#fff] underline"
								to=""
							>
								About ThinkFirm
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}
