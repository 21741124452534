import React from 'react'
import PropTypes from 'prop-types'
import '../../../assets/css/Avatar/index.css'
const Avatar = ({ className, image, alt, size }) => {
	const defaultSize = 'md'
	const validSizes = ['xl', 'lg', 'sm', 'xs']

	const avatarSize = validSizes.includes(size) ? size : defaultSize
	const avatarSizeClass = `avatar-${avatarSize}`

	return (
		<div className={`avatar ${avatarSizeClass} ${className}`}>
			<div className="h-full w-full">
				<img src={image} alt={alt} />
			</div>
		</div>
	)
}

Avatar.propTypes = {
	className: PropTypes.string,
	image: PropTypes.string.isRequired,
	alt: PropTypes.string,
	size: PropTypes.oneOf(['xl', 'lg', 'sm', 'xs', 'md'])
}
Avatar.defaultProps = {
	alt: '',
	size: 'md'
}
export default Avatar
