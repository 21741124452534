import { gql } from '@apollo/client'
export const FORGOTPASSWORD_MUTATION = gql`
	mutation ForgetPassword($input: forgetPasswordInput!) {
		forgetPassword(input: $input) {
			status
			message
			errors
		}
	}
`
