import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import PageAlerts from '../../PageAlerts'
import TextBox from '../../../components/ui/TextBox'
import ElasticTextBox from '../../../components/ui/Elastic-textbox'
import Attachment from '../../../components/ui/Attachments'
import KeyValueDropDown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import Simpledropdown from '../../../components/Dropdowns/Simpledropdown'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import Button from '../../../components/ui/Buttons/Button'
import ExistingHoldingCompanies from '../ExistingHoldingCompanies'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import CommitteeChairman from '../CommiteChairman'
import { logoutUser } from '../../../store/AuthSlice/AuthSlice'
import {
	FILE_UPLOADING_CONTEXT,
	UPDATE_ORGANIZATION_CONTEXT
} from '../../../utils/GraphQl/OrganizationContext'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import ProgressBar from '../../ProgressBar'
import LookupModal from '../LookupModal'
import ExistingExtraLocations from '../ExistingExtraLocations'
import ExistingSubsidaries from '../ExistingSubsidaries'
import ChairmanTable from '../ChairmanTable'
import MembersTable from '../MembersTable/Members'
import Committee from '../Committee'
import CommitteeMembers from '../CommitteeMembers'
import FalseAttachment from '../../../components/ui/FalseAttachments'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const organisationNameDocumentOptions = [
	{ label: 'Trade License', value: 'trade license' },
	{ label: 'MOA', value: 'memorandum of association' }
]
const BusinessSector = [
	{ label: 'IT', value: 'it' },
	{ label: 'MEDICAL', value: 'medical' }
]
const businessSectorDocumentOptions = [
	{ label: 'Trade License Application', value: 'trade license application' },
	{ label: 'MOA', value: 'Memorandum of Association' },
	{
		label: 'Industry Specific License And Permit',
		value: 'Industry Specific License And Permit'
	}
]
const RegisteredAddressDocumentOptions = [
	{ label: 'Ejari Certificate', value: 'ejari certificate' },
	{
		label: 'Tenancy Contract or Lease Agreement',
		value: 'Trade License Application'
	},
	{ label: 'NOC', value: 'No objection certificate' },
	{ label: 'Utility Bill', value: 'Utility Bill' }
]
const Edit = ({
	setDrawerstate,
	setDataTableData,
	DataTableData,
	selectedData,
	setSelectedData
}) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [loader, setLoader] = useState(false)
	const [size, setSize] = useState('60%')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const dispatch = useDispatch()
	const [lookUp, setLookUp] = useState(false)
	const [update_organization_context] = useMutation(
		UPDATE_ORGANIZATION_CONTEXT
	)
	const [upload_file] = useMutation(FILE_UPLOADING_CONTEXT)
	const [Formdata, setFormdata] = useState({
		organization_name: selectedData[0]?.organization_name ?? '',
		information_completion:
			selectedData[0]?.data?.information_completion ?? '',
		alternate_name_of_organization:
			selectedData[0]?.data?.organization_overview
				?.alternate_name_of_organization ?? '',
		history_behind_organization_name:
			selectedData[0]?.data?.organization_overview
				?.history_behind_organization_name ?? '',
		organization_name_doctype:
			selectedData[0]?.data?.organization_overview
				?.organization_name_doctype ?? '',
		organization_mission:
			selectedData[0]?.data?.organization_overview
				?.organization_mission ?? '',
		organization_vision:
			selectedData[0]?.data?.organization_overview?.organization_vision ??
			'',
		organization_goals:
			selectedData[0]?.data?.organization_overview?.organization_goals ??
			'',
		organization_objectives:
			selectedData[0]?.data?.organization_overview
				?.organization_objectives ?? '',
		business_sector:
			selectedData[0]?.data?.organization_overview?.business_sector ?? '',
		business_sector_doctype:
			selectedData[0]?.data?.organization_overview
				?.business_sector_doctype ?? '',
		address_line_1:
			selectedData[0]?.data?.organization_overview?.address_line_1 ?? '',
		address_line_2:
			selectedData[0]?.data?.organization_overview?.address_line_2 ?? '',
		floor_number:
			selectedData[0]?.data?.organization_overview?.floor_number ?? '',
		latitude: selectedData[0]?.data?.organization_overview?.latitude ?? '',
		longitude:
			selectedData[0]?.data?.organization_overview?.longitude ?? '',
		registered_address_country:
			selectedData[0]?.data?.organization_overview
				?.registered_address_country ?? '',
		registered_address_pin:
			selectedData[0]?.data?.organization_overview
				?.registered_address_pin ?? '',
		registered_address_city:
			selectedData[0]?.data?.organization_overview
				?.registered_address_city ?? '',
		registered_address_state:
			selectedData[0]?.data?.organization_overview
				?.registered_address_state ?? '',
		registered_address_doctype:
			selectedData[0]?.data?.organization_overview
				?.registered_address_doctype ?? '',
		public_listed:
			selectedData[0]?.data?.corporate_structure?.public_listed
				?.public_listed ?? '',
		stock_exchange:
			selectedData[0]?.data?.corporate_structure?.public_listed
				?.stock_exchange ?? '',
		board: selectedData[0]?.data?.corporate_structure?.board?.board ?? '',
		organization_supporting_document:
			selectedData[0]?.data?.organization_overview
				?.organization_supporting_document ?? '',
		business_sector_supporting_document:
			selectedData[0]?.data?.organization_overview
				?.business_sector_supporting_document ?? '',
		registered_address_supporting_document:
			selectedData[0]?.data?.organization_overview
				?.registered_address_supporting_document ?? '',
		stock_symbol:
			selectedData[0]?.data?.corporate_structure?.public_listed
				?.stock_symbol ?? ''
	})
	const [organization_name_document, setOrganization_name_document] =
		useState([])
	const [business_sector_document, setBusiness_sector_document] = useState([])
	const [registered_address_document, setRegistered_address_document] =
		useState([])
	const [stockSymbol, setStockSymbol] = useState([])
	useEffect(() => {
		if (
			selectedData[0]?.data?.organization_overview
				?.organization_name_doctype
		) {
			let value =
				selectedData[0]?.data?.organization_overview
					?.organization_name_doctype
			if (value) {
				let index = organisationNameDocumentOptions.findIndex(
					(item) => item.value === value
				)
				HANDLEFORMDATA(
					'organization_name_doctype',
					organisationNameDocumentOptions[index]
				)
			}
		}
		if (selectedData[0]?.data?.organization_overview?.business_sector) {
			let value =
				selectedData[0]?.data?.organization_overview?.business_sector
			if (value) {
				let index = BusinessSector.findIndex(
					(item) => item.value === value
				)
				HANDLEFORMDATA('business_sector', BusinessSector[index])
			}
		}
		if (
			selectedData[0]?.data?.organization_overview
				?.business_sector_doctype
		) {
			let value =
				selectedData[0]?.data?.organization_overview
					?.business_sector_doctype
			if (value) {
				let index = businessSectorDocumentOptions.findIndex(
					(item) => item.value === value
				)
				HANDLEFORMDATA(
					'business_sector_doctype',
					businessSectorDocumentOptions[index]
				)
			}
		}
		if (
			selectedData[0]?.data?.organization_overview
				?.registered_address_doctype
		) {
			let value =
				selectedData[0]?.data?.organization_overview
					?.registered_address_doctype
			if (value) {
				let index = RegisteredAddressDocumentOptions.findIndex(
					(item) => item.value === value
				)
				HANDLEFORMDATA(
					'registered_address_doctype',
					RegisteredAddressDocumentOptions[index]
				)
			}
		}
	}, [])
	const HANDLEFORMDATA = (field, value) => {
		setFormdata((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}
	const updatedItem = {
		organization_name: Formdata.organization_name,
		information_completion: Formdata.information_completion,
		color: 'badge-complete300',
		...selectedData[0]?.data,
		organization_overview: {
			...selectedData[0]?.data?.organization_overview,
			registered_address_supporting_document:
				Formdata?.registered_address_supporting_document,
			organization_supporting_document:
				Formdata?.organization_supporting_document,
			business_sector_supporting_document:
				Formdata.business_sector_supporting_document,
			extra_locations:
				selectedData[0]?.data?.organization_overview?.extra_locations,
			address_line_1: Formdata.address_line_1,
			address_line_2: Formdata.address_line_2,
			alternate_name_of_organization:
				Formdata.alternate_name_of_organization,
			business_sector: Formdata.business_sector?.value,
			business_sector_doctype: Formdata?.business_sector_doctype?.value,
			floor_number: Formdata.floor_number,
			history_behind_organization_name:
				Formdata.history_behind_organization_name,
			latitude: Formdata.latitude,
			longitude: Formdata.longitude,
			organization_goals: Formdata.organization_goals,
			organization_mission: Formdata.organization_mission,
			organization_name: Formdata.organization_name,
			organization_objectives: Formdata.organization_objectives,
			organization_supporting_doctype:
				Formdata.organization_name_doctype?.value,
			organization_vision: Formdata.organization_vision,
			registered_address_doctype:
				Formdata.registered_address_doctype?.value,
			registered_address_city: Formdata.registered_address_city,
			registered_address_country: Formdata.registered_address_country,
			registered_address_pin: Formdata.registered_address_pin,
			registered_address_state: Formdata.registered_address_state
		},
		corporate_structure: {
			...selectedData[0]?.data?.corporate_structure,
			public_listed: {
				public_listed: Formdata.public_listed,
				stock_exchange: Formdata.stock_exchange,
				stock_symbol: Formdata.stock_symbol
			},
			board: {
				...selectedData[0].board,
				board: Formdata.board
			}
		}
	}
	function formatDate(dateString) {
		const date = new Date(dateString)
		const day = String(date.getDate()).padStart(2, '0')
		const month = String(date.getMonth() + 1).padStart(2, '0')
		const year = date.getFullYear()
		return `${day}-${month}-${year}`
	}
	const HandleSubmit = async () => {
		try {
			setLoader(true)
			const response = await update_organization_context({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						id: selectedData[0]?.id,
						data: updatedItem
					}
				}
			})
			setLoader(false)
			let data = await response?.data
			let updateContext = data?.updateContext
			if (updateContext.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'edit_organisation_context',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(
						removeAlerts({ pageId: 'edit_organisation_context' })
					)
				}, 3000)
			} else if (
				updateContext.status !== 200 &&
				updateContext.errors.length > 0
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'edit_organisation_context',
						type: 'danger',
						message: updateContext.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (updateContext.status === 200) {
				setLoader(false)
				let UpdatedAt = formatDate(updateContext?.data?.updated_at)
				setDataTableData((prev) => {
					const index = prev.findIndex(
						(item) => item.id === selectedData[0]?.id
					)
					const updatedItem = {
						...selectedData[0],
						organization_name: Formdata.organization_name,
						information_completion: Formdata.information_completion,
						last_updated_date: UpdatedAt,
						data: {
							organization_overview: {
								...prev[index]?.organization_overview,
								extra_locations:
									selectedData[0]?.data?.organization_overview
										?.extra_locations,
								address_line_1: Formdata.address_line_1,
								address_line_2: Formdata.address_line_2,
								alternate_name_of_organization:
									Formdata.alternate_name_of_organization,
								business_sector:
									Formdata.business_sector?.value,
								business_sector_doctype:
									Formdata?.business_sector_doctype?.value,
								floor_number: Formdata.floor_number,
								history_behind_organization_name:
									Formdata.history_behind_organization_name,
								latitude: Formdata.latitude,
								longitude: Formdata.longitude,
								organization_goals: Formdata.organization_goals,
								organization_mission:
									Formdata.organization_mission,
								organization_name: Formdata.organization_name,
								organization_objectives:
									Formdata.organization_objectives,
								organization_name_doctype:
									Formdata.organization_name_doctype?.value,
								organization_vision:
									Formdata.organization_vision,
								registered_address_doctype:
									Formdata.registered_address_doctype?.value,
								registered_address_city:
									Formdata.registered_address_city,
								registered_address_country:
									Formdata.registered_address_country,
								registered_address_pin:
									Formdata.registered_address_pin,
								registered_address_state:
									Formdata.registered_address_state,
								organization_supporting_document:
									Formdata.organization_supporting_document,
								business_sector_supporting_document:
									Formdata.business_sector_supporting_document,
								registered_address_supporting_document:
									Formdata.registered_address_supporting_document
							},
							corporate_structure: {
								...selectedData[0]?.data?.corporate_structure,
								public_listed: {
									public_listed: Formdata.public_listed,
									stock_exchange: Formdata.stock_exchange,
									stockSymbol: Formdata.stock_symbol
								},
								board: {
									...selectedData[0].board,
									board: Formdata.board
								}
							}
						}
					}
					const newData = [...prev]
					newData[index] = updatedItem
					setSelectedData([updatedItem])
					setTimeout(() => {
						handleDrawerClose()
					}, 3000)

					return newData
				})

				dispatch(
					addAlerts({
						pageId: 'edit_organisation_context',
						type: 'success',
						message: updateContext.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'edit_organisation_context',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const handleSelectedDocument = async (key, file) => {
		let variable = {
			folderName: '',
			oldFile: '',
			file: {}
		}
		if (key === 'organization_supporting_document') {
			variable = {
				folderName: 'organization-supporting-document',
				oldFile: Formdata.organization_supporting_doctype || '',
				file: file[0]
			}
			setOrganization_name_document([...file])
		} else if (key === 'business_sector_supporting_document') {
			variable = {
				folderName: 'business-sector-supporting-document',
				oldFile: Formdata.business_sector_supporting_document || '',
				file: file[0]
			}
			setBusiness_sector_document([...file])
		} else if (key === 'registered_address_supporting_document') {
			variable = {
				folderName: 'registered-address-supporting-document',
				oldFile: Formdata.registered_address_supporting_document || '',
				file: file[0]
			}
			setRegistered_address_document([...file])
		} else if ((key = 'stock_symbol')) {
			variable = {
				folderName: 'stock-symbol',
				oldFile: Formdata.stock_symbol || '',
				file: file[0]
			}
			setStockSymbol([...file])
		}
		try {
			const response = await upload_file({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: variable
			})
			setLoader(false)
			let data = await response?.data
			let uploadFile = data?.uploadFile
			if (uploadFile.status === 200) {
				let value = uploadFile.data
				HANDLEFORMDATA(key, value)
			} else if (
				uploadFile.status !== 200 &&
				uploadFile.errors.length > 0
			) {
				return
			}
		} catch (err) {
			console.log('err')
		}
	}
	const handleRemoveDocument = (key, file) => {
		if (key === 'organization_supporting_document') {
			HANDLEFORMDATA('organization_supporting_document', '')
			let filteredarray = organization_name_document.filter(
				(item) => item.id !== file.id
			)
			setOrganization_name_document(filteredarray)
		} else if (key === 'business_sector_supporting_document') {
			HANDLEFORMDATA('business_sector_supporting_document', '')
			let filteredarray = business_sector_document.filter(
				(item) => item.id !== file.id
			)
			setBusiness_sector_document(filteredarray)
		} else if (key === 'registered_address_supporting_document') {
			HANDLEFORMDATA('registered_address_supporting_document', '')
			let filteredarray = registered_address_document.filter(
				(item) => item.id !== file.id
			)
			setRegistered_address_document(filteredarray)
		} else if ((key = 'stock_symbol')) {
			HANDLEFORMDATA('stock_symbol', '')
			let filteredarray = stockSymbol.filter(
				(item) => item.id !== file.id
			)
			setStockSymbol(filteredarray)
		}
	}
	const FORM = (
		<form
			encType="multipart/form-data"
			className=" flex  flex-col justify-start"
		>
			<>
				<div className="flex w-full flex-col">
					<h5>Organization Overview </h5>
					<hr />
					<div className="flex  gap-2">
						<div className="flex  w-1/2 flex-col">
							<TextBox
								name={'organization_name'}
								id={'organization_name'}
								value={Formdata.organization_name}
								labelName={'Organization Name'}
								onChange={(data) =>
									HANDLEFORMDATA('organization_name', data)
								}
							/>
							<div>
								<Attachment
									labelName={'Supporting Document'}
									id="organization_name_doc"
									onSelect={(file) =>
										handleSelectedDocument(
											'organization_supporting_document',
											file
										)
									}
									onRemove={(file) =>
										handleRemoveDocument(
											'organization_supporting_document',
											file
										)
									}
									files={organization_name_document}
								/>
								{Formdata.organization_supporting_document &&
									organization_name_document.length == 0 && (
										<FalseAttachment
											filename={
												Formdata?.organization_supporting_document.split(
													'/'
												)[2]
											}
											onFileRemove={() =>
												HANDLEFORMDATA(
													'organization_supporting_document',
													''
												)
											}
										/>
									)}
							</div>
							<KeyValueDropDown
								options={organisationNameDocumentOptions}
								label="Document Type"
								value={
									Formdata?.organization_name_doctype?.label
								}
								onChange={(data) =>
									HANDLEFORMDATA(
										'organization_name_doctype',
										data
									)
								}
							/>
							<ElasticTextBox
								name={'alternate_name_of_organization'}
								id={'alternate_name_of_organization'}
								value={Formdata?.alternate_name_of_organization}
								labelName={'Alternate Name of Organization'}
								onChange={(data) =>
									HANDLEFORMDATA(
										'alternate_name_of_organization',
										data
									)
								}
							/>

							<ElasticTextBox
								name={'organization_mission'}
								id={'organization_mission'}
								value={Formdata?.organization_mission}
								labelName={'Organization Mission'}
								onChange={(data) =>
									HANDLEFORMDATA('organization_mission', data)
								}
							/>

							<ElasticTextBox
								name={'organization_goals'}
								id={'organization_goals'}
								value={Formdata?.organization_goals}
								labelName={'Organization Goals'}
								onChange={(data) =>
									HANDLEFORMDATA('organization_goals', data)
								}
							/>

							<ElasticTextBox
								name={'organization_objectives'}
								id={'organization_objectives'}
								value={Formdata?.organization_objectives}
								labelName={'Organization Objectives'}
								onChange={(data) =>
									HANDLEFORMDATA(
										'organization_objectives',
										data
									)
								}
							/>
							<Button
								className="btn-large btn-primary w-fit"
								children={'Add'}
								onClick={() => setLookUp(true)}
							/>
						</div>

						<div className="w-1/2">
							<KeyValueDropDown
								options={BusinessSector}
								label="Business Sector "
								value={Formdata?.business_sector?.label}
								onChange={(data) =>
									HANDLEFORMDATA('business_sector', data)
								}
							/>
							<div>
								<Attachment
									labelName={'Supporting Document'}
									id="business_sector_doc"
									onSelect={(file) =>
										handleSelectedDocument(
											'business_sector_supporting_document',
											file
										)
									}
									onRemove={(file) =>
										handleRemoveDocument(
											'business_sector_supporting_document',
											file
										)
									}
									files={business_sector_document}
								/>
								{Formdata.business_sector_supporting_document &&
									business_sector_document.length == 0 && (
										<FalseAttachment
											filename={
												Formdata?.business_sector_supporting_document.split(
													'/'
												)[2]
											}
											onFileRemove={() =>
												HANDLEFORMDATA(
													'business_sector_supporting_document',
													''
												)
											}
										/>
									)}
							</div>

							<KeyValueDropDown
								options={businessSectorDocumentOptions}
								label="Document Type"
								value={Formdata?.business_sector_doctype?.label}
								onChange={(data) =>
									HANDLEFORMDATA(
										'business_sector_doctype',
										data
									)
								}
							/>
							<ElasticTextBox
								name={'history_behind_organization_name'}
								id={'history_behind_organization_name'}
								value={
									Formdata?.history_behind_organization_name
								}
								labelName={'History Behind Organization Name'}
								onChange={(data) =>
									HANDLEFORMDATA(
										'history_behind_organization_name',
										data
									)
								}
							/>
							<ElasticTextBox
								name={'organization_vision'}
								id={'organization_vision'}
								value={Formdata?.organization_vision}
								labelName={'Organization Vision'}
								onChange={(data) =>
									HANDLEFORMDATA('organization_vision', data)
								}
							/>

							<ElasticTextBox
								name={'address_line_1'}
								id={'address_line_1'}
								value={Formdata?.address_line_1}
								labelName={'Addresss Line 1'}
								onChange={(data) =>
									HANDLEFORMDATA('address_line_1', data)
								}
							/>
							<ElasticTextBox
								name={'address_line_2'}
								id={'address_line_2'}
								value={Formdata?.address_line_2}
								labelName={'Addresss Line 2'}
								onChange={(data) =>
									HANDLEFORMDATA('address_line_2', data)
								}
							/>
						</div>
					</div>
					<div></div>
					{lookUp && (
						<LookupModal
							setShowModal={setLookUp}
							HANDLEFORMDATA={HANDLEFORMDATA}
						/>
					)}
					<div className="flex  gap-2">
						<div className="flex w-1/2 flex-col">
							<TextBox
								name={'floor_number'}
								id={'floor_nnumber'}
								value={Formdata?.floor_number}
								labelName={'Floor Number'}
								onChange={(data) =>
									HANDLEFORMDATA('floor_number', data)
								}
							/>

							<TextBox
								name={'longitude'}
								id={'longitude'}
								value={Formdata?.longitude}
								labelName={'Longitude'}
								onChange={(data) =>
									HANDLEFORMDATA('longitude', data)
								}
							/>
							<TextBox
								name={'registered_address_city'}
								id={'registered_address_city'}
								value={Formdata?.registered_address_city}
								labelName={'City'}
								onChange={(data) =>
									HANDLEFORMDATA(
										'registered_address_city',
										data
									)
								}
							/>
							<TextBox
								name={'registered_address_state'}
								id={'registered_address_state'}
								value={Formdata?.registered_address_state}
								labelName={'State'}
								onChange={(data) =>
									HANDLEFORMDATA(
										'registered_address_state',
										data
									)
								}
							/>
							<div>
								<Attachment
									labelName={'Supporting Document'}
									id="registered_address_supporting_document"
									onSelect={(file) =>
										handleSelectedDocument(
											'registered_address_supporting_document',
											file
										)
									}
									onRemove={(file) =>
										handleRemoveDocument(
											'registered_address_supporting_document',
											file
										)
									}
									files={registered_address_document}
								/>
								{Formdata.registered_address_supporting_document &&
									registered_address_document.length == 0 && (
										<FalseAttachment
											filename={
												Formdata?.registered_address_supporting_document.split(
													'/'
												)[2]
											}
											onFileRemove={() =>
												HANDLEFORMDATA(
													'registered_address_supporting_document',
													''
												)
											}
										/>
									)}
							</div>
						</div>

						<div className="flex w-1/2 flex-col">
							<TextBox
								name={'latitude'}
								id={'latitude'}
								value={Formdata?.latitude}
								labelName={'Latitude'}
								onChange={(data) =>
									HANDLEFORMDATA('latitude', data)
								}
							/>

							<TextBox
								name={'registered_address_country'}
								id={'registered_address_country'}
								value={Formdata?.registered_address_country}
								labelName={'Country'}
								onChange={(data) =>
									HANDLEFORMDATA(
										'registered_address_country',
										data
									)
								}
							/>
							<TextBox
								name={'registered_address_pin'}
								id={'registered_address_pin'}
								value={Formdata?.registered_address_pin}
								labelName={'Pin'}
								onChange={(data) =>
									HANDLEFORMDATA(
										'registered_address_pin',
										data
									)
								}
							/>

							<KeyValueDropDown
								options={RegisteredAddressDocumentOptions}
								label="Document Type"
								value={
									Formdata?.registered_address_doctype?.label
								}
								onChange={(data) =>
									HANDLEFORMDATA(
										'registered_address_doctype',
										data
									)
								}
							/>
						</div>
					</div>

					<ExistingExtraLocations
						originalData={selectedData}
						setSelectedParentsData={setSelectedData}
					/>
					<div className="flex w-full flex-col ">
						<h5>Corporate Structure </h5>
						<hr />

						<ExistingSubsidaries
							originalData={selectedData}
							setSelectedParentsData={setSelectedData}
						/>

						<ExistingHoldingCompanies
							originalData={selectedData}
							setSelectedParentsData={setSelectedData}
						/>
						<div className="flex  w-full gap-2">
							<div className="flex w-1/2 flex-col">
								<Simpledropdown
									options={['Yes', 'No']}
									label="Public Listed"
									value={Formdata?.public_listed}
									onChange={(data) =>
										HANDLEFORMDATA('public_listed', data)
									}
								/>
								{Formdata.public_listed == 'Yes' && (
									<>
										<Attachment
											labelName={'Stock Symbol'}
											id="stock_symbol"
											onSelect={(file) =>
												handleSelectedDocument(
													'stock_symbol',
													file
												)
											}
											onRemove={(file) =>
												handleRemoveDocument(
													'stock_symbol',
													file
												)
											}
											files={stockSymbol}
										/>
										{Formdata.stock_symbol &&
											stockSymbol.length == 0 && (
												<FalseAttachment
													filename={
														Formdata?.stock_symbol.split(
															'/'
														)[2]
													}
													onFileRemove={() =>
														HANDLEFORMDATA(
															'stock_symbol',
															''
														)
													}
												/>
											)}
									</>
								)}
							</div>
							<div className="flex w-1/2 flex-col">
								<Simpledropdown
									options={['Yes', 'No']}
									label="Board"
									value={Formdata?.board}
									onChange={(data) =>
										HANDLEFORMDATA('board', data)
									}
								/>

								{Formdata.public_listed == 'Yes' && (
									<>
										<TextBox
											name={'stock_exchange'}
											id={'stock_exchange'}
											value={Formdata?.stock_exchange}
											labelName={'Stock Exchange'}
											onChange={(data) =>
												HANDLEFORMDATA(
													'stock_exchange',
													data
												)
											}
										/>
									</>
								)}
							</div>
						</div>

						<div className="flex gap-2"></div>

						{Formdata?.board == 'Yes' && (
							<>
								<ChairmanTable
									originalData={selectedData}
									setSelectedParentsData={setSelectedData}
								/>
								<MembersTable
									originalData={selectedData}
									setSelectedParentsData={setSelectedData}
								/>
							</>
						)}
						<Committee
							originalData={selectedData}
							setSelectedParentsData={setSelectedData}
						/>
						<CommitteeMembers
							originalData={selectedData}
							setSelectedParentsData={setSelectedData}
						/>
						<CommitteeChairman
							originalData={selectedData}
							setSelectedParentsData={setSelectedData}
						/>
					</div>
				</div>
			</>
		</form>
	)
	const handleDrawerClose = () => {
		dispatch(removeAlerts({ pageId: 'edit_organisation_context' }))
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setDrawerstate(false)
	}
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])

	useEffect(() => {
		setBody(FORM)
	}, [
		showDrawer,
		Formdata,
		organization_name_document,
		lookUp,
		business_sector_document,
		registered_address_document,
		selectedData,
		stockSymbol
	])

	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "> </h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>
				<IconButton
					onClick={HandleSubmit}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)
	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)
	return (
		<>
			{loader && <ProgressBar />}
			<PageAlerts pageId={'edit_organisation_context'} />
			<Drawer
				label="Edit"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default Edit
