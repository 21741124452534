import React, { useEffect, useState } from 'react'
import Modal from '../../../../components/ui/modals'
import ElasticTextbox from '../../../../components/ui/Elastic-textbox'
import TextBox from '../../../../components/ui/TextBox'
import {
	faBook,
	faSave,
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../../../components/ui/Buttons/Icon-button'
import { useDispatch } from 'react-redux'
import { addAlerts } from '../../../../store/AlertSlice'
const CATEGORIES = [
	{ label: 'Select', value: 'Select' },
	{ label: 'Natural Disasters', value: 'natural_disasters' },
	{ label: 'Technological Risks', value: 'technological_risks' },
	{ label: 'Health Risks', value: 'health_risks' },
	{ label: 'Environmental Risks', value: 'environmental_risks' },
	{ label: 'Economic Risks', value: 'economic_risks' },
	{ label: 'Operational Risks', value: 'operational_risks' },
	{ label: 'Financial Risks', value: 'financial_risks' },
	{ label: 'Strategic Risks', value: 'strategic_risks' },
	{ label: 'Safety Risks', value: 'safety_risks' },
	{ label: 'Political Risks', value: 'political_risks' },
	{ label: 'Social Risks', value: 'social_risks' },
	{ label: 'Project Risks', value: 'project_risks' }
]
const EditLoadedRisk = ({
	OriginalData,
	setOriginalData,
	setShowModal,
	selectedData
}) => {
	const [overLay, setOverLay] = React.useState('')
	const [scrollable, setScrollable] = React.useState(true)
	const dispatch = useDispatch()
	const [Formdata, setFormdata] = useState({
		category: selectedData[0].catalogue_category || '',
		risk_heading: selectedData[0].risk_heading || '',
		risk_description: selectedData[0].risk_description || ''
	})
	useEffect(() => {
		let index = CATEGORIES.findIndex(
			(item) => item.value == selectedData[0].catalogue_category
		)
		setFormdata({
			...Formdata,
			category: CATEGORIES[index]?.label
		})
	}, [])
	const HandleFormData = (key, value) => {
		setFormdata((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}
	const handleModalClose = () => {
		// console.log(e)
		// e.preventDefault()
		setShowModal(false)
		setScrollable(false)
		setOverLay('false')
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		let PRODUCTS = [...OriginalData]
		// console.log('productys => ',JSON.parse(PRODUCTS))
		let newRisk = {
			id: PRODUCTS.length + 1000,
			risk_category: Formdata.category,
			risk_heading: Formdata.risk_heading,
			risk_description: Formdata.risk_description,
			risk_function: '',
			risk_owner: '',
			inherent_rating: '',
			position: PRODUCTS.length,
			expand_data: [
				{
					label: 'Risk Heading',
					data: ''
				},
				{
					label: 'Risk Description',
					data: ''
				},
				{
					label: 'Risk Function',
					data: ''
				},
				{ label: 'Risk Owner', data: '' }
			],
			treatment_plan: [],
			image: '',
			image1: '',
			residual_rating: ''
		}
		dispatch(
			addAlerts({
				pageId: 'add_risk',
				type: 'success',
				message: 'Data Added successfully',
				icon: faCheck,
				autoclose: 3000
			})
		)

		PRODUCTS.push(newRisk)
		setOriginalData(PRODUCTS)
		localStorage.setItem('products', JSON.stringify(PRODUCTS))
		handleModalClose()
	}
	const FORM = (
		<div className="w-[400px]">
			<TextBox
				id="category"
				labelName="Category"
				readOnly={true}
				onChange={(data) => HandleFormData('category', data)}
				value={Formdata.category}
			/>

			<ElasticTextbox
				id="risk_heading"
				labelName="Risk Heading"
				value={Formdata.risk_heading}
				onChange={(data) => HandleFormData('risk_heading', data)}
			/>
			<ElasticTextbox
				id="risk_description"
				labelName="Risk Description"
				value={Formdata.risk_description}
				onChange={(data) => HandleFormData('risk_description', data)}
			/>
		</div>
	)
	const footer = (
		<>
			<div className="modal-footer flex  items-center justify-between border-t  ">
				<h5 className="invisible font-normal">{'T'}</h5>
				<div className="flex items-center justify-end gap-[3px]">
					<IconButton
						Icon={faBook}
						className="btn-complete btn-large flex gap-1.5"
						type="button"
						onClick={handleModalClose}
						children="Cancel"
					/>
					<IconButton
						Icon={faSave}
						className="btn-success btn-large flex gap-1.5"
						type="button"
						onClick={handleSubmit}
						children="Save Risk"
					/>
				</div>
			</div>
		</>
	)
	return (
		<div>
			<Modal
				modalSize={'xl'}
				title={'Edit Risk'}
				onClose={handleModalClose}
				body={FORM}
				overLay={overLay}
				showModal={true}
				scrollable={scrollable}
				Footer={footer}
			/>
		</div>
	)
}

export default EditLoadedRisk
