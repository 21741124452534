import { gql } from '@apollo/client'

export const EMAIL_SERVER_QUERY = gql`
	query GetSetting($key: String) {
		getSetting(key: $key) {
			status
			message
			data
			errors
		}
	}
`
// export const UPDATE_EMAIL_SERVER_QUERY = gql`
// mutation UpdateSettings($key: String!, $input: EditSettingsInput!) {
// 	updateSettings(key: $key, input: $input) {
// 	  status
// 	  message
// 	  data
// 	  errors
// 	}
//   }
// `

export const UPDATE_EMAIL_SERVER_QUERY = gql`
	mutation UpdateMultipleSettings($input: JSON) {
		updateMultipleSettings(input: $input) {
			status
			message
			data
			errors
		}
	}
`
