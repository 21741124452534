import React, { useState } from 'react'
import AuthLayout from '../../Layouts/AuthLayout'
import FormHeader from '../../components/Common/FormHeader'
import FormBody from '../../components/Common/FormBody'
import FormFooter from '../../components/Common/FormFooter'
import PageAlerts from '../PageAlerts'
import TextBox from '../../components/ui/TextBox'
import Button from '../../components/ui/Buttons/Button'
import Icon from '../../components/ui/icon'
import { useLocation, useNavigate } from 'react-router-dom'
import { RESET_PASSWORD_MUTATION } from '../../utils/GraphQl/ResetPasswordMutation'
import { useMutation } from '@apollo/client'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../store/AuthSlice/AuthSlice'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
const ResetPassword = () => {
	const [userData, setUserData] = useState({
		password: '',
		confirmpassword: ''
	})
	const [resetpassword] = useMutation(RESET_PASSWORD_MUTATION)
	const location = useLocation()
	const token = new URLSearchParams(location.search).get('token')
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [loader, setLoader] = useState(false)
	const handleVerification = async () => {
		try {
			setLoader(true)
			const response = await resetpassword({
				variables: {
					input: {
						password: userData.password,
						confirm_password: userData.confirmpassword,
						token: token
					}
				}
			})
			let data = await response.data
			if (data.resetPassword.status === 400) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'resetpassword',
						type: 'danger',
						message: data.resetPassword.errors.map((msg) => msg),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.resetPassword.status === 429) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'resetpassword',
						type: 'danger',
						message: data.resetPassword.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'resetpassword' }))
					navigate('/forgot-password')
				}, 3000)
			} else if (data.resetPassword.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'resetpassword',
						type: 'danger',
						message: data.resetPassword.errors
							? data.resetPassword.errors.map((msg) => msg)
							: 'inValid Token',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					navigate('/forgot-password')
					dispatch(removeAlerts({ pageId: 'resetpassword' }))
				}, 3000)
			} else if (data.resetPassword.status === 200) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'resetpassword',
						type: 'success',
						message: 'Your password changed successfully.',
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'resetpassword' }))
					navigate('/')
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'resetpassword',
					type: 'danger',
					message: 'Something went wrong.',
					icon: faCheck,
					autoclose: 3000
				})
			)
		}
	}
	const HandleSubmit = (event) => {
		event.preventDefault()
		const PSW_REGEXP =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/
		let isValidPassword = PSW_REGEXP.test(userData.password)

		if (
			!userData.password ||
			!isValidPassword ||
			!userData.confirmpassword ||
			userData.password !== userData.confirmpassword
		) {
			if (!userData.password) {
				dispatch(
					addAlerts({
						pageId: 'resetpassword',
						type: 'danger',
						message: 'Please enter your password.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!isValidPassword) {
				dispatch(
					addAlerts({
						pageId: 'resetpassword',
						type: 'danger',
						message: 'Please enter a valid password.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!userData.confirmpassword) {
				dispatch(
					addAlerts({
						pageId: 'resetpassword',
						type: 'danger',
						message: 'Please enter your confirm password.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (userData.password !== userData.confirmpassword) {
				dispatch(
					addAlerts({
						pageId: 'resetpassword',
						type: 'danger',
						message: 'Both passwords should match.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				dispatch(
					addAlerts({
						pageId: 'resetpassword',
						type: 'danger',
						message:
							'Something went wrong. Please try again after sometime.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
		} else {
			handleVerification()
		}
	}
	const HandleChange = (key, value) => {
		setUserData((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}
	return (
		<>
			<PageAlerts pageId="resetpassword" />
			<AuthLayout>
				<FormHeader
					title="Create New Password"
					message="Welcome! fill the below fields to continue."
				/>
				<FormBody>
					<TextBox
						id="cretae_password"
						name="create_password"
						onChange={(data) => HandleChange('password', data)}
						labelName="Password"
						type={'text'}
						className={'mb-4'}
						value={userData.password}
					/>

					<TextBox
						id={'password'}
						type="password"
						name={'confirm_password'}
						labelName="Confirm Password"
						className={'mb-4'}
						onChange={(data) =>
							HandleChange('confirmpassword', data)
						}
						value={userData.confirmpassword}
					/>

					<div className="mb-3">
						<p className="small-text">
							By clicking <strong>Create Password </strong> below,
							you agree to our Terms and Conditions and Privacy
							Policy.
						</p>
					</div>

					{loader ? (
						<Button
							className="btn-large btn-primary btn-all flex  w-full cursor-not-allowed gap-2"
							isIconBtn={true}
							onClick={() => console.log('')}
						>
							<span className="waiting-loader"> </span> Please
							Wait
						</Button>
					) : (
						<Button
							className="btn-large btn-primary btn-all flex  w-full gap-2"
							isIconBtn={true}
							onClick={HandleSubmit}
						>
							<Icon
								name="line-signup"
								width={'20px'}
								height={'20px'}
								className={'icon-fill-white'}
								viewBox={'0 0 24 24'}
							/>{' '}
							Create Password
						</Button>
					)}
				</FormBody>
				<FormFooter
					message2="Need assistance or have questions"
					linktext2="Contact Support"
					link2="contact-us"
					message1="Don't have an account"
					linktext1="Create an Account"
					link1="register"
				/>
			</AuthLayout>
		</>
	)
}
export default ResetPassword
