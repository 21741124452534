import { gql } from '@apollo/client'
export const CREATE_TABLE_LAYOUT = gql`
	mutation UpdateCreateTableStructure($input: JSON) {
		updateCreateTableStructure(input: $input) {
			status
			message
			errors
			data
		}
	}
`

export const GET_ALL_TABLE_LAYOUTS = gql`
	query GetAllTableStructures {
		getAllTableStructures {
			status
			message
			data
			errors
		}
	}
`
