import { useState, useEffect } from 'react'
import DataTableWrapper from '../../../components/ui/DataTables'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import React from 'react'
import Button from '../../../components/ui/Buttons/Button'
import EditChairman from '../AddBoardMembers'
import { faClose, faEdit } from '@fortawesome/free-solid-svg-icons'
import AddMembers from '../AddBoardMembers'
const ChairmanTable = ({ originalData, setSelectedParentsData }) => {
	const [BoardChairman, setBoardChairman] = useState([])
	const [selectedData, setSelectedData] = useState([])
	const [showEditBoardChairman, setShowEditBoardChairman] = useState(false)
	const [showChairman, setShowChairman] = useState(false)
	const dopdownOptions = ['All']
	useEffect(() => {
		const allChairmains = []
		if (Array.isArray(originalData)) {
			if (originalData.length > 0) {
				originalData?.forEach((item) => {
					item?.data?.corporate_structure?.board?.chairman?.forEach(
						(obj) => {
							if (
								!allChairmains.some(
									(person) => person.id === obj.id
								)
							) {
								allChairmains.push(obj)
							}
						}
					)
				})
				setBoardChairman((prev) => {
					const filteredPrev = prev.filter(
						(prevObj) =>
							!allChairmains.some(
								(newObj) => newObj.id === prevObj.id
							)
					)
					return [...filteredPrev, ...allChairmains]
				})
			}
		}
	}, [originalData])

	const handleFilter = (rowData) => {
		setSelectedParentsData((prev) => {
			const chairmen = prev[0]?.data?.corporate_structure?.board?.chairman
			if (!chairmen) {
				return prev
			}

			const filteredChairmen = chairmen.filter(
				(person) => person.id !== rowData.id
			)
			const updatedFirstElement = {
				...prev[0],
				data: {
					...prev[0]?.data,
					corporate_structure: {
						...prev[0]?.data?.corporate_structure,
						board: {
							...prev[0]?.data?.corporate_structure?.board,
							chairman: filteredChairmen
						}
					}
				}
			}
			const newState = [updatedFirstElement, ...prev.slice(1)]
			let allChairmen = BoardChairman.filter(
				(member) => member.id !== rowData.id
			)
			setBoardChairman(allChairmen)
			return newState
		})
	}
	const ActionBodyTemplate = (rowData) => {
		return (
			<div className="flex justify-between">
				<div className="">
					<IconButton
						type="button"
						className={
							'relative top-0.5 flex h-5 w-6 items-center justify-center rounded-sm border bg-transparent text-sm text-grey-200 hover:bg-transparent hover:text-grey-500'
						}
						onClick={(e) => {
							e.preventDefault()
							setSelectedData([{ ...rowData }])
							setShowEditBoardChairman(true)

							// console.log(rowData)
							// handleFilter(rowData)
						}}
						children=""
						Icon={faEdit}
						size={'sm'}
					/>
				</div>
				<div>
					<IconButton
						type="button"
						className={
							'relative top-0.5 flex h-5 w-6 items-center justify-center rounded-sm border bg-transparent text-sm text-red-500 hover:bg-transparent hover:text-red-700'
						}
						onClick={(e) => {
							e.preventDefault()
							// console.log(rowData)
							handleFilter(rowData)
						}}
						children=""
						Icon={faClose}
						size={'lg'}
					/>
				</div>
			</div>
		)
	}

	let COLUMNS = [
		{
			sortable: true,
			header: 'Full Name ',
			field: 'full_name',
			style: { width: '20%' },
			headerStyle: { width: '20%' }
		},

		{
			sortable: true,
			header: 'Designation',
			field: 'designation',
			style: { width: '18%' },
			headerStyle: { width: '18%' }
		},
		{
			sortable: true,
			header: 'Email',
			field: 'email',
			style: { width: '25%' },
			headerStyle: { width: '25%' }
		},
		{
			sortable: true,
			header: 'Phone',
			field: 'phone',
			style: { width: '15%' },
			headerStyle: { width: '15%' }
		},

		{
			header: ' Action',
			style: { width: '70px' },
			body: ActionBodyTemplate,
			headerStyle: { width: '70px' }
		}
	]

	const HandleData = (data) => {
		setSelectedData(data)
	}

	const HeaderButtons = ({ exportCSV }) => (
		<>
			<Button
				onClick={() => setShowChairman(true)}
				children={'Add Chairmain'}
				className="btn-large btn-primary w-fit"
			/>
		</>
	)

	return (
		<div>
			<div className="h5 border-b pb-1">Board Chairman Persons</div>
			{showEditBoardChairman && (
				<EditChairman
					selectedData={originalData}
					type="edit_chairman"
					setSelectedData={setSelectedParentsData}
					setShowModal={setShowEditBoardChairman}
					rowData={selectedData}
				/>
			)}
			{showChairman && (
				<AddMembers
					type={'add_chairman'}
					setSelectedData={setSelectedParentsData}
					setShowModal={setShowChairman}
				/>
			)}
			<DataTableWrapper
				showHeader={true}
				paginator={false}
				products={BoardChairman}
				HeaderButtons={HeaderButtons}
				options={dopdownOptions}
				columns={COLUMNS}
				onDataSelection={HandleData}
				selectedData={selectedData}
			/>
		</div>
	)
}
export default ChairmanTable
