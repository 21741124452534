import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import PageAlerts from '../../PageAlerts'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import { IMPORT_BASE } from '../../../utils/GraphQl/KnowledgeBase'
import Attachments from '../../../components/ui/Attachments'
import { logoutUser } from '../../../store/AuthSlice/AuthSlice'
import ProgressBar from '../../ProgressBar'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const Import = ({ setIsImportList, ExistingData }) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [loader, setLoader] = useState(false)
	const [size, setSize] = useState('25%')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const dispatch = useDispatch()
	const [Files, setFiles] = useState([])
	const [importBases] = useMutation(IMPORT_BASE)

	const HandleUploadBase = async () => {
		try {
			setLoader(true)
			const response = await importBases({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					file: Files[0]
				}
			})
			let data = await response.data
			let Bases = data?.importKnowledgeBaseData
			if (Bases.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'import_base',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'import_base' }))
				}, 3000)
			} else if (Bases.status !== 200 && Bases.errors.length > 0) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'import_base',
						type: 'danger',
						message: Bases.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (Bases.status === 200) {
				setLoader(false)
				let uploadedData = Bases.data
				uploadedData.forEach((item) => {
					ExistingData.push({
						...item,
						category: uploadedData.category_id
							? uploadedData.category_id
							: '---'
					})
				})
				dispatch(
					addAlerts({
						pageId: 'import_base',
						type: 'success',
						message: Bases.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					setShowDrawer(false)
					setScrollable(false)
					setScrollableFooter(false)
					setSize('25%')
					setOverlay('')
					setBody(null)
					setIsImportList(false)
					dispatch(removeAlerts({ pageId: 'import_base' }))
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'import_base',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('25%')
		setOverlay('')
		setBody(null)
		setIsImportList(false)
	}
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])

	useEffect(() => {
		const HandleSelectedFile = (file) => {
			if (file[0].name.endsWith('.json')) {
				setFiles([file[0]])
			} else {
				dispatch(
					addAlerts({
						pageId: 'import_base',
						type: 'danger',
						message: 'Please upload a valid JSON file',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
		}

		const RemoveFIle = (files) => {
			let FIlteredFiles = Files.filter((file) => file.name !== files.name)
			setFiles(FIlteredFiles)
		}
		const FORM = (
			<form
				encType="multipart/form-data"
				className=" flex w-full flex-col justify-start"
			>
				<>
					<Attachments
						id="import_json_list"
						labelName={'Select File'}
						files={Files}
						onSelect={HandleSelectedFile}
						onRemove={RemoveFIle}
					/>
				</>
			</form>
		)

		setBody(FORM)
	}, [showDrawer, Files, dispatch])
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "> </h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={HandleUploadBase}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') {
			handleDrawerClose()
		}
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			{loader && <ProgressBar />}
			<PageAlerts pageId={'import_base'} />
			<Drawer
				label="Import data"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default Import
