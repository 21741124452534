import React from 'react'
import classes from '../../../assets/css/Drawers/index.module.css'
const DrawerFooter = (props) => {
	return (
		<>
			<div className={classes.drawerFooter}>{props.drawerFooterBody}</div>
		</>
	)
}

export default React.memo(DrawerFooter)
