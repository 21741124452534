import React from 'react'
import DataTableWrapper from '../../components/ui/DataTables'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useState, useEffect } from 'react'
import { classNames } from 'primereact/utils'
import Button from '../../components/ui/Buttons/Button'
import { useDispatch, useSelector } from 'react-redux'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import IconButton from '../../components/ui/Buttons/Icon-button'
import { removePage } from '../../store/SettingsSlice'
import {
	faCheck,
	faTrash,
	faTriangleExclamation,
	faBook,
	faPen,
	faPlus
} from '@fortawesome/free-solid-svg-icons'
import GlobalDrawer from '../GlobalDrawer'
import { logoutUser } from '../../store/AuthSlice/AuthSlice'
import AddAdditionalFields from './Form'
import TableLayout from './TableLayout'
import useGlobalKeyPress from '../../hooks/globalKeyPress'
import client from '../../utils/GraphQl/apolloClient'
import { DELETE_ADDITIONAL_FIELDS } from '../../utils/GraphQl/AdditionalFields'
import EditAdditionalFields from './editAdditionalFieldDrawer'
const GET_ADDITIONAL_FIELDS = gql`
	query GetAllAdditionalFields($pagination: PaginationInput) {
		getAllAdditionalFields(pagination: $pagination) {
			status
			message
			data
			errors
		}
	}
`
const GET_CATEGORY_LIST = gql`
	query GetCategoryList($listId: Int) {
		getCategoryList(list_id: $listId) {
			data
			errors
			status
			message
		}
	}
`

const AdditionalFieldsAdded = () => {
	const TABLE_LAYOUTS = useSelector(
		(state) => state.tableLayouts?.additionalFieldsLayout
	)
	let COLUMNS = [
		{
			selectionMode: 'multiple',
			isHidden: false,
			position: 'left',
			field: 'checkbox',
			headerStyle: { width: '2%' },
			style: { width: '2%' },
			className: 'chk-dt chk-primary',
			id: 'primary'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'left',
			style: { width: '10%', minWidth: '10%' },
			headerStyle: { width: '10%', minWidth: '10%' },
			header: 'Scope',
			field: 'scope'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'left',
			style: { width: '10%', minWidth: '10%' },
			headerStyle: { width: '10%', minWidth: '10%' },
			header: 'Field Name',
			field: 'field_name'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'left',
			style: { width: '10%', minWidth: '10%' },
			headerStyle: { width: '10%', minWidth: '10%' },
			header: 'Field Type',
			field: 'field_type_label'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'left',
			style: { width: '10%', minWidth: '10%' },
			headerStyle: { width: '10%', minWidth: '10%' },
			header: 'Label',
			field: 'label'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'left',
			style: { width: '10%', minWidth: '10%' },
			headerStyle: { width: '10%', minWidth: '10%' },
			header: 'Data Type',
			field: 'data_type'
		}
	]
	const [showDrawer, setShowDrawer] = useState(true)
	const [body, setBody] = useState(null)
	const [pageId, setPageId] = useState('')
	const userdata = useSelector((state) => state.Auth)
	const [showAddDrawer, setShowAddDrawer] = useState(false)
	const [showEditDrawer, setShowEditDrawer] = useState(false)
	const [abc, setAbc] = useState(false)
	const authToken = userdata.token
	const [Components, setComponents] = useState([])
	const [Items, setItems] = useState(null)
	const [selectedData, setSelectedData] = useState([])
	const [reCall, setRecall] = useState(false)
	const dispatch = useDispatch()
	const [isLayout, setIsLayout] = useState(false)
	const [loader, setLoader] = useState(false)
	const [delete_additional_fields] = useMutation(DELETE_ADDITIONAL_FIELDS)
	const [scopeSelectorData, setScopeSelectorData] = useState(null)
	const [categoryListID, setCategoryListID] = useState(null)

	const key = 'appsetting_additional_fields_scope_id'
	const [columns, setColumns] = useState(COLUMNS)
	useEffect(() => {
		if (TABLE_LAYOUTS.length > 0) {
			TABLE_LAYOUTS.forEach((data) => {
				if (data.table_name == 'table_additional_fields_list') {
					setColumns(data.structure)
					COLUMNS = data.structure
				}
			})
		}
	}, [TABLE_LAYOUTS])
	const { data, loading, error, refetch } = useQuery(
		gql`
			query GetSetting($key: String) {
				getSetting(key: $key) {
					status
					message
					errors
					data
				}
			}
		`,
		{
			context: {
				headers: {
					authorization: authToken
				}
			},
			fetchPolicy: 'network-only',
			variables: {
				key: key
			}
		}
	)

	const fieldTypeOptions = {
		'elastic-textbox': 'Elastic Textbox',
		textbox: 'Textbox',
		date: 'Date',
		radio: 'Radio',
		'drop-down': 'Dropdown',
		checkbox: 'Checkbox',
		'file-attachment': 'File Attachment',
		'multi-select-drop-down': 'Multiselect Dropdown'
	}

	useEffect(() => {
		FetchAdditionalFields()
		// refetch()
	}, [reCall, refetch])

	const HandleData = (data) => {
		setSelectedData(data)
	}
	const fetchSettings = () => {}
	const settingData = data?.getSetting?.data
	useEffect(() => {
		if (loading) {
			setLoader(true)
		} else if (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'additionalfieldsadded',
					type: 'danger',
					message: 'Failed to fetch settings.',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (data.getSetting.status === 403) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'additionalfieldsadded',
					type: 'danger',
					message: data.getSetting.errors[0],
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (!loading && !error && data && settingData !== undefined) {
			setLoader(false)
			setCategoryListID(Number(settingData.value))
		}
	}, [data, settingData, error, loading])

	useEffect(() => {
		if (categoryListID) fetchCategoryList(categoryListID)
	}, [categoryListID])

	const fetchCategoryList = async (id) => {
		try {
			const { data } = await client.query({
				query: GET_CATEGORY_LIST,
				context: {
					headers: {
						authorization: authToken
					}
				},
				fetchPolicy: 'network-only',
				variables: {
					listId: id
				}
			})
			let categoryList = data?.getCategoryList
			if (categoryList.status === 403) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'additionalfieldsadded',
						type: 'danger',
						message: categoryList.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'additionalfieldsadded' }))
				}, 3100)
			} else if (categoryList.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'additionalfieldsadded',
						type: 'danger',
						message: 'Session expired, please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'additionalfieldsadded' }))
				}, 3100)
			} else if (categoryList.status === 200) {
				const categoryListData = categoryList.data
				setScopeSelectorData(categoryListData)
			}
		} catch (error) {
			console.log(error)
		}
	}

	const FetchAdditionalFields = async () => {
		try {
			// setLoader(true)
			const { data } = await client.query({
				query: GET_ADDITIONAL_FIELDS,
				variables: {
					pagination: {
						offset: 0,
						limit: 100
					}
				},
				context: {
					headers: {
						authorization: authToken
					}
				}
				// fetchPolicy: 'network-only'
			})
			let additionalFields = data.getAllAdditionalFields
			if (additionalFields.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'additionalfieldsadded',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'additionalfieldsadded' }))
				}, 3100)
			} else if (additionalFields.status === 204) {
				setLoader(false)
				dispatch(removeAlerts({ pageId: 'additionalfieldsadded' }))
			} else if (additionalFields.status === 200) {
				setLoader(false)
				setComponents(() => [])
				const ParsedData = additionalFields.data.items
				setItems(ParsedData)
				setComponents((prevFields) => {
					const updatedFields = ParsedData.map(
						(additional_field, idx) => ({
							...additional_field,
							field_id: additional_field.id,
							field_type_label:
								fieldTypeOptions[additional_field.field_type],
							id: idx
						})
					)
					return [...prevFields, ...updatedFields]
				})
			} else if (additionalFields.status === 403) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'additionalfieldsadded',
						type: 'danger',
						message: additionalFields.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'additionalfieldsadded' }))
				}, 3100)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'additionalfieldsadded',
					type: 'danger',
					message: 'Something went wrong pleasse try again.',
					icon: faCheck,
					autoclose: 3000
				})
			)
		}
	}
	useEffect(() => {
		fetchSettings()
		FetchAdditionalFields()
	}, [])

	const dopdownOptions = ['All', 'Label', 'Fieldtype']

	const template1 = {
		layout: 'CustomPaginator PrevPageLink PageLinks NextPageLink',
		CustomPaginator: (options) => {
			return (
				<span style={{ marginRight: 'auto' }}>
					Showing {options.first + 1} to{' '}
					{options.first + options.rows} of {options.totalRecords}{' '}
					entries
				</span>
			)
		},
		PrevPageLink: (options) => {
			options.className = classNames(options.className).replace(
				'p-disabled',
				''
			)
			return (
				<Button
					type={'button'}
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={options.onClick}
					// disabled={options.disabled}
					children={'Previous'}
				/>
			)
		},
		NextPageLink: (options) => {
			return (
				<Button
					type="button"
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={(event) => {
						// if (!stopfetching) {
						// 	fetchAgain()
						// 	setLimit((prev) => prev + 10)
						// }
						options.onClick(event)
					}}
					// disabled={options.disabled}
					children={'Next'}
				/>
			)
		},

		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page &&
					options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, {
					'p-disabled': true
				})

				return (
					<span className={className} style={{ userSelect: 'none' }}>
						...
					</span>
				)
			}
			const isCurrentPage = options.page === options.currentPage
			const linkClassName = classNames(options.className, {
				p: isCurrentPage
			})
			const linkStyle = isCurrentPage
				? {
						backgroundColor: '#7252d3',
						borderRadius: '0',
						color: '#fff'
					}
				: {
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: '#007bff'
					}
			return (
				<Button
					type="button"
					className={linkClassName}
					style={linkStyle}
					onClick={(event) => {
						// if (!stopfetching) {
						// 	fetchAgain()
						// 	setLimit((prev) => prev + 10)
						// }
						options.onClick(event)
					}}
					children={`${options.page + 1}`}
				/>
			)
		}
	}
	const paginatorLeft = (options) => {
		return (
			<span style={{ marginRight: 'auto' }}>
				Showing {options.first + 1} to{' '}
				{Math.min(options.first + options.rows, options.totalRecords)}{' '}
				entries from {options.totalRecords}
			</span>
		)
	}

	const HandleDelete = async () => {
		if (selectedData) {
			// handleVerification()
			let IDS = selectedData.map((value) => value.field_id)
			try {
				setLoader(true)
				const response = await delete_additional_fields({
					context: {
						headers: {
							authorization: authToken
						}
					},
					variables: {
						fieldIds: IDS
					}
				})
				setLoader(false)
				let data = await response?.data
				let deleteAdditionalField = data?.deleteAdditionalField
				if (
					deleteAdditionalField.status === 401 ||
					deleteAdditionalField.status === 400
				) {
					setLoader(false)
					dispatch(
						addAlerts({
							pageId: 'additionalfieldsadded',
							type: 'danger',
							message: deleteAdditionalField.message,
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
				} else if (
					deleteAdditionalField.status !== 200 &&
					deleteAdditionalField?.errors?.length > 0
				) {
					setLoader(false)
					dispatch(
						addAlerts({
							pageId: 'additionalfieldsadded',
							type: 'danger',
							message: deleteAdditionalField.errors.map(
								(err) => err
							),
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
				} else if (deleteAdditionalField.status === 200) {
					setLoader(false)
					setComponents((prev) => {
						let filtered = prev.filter(
							(item) => !IDS.includes(item.field_id)
						)
						setSelectedData([])
						return filtered
					})
					setSelectedData([])
					dispatch(
						addAlerts({
							pageId: 'additionalfieldsadded',
							type: 'success',
							message: deleteAdditionalField.message,
							icon: faCheck,
							autoclose: 3000
						})
					)
				} else if (deleteAdditionalField.status === 500) {
					setLoader(false)
					dispatch(
						addAlerts({
							pageId: 'additionalfieldsadded',
							type: 'danger',
							message: 'something went wrong',
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
				}
			} catch (error) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'additionalfieldsadded',
						type: 'danger',
						message: 'something went wrong',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
		}
	}
	useEffect(() => {
		if (Array.isArray(Items)) {
			setItems((prevItems) => {
				const filteredItems = prevItems.filter((item) => {
					return !selectedData?.some(
						(selectedItem) => selectedItem.name === item.name
					)
				})
				return filteredItems
			})
		}
	}, [selectedData])

	const HandleAddFields = () => {
		setShowAddDrawer((prev) => !prev)
	}
	const handleEditColumns = () => {
		setAbc((prev) => !prev)
		setShowEditDrawer((prev) => !prev)
	}

	const HeaderButtons = ({ exportCSV }) => (
		<>
			<div className="flex flex-wrap items-center justify-start  gap-[3px]">
				<IconButton
					onClick={() => setIsLayout((prev) => !prev)}
					Icon={faPlus}
					size="sm"
					className="btn btn-large btn-primary flex gap-1.5"
				>
					Layout
				</IconButton>{' '}
				<IconButton
					onClick={HandleAddFields}
					Icon={faCheck}
					size="sm"
					className="btn btn-large btn-primary flex gap-1.5"
				>
					Add Field
				</IconButton>{' '}
				<IconButton
					disabled={selectedData.length === 0}
					onClick={handleEditColumns}
					Icon={faPen}
					size={'sm'}
					className="btn btn-large btn-edit flex gap-1.5"
				>
					Edit
				</IconButton>{' '}
				<IconButton
					disabled={selectedData.length === 0}
					onClick={HandleDelete}
					Icon={faTrash}
					size={'sm'}
					className="btn btn-large btn-danger flex gap-1.5"
				>
					Delete Field
				</IconButton>
			</div>{' '}
		</>
	)

	const FORM = (
		<>
			{isLayout && (
				<TableLayout
					setDrawerstate={setIsLayout}
					setPageId={setPageId}
					setLoader={setLoader}
					columns={columns}
					originalColumns={COLUMNS}
					setColumns={setColumns}
				/>
			)}

			{showEditDrawer && (
				<EditAdditionalFields
					setDrawerstate={setShowEditDrawer}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					Components={Components}
					fieldTypeOptions={fieldTypeOptions}
					setComponents={setComponents}
					SetFetchAgian={setRecall}
					setPageId={setPageId}
					scopeSelectorData={scopeSelectorData}
					setLoader={setLoader}
				/>
			)}
			{showAddDrawer && (
				<AddAdditionalFields
					setDrawerstate={setShowAddDrawer}
					SetFetchAgian={setRecall}
					setPageId={setPageId}
					scopeSelectorData={scopeSelectorData}
					setLoader={setLoader}
				/>
			)}
			<>
				<DataTableWrapper
					products={Components}
					HeaderButtons={HeaderButtons}
					options={dopdownOptions}
					columns={columns}
					onDataSelection={HandleData}
					template={template1}
					paginatorLeft={paginatorLeft}
					selectedData={selectedData}
				/>
			</>
		</>
	)
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])
	useEffect(() => {
		setBody(FORM)
	}, [
		showDrawer,
		selectedData,
		showEditDrawer,
		Components,
		Items,
		showAddDrawer,
		isLayout,
		columns,
		TABLE_LAYOUTS
	])

	const handleDrawerClose = () => {
		dispatch(removeAlerts({ pageId: 'additionalfieldsadded' }))
		dispatch(removePage())
	}
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal ">{''}</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				{/* <IconButton
					onClick={() => handleVerification()}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton> */}
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') {
			if (!showAddDrawer && !showEditDrawer && !isLayout) {
				handleDrawerClose()
			}
		}
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			<GlobalDrawer
				label={'Additional Fields '}
				onClose={handleDrawerClose}
				body={body}
				scrollable={true}
				overlay={''}
				size={'80%'}
				showDrawer={true}
				drawerFooterBody={drawerFooterBody}
				loader={loader}
				scrollableFooter={false}
				drawerPosition={'right'}
				pageId={pageId}
			/>
		</>
	)
}
export default AdditionalFieldsAdded
