import React, { useState, useEffect } from 'react'
import Modal from '../../../components/ui/modals'
import MultiSelectdropdown from '../../../components/Dropdowns/MultiSelectdropdown'
import { gql, useApolloClient } from '@apollo/client'
import {
	faCheck,
	faSave,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { useDispatch } from 'react-redux'
import { addAlerts } from '../../../store/AlertSlice'
import { useSelector } from 'react-redux'
const GET_BASES = gql`
	query GetAllBases($pagination: PaginationInput) {
		getAllBases(pagination: $pagination) {
			status
			message
			errors
			data
		}
	}
`
const LookupModal = ({ setShowModal, HANDLEFORMDATA }) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [overLay, setOverLay] = React.useState('')
	const [showPromptModal, setShowPromptModal] = useState(true)
	const [scrollable, setScrollable] = React.useState(true)
	const [lists, setLists] = useState([])
	const [items, setItems] = useState([])
	const [loader, setLoader] = useState(false)
	const dispatch = useDispatch()
	const client = useApolloClient()
	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoader(true)
				const { data } = await client.query({
					query: GET_BASES,
					variables: {
						pagination: {
							offset: 0,
							limit: 100000
						}
					},
					context: {
						headers: {
							authorization: authToken
						}
					},
					fetchPolicy: 'network-only'
				})

				let bases = data?.getAllBases
				if (bases.status === 401) {
					setLoader(false)
					dispatch(
						addAlerts({
							pageId: 'DeleteBase',
							type: 'danger',
							message: 'Session expired, please login again',
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
					setTimeout(() => {
						// dispatch(logoutUser())
						// dispatch(removeAlerts({ pageId: 'DeleteBase' }))
					}, 3100)
				}
				if (bases.status === 403) {
					setLoader(false)
					dispatch(
						addAlerts({
							pageId: 'DeleteBase',
							type: 'danger',
							message: bases.message,
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
				} else if (bases.status === 204) {
					setLoader(false)
					dispatch(
						addAlerts({
							pageId: 'DeleteBase',
							type: 'danger',
							message: 'No data found',
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
				} else if (bases.status === 200) {
					setLoader(false)
					let FinalData = bases.data.items
					FinalData?.forEach((item) => {
						setItems((prev) => {
							return [
								...prev,
								{ label: item.heading, value: item.heading }
							]
						})
					})
				}
			} catch (error) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'DeleteBase',
						type: 'danger',
						message: 'Something went wrong, please try again.',
						icon: faCheck,
						autoclose: 3000
					})
				)
			}
		}

		fetchData()
	}, [])
	const add = (data) => {
		setLists([...lists, data])
		setItems(items.filter((item) => item.label !== data.label))
	}
	const removeItem = (item) => {
		setLists((prevLists) =>
			prevLists.filter((listItem) => listItem.label !== item.label)
		)
		setItems([item, ...items])
	}

	const handleModalClose = () => {
		setShowModal(false)
		setScrollable(false)
		setShowPromptModal(false)
		setOverLay('false')
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		let valArray = lists.map((list) => list?.value)
		HANDLEFORMDATA('organization_objectives', valArray.join(','))
		handleModalClose()
	}

	const FORM = (
		<div className="w-[350px]">
			<MultiSelectdropdown
				label="Select"
				remainingItems={items}
				selectedItems={lists}
				onItemAdd={(item) => add(item)}
				onItemRemove={(item) => removeItem(item)}
			/>
		</div>
	)
	const footer = (
		<>
			<div className="modal-footer flex  items-center justify-between border-t  ">
				<h5 className="invisible font-normal">{'T'}</h5>
				<div className="flex items-center justify-end gap-[3px]">
					<IconButton
						// disabled={!Formdata.response}
						Icon={faSave}
						className="btn-success btn-large flex gap-1.5"
						type="button"
						onClick={handleSubmit}
						children="Use"
					/>
				</div>
			</div>
		</>
	)

	return (
		<div>
			<Modal
				modalSize={'lg'}
				title={`Lookup`}
				onClose={handleModalClose}
				body={FORM}
				overLay={overLay}
				showModal={showPromptModal}
				position="!top-[36%] !left-[30%] !z-[99999]"
				className="z-[9999]"
				scrollable={scrollable}
				Footer={footer}
			/>
		</div>
	)
}

export default LookupModal
