import React, { useEffect, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { UPDATE_MULTIPLE_SETTINGS } from '../../utils/GraphQl/SettingsMutation'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import { logoutUser } from '../../store/AuthSlice/AuthSlice'
import SimpleDropDown from '../../components/Dropdowns/SimpledropdownOptionKey'
import GlobalDrawer from '../GlobalDrawer'
import { fetchMagicLinkSettings } from '../../store/MagicLinkSettings'
import { removePage } from '../../store/SettingsSlice'
import {
	faCheck,
	faTriangleExclamation,
	faBook,
	faUniversalAccess
} from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../components/ui/Buttons/Icon-button'
import useGlobalKeyPress from '../../hooks/globalKeyPress'
const ExpireMagicLink = () => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [body, setBody] = useState(null)
	const dispatch = useDispatch()
	const [loader, setLoader] = useState(false)
	const [updateSettings] = useMutation(UPDATE_MULTIPLE_SETTINGS)
	const [time, setTime] = useState({})
	let dropdownOptions = [
		{ label: '8 Hours', value: '0' },
		{ label: 'One time', value: '1' }
	]
	const { data, loading, error } = useSelector(
		(state) => state.magicLinkSettings
	)
	useEffect(() => {
		if (loading) {
			// setLoader(true)
		} else if (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'magicklinksettings',
					type: 'danger',
					message: 'Failed to fetch  settings.',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (data?.MagicLink?.status === 403) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'magicklinksettings',
					type: 'danger',
					message: data.MagicLink.errors[0],
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (!loading && !error && data) {
			setLoader(false)
			let value = data.MagicLink.data?.value
			let index = dropdownOptions.findIndex(
				(option) => option.value === value
			)
			setTime(dropdownOptions[index])
		}
	}, [data, loading, error])

	const handleMagicLinkSettings = async () => {
		try {
			setLoader(true)
			const response = await updateSettings({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						magic_link_expiry: {
							value: time?.value
						}
					}
				}
			})
			let data = await response.data
			if (data.updateMultipleSettings.status === 400) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'magicklinksettings',
						type: 'danger',
						message: 'Not found',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateMultipleSettings.status === 403) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'magicklinksettings',
						type: 'danger',
						message: data.updateMultipleSettings.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateMultipleSettings.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'magicklinksettings',
						type: 'danger',
						message: data.updateMultipleSettings.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateMultipleSettings.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'magicklinksettings',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)

				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'magicklinksettings' }))
				}, 3000)
			} else if (data.updateMultipleSettings.status === 200) {
				setLoader(false)
				dispatch(fetchMagicLinkSettings())
				dispatch(
					addAlerts({
						pageId: 'magicklinksettings',
						type: 'success',
						message: 'Data updated successsfully',
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					handleDrawerClose()
				}, 3000)
			}
		} catch (error) {
			console.error('update failed', error)
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'updateEmailSettings',
					type: 'danger',
					message: 'error',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const HandleChange = (data) => {
		setTime(data)
	}
	const FORM = (
		<>
			<form className="w-[350px]">
				<SimpleDropDown
					options={dropdownOptions}
					label="Magic Link Expiry"
					value={time?.label}
					onChange={(data) => HandleChange(data)}
				/>
			</form>
		</>
	)
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])
	useEffect(() => {
		setBody(FORM)
	}, [showDrawer, time])
	const handleDrawerClose = () => {
		dispatch(removeAlerts({ pageId: 'magicklinksettings' }))
		dispatch(removePage())
	}
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal ">{''}</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>
				<IconButton
					onClick={() => handleMagicLinkSettings()}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			<GlobalDrawer
				label={'Magic Link Settings'}
				onClose={handleDrawerClose}
				body={body}
				scrollable={true}
				overlay={''}
				size={'md'}
				showDrawer={true}
				drawerFooterBody={drawerFooterBody}
				loader={loader}
				scrollableFooter={false}
				drawerPosition={'right'}
				pageId={'magicklinksettings'}
			/>
		</>
	)
}

export default ExpireMagicLink
