import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import PageAlerts from '../../PageAlerts'
import TextBox from '../../../components/ui/TextBox'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import {
	faBook,
	faTrash,
	faUniversalAccess,
	faTriangleExclamation,
	faCheck
} from '@fortawesome/free-solid-svg-icons'
import ProgressBar from '../../ProgressBar'
import Simpledropdown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import { useDispatch, useSelector } from 'react-redux'
import { CREATE_TABLE_LAYOUT } from '../../../utils/GraphQl/TableLayout'
import { useMutation } from '@apollo/client'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
import { updateOrganizationContextLayout } from '../../../store/GetTableLayouts'
const TableLayout = ({
	setDrawerstate,
	columns,
	originalColumns,
	setColumns
}) => {
	const authToken = useSelector((state) => state?.Auth?.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [loader, setLoader] = useState(false)
	const [size, setSize] = useState('40%')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const [selectedColumns, setSelectedColumns] = useState([...columns])
	const [update_layout] = useMutation(CREATE_TABLE_LAYOUT)
	const [newColumnsCount, setNewColumnsCount] = useState(0)
	const dispatch = useDispatch()

	const UpdateLayout = async (updated_columns) => {
		try {
			setLoader(true)
			const response = await update_layout({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						table_name: 'table_organization_context',
						structure: selectedColumns
					}
				}
			})
			let updateCreateTableStructur =
				await response.data?.updateCreateTableStructure
			if (updateCreateTableStructur.status === 200) {
				setLoader(false)
				setColumns(updated_columns)
				dispatch(
					addAlerts({
						pageId: 'organization_context_table_Layout',
						type: 'success',
						message: 'Columns updated successfully ',
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(
						updateOrganizationContextLayout(
							updateCreateTableStructur?.data
						)
					)
					dispatch(
						removeAlerts({
							pageId: 'organization_context_table_Layout'
						})
					)
					handleDrawerClose()
				}, 3000)
			} else if (
				updateCreateTableStructur.status !== 200 &&
				updateCreateTableStructur.errors
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'organization_context_table_Layout',
						type: 'danger',
						message: updateCreateTableStructur.errors,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (
				updateCreateTableStructur.status !== 200 &&
				updateCreateTableStructur.message
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'organization_context_table_Layout',
						type: 'danger',
						message: updateCreateTableStructur.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'organization_context_table_Layout',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('sm')
		setOverlay('')
		setBody(null)
		setDrawerstate(false)
	}

	useEffect(() => {
		const columnList = [
			{ label: 'Checkbox', value: 'checkbox' },
			{ label: 'Id', value: 'id' },
			{ label: 'Organization Name', value: 'organization_name' },
			{
				label: 'Information Completion',
				value: 'information_completion'
			},
			{ label: 'Last Updated Date', value: 'last_updated_date' }
		]
		const positionList = [
			{ label: 'left', value: 'left' },
			{ label: 'center', value: 'center' },
			{ label: 'right', value: 'right' }
		]
		const updateColumnData = (index, key, value) => {
			// let newColumns = [...selectedColumns]
			let newColumns = JSON.parse(JSON.stringify([...selectedColumns]))

			if (index > selectedColumns.length) {
				if (key === 'label') {
					const selectedValue = originalColumns.filter(
						(column) => column.field === value.value
					)
					newColumns.push(selectedValue[0])
					setNewColumnsCount(newColumnsCount - 1)
				}
			} else {
				if (key === 'label') {
					let labelChanged = false
					let currValue = ''
					let newValue = ''
					currValue = selectedColumns[index].field
					newValue = value.value

					if (currValue !== newValue) labelChanged = true
					if (labelChanged) {
						const valueToChange = JSON.parse(
							JSON.stringify(originalColumns)
						).filter((column) => {
							column.isHidden = false
							return column.field === newValue
						})
						newColumns[index] = valueToChange[0]
					}
				} else {
					const updateValue = { ...newColumns[index] }
					// const updateValue = JSON.parse(JSON.stringify(newColumns[index]))
					if (key === 'width') {
						updateValue.style.width = value
						updateValue.style.minWidth = value
						updateValue.headerStyle.width = value
					}
					if (key === 'position') {
						updateValue.position = value.value
					}
					newColumns[index] = updateValue
				}
			}

			setSelectedColumns(newColumns)
		}
		const removeColumns = (e, columnIndex) => {
			e.preventDefault()
			const newColumns = selectedColumns.map((column, index) => {
				if (index === columnIndex) {
					const updatedColumn = { ...column }
					updatedColumn.isHidden = true
					return updatedColumn
				}

				return column
			})
			if (columnIndex > columns.length) {
				setNewColumnsCount(newColumnsCount - 1)
			}
			setSelectedColumns(newColumns)
		}
		const FORM = (
			<form
				encType="multipart/form-data"
				className=" flex w-full flex-col justify-start"
			>
				<>
					<div className="flex w-full flex-col justify-start ">
						<div>
							{selectedColumns.map((column, index) => {
								let newIndex = index
								if (
									!column?.expander &&
									column?.isHidden === false
								) {
									return (
										<div className="flex w-full gap-2">
											{/* <div className=' form-group'>
												<div className='label'>
													Index
													</div>
												<div>
												{index}
													</div>
												</div> */}
											<Simpledropdown
												key={newIndex++}
												value={
													column.header
														? column.header
														: column.selectionMode ===
															  'multiple'
															? 'checkbox'
															: ''
												}
												options={columnList}
												label="Label"
												className={'w-[45%]'}
												onChange={(data) =>
													updateColumnData(
														index,
														'label',
														data
													)
												}
											/>
											<Simpledropdown
												key={newIndex++}
												value={column.position}
												options={positionList}
												label="Position"
												className={'w-[45%]'}
												onChange={(data) =>
													updateColumnData(
														index,
														'position',
														data
													)
												}
											/>
											<TextBox
												id={'wisth_one'}
												name="width_one"
												key={newIndex++}
												value={column.style.width}
												labelName={'Width'}
												className={'w-[45%]'}
												onChange={(data) =>
													updateColumnData(
														index,
														'width',
														data
													)
												}
											/>
											<IconButton
												key={newIndex++}
												Icon={faTrash}
												size={'sm'}
												type={'button'}
												className="btn btn-sm btn-primary mt-[30px] h-[32px]"
												onClick={(e) =>
													removeColumns(e, index)
												}
											></IconButton>
										</div>
									)
								}
							})}
						</div>
					</div>

					<div className="flex w-full flex-col justify-start pr-1">
						<div>
							{newColumnsCount > 0 &&
								Array.from(
									{ length: newColumnsCount },
									(_, index) => index
								).map((column, index) => {
									index = selectedColumns.length + 1
									return (
										<div className="flex w-full gap-2">
											<Simpledropdown
												key={selectedColumns.length + 1}
												value={''}
												options={columnList}
												label="Label"
												className={'w-[45%]'}
												onChange={(data) =>
													updateColumnData(
														index,
														'label',
														data
													)
												}
											/>
											<Simpledropdown
												key={selectedColumns.length + 2}
												value={''}
												options={positionList}
												label="Position"
												className={'w-[45%]'}
												onChange={(data) =>
													updateColumnData(
														index,
														'position',
														data
													)
												}
											/>
											<TextBox
												id={'width'}
												name="width"
												key={selectedColumns.length + 3}
												value={''}
												labelName={'Width'}
												className={'w-[45%]'}
												onChange={(data) =>
													updateColumnData(
														index,
														'width',
														data
													)
												}
											/>
											<IconButton
												key={selectedColumns.length + 4}
												Icon={faTrash}
												size={'sm'}
												type={'button'}
												className="btn btn-sm btn-primary mt-[30px] h-[32px]"
												onClick={(e) =>
													removeColumns(e, index)
												}
											></IconButton>
										</div>
									)
								})}
						</div>
					</div>
				</>
			</form>
		)
		setBody(FORM)
	}, [showDrawer, selectedColumns, newColumnsCount, columns])
	useEffect(() => {}, [selectedColumns])
	const saveColumns = () => {
		let regexFailed = false
		let Totalwidth = 100
		let REGEX = /^\d+%$/
		let EXTRACTNUM = /\d+/
		const uniqueColumns = selectedColumns.reduce((accumulator, column) => {
			accumulator[column.header] = column
			return accumulator
		}, {})
		const filteredColumns = Object.values(uniqueColumns)
		filteredColumns.map((col) => {
			if (!col?.expander && !col?.isHidden) {
				let width = col.style.width
				if (REGEX.test(width)) {
					let input = width
					let extractedNumbers = input.match(EXTRACTNUM)
					let num = extractedNumbers[0]
					Totalwidth -= Number(num)
				} else {
					dispatch(
						addAlerts({
							pageId: 'organization_context_table_Layout',
							type: 'danger',
							message: 'Column width must be in %',
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
					regexFailed = true
				}
			}
		})
		if (regexFailed) {
			return
		}

		if (Totalwidth > 0) {
			dispatch(
				addAlerts({
					pageId: 'organization_context_table_Layout',
					type: 'danger',
					message: `Please fill the remaning width ${Totalwidth}%`,
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (Totalwidth < 0) {
			dispatch(
				addAlerts({
					pageId: 'organization_context_table_Layout',
					type: 'danger',
					message: 'Columns width exceeded',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (Totalwidth === 0) {
			const lastIndexMap = {}
			selectedColumns.forEach((column, index) => {
				lastIndexMap[column.field] = index
			})

			const filteredSelectedColumns = selectedColumns.filter(
				(column, index) => {
					return lastIndexMap[column.field] === index
				}
			)
			UpdateLayout(filteredSelectedColumns)
		}
	}
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "> </h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={() => setNewColumnsCount(newColumnsCount + 1)}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Add Column
				</IconButton>

				<IconButton
					onClick={saveColumns}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Save Columns
				</IconButton>
			</div>
		</>
	)
	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])

	useGlobalKeyPress(handleKeyPress)
	return (
		<>
			{loader && <ProgressBar />}
			<PageAlerts pageId={'organization_context_table_Layout'} />
			<Drawer
				label="Update Structure"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default TableLayout
