import { gql } from '@apollo/client'
export const CREATE_CATALOGUE = gql`
	mutation CreateCatalogue($input: CatalogueInput!) {
		createCatalogue(input: $input) {
			status
			message
			errors
			data
		}
	}
`
export const UPDATE_CATALOGUE = gql`
	mutation UpdateCatalogue($input: EditCatalogueInput!, $catalogueId: Int!) {
		updateCatalogue(input: $input, catalogue_id: $catalogueId) {
			status
			message
			data
			errors
		}
	}
`
export const DELETE_CATALOGUE = gql`
	mutation DeleteCatalogue($catalogueId: [String]) {
		deleteCatalogue(catalogue_id: $catalogueId) {
			status
			message
			data
			errors
		}
	}
`

export const GET_CATALOGUE_LIST = gql`
	query ListCatalogues($keys: [String], $pagination: PaginationInput) {
		listCatalogues(pagination: $pagination) {
			status
			message
			data
			errors
		}
		getMultipleSettings(keys: $keys) {
			status
			message
			errors
			data
		}
	}
`
export const FILTER_CATALOGUE = gql`
	query FilterCatalogues($filter: CatalogueFilterInput) {
		filterCatalogues(filter: $filter) {
			status
			message
			errors
			data
		}
	}
`
export const GET_MULTIPLE_CATEGORY_LISTS = gql`
	query Query($ids: [Int]) {
		getMultipleCategoryLists(ids: $ids) {
			status
			message
			data
			errors
		}
	}
`
