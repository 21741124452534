import React from 'react'
import DataTableWrapper from '../../components/ui/DataTables'
import IconButton from '../../components/ui/Buttons/Icon-button'
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useState, useEffect } from 'react'
import { classNames } from 'primereact/utils'
import Button from '../../components/ui/Buttons/Button'
import { useDispatch, useSelector } from 'react-redux'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import PageAlerts from '../PageAlerts'
import { logoutUser } from '../../store/AuthSlice/AuthSlice'
import AddUser from './AddUser'
import EditUser from './EditUser'
import { CSVLink } from 'react-csv'
import Modal from '../../components/ui/modals'

import {
	faTrash,
	faUserSlash,
	faFileExport,
	faPlus,
	faPen,
	faEyeSlash,
	faKey,
	faFileArrowDown
} from '@fortawesome/free-solid-svg-icons'
import {
	faTriangleExclamation,
	faCheck,
	faSave,
	faXmark,
	faBook
} from '@fortawesome/free-solid-svg-icons'
import { DISABLE_USER_MUTATION } from '../../utils/GraphQl/DisableUserMutation'
import { DELETE_USER_MUTATION } from '../../utils/GraphQl/DeleteUserMutation'
import { ENABLE_USER_MUTATION } from '../../utils/GraphQl/Enableuser'
import ProgressBar from '../ProgressBar'
import ResetPassword from './ResetPassword'
import ImportUser from './ImportUser'
import Card from '../../components/ui/Card'
import TableLayout from './TableLayout'
const GET_USERS = gql`
	query Users($pagination: PaginationInput) {
		users(pagination: $pagination) {
			status
			message
			errors
			data {
				totalCount
				items
			}
		}
	}
`

const UserList = () => {
	const authToken = useSelector((state) => state.Auth.token)
	const TABLE_LAYOUTS = useSelector(
		(state) => state.tableLayouts?.userListLayout
	)
	const [limit, setLimit] = useState(60)
	const [users, setUsers] = useState([])
	const [isLayout, setIsLayout] = useState(false)
	const [isAdduser, setISAddUser] = useState(false)
	const [isEditUser, setIsEditUser] = useState(false)
	const [isResetPassword, setIsResetPassword] = useState(false)
	const [stopfetching, setStopFetching] = useState(false)
	const [isImportuser, setIsImportUser] = useState(false)
	const dispatch = useDispatch()
	const client = useApolloClient()
	const [selectedUsers, setselectedUsers] = useState([])
	const [exportData, setExportData] = useState([])
	const [disableUsers] = useMutation(DISABLE_USER_MUTATION)
	const [deleteUsers] = useMutation(DELETE_USER_MUTATION)
	const [enableUsers] = useMutation(ENABLE_USER_MUTATION)
	const [userId, setUserId] = useState(null)
	const [loader, setLoader] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [isEnable, setIsEnable] = useState(false)
	const [isDisable, setIsDisable] = useState(false)
	let COLUMNS = [
		{
			selectionMode: 'multiple',
			isHidden: false,
			position: 'left',
			field: 'checkbox',
			headerStyle: { width: '2%' },
			style: { width: '2%' },
			className: 'chk-dt chk-primary',
			id: 'primary'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'User Id',
			field: 'userid'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'center',
			style: { width: '17%', minWidth: '17%' },
			headerStyle: { width: '17%', minWidth: '17%' },
			header: 'Email',
			field: 'email'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'center',
			style: { width: '10%', minWidth: '10%' },
			headerStyle: { width: '10%', minWidth: '10%' },
			header: 'Fullname',
			field: 'fullname'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'center',
			style: { width: '10%', minWidth: '10%' },
			headerStyle: { width: '10%', minWidth: '10%' },
			header: 'Gender',
			field: 'gender'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'center',
			style: { width: '15%', minWidth: '15%' },
			headerStyle: { width: '15%', minWidth: '15%' },
			header: 'Role',
			field: 'role'
		},

		{
			sortable: true,
			isHidden: false,
			position: 'center',
			style: { width: '10%', minWidth: '10%' },
			headerStyle: { width: '10%', minWidth: '10%' },
			header: 'Status',
			field: 'active'
		}
	]

	const [columns, setColumns] = useState([...COLUMNS])
	useEffect(() => {
		if (TABLE_LAYOUTS.length > 0) {
			TABLE_LAYOUTS.forEach((data) => {
				if (data.table_name === 'table_user_list') {
					setColumns(data.structure)
					COLUMNS = data.structure
				}
			})
		}
	}, [TABLE_LAYOUTS])
	const HandleData = (data) => {
		setselectedUsers(data)
		setExportData((prev) =>
			prev.filter((prevUser) =>
				data.some(
					(selectedUser) => selectedUser.user_id === prevUser.user_id
				)
			)
		)
	}

	const fetchData = async () => {
		try {
			setLoader(true)
			const { data } = await client.query({
				query: GET_USERS,
				variables: {
					pagination: {
						offset: 0,
						limit: limit
					}
				},
				context: {
					headers: {
						authorization: authToken
					}
				},
				fetchPolicy: 'network-only'
			})
			let users = data.users
			if (users.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'userlist',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'userlist' }))
				}, 3100)
			} else if (users.status === 204) {
			} else if (users.status === 200) {
				setLoader(false)
				data.users.data.items.forEach((user) => {
					setUsers((prevUsers) => {
						const updatedUser = {
							...user,
							active:
								user.active === false ? 'inactive' : 'active',
							role: user.role === null ? 'null' : user.role,
							id: user.user_id,
							userid: user.user_id,
							gender:
								user?.user_data?.personal_information?.gender ||
								'--'
						}
						const filteredUsers = prevUsers.filter(
							(prevUser) => prevUser.id !== user.user_id
						)
						return [...filteredUsers, updatedUser]
					})
				})
			} else if (users.status === 403) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'userlist',
						type: 'danger',
						message: users.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'userlist' }))
				}, 3100)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'userlist',
					type: 'danger',
					message: 'Something went wrong pleasse try again.',
					icon: faCheck,
					autoclose: 3000
				})
			)
		}
	}
	const fetchAgain = async () => {
		try {
			const { data } = await client.query({
				query: GET_USERS,
				variables: {
					pagination: {
						offset: limit,
						limit: 10
					}
				},
				context: {
					headers: {
						authorization: authToken
					}
				}
			})
			let users = data.users
			if (users.status === 401) {
				dispatch(
					addAlerts({
						pageId: 'userlist',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'userlist' }))
				}, 3000)
			} else if (users.status === 204) {
				setStopFetching(true)
			} else if (users.status === 200) {
				data.users.data.items.forEach((user) => {
					setUsers((prevUsers) => {
						const updatedUser = {
							...user,
							active:
								user.active === false ? 'inactive' : 'active',
							role: user.role === null ? 'null' : user.role,
							id: user.user_id,
							userid: user.user_id,
							gender:
								user?.user_data?.personal_information?.gender ||
								'--'
						}
						const filteredUsers = prevUsers.filter(
							(prevUser) => prevUser.id !== user.user_id
						)
						return [...filteredUsers, updatedUser]
					})
				})
			} else if (users.status === 403) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'userlist',
						type: 'danger',
						message: users.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'userlist' }))
				}, 3100)
			}
		} catch (error) {
			dispatch(
				addAlerts({
					pageId: 'userlist',
					type: 'danger',
					message: 'Something went wrong pleasse try again.',
					icon: faCheck,
					autoclose: 3000
				})
			)
		}
	}
	useEffect(() => {
		fetchData()
	}, [])
	const handleDisableUsers = () => {
		if (selectedUsers.length <= 0) {
			dispatch(
				addAlerts({
					pageId: 'userlist',
					type: 'danger',
					message: 'Please select atleast 1 user!',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
			return
		} else {
			const selectedUserIds = selectedUsers.map((value) => {
				return value.user_id
			})
			setLoader(true)
			setIsEnable(false)
			disableUsers({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					user_id: selectedUserIds
				}
			})
				.then((response) => {
					dispatch(removeAlerts({ pageId: 'userlist' }))
					if (response && response.data.disableUser.status === 200) {
						setLoader(false)
						dispatch(
							addAlerts({
								pageId: 'userlist',
								type: 'success',
								message: response.data.disableUser.message,
								icon: faCheck,
								autoclose: 3000
							})
						)
						setselectedUsers((currentUsers) => {
							return currentUsers.map((user) => {
								return { ...user, active: 'inactive' }
							})
						})
						selectedUserIds.forEach((id) => {
							setUsers((prevUsers) => {
								prevUsers.forEach((prevUser) => {
									if (prevUser.id === id) {
										return (prevUser.active = 'inactive')
									}
								})
								return prevUsers
							})
						})
					} else if (
						response &&
						response.data.disableUser.status !== 200
					) {
						setLoader(false)
						dispatch(
							addAlerts({
								pageId: 'userlist',
								type: 'danger',
								message: response.data.disableUser.errors[0],
								icon: faTriangleExclamation,
								autoclose: 3000
							})
						)
					}
				})
				.catch((error) => {
					setLoader(false)
					dispatch(removeAlerts({ pageId: 'userlist' }))
					console.error('Response error:', error)
				})
		}
	}
	const EnableUsers = () => {
		const selectedUserIds = selectedUsers.map((value) => {
			return value.user_id
		})
		setLoader(true)
		enableUsers({
			context: {
				headers: {
					authorization: authToken
				}
			},
			variables: {
				userId: selectedUserIds
			}
		})
			.then((response) => {
				dispatch(removeAlerts({ pageId: 'userlist' }))
				if (response && response.data.enableUser.status === 200) {
					setIsDisable(false)
					setLoader(false)
					dispatch(
						addAlerts({
							pageId: 'userlist',
							type: 'success',
							message: response.data.enableUser.message,
							icon: faCheck,
							autoclose: 3000
						})
					)

					setselectedUsers((currentUsers) => {
						return currentUsers.map((user) => {
							return { ...user, active: 'active' }
						})
					})

					selectedUserIds.forEach((id) => {
						setUsers((prevUsers) => {
							return prevUsers.map((user) => {
								if (user.id === id) {
									return { ...user, active: 'active' } // Ensure a new object is created
								}
								return user
							})
						})
					})
				} else if (
					response &&
					response.data.enableUser.status === 500
				) {
					setLoader(false)
					dispatch(
						addAlerts({
							pageId: 'userlist',
							type: 'danger',
							message: response.data.enableUser.message,
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
				} else if (
					response &&
					response.data.enableUser.status !== 200
				) {
					setLoader(false)
					dispatch(
						addAlerts({
							pageId: 'userlist',
							type: 'danger',
							message: response.data.enableUser.errors[0],
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
				}
			})
			.catch((error) => {
				setLoader(false)
				dispatch(removeAlerts({ pageId: 'userlist' }))
				console.error('Response error:', error)
			})
	}

	const handleDeleteUsers = () => {
		if (selectedUsers.length <= 0) {
			dispatch(
				addAlerts({
					pageId: 'userlist',
					type: 'danger',
					message: 'Please select atleast 1 user!',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
			return
		} else {
			setShowModal((prev) => !prev)
		}
	}
	const deleteUser = () => {
		setShowModal(false)
		const selectedUserIds = selectedUsers.map((value) => {
			return value.user_id
		})
		setLoader(true)
		deleteUsers({
			context: {
				headers: {
					authorization: authToken
				}
			},
			variables: {
				user_id: selectedUserIds
			}
		})
			.then((response) => {
				dispatch(removeAlerts({ pageId: 'userlist' }))
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'userlist',
						type: 'success',
						message: 'User Deleted successfully',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				if (response && response.data.deleteUser.status === 200) {
					setselectedUsers([])
					selectedUserIds.forEach((id) => {
						setUsers((prevUsers) => {
							const filteredusers = prevUsers.filter(
								(prevUser) => {
									return prevUser.id !== id
								}
							)
							return filteredusers
						})
					})
				}
			})
			.catch((error) => {
				console.error('Response error:', error)
			})
	}
	const HandleEditUser = () => {
		if (selectedUsers.length <= 0) {
			dispatch(
				addAlerts({
					pageId: 'userlist',
					type: 'danger',
					message: 'Please select  user!',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
			return
		} else if (selectedUsers.length > 1) {
			dispatch(
				addAlerts({
					pageId: 'userlist',
					type: 'danger',
					message: 'Please select only one  user!',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
			return
		} else {
			// console.log('user to edit =>', selectedUsers[0])
			setUserId(selectedUsers[0])
			setIsEditUser((prev) => !prev)
		}
	}
	const HandleResetPassword = () => {
		if (selectedUsers.length <= 0) {
			dispatch(
				addAlerts({
					pageId: 'userlist',
					type: 'danger',
					message: 'Please select atleast 1 user!',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
			return
		} else {
			setIsResetPassword((prev) => !prev)
		}
	}
	const HandleImportUser = () => {
		setIsImportUser((prev) => !prev)
	}
	const handleEnableUsers = () => {
		EnableUsers()
	}
	const headers = [
		{ label: 'User ID', key: 'user_id' },
		{ label: 'Email', key: 'email' },
		{ label: 'Full Name', key: 'fullname' }
	]
	useEffect(() => {
		let disable = false
		let enable = false
		selectedUsers.forEach((user) => {
			setExportData((prev) => {
				if (!prev.some((prevUser) => prevUser.user_id === user.id)) {
					return [
						...prev,
						{
							user_id: user.id,
							email: user.email,
							fullname: user.fullname
						}
					]
				}
				return prev
			})
		})
		selectedUsers.forEach((user) => {
			if (user.active === 'active') {
				disable = true
			} else if (user.active === 'inactive') {
				enable = true
			}
		})
		setIsDisable(disable)
		setIsEnable(enable)
	}, [selectedUsers])

	const HeaderButtons = ({ exportCSV }) => (
		<div className="flex flex-wrap items-center justify-start  gap-[3px]">
			<IconButton
				onClick={() => setIsLayout((prev) => !prev)}
				Icon={faPlus}
				size="sm"
				className="btn btn-large btn-primary flex gap-1.5"
			>
				Layout
			</IconButton>
			<IconButton
				onClick={() => setISAddUser((prev) => !prev)}
				Icon={faPlus}
				size="sm"
				className="btn btn-large btn-primary flex gap-1.5"
			>
				Add User
			</IconButton>

			<IconButton
				onClick={HandleEditUser}
				Icon={faPen}
				size={'sm'}
				disabled={selectedUsers.length === 0}
				className="btn btn-large btn-primary flex gap-1.5"
			>
				Edit User
			</IconButton>
			<IconButton
				Icon={faTrash}
				size={'sm'}
				disabled={selectedUsers.length === 0}
				onClick={handleDeleteUsers}
				className="btn btn-large btn-danger flex gap-1.5"
			>
				Delete User
			</IconButton>
			<IconButton
				Icon={faEyeSlash}
				size={'sm'}
				disabled={!isDisable}
				onClick={handleDisableUsers}
				className="btn btn-large btn-edit flex gap-1.5"
			>
				Disable User
			</IconButton>
			<IconButton
				Icon={faUserSlash}
				size={'sm'}
				disabled={!isEnable}
				onClick={handleEnableUsers}
				className="btn btn-large btn-edit flex gap-1.5"
			>
				Enable User
			</IconButton>
			<IconButton
				onClick={HandleResetPassword}
				Icon={faKey}
				size={'sm'}
				disabled={selectedUsers.length === 0}
				className="btn btn-large btn-primary flex gap-1.5"
			>
				Password Reset
			</IconButton>

			<CSVLink data={exportData} headers={headers}>
				<IconButton
					Icon={faFileExport}
					size={'sm'}
					disabled={selectedUsers.length === 0}
					className={`btn btn-large btn-primary flex gap-1.5`}
					onClick={() => ''}
				>
					Export List
				</IconButton>
			</CSVLink>
			<IconButton
				Icon={faFileArrowDown}
				onClick={HandleImportUser}
				size={'sm'}
				className="btn btn-large btn-primary flex gap-1.5"
			>
				Import User
			</IconButton>
		</div>
	)
	const dopdownOptions = ['All', 'Email', 'FullName']

	const template1 = {
		layout: 'CustomPaginator PrevPageLink PageLinks NextPageLink',
		CustomPaginator: (options) => {
			return (
				<span style={{ marginRight: 'auto' }}>
					Showing {options.first + 1} to{' '}
					{options.first + options.rows} of {options.totalRecords}{' '}
					entries
				</span>
			)
		},
		PrevPageLink: (options) => {
			options.className = classNames(options.className).replace(
				'p-disabled',
				''
			)
			return (
				<Button
					type={'button'}
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={options.onClick}
					// disabled={options.disabled}
					children={'Previous'}
				/>
			)
		},
		NextPageLink: (options) => {
			return (
				<Button
					type="button"
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={(event) => {
						if (!stopfetching) {
							fetchAgain()
							setLimit((prev) => prev + 10)
						}
						options.onClick(event)
					}}
					// disabled={options.disabled}
					children={'Next'}
				/>
			)
		},
		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page &&
					options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, {
					'p-disabled': true
				})

				return (
					<span className={className} style={{ userSelect: 'none' }}>
						...
					</span>
				)
			}
			const isCurrentPage = options.page === options.currentPage
			const linkClassName = classNames(options.className, {
				p: isCurrentPage
			})
			const linkStyle = isCurrentPage
				? {
						backgroundColor: '#7252d3',
						borderRadius: '0',
						color: '#fff'
					}
				: {
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: '#007bff'
					}
			return (
				<Button
					type="button"
					className={linkClassName}
					style={linkStyle}
					onClick={(event) => {
						if (!stopfetching) {
							fetchAgain()
							setLimit((prev) => prev + 10)
						}
						options.onClick(event)
					}}
					children={`${options.page + 1}`}
				/>
			)
		}
	}
	const paginatorLeft = (options) => {
		return (
			<span style={{ marginRight: 'auto' }}>
				Showing {options.first + 1} to{' '}
				{Math.min(options.first + options.rows, options.totalRecords)}{' '}
				entries from {options.totalRecords}
			</span>
		)
	}
	const xlBody = (
		<div className="w-[300px]">
			<p>Are you sure to delete users</p>
		</div>
	)
	const closeHandler = () => {
		setShowModal(false)
	}
	const footer = (
		<>
			<div className="modal-footer flex  items-center justify-between border-t  ">
				<h5 className="invisible font-normal">{'T'}</h5>
				<div className="flex items-center justify-end gap-[3px]">
					<IconButton
						Icon={faBook}
						size={'sm'}
						className="btn btn-large btn-complete flex gap-[6px]"
						type="button"
						onClick={() => setShowModal(false)}
						children="Cancel"
					>
						Cancel
					</IconButton>
					<IconButton />
					<IconButton
						Icon={faSave}
						className="btn-success btn-large flex gap-1.5"
						type="button"
						onClick={() => deleteUser()}
						children="Confirm"
					/>
				</div>
			</div>
		</>
	)
	useEffect(() => {
		const handleScrollPosition = () => {
			if (
				isImportuser ||
				isResetPassword ||
				isEditUser ||
				isAdduser ||
				isLayout
			) {
				const scrollPosition = window.pageYOffset
				document.body.classList.add('scroll-disabled')
				document.body.style.top = `-${scrollPosition}px`
			} else {
				document.body.classList.remove('scroll-disabled')
				const scrollPosition = parseInt(
					document.body.style.top || '0',
					10
				)
				document.body.style.top = ''
				window.scrollTo(0, -scrollPosition)
			}
		}

		setTimeout(() => {
			document.body.classList.remove('scroll-disabled')
		}, 300)

		handleScrollPosition()
	}, [isImportuser, isResetPassword, isEditUser, isAdduser, isLayout])
	return (
		<Card>
			{isLayout && (
				<TableLayout
					setDrawerstate={setIsLayout}
					columns={columns}
					originalColumns={COLUMNS}
					setColumns={setColumns}
				/>
			)}
			{isAdduser && (
				<AddUser setDrawerstate={setISAddUser} fetchUsers={fetchData} />
			)}
			{isEditUser && (
				<EditUser
					setDrawerstate={setIsEditUser}
					userData={userId}
					fetchUsers={fetchData}
					users={users}
					setUsers={setUsers}
					setselectedUsers={setselectedUsers}
				/>
			)}
			{isResetPassword && (
				<ResetPassword
					setDrawerstate={setIsResetPassword}
					USERDATA={selectedUsers}
				/>
			)}
			{isImportuser && (
				<ImportUser
					setDrawerstate={setIsImportUser}
					fetchData={fetchData}
				/>
			)}
			<PageAlerts pageId={'userlist'} />
			{loader && <ProgressBar />}
			<Modal
				modalSize={'lg'}
				title={'Delete User'}
				body={xlBody}
				onClose={closeHandler}
				overLay={'custom'}
				showModal={showModal}
				className={'!z-[1000]'}
				// scrollable={true}
				Footer={footer}
			/>
			<DataTableWrapper
				products={users}
				HeaderButtons={HeaderButtons}
				options={dopdownOptions}
				columns={columns}
				onDataSelection={HandleData}
				template={template1}
				paginatorLeft={paginatorLeft}
				selectedData={selectedUsers}
			/>
		</Card>
	)
}
export default UserList
