import React, { useState, useEffect } from 'react'
import '../../assets/css/ViewProfile/index.css'
import Header from './HeaderTabes'
import Profile from './FormHeader/index.jsx'
import FormButtons from './FormMiddleButtons'
import PersonalInfoForm from './UserProfile/PersonalInfo.jsx'
import BusinessInfo from './UserProfile/BusinessInfo'
import SocialInfo from './UserProfile/SocialInfo'
import AdditionalInfo from './UserProfile/AdditionalInfo'
import Card from '../../components/ui/Card/index.jsx'
import { gql, useApolloClient } from '@apollo/client'
import { useSelector } from 'react-redux'
const GET_USERS = gql`
	query Users($pagination: PaginationInput) {
		users(pagination: $pagination) {
			status
			message
			errors
			data {
				totalCount
				items
			}
		}
	}
`
const ViewProfile = () => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showTab, setShowTab] = useState('01')
	const [users, setUsers] = useState()
	const client = useApolloClient()
	const handleTabesStates = (id) => {
		setShowTab(id)
	}

	const fetchData = async () => {
		try {
			const { data } = await client.query({
				query: GET_USERS,
				variables: {
					pagination: {
						offset: 0,
						limit: 100000
					}
				},
				context: {
					headers: {
						authorization: authToken
					}
				},
				fetchPolicy: 'network-only'
			})
			let users = data.users

			if (users.status === 200) {
				let Users = data.users.data.items.map((user) => user.fullname)
				setUsers(Users)
			}
		} catch (error) {}
	}
	useEffect(() => {
		fetchData()
	}, [])
	return (
		<Card>
			<div className="border">
				<Header onIdChange={handleTabesStates} />
				<div className="p-5">
					<div>
						<Profile />
					</div>
					<div className="divider divider-margin"></div>

					{showTab === '01' && (
						<div>
							<FormButtons
								heading="Personal Information"
								setdrawer="personalinfoedit"
							/>
							<PersonalInfoForm />
						</div>
					)}
					{showTab === '02' && (
						<div>
							<FormButtons
								heading="Business Information"
								setdrawer="busnessinfoedit"
								users={users}
							/>
							<BusinessInfo />
						</div>
					)}
					{showTab === '03' && (
						<div>
							<FormButtons
								heading="Social Information"
								setdrawer="socialinfoedit"
							/>
							<SocialInfo />
						</div>
					)}
					{showTab === '04' && (
						<div>
							<FormButtons
								heading="Additional Information"
								setdrawer="additionalinfoedit"
							/>
							<AdditionalInfo />
						</div>
					)}
				</div>
			</div>
		</Card>
	)
}
export default React.memo(ViewProfile)
