import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { UPDATE_MULTIPLE_SETTINGS } from '../../utils/GraphQl/SettingsMutation'
import { fetchLoginSettings } from '../../store/LoginSettings'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import MultiSelectdropdown from '../../components/Dropdowns/MultiSelectdropdown'
import { logoutUser } from '../../store/AuthSlice/AuthSlice'
import { removePage } from '../../store/SettingsSlice'
import {
	faCheck,
	faTriangleExclamation,
	faBook,
	faUniversalAccess
} from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../components/ui/Buttons/Icon-button'
import Checkbox from '../../components/ui/Form/Checkbox'
import GlobalDrawer from '../GlobalDrawer'
import useGlobalKeyPress from '../../hooks/globalKeyPress'
const DATA = [
	{ id: 0, label: 'Title', value: 'title' },
	{ id: 1, label: 'Full Name', value: 'full_name' },
	{ id: 2, label: 'Dob', value: 'dob' },
	{ id: 3, label: 'Age', value: 'age' },
	{ id: 4, label: 'About', value: 'about' },
	{ id: 5, label: 'Security Clearance', value: 'security_clearance' },
	{ id: 6, label: 'Status', value: 'status' },
	{ id: 7, label: 'Gender', value: 'gender' },
	{ id: 8, label: 'Personal Email Id', value: 'personal_email_id' },
	{ id: 9, label: 'Personal Mobile Number', value: 'personal_mobile_number' },
	{ id: 10, label: 'Citizenship', value: 'citizenship' },
	{ id: 11, label: 'Residency', value: 'residency' },
	{ id: 12, label: 'Dual Citizenship', value: 'dual_citizenship' },
	{ id: 13, label: 'Second Citizenship', value: 'second_citizenship' },
	{
		id: 14,
		label: 'Identification Document',
		value: 'identification_document'
	},
	{ id: 15, label: 'Id Number', value: 'id_number' },
	{ id: 16, label: 'Expiry Date', value: 'expiry_date' },
	{ id: 17, label: 'Issue Location', value: 'issue_location' },
	{ id: 18, label: 'Home Address', value: 'home_address' },
	{ id: 19, label: 'Permenant Address', value: 'permenant_address' },
	{ id: 20, label: 'Home Address City', value: 'home_address_city' },
	{ id: 21, label: 'Home Address State', value: 'home_address_state' },
	{ id: 22, label: 'Home Address Pin Code', value: 'home_address_pin_code' },
	{ id: 23, label: 'Home Address Country', value: 'home_address_country' },
	{
		id: 24,
		label: 'Permanent Address City',
		value: 'permanent_address_city'
	},
	{
		id: 25,
		label: 'Permanent Address Pin Code',
		value: 'permanent_address_pin_code'
	},
	{
		id: 26,
		label: 'Permanent Address State',
		value: 'permanent_address_state'
	},
	{
		id: 27,
		label: 'Permanent Address Country',
		value: 'permanent_address_country'
	},
	{
		id: 28,
		label: 'Emergency Contact label',
		value: 'emergency_contact_label'
	},
	{ id: 29, label: 'Relationship', value: 'relationship' },
	{
		id: 30,
		label: 'Emergency Contact Number',
		value: 'emergency_contact_number'
	},
	{
		id: 31,
		label: 'Emergency Contact Email',
		value: 'emergency_contact_email'
	},
	{ id: 32, label: 'Profile Photo', value: 'profile_photo' },
	{ id: 33, label: 'Employee Id', value: 'employee_id' },
	{ id: 34, label: 'Grade', value: 'grade' },
	{ id: 35, label: 'Designation', value: 'designation' },
	{ id: 36, label: 'Role', value: 'role' },
	{ id: 37, label: 'Date Of Joining', value: 'date_of_joining' },
	{ id: 38, label: 'Clearence', value: 'clearence' },
	{
		id: 39,
		label: 'Reporting Manager Administrative',
		value: 'reporting_manager_administrative'
	},
	{
		id: 40,
		label: 'Reporting Manager Functional',
		value: 'reporting_manager_functional'
	},
	{ id: 41, label: 'Ou1 Group', value: 'ou1_group' },
	{ id: 42, label: 'Ou2 Company', value: 'ou2_company' },
	{ id: 43, label: 'Ou3 Department', value: 'ou3_department' },
	{ id: 44, label: 'Work Email Id', value: 'work_email_id' },
	{ id: 45, label: 'Work Phone Number', value: 'work_phone_number' },
	{ id: 46, label: 'Work Address', value: 'work_address' },
	{ id: 47, label: 'Work Address City', value: 'work_address_city' },
	{ id: 48, label: 'Work Address Pin Code', value: 'work_address_pin_code' },
	{ id: 49, label: 'Work Address State', value: 'work_address_state' },
	{ id: 50, label: 'Work Address Country', value: 'work_address_country' },
	{ id: 51, label: 'Business Address', value: 'business_address' },
	{ id: 52, label: 'Business Address City', value: 'business_address_city' },
	{
		id: 53,
		label: 'Business Address Pin Code',
		value: 'business_address_pin_code'
	},
	{
		id: 54,
		label: 'Business Address State',
		value: 'business_address_state'
	},
	{
		id: 55,
		label: 'Business Address Country',
		value: 'business_address_country'
	},
	{ id: 56, label: 'Linked In', value: 'linked_in' },
	{ id: 57, label: 'Whatsapp', value: 'whatsapp' },
	{ id: 58, label: 'Github', value: 'github' },
	{ id: 59, label: 'Telegram', value: 'telegram' }
]
const LoginSettings = () => {
	const [loader, setLoader] = useState(false)
	const [showDrawer, setShowDrawer] = useState(true)
	const [body, setBody] = useState(null)
	const authToken = useSelector((state) => state.Auth.token)
	const dispatch = useDispatch()
	const { data, loading, error } = useSelector((state) => state.loginSettings)
	const [updateSettings] = useMutation(UPDATE_MULTIPLE_SETTINGS)
	const [Keys, setKeys] = useState('')
	const [saveData, setSaveData] = useState(false)
	const [enquiery, setEnquiery] = useState(false)
	const [lists, setLists] = useState([])
	const [items, setItems] = useState(DATA)
	const add = (data) => {
		setLists([...lists, data])
		setItems(items.filter((item) => item.label !== data.label))
	}
	const removeItem = (item) => {
		setLists((prevLists) =>
			prevLists.filter((listItem) => listItem.label !== item.label)
		)
		setItems([item, ...items])
	}
	useEffect(() => {
		if (loading) {
			// setLoader(true)
		} else if (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'login_settings',
					type: 'danger',
					message: 'Failed to fetch  settings.',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (data?.FieldKeys?.status === 403) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'login_settings',
					type: 'danger',
					message: data.FieldKeys.errors[0],
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (!loading && !error && data) {
			setLoader(false)
			const { FieldKeys, SaveData, ContactEnquiery, MandatoryFields } =
				data
			let inputString = FieldKeys?.data?.value || ''
			let dataEntries = inputString.split(',')
			let ArrayOfObjects = dataEntries.map((item, idx) => {
				let str = item.replace(/_/g, ' ')
				let words = str.split(' ')
				let capitalizedWords = words.map(
					(word) => word.charAt(0).toUpperCase() + word.slice(1)
				)
				let capitalizedStr = capitalizedWords.join(' ')
				return { id: 1000 + idx, value: item, label: capitalizedStr }
			})
			setLists(ArrayOfObjects)
			if (MandatoryFields?.data?.value) {
				let VALUES = JSON.parse(MandatoryFields?.data?.value)
				VALUES.map((val, idx) => {
					let str = val.name.replace(/_/g, ' ')
					let words = str.split(' ')
					let capitalizedWords = words.map(
						(word) => word.charAt(0).toUpperCase() + word.slice(1)
					)
					let capitalizedStr = capitalizedWords.join(' ')
					setItems((prev) => [
						...prev,
						{
							id: 1500 + idx,
							label: capitalizedStr,
							value: val.name
						}
					])
				})
			}

			if (ContactEnquiery?.data?.value === 'true') {
				setEnquiery(true)
			} else {
				setEnquiery(false)
			}
			if (SaveData?.data?.value === 'true') {
				setSaveData(true)
			} else {
				setSaveData(false)
			}
		}
	}, [data, loading, error])

	useEffect(() => {
		const filtered = items.filter(
			(item) => !lists.find((listItem) => listItem.label === item.label)
		)
		setItems(filtered)
	}, [lists])

	useEffect(() => {
		let string = ''
		if (lists.length > 0) {
			lists.map((list) => (string = string + list.value + ','))
			string = string.slice(0, -1)
		}
		setKeys(string)
	}, [lists])
	const handleVerification = async () => {
		try {
			setLoader(true)
			const response = await updateSettings({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						appsetting_mandatory_user_profile_fields: {
							value: Keys
						},
						appsetting_force_update_profile_settings: {
							value: saveData === true ? 'true' : 'false'
						},
						appsetting_check_document_expiry: {
							value: enquiery === true ? 'true' : 'false'
						}
					}
				}
			})
			let data = await response.data
			if (data.updateMultipleSettings.status === 400) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'login_settings',
						type: 'danger',
						message: 'Not found',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateMultipleSettings.status === 403) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'login_settings',
						type: 'danger',
						message: data.updateMultipleSettings.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateMultipleSettings.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'login_settings',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'login_settings' }))
				}, 3000)
			} else if (data.updateMultipleSettings.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'login_settings',
						type: 'danger',
						message:
							'Something went wrong, Please try again later.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'login_settings' }))
				}, 3000)
			} else if (data.updateMultipleSettings.status === 200) {
				setLoader(false)
				dispatch(fetchLoginSettings())
				dispatch(
					addAlerts({
						pageId: 'login_settings',
						type: 'success',
						message: 'Data updated successsfully',
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					handleDrawerClose()
				}, 3000)
			} else if (data.updateMultipleSettings.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'login_settings',
						type: 'danger',
						message: data?.updateMultipleSettings?.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
		} catch (error) {
			setLoader(false)

			dispatch(
				addAlerts({
					pageId: 'updateEmailSettings',
					type: 'danger',
					message: 'error',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const FORM = (
		<form className="w-[350px]">
			<MultiSelectdropdown
				label="Add fields to be mandatory"
				remainingItems={items}
				selectedItems={lists}
				onItemAdd={(item) => add(item)}
				onItemRemove={(item) => removeItem(item)}
			/>

			<div className="form-group">
				<label className="label">
					{' '}
					Check box for force update profile setting
				</label>
				<Checkbox
					id="010"
					label={saveData ? 'Enable' : 'Disable'}
					checked={saveData}
					onChange={() => setSaveData((prev) => !prev)}
					className="check-square"
					iconClass="simple-checkmark"
				/>
			</div>
			<div className="form-group">
				<label className="label">
					Check box for forcing update expired fields
				</label>
				<Checkbox
					id="0010"
					label={enquiery ? 'Enable' : 'Disable'}
					checked={enquiery}
					onChange={() => setEnquiery((prev) => !prev)}
					className="check-square"
					iconClass="simple-checkmark"
				/>
			</div>
		</form>
	)
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])
	useEffect(() => {
		setBody(FORM)
	}, [showDrawer, lists, items, enquiery, saveData])
	const handleDrawerClose = () => {
		dispatch(removeAlerts({ pageId: 'login_settings' }))
		dispatch(removePage())
	}
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal ">{''}</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={() => handleVerification()}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			<GlobalDrawer
				label={'Login Settings'}
				onClose={handleDrawerClose}
				body={body}
				scrollable={true}
				overlay={''}
				size={'md'}
				showDrawer={true}
				drawerFooterBody={drawerFooterBody}
				loader={loader}
				scrollableFooter={false}
				drawerPosition={'right'}
				pageId={'login_settings'}
			/>
		</>
	)
}
export default LoginSettings
