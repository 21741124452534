import { configureStore } from '@reduxjs/toolkit'
import { AuthReducer } from './AuthSlice/AuthSlice'
import { NotificationSliceReducer } from './NotificatinSlice'
import AlertReducer from './AlertSlice'
import { templateSliceReducer } from './TemplateSlice'
import { settingsSliceReducer } from './SettingsSlice'
import registerSettingsReducer from './RegisterSettings'
import LoginSettingsReducer from './LoginSettings'
import ContactUsSettingsReducer from './ContactusSettings'
import FileUploadSettingsReducer from './FileUploadSettings'
import MagicLinkSettingsReducer from './MagicLinkSettings'
import EmailServerSettingsReducer from './EmailServerSettings'
import UserProfileAdditonalFieldsSetting from './AdditionalFieldsSettings'
import { AdditionalInfoFieldsReducer } from './userProfileAdditionalFields'
import { PersonalinfoDynamicFieldsReducer } from './PersonalInfoDynamicFields'
import { BusinessinfoDynamicFieldsReducer } from './BusinessInfoDynamicFields'
import tableLayoutReducer from './GetTableLayouts'
export const store = configureStore({
	reducer: {
		Auth: AuthReducer,
		Notifications: NotificationSliceReducer,
		Alerts: AlertReducer,
		Templates: templateSliceReducer,
		SettingsDrawer: settingsSliceReducer,
		registerSettings: registerSettingsReducer,
		loginSettings: LoginSettingsReducer,
		contactusSettings: ContactUsSettingsReducer,
		fileUploadingSettings: FileUploadSettingsReducer,
		magicLinkSettings: MagicLinkSettingsReducer,
		emailServerSettings: EmailServerSettingsReducer,
		userProfileAdditionalFieldsSettings: UserProfileAdditonalFieldsSetting,
		additionalInfoFields: AdditionalInfoFieldsReducer,
		personalInfoDynamicFields: PersonalinfoDynamicFieldsReducer,
		businessInfoDynamicFields: BusinessinfoDynamicFieldsReducer,
		tableLayouts: tableLayoutReducer
	}
})
