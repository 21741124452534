import React, { useEffect } from 'react'
import DataTableWrapper from '../../components/ui/DataTables'
import { useState } from 'react'
import { classNames } from 'primereact/utils'
import Button from '../../components/ui/Buttons/Button'
import { useSelector, useDispatch } from 'react-redux'
import Badges from '../../components/ui/Badges'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import { logoutUser } from '../../store/AuthSlice/AuthSlice'
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
	GET_CONTEXT_LIST,
	DELETE_ORGANIZATION_CONTEXT
} from '../../utils/GraphQl/OrganizationContext'
import {
	faCheck,
	faEdit,
	faPlus,
	faTrash,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import PageAlerts from '../PageAlerts'
import Edit from './Edit'
import IconButton from '../../components/ui/Buttons/Icon-button'
import Add from './Add'
import ProgressBar from '../ProgressBar'
import TableLayout from './TableLayout'

const OrganizationContext = () => {
	const BodyTemplate = (rowdata) => {
		let color = rowdata.color

		let Message = '! Complete'
		switch (color) {
			case 'badge-success300':
				Message = 'Complete'
				break
			case 'badge-warning300':
				Message = 'Pending'
				break
			case 'badge-danger300':
				Message = 'Incomplete '
				break
			case 'badge-complete300':
				Message = 'Completed'
				break
			default:
				Message = 'In Progress'
				break
		}
		return (
			<Badges
				text={Message}
				className={`${color} badge-default h-5`}
				positionClassName=""
			/>
		)
	}

	let COLUMNS = [
		{
			selectionMode: 'multiple',
			isHidden: false,
			position: 'left',
			field: 'checkbox',
			headerStyle: { width: '2%' },
			style: { width: '2%' },
			className: 'chk-dt chk-primary',
			id: 'primary'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'Id',
			field: 'id'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'Organization Name',
			field: 'organization_name'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'Information Completion',
			field: 'information_completion',
			body: BodyTemplate
		},
		{
			sortable: true,
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'Last Updated Date',
			field: 'last_updated_date'
		}
	]
	const authToken = useSelector((state) => state.Auth.token)
	const TABLE_LAYOUTS = useSelector(
		(state) => state.tableLayouts?.organizationContextLayout
	)
	const [DataTableData, setDataTableData] = useState([])
	const [isAdd, setIsAdd] = useState(false)
	const [isEdit, setIsEdit] = useState(false)
	const [Items, setItems] = useState(null)
	const [selectedData, setSelectedData] = useState([])
	const dispatch = useDispatch()
	const client = useApolloClient()
	const [loader, setLoader] = useState(false)
	const [columns, setColumns] = useState(COLUMNS)
	const [isLayout, setIsLayout] = useState(false)
	const dopdownOptions = ['All']
	const [delete_context] = useMutation(DELETE_ORGANIZATION_CONTEXT)
	useEffect(() => {
		if (TABLE_LAYOUTS.length > 0) {
			TABLE_LAYOUTS.forEach((data) => {
				if (data.table_name === 'table_organization_context') {
					let DATA = data.structure
					if (Array.isArray(DATA) && DATA.length > 0) {
						let NEWDATA = DATA.map((item) => {
							if (item.header == 'Information Completion') {
								return {
									...item,
									body: (rowdata) => {
										let color = rowdata.color

										let Message = '! Complete'
										switch (color) {
											case 'badge-success300':
												Message = 'Complete'
												break
											case 'badge-warning300':
												Message = 'Pending'
												break
											case 'badge-danger300':
												Message = 'Incomplete '
												break
											case 'badge-complete300':
												Message = 'Completed'
												break
											default:
												Message = 'In Progress'
												break
										}
										return (
											<Badges
												text={Message}
												className={`${color} badge-default h-5`}
												positionClassName=""
											/>
										)
									}
								}
							} else {
								return { ...item }
							}
						})
						setColumns(NEWDATA)
						COLUMNS = NEWDATA
					}
				}
			})
		}
	}, [TABLE_LAYOUTS])
	useEffect(() => {
		const handleScrollPosition = () => {
			if (isEdit || isAdd || isLayout) {
				const scrollPosition = window.pageYOffset
				document.body.classList.add('scroll-disabled')
				document.body.style.top = `-${scrollPosition}px`
			} else {
				document.body.classList.remove('scroll-disabled')
				const scrollPosition = parseInt(
					document.body.style.top || '0',
					10
				)
				document.body.style.top = ''
				window.scrollTo(0, -scrollPosition)
			}
		}

		setTimeout(() => {
			document.body.classList.remove('scroll-disabled')
		}, 300)

		handleScrollPosition()
	}, [isEdit, isAdd, isLayout])

	function formatDate(dateString) {
		const date = new Date(dateString)
		const day = String(date.getDate()).padStart(2, '0')
		const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
		const year = date.getFullYear()

		return `${day}-${month}-${year}`
	}
	const fetchContext = async () => {
		try {
			setLoader(true)
			const { data } = await client.query({
				query: GET_CONTEXT_LIST,
				variables: {
					pagination: {
						offset: 0,
						limit: 100000
					}
				},
				context: {
					headers: {
						authorization: authToken
					}
				},
				fetchPolicy: 'network-only'
			})
			let contextList = data.getContextList

			if (contextList.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'organisation_context',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'organisation_context' }))
				}, 3100)
			} else if (contextList.status === 204) {
			} else if (contextList.status === 200) {
				setLoader(false)
				let items = contextList?.data?.items
				const newData = items.map((item) => {
					const badgeColors = [
						'badge-success300',
						'badge-warning300',
						'badge-danger300',
						'badge-complete300',
						'badge-primary300'
					]
					const randomIndex = Math.floor(
						Math.random() * badgeColors.length
					)
					const color = badgeColors[randomIndex]
					return {
						id: items?.id,
						organization_name: item?.data?.organization_overview
							?.organization_name
							? item?.data?.organization_overview
									?.organization_name
							: item?.organization_name,
						color: color,
						last_updated_date: formatDate(item?.updated_at),
						...item
					}
				})

				setDataTableData((prev) => [...prev, ...newData])
			} else if (contextList.status === 403) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'organisation_context',
						type: 'danger',
						message: contextList.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'organisation_context' }))
				}, 3100)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'organisation_context',
					type: 'danger',
					message: 'Something went wrong pleasse try again.',
					icon: faCheck,
					autoclose: 3000
				})
			)
		}
	}
	const DeleteContext = async () => {
		let ids = selectedData.map((item) => item.id)

		try {
			setLoader(true)
			const response = await delete_context({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					contextId: ids
				}
			})
			setLoader(false)
			let data = await response?.data
			let deleteContext = data?.deleteContext
			if (deleteContext.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'organisation_context',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'organisation_context' }))
				}, 3000)
			} else if (deleteContext.status !== 200) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'organisation_context',
						type: 'danger',
						message: deleteContext?.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (deleteContext.status === 200) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'organisation_context',
						type: 'success',
						message: deleteContext.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				let filteredItems = DataTableData.filter(
					(item) => !ids.includes(item.id)
				)
				setDataTableData(filteredItems)
				setSelectedData([])
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'organisation_context' }))
				}, 3000)
			}
		} catch (error) {
			console.log('error ', error)
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'organisation_context',
					type: 'danger',
					message: 'Something went wrong pleasse try again.',
					icon: faCheck,
					autoclose: 3000
				})
			)
		}
	}
	useEffect(() => {
		fetchContext()
	}, [])

	const template1 = {
		layout: 'CustomPaginator PrevPageLink PageLinks NextPageLink',
		CustomPaginator: (options) => {
			return (
				<span style={{ marginRight: 'auto' }}>
					Showing {options.first + 1} to{' '}
					{options.first + options.rows} of {options.totalRecords}{' '}
					entries
				</span>
			)
		},
		PrevPageLink: (options) => {
			options.className = classNames(options.className).replace(
				'p-disabled',
				''
			)
			return (
				<Button
					type={'button'}
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={options.onClick}
					// disabled={options.disabled}
					children={'Previous'}
				/>
			)
		},
		NextPageLink: (options) => {
			return (
				<Button
					type="button"
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={(event) => {
						// if (!stopfetching) {
						// 	fetchAgain()
						// 	setLimit((prev) => prev + 10)
						// }
						options.onClick(event)
					}}
					// disabled={options.disabled}
					children={'Next'}
				/>
			)
		},

		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page &&
					options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, {
					'p-disabled': true
				})

				return (
					<span className={className} style={{ userSelect: 'none' }}>
						...
					</span>
				)
			}
			const isCurrentPage = options.page === options.currentPage
			const linkClassName = classNames(options.className, {
				p: isCurrentPage
			})
			const linkStyle = isCurrentPage
				? {
						backgroundColor: '#7252d3',
						borderRadius: '0',
						color: '#fff'
					}
				: {
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: '#007bff'
					}
			return (
				<Button
					type="button"
					className={linkClassName}
					style={linkStyle}
					onClick={(event) => {
						// if (!stopfetching) {
						// 	fetchAgain()
						// 	setLimit((prev) => prev + 10)
						// }
						options.onClick(event)
					}}
					children={`${options.page + 1}`}
				/>
			)
		}
	}
	const paginatorLeft = (options) => {
		return (
			<span style={{ marginRight: 'auto' }}>
				Showing {options.first + 1} to{' '}
				{Math.min(options.first + options.rows, options.totalRecords)}{' '}
				entries from {options.totalRecords}
			</span>
		)
	}
	const HandleData = (data) => {
		setSelectedData(data)
	}

	const HandleEdit = () => {
		if (selectedData.length > 1) {
			dispatch(
				addAlerts({
					pageId: 'organisation_context',
					type: 'danger',
					message: 'Please Select only one row',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else {
			setIsEdit(true)
		}
	}
	const HeaderButtons = ({ exportCSV }) => (
		<>
			<div className="flex flex-wrap items-center justify-start  gap-[3px]">
				<IconButton
					onClick={() => setIsLayout(true)}
					Icon={faPlus}
					size="sm"
					className="btn btn-large btn-primary flex gap-1.5"
				>
					Layout
				</IconButton>{' '}
				<IconButton
					onClick={() => setIsAdd(true)}
					Icon={faCheck}
					size="sm"
					className="btn btn-large btn-primary flex gap-1.5"
				>
					Add
				</IconButton>{' '}
				<IconButton
					disabled={selectedData.length === 0}
					onClick={HandleEdit}
					Icon={faEdit}
					size={'sm'}
					className="btn btn-large btn-edit flex gap-1.5"
				>
					Edit
				</IconButton>
				<IconButton
					disabled={selectedData.length === 0}
					onClick={DeleteContext}
					Icon={faTrash}
					size={'sm'}
					className="btn btn-large btn-danger flex gap-1.5"
				>
					Delete
				</IconButton>
			</div>{' '}
		</>
	)
	return (
		<>
			{loader && <ProgressBar />}
			{isLayout && (
				<TableLayout
					setDrawerstate={setIsLayout}
					columns={columns}
					originalColumns={COLUMNS}
					setColumns={setColumns}
				/>
			)}
			{isAdd && (
				<Add
					setDrawerstate={setIsAdd}
					setDataTableData={setDataTableData}
				/>
			)}
			{isEdit && (
				<Edit
					DataTableData={DataTableData}
					setDrawerstate={setIsEdit}
					setDataTableData={setDataTableData}
					setSelectedData={setSelectedData}
					selectedData={selectedData}
				/>
			)}
			<PageAlerts pageId="organisation_context" />
			<DataTableWrapper
				products={DataTableData}
				HeaderButtons={HeaderButtons}
				options={dopdownOptions}
				columns={columns}
				onDataSelection={HandleData}
				template={template1}
				paginatorLeft={paginatorLeft}
				selectedData={selectedData}
			/>
		</>
	)
}

export default OrganizationContext
