import React, { useState, useEffect } from 'react'
import Modal from '../../../components/ui/modals'
import TextBox from '../../../components/ui/TextBox'
import Attachment from '../../../components/ui/Attachments'
import KeyValueDropDown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { useSelector } from 'react-redux'
import FalseAttachment from '../../../components/ui/FalseAttachments'
import { useMutation } from '@apollo/client'
import { FILE_UPLOADING_CONTEXT } from '../../../utils/GraphQl/OrganizationContext'
const HoldingCompanyDoctypeOptions = [
	{ label: 'Shareholder Certificates', value: 'Shareholder Certificates' },
	{
		label: ' Ownership Certificates',
		value: ' Ownership Certificates'
	},
	{ label: 'Legal Entity Documents', value: 'Legal Entity Documents' },
	{ label: 'Board Resolutions', value: 'Board Resolutions' },
	{ label: 'Corporate Records', value: 'Corporate Records' },
	{ label: 'Financial Statements', value: 'Financial Statements' },
	{ label: 'Articles of Association', value: 'Articles of Association' },
	{ label: 'BShareholder Agreements', value: 'BShareholder Agreements' }
]
const AddHoldingCompanies = ({
	setShowModal,
	type,
	rowData,
	setSelectedData
}) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [overLay, setOverLay] = React.useState('')
	const [showPromptModal, setShowPromptModal] = useState(true)
	const [scrollable, setScrollable] = React.useState(true)
	const [FILES, setFILES] = useState([])
	const [upload_file] = useMutation(FILE_UPLOADING_CONTEXT)
	const [HoldingCompanyData, setHoldingCompanyData] = useState(
		type === 'edit'
			? rowData[0]
			: {
					holding_company_name: '',
					holding_company_doctype: '',
					holding_company_supporting_document: ''
				}
	)
	useEffect(() => {
		if (type === 'edit') {
			if (rowData) {
				let value = rowData[0]?.holding_company_doctype
				if (value) {
					let index = HoldingCompanyDoctypeOptions.findIndex(
						(item) => item.value === value
					)
					HandleChanges(
						'holding_company_doctype',
						HoldingCompanyDoctypeOptions[index]
					)
				}
			}
		}
	}, [])
	const handleSelectedDocument = async (file) => {
		setFILES([...file])
		let variable = {
			folderName: 'holding_company_supporting_document',
			oldFile: HoldingCompanyData?.holding_company_supporting_document,
			file: file[0]
		}
		try {
			const response = await upload_file({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: variable
			})
			let data = await response?.data
			let uploadFile = data?.uploadFile
			if (uploadFile.status === 200) {
				let value = uploadFile.data
				HandleChanges('holding_company_supporting_document', value)
			} else if (
				uploadFile.status !== 200 &&
				uploadFile.errors.length > 0
			) {
				return
			}
		} catch (err) {
			console.log('err')
		}
	}
	const handleRemoveDocument = (file) => {
		HandleChanges('holding_company_supporting_document', '')
		let filteredarray = FILES.filter((item) => item.id !== file.id)
		setFILES(filteredarray)
	}
	const handleModalClose = () => {
		setShowModal(false)
		setScrollable(false)
		setShowPromptModal(false)
		setOverLay('false')
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		if (type === 'add') {
			let newHoldingCompanyData = {
				...HoldingCompanyData,
				holding_company_doctype:
					HoldingCompanyData?.holding_company_doctype?.value ??
					HoldingCompanyData?.holding_company_doctype
			}
			setSelectedData((prev) => {
				const baseSection = prev[0]?.data?.corporate_structure || {}
				const existingHoldingCompanies = Array.isArray(
					baseSection.holding_companies
				)
					? baseSection.holding_companies
					: []
				const updatedHoldingCompanies = [
					...existingHoldingCompanies,
					{
						id: existingHoldingCompanies.length + 500,
						...newHoldingCompanyData
					}
				]
				const updatedFirstElement = {
					...prev[0],
					data: {
						...prev[0].data,
						corporate_structure: {
							...baseSection,
							holding_companies: updatedHoldingCompanies
						}
					}
				}

				return [updatedFirstElement, ...prev.slice(1)]
			})
		} else if (type === 'edit') {
			setSelectedData((prev) => {
				const newData = [...prev]
				if (newData[0]?.data?.corporate_structure?.holding_companies) {
					let index =
						newData[0]?.data?.corporate_structure?.holding_companies?.findIndex(
							(company) => company.id == rowData[0].id
						)
					if (index !== -1) {
						const updatedCompany = {
							...newData[0]?.data?.corporate_structure
								?.holding_companies[index],
							...HoldingCompanyData,
							holding_company_doctype:
								HoldingCompanyData?.holding_company_doctype
									?.value ??
								HoldingCompanyData?.holding_company_doctype
						}
						newData[0].data.corporate_structure.holding_companies[
							index
						] = updatedCompany
					}
				}
				return newData
			})
		}
		handleModalClose()
	}

	const HandleChanges = (field, value) => {
		setHoldingCompanyData((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}
	const FORM = (
		<div className="w-[350px]">
			<TextBox
				name={'holding_company_name'}
				id={'holding_company_name'}
				value={HoldingCompanyData?.holding_company_name}
				labelName={'Holding Company Name'}
				onChange={(data) => HandleChanges('holding_company_name', data)}
			/>
			<Attachment
				labelName={'Supporting Document'}
				id="holding_company_documnet"
				onSelect={(file) => handleSelectedDocument(file)}
				onRemove={(file) => handleRemoveDocument(file)}
				files={FILES}
			/>
			{HoldingCompanyData?.holding_company_supporting_document &&
				FILES.length == 0 && (
					<FalseAttachment
						filename={
							HoldingCompanyData?.holding_company_supporting_document.split(
								'/'
							)[2]
						}
						onFileRemove={() =>
							HandleChanges(
								'holding_company_supporting_document',
								''
							)
						}
					/>
				)}
			<KeyValueDropDown
				options={HoldingCompanyDoctypeOptions}
				label="Document Type"
				value={HoldingCompanyData?.holding_company_doctype?.label}
				onChange={(data) =>
					HandleChanges('holding_company_doctype', data)
				}
			/>
		</div>
	)
	const footer = (
		<>
			<div className="modal-footer flex  items-center justify-between border-t  ">
				<h5 className="invisible font-normal">{'T'}</h5>
				<div className="flex items-center justify-end gap-[3px]">
					<IconButton
						// disabled={!HoldingCompanyData.response}
						Icon={faSave}
						className="btn-success btn-large flex gap-1.5"
						type="button"
						onClick={handleSubmit}
						children="Use"
					/>
				</div>
			</div>
		</>
	)
	return (
		<div>
			<Modal
				modalSize={'lg'}
				title={
					type === 'add'
						? `Add Holding Companies`
						: 'Edit Holding Companies'
				}
				onClose={handleModalClose}
				body={FORM}
				overLay={overLay}
				showModal={showPromptModal}
				position="!top-[36%] !left-[30%] !z-[99999]"
				className="z-[9999]"
				scrollable={scrollable}
				Footer={footer}
			/>
		</div>
	)
}

export default AddHoldingCompanies
