import parse from 'html-react-parser'
import React from 'react'
import IconButton from '../Buttons/Icon-button'
import { faXmark, faSave, faClose } from '@fortawesome/free-solid-svg-icons'
import classes from '../../../assets/css/Modals/index.module.css'
export default function ModalLarge({ ...props }) {
	return (
		<>
			<div
				className={`${classes.modalLargeContainer} ${props.position}  ${props.extraClass ? 'opacity-100' : 'opacity-0'}`}
			>
				<div className={`${classes.modalLargeContent}`}>
					<div className="px-4 pt-1">
						<div className="modal-header  box-border ">
							<div className={classes.modalHeader}>
								<h5 className="">{props.title}</h5>
								<IconButton
									type="button"
									className={classes.modalHeaderBtn}
									onClick={() => props.onClose()}
									children=""
									Icon={faClose}
									size={'lg'}
								/>
							</div>
						</div>
					</div>
					<div
						className="scroll-bar-css relative flex-auto"
						style={
							props.scrollable
								? {
										overflowY: 'scroll',
										maxHeight: props.maxHeight
											? props.maxHeight
											: '327px',
										minHeight: props.minHeight
											? props.minHeight
											: ''
									}
								: {
										maxHeight: props.maxHeight
											? props.maxHeight
											: '',
										minHeight: props.minHeight
											? props.minHeight
											: ''
									}
						}
					>
						<div className="modal-body p-4">
							<div className="relative flex-auto">
								{props.body}
							</div>
						</div>
					</div>

					<div className="px-4 ">
						{props.Footer ? (
							props.Footer
						) : (
							<div className={classes.modalFooter}>
								<h5 className="font-normal">Additional TexT</h5>
								<div className={classes.modalFooterBtns}>
									<IconButton
										Icon={faXmark}
										className="btn-default btn-large flex gap-1.5"
										// className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
										type="button"
										onClick={() => props.onClose()}
										children="Close"
									/>
									<IconButton
										Icon={faSave}
										// size={'sm'}
										className="btn-success btn-large flex gap-1.5"
										// className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
										type="button"
										onClick={() => props.onSubmit()}
										children="Save Changes"
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
