import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import TextBox from '../../../components/ui/TextBox'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import { UPDATE_SEETINGS } from '../../../utils/GraphQl/SettingsMutation'
import SimpleDropDown from '../../../components/Dropdowns/Simpledropdown'
import { useMutation } from '@apollo/client'
import Button from '../../../components/ui/Buttons/Button'
import FalseAttachments from '../../../components/ui/FalseAttachments'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { UPDATE_ADDITIONAL_FIELDS } from '../../../utils/GraphQl/AdditionalFields'
import Simpledropdown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'

const EditAdditionalFields = ({
	setDrawerstate,
	selectedData,
	Components,
	setComponents,
	SetFetchAgian,
	fieldTypeOptions,
	setPageId,
	scopeSelectorData,
	setLoader,
	setSelectedData
}) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const [updateProfileFields] = useMutation(UPDATE_ADDITIONAL_FIELDS)
	const dispatch = useDispatch()
	const [key, setKeys] = useState({
		key: '',
		value: ''
	})
	const [ArrayofObjects, setArrayofObjects] = useState(
		selectedData[0].options.length > 0 ? selectedData[0].options : []
	)
	let selectedScope = null
	scopeSelectorData.options_data.forEach((item) => {
		if (item.value === selectedData[0]?.scope) {
			selectedScope = item
		}
	})
	const [Formdata, setFormdata] = useState({
		scope: selectedScope,
		field_type_label: selectedData[0]?.field_type_label,
		field_type: selectedData[0]?.field_type,
		field_label: selectedData[0]?.label,
		field_variable: selectedData[0]?.field_name,
		field_description: selectedData[0]?.field_description,
		data_type: selectedData[0]?.data_type
	})

	const handleSubmit = async () => {
		setPageId('edit-additional-fields')
		try {
			setLoader(true)
			const field_id = selectedData[0].field_id
			const response = await updateProfileFields({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					fieldId: field_id,
					input: {
						data_type: Formdata?.data_type,
						field_name: Formdata?.field_variable,
						field_description: Formdata?.field_description,
						field_type: Formdata?.field_type,
						label: Formdata?.field_label,
						scope: Formdata?.scope.value,
						options: ArrayofObjects
					}
				}
			})
			let data = await response.data
			if (data.updateAdditionalField.status === 400) {
				dispatch(
					addAlerts({
						pageId: 'edit-additional-fields',
						type: 'danger',
						message: data.updateAdditionalField.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateAdditionalField.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'edit-additional-fields',
						type: 'danger',
						message: 'Session Expired  please Login again ',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateAdditionalField.status === 200) {
				setLoader(false)
				const filteredComponents = Components.map((value, idx) => {
					if (value.field_id === field_id) {
						let updated_data = data.updateAdditionalField.data
						updated_data.id = idx
						updated_data.field_id = field_id
						updated_data.field_type_label =
							fieldTypeOptions[updated_data.field_type]
						return updated_data
					} else {
						value.id = idx
						return value
					}
				})
				setComponents(filteredComponents)
				// SetFetchAgian((prev) => !prev)
				dispatch(
					addAlerts({
						pageId: 'edit-additional-fields',
						type: 'success',
						message: 'Field Updated successsfully',
						icon: faCheck,
						autoclose: 3000
					})
				)
			} else if (data.updateAdditionalField.status === 403) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'edit-additional-fields',
						type: 'danger',
						message: data.updateAdditionalField.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'edit-additional-fields' }))
				}, 3100)
			} else if (data.updateAdditionalField.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'edit-additional-fields',
						type: 'danger',
						message: data.updateAdditionalField.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'edit-additional-fields' }))
				}, 3100)
			}
			setTimeout(() => {
				setShowDrawer(false)
				setSelectedData([])
				setScrollable(false)
				setScrollableFooter(false)
				setSize('md')
				setOverlay('')
				setBody(null)
				setDrawerstate(false)
				dispatch(removeAlerts({ pageId: 'setting' }))
			}, 3000)
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'edit-additional-fields',
					type: 'danger',
					message: 'error',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const HandleFormData = (key, value) => {
		setFormdata((prev) => {
			if (key === 'field_type') {
				return {
					...prev,
					[key]: value.value,
					field_type_label: value.label
				}
			} else {
				return {
					...prev,
					[key]: value
				}
			}
		})
	}
	const HandleKeyValue = (key, value) => {
		setKeys((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)

	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setDrawerstate(false)
	}

	useEffect(() => {
		const addValue = (e) => {
			e.preventDefault()
			if (!key.key) {
				dispatch(
					addAlerts({
						pageId: 'edit-additional-fields',
						type: 'danger',
						message: 'Please enter key ',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!key.value) {
				dispatch(
					addAlerts({
						pageId: 'edit-additional-fields',
						type: 'danger',
						message: 'Please enter value',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				setArrayofObjects((prev) => [
					...prev,
					{ key: key.key, value: key.value }
				])
				setKeys({
					key: '',
					value: ''
				})
			}
		}
		const handleAttachmentRemove = (object) => {
			console.log('object => ', object)

			const FilteredValues = ArrayofObjects.filter(
				(obj) => obj.key !== object.key
			)
			setArrayofObjects(FilteredValues)
		}
		const FORM = (
			<>
				<form
					encType="multipart/form-data"
					className=" flex w-[350px] flex-col justify-start"
				>
					{scopeSelectorData ? (
						<>
							{scopeSelectorData?.field_type ===
								'simple_dropdown' && (
								<Simpledropdown
									key={'Scope'}
									label={'Scope'}
									options={
										scopeSelectorData.options_data
											? scopeSelectorData.options_data
											: []
									}
									value={Formdata.scope?.label}
									onChange={(data) =>
										HandleFormData('scope', data)
									}
								/>
							)}
						</>
					) : null}
					<Simpledropdown
						label="Field Type"
						key="Field Type"
						options={[
							{
								value: 'elastic-textbox',
								label: 'Elastic Textbox'
							},
							{ value: 'textbox', label: 'Textbox' },
							{ value: 'date', label: 'Date' },
							{ value: 'radio', label: 'Radio' },
							{ value: 'drop-down', label: 'Dropdown' },
							{ value: 'checkbox', label: 'Checkbox' },
							{
								value: 'file-attachment',
								label: 'File Attachment'
							},
							{
								value: 'multi-select-drop-down',
								label: 'Multiselect Dropdown'
							}
						]}
						value={Formdata.field_type_label}
						onChange={(data) => HandleFormData('field_type', data)}
					/>
					{/* <SimpleDropDown
						label="Field Type "
						options={[
							'elastic-textbox',
							'textbox',
							'radio',
							'drop-down',
							'checkbox',
							'file-attachment'
						]}
						value={Formdata.field_type}
						onChange={(data) => HandleFormData('field_type', data)}
					/> */}
					{Formdata.field_type === 'drop-down' && (
						<>
							<div className="flex w-full gap-1.5">
								<TextBox
									id={'key'}
									className="flex-1"
									key="Key"
									labelName="Key"
									value={key.key}
									onChange={(data) =>
										HandleKeyValue('key', data)
									}
								/>
								<TextBox
									id={'value'}
									className="flex-1"
									key="Value"
									labelName="Value"
									value={key.value}
									onChange={(data) =>
										HandleKeyValue('value', data)
									}
								/>
								<Button
									onClick={addValue}
									children="Add"
									key="Add"
									className="btn btn-primary btn-large !mb-[10px] !mt-[31px]"
								/>
							</div>

							{ArrayofObjects.length > 0 &&
								ArrayofObjects.map((obj) => {
									return (
										<FalseAttachments
											filename={obj.key}
											key={obj.key}
											onFileRemove={() =>
												handleAttachmentRemove(obj)
											}
										/>
									)
								})}
						</>
					)}
					{Formdata.field_type === 'multi-select-drop-down' && (
						<>
							<div className="flex w-full gap-1.5">
								<TextBox
									id={'key'}
									className="flex-1"
									key="Key"
									labelName="Key"
									value={key.key}
									onChange={(data) =>
										HandleKeyValue('key', data)
									}
								/>
								<TextBox
									id={'value'}
									className="flex-1"
									key="Value"
									labelName="Value"
									value={key.value}
									onChange={(data) =>
										HandleKeyValue('value', data)
									}
								/>
								<Button
									onClick={addValue}
									children="Add"
									className="btn btn-primary btn-large !mb-[10px] !mt-[31px]"
								/>
							</div>

							{ArrayofObjects.length > 0 &&
								ArrayofObjects.map((obj) => {
									return (
										<FalseAttachments
											filename={obj.key}
											key={obj.key}
											onFileRemove={() =>
												handleAttachmentRemove(obj)
											}
										/>
									)
								})}
						</>
					)}
					{Formdata.field_type === 'checkbox' && (
						<>
							<div className="flex w-full gap-1.5">
								<TextBox
									id={'key_next'}
									className="flex-1"
									key="label"
									labelName="label"
									value={key.key}
									onChange={(data) =>
										HandleKeyValue('key', data)
									}
								/>
								<TextBox
									id={'value_next'}
									className="flex-1"
									key="Value"
									labelName="Value"
									value={key.value}
									onChange={(data) =>
										HandleKeyValue('value', data)
									}
								/>
								<Button
									onClick={addValue}
									key="Add"
									children="Add"
									className="btn btn-primary btn-large !mb-[10px] !mt-[31px]"
								/>
							</div>

							{ArrayofObjects.length > 0 &&
								ArrayofObjects.map((obj) => {
									return (
										<FalseAttachments
											key={obj.key}
											filename={obj.key}
											onFileRemove={() =>
												handleAttachmentRemove(obj)
											}
										/>
									)
								})}
						</>
					)}
					{Formdata.field_type === 'radio' && (
						<>
							<>
								<div className="flex w-full gap-1.5">
									<TextBox
										id={'name'}
										className="flex-1"
										key="Name"
										labelName="Name"
										value={key.key}
										onChange={(data) =>
											HandleKeyValue('key', data)
										}
									/>
									<TextBox
										id={'name_value'}
										className="flex-1"
										key="Value"
										labelName="Value"
										value={key.value}
										onChange={(data) =>
											HandleKeyValue('value', data)
										}
									/>
									<Button
										onClick={addValue}
										key="Add"
										children="Add"
										className="btn btn-primary btn-large !mb-[10px] !mt-[31px]"
									/>
								</div>

								{ArrayofObjects.length > 0 &&
									ArrayofObjects.map((obj) => {
										return (
											<FalseAttachments
												filename={obj.key}
												key={obj.key}
												onFileRemove={() =>
													handleAttachmentRemove(obj)
												}
											/>
										)
									})}
							</>
						</>
					)}

					<TextBox
						id={'label'}
						key={'label'}
						value={Formdata.field_label}
						type={'text'}
						labelName={'Field Label '}
						onChange={(data) => HandleFormData('field_label', data)}
					/>

					<TextBox
						id={'label_value'}
						key={'label_value'}
						value={Formdata.field_variable}
						labelName={'Field Variable Name'}
						onChange={(data) =>
							HandleFormData('field_variable', data)
						}
					/>
					<TextBox
						id={'description'}
						key={'description'}
						value={Formdata.field_description}
						labelName={'Field Description'}
						onChange={(data) =>
							HandleFormData('field_description', data)
						}
					/>
					<TextBox
						id={'datatype'}
						value={Formdata.data_type}
						key={'Data Type'}
						labelName={'Data Type'}
						onChange={(data) => HandleFormData('data_type', data)}
					/>
				</form>
			</>
		)
		setBody(FORM)
	}, [showDrawer, Formdata, key, ArrayofObjects])
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "> </h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={handleSubmit}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	return (
		<>
			<Drawer
				label="Update Additional Fields"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default EditAdditionalFields
