import { createSlice } from '@reduxjs/toolkit'
const InitialState = {
	Notifications: [],
	Actions: [],
	loader: false
}
const NotificationSlice = createSlice({
	name: 'notification slice',
	initialState: InitialState,
	reducers: {
		AddNotifications: (state, action) => {
			// console.log(action.payload)
			state.Notifications = action.payload.notifications
			state.Actions = action.payload.actions
		},
		setLoader: (state, action) => {
			state.loader = action.payload
		}
	}
})

export const { AddNotifications, RemoveNotification, setLoader } =
	NotificationSlice.actions
export const NotificationSliceReducer = NotificationSlice.reducer
