import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import FormHeader from '../../components/Common/FormHeader'
import FormBody from '../../components/Common/FormBody'
import Formfooter from '../../components/Common/FormFooter'
import AuthLayout from '../../Layouts/AuthLayout'
import TextBox from '../../components/ui/TextBox'
import Button from '../../components/ui/Buttons/Button'
import IconButton from '../../components/ui/Buttons/Icon-button'
import Icon from '../../components/ui/icon'
import { useDispatch } from 'react-redux'
import { loginUser } from '../../store/AuthSlice/AuthSlice'
import { faCheck, faLink } from '@fortawesome/free-solid-svg-icons'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import { LOGIN_MUTATION } from '../../utils/GraphQl/LoginMutation'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import PageAlerts from '../PageAlerts'
const Login = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [loader, setLoader] = useState(false)
	const [userData, setUserData] = useState({
		email: '',
		password: ''
	})
	const [login] = useMutation(LOGIN_MUTATION)
	const handleLogin = async () => {
		try {
			setLoader(true)
			const response = await login({
				variables: {
					input: {
						email: userData.email,
						password: userData.password
					}
				}
			})
			let data = await response.data
			if (data.login.errors) {
				setLoader(false)

				dispatch(
					addAlerts({
						pageId: 'login',
						type: 'danger',
						message: data.login.errors.map((msg) => msg),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.login.status === 429) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'login',
						type: 'danger',
						message: data.login.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (
				data.login.status === 200 &&
				data.login.data.user.active === false
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'login',
						type: 'danger',
						message: 'Please activate your account before login.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (
				data.login.status === 200 &&
				data.login.data.user.active === true
			) {
				let userdata = data.login.data
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'login',
						type: 'success',
						message: 'Congratulations! You are login successfully.',
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(loginUser(userdata))
					dispatch(removeAlerts({ pageId: 'login' }))
					navigate('/')
				}, 3000)
			} else {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'login',
						type: 'danger',
						message: 'something went wrong',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'login',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const HandleChange = (key, value) => {
		setUserData((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}
	const HandleSubmit = (event) => {
		event.preventDefault()
		const PSW_REGEXP =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/
		const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		let isValidEmail = EMAIL_REGEXP.test(userData.email)
		let isValidPassword = PSW_REGEXP.test(userData.password)
		if (
			!userData.email ||
			!isValidEmail ||
			!userData.password ||
			!isValidPassword
		) {
			if (!userData.email && !userData.password) {
				dispatch(
					addAlerts({
						pageId: 'login',
						type: 'danger',
						message: 'Please enter valid credentials.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!userData.email) {
				dispatch(
					addAlerts({
						pageId: 'login',
						type: 'danger',
						message: 'Please enter your email address.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!userData.password) {
				dispatch(
					addAlerts({
						pageId: 'login',
						type: 'danger',
						message: 'Please enter your password.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!isValidEmail) {
				dispatch(
					addAlerts({
						pageId: 'login',
						type: 'danger',
						message: 'Please enter valid email address.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!isValidPassword) {
				dispatch(
					addAlerts({
						pageId: 'login',
						type: 'danger',
						message: 'Please enter a valid credentials.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				dispatch(
					addAlerts({
						pageId: 'login',
						type: 'danger',
						message: 'Please enter valid credentials.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
		} else {
			handleLogin()
		}
	}
	const HandleKeyPress = (e) => {
		if (e.key === 'Enter') handleLogin()
	}
	return (
		<>
			<PageAlerts pageId={'login'} />
			<AuthLayout>
				<FormHeader
					title="Sign In"
					message="Welcome! Please signin to continue."
				/>
				<FormBody>
					<TextBox
						id="email_address"
						name="email_address"
						onChange={(data) => HandleChange('email', data)}
						labelName="Email Address"
						type={'text'}
						className={'mb-4'}
						value={userData.email}
					/>
					<div className="relative">
						<Link
							to="/forgot-password"
							className="absolute right-0 top-[5px] text-sm text-[#C70039]"
						>
							Forgot Password
						</Link>
						<TextBox
							id={'password'}
							name={'password'}
							OnKeyPress={HandleKeyPress}
							type="password"
							labelName="Password"
							className={'mb-4'}
							onChange={(data) => HandleChange('password', data)}
							value={userData.password}
						/>
					</div>

					<div className="mb-3">
						<p className="small-text">
							By clicking <strong>Sign In</strong> below, you
							agree to our Terms and Conditions and Privacy
							Policy.
						</p>
					</div>

					{loader ? (
						<Button
							className="btn-large btn-primary btn-all flex  w-full cursor-not-allowed gap-2"
							isIconBtn={true}
							onClick={() => console.log('')}
						>
							<span className="waiting-loader"> </span> Please
							Wait
						</Button>
					) : (
						<Button
							className="btn-large btn-primary btn-all flex  w-full gap-2 "
							isIconBtn={true}
							onClick={HandleSubmit}
						>
							<Icon
								name="line-signup"
								width={'20px'}
								height={'20px'}
								className={'icon-fill-white'}
								viewBox={'0 0 24 24'}
							/>{' '}
							Sign In
						</Button>
					)}

					<div className="divider">
						<span>or sign in with</span>
					</div>

					<div className="flex w-full gap-1">
						<div className="flex-1">
							<Link to={'/sendmagiclink'}>
								<IconButton
									className="btn-large btn-primary btn-all flex w-full gap-2 p-1"
									children={'Magic Link'}
									Icon={faLink}
								/>
							</Link>
						</div>

						{/* <div className="w-[50%]">
							<Link to={'/pages'}>
								<Button
									className="btn-large btn-primary btn-all flex w-full gap-2 p-1"
									isIconBtn={true}
								>
									<Icon
										name="line-phone-lock"
										width={'16px'}
										height={'16px'}
										className={'icon-fill-white'}
										viewBox={'0 0 24 24'}
									/>{' '}
									OTP
								</Button>
							</Link>
						</div> */}
					</div>
				</FormBody>

				<Formfooter
					message2="Need assistance or have questions"
					linktext2="Contact Support"
					link2="contact-us"
					message1="Don't have an account"
					linktext1="Create an Account"
					link1="register"
				/>
			</AuthLayout>
		</>
	)
}
export default Login
