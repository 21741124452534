import React from 'react'
import '../../assets/css/ProgressBar/index.css'
const ProgressBar = () => {
	return (
		<div
			className="fixed left-0 top-0 z-[2000] flex h-screen w-full items-center justify-center bg-black"
			style={{ opacity: '0.7' }}
		>
			<div className="flex items-center justify-center ">
				<div className="loader"></div>
			</div>
		</div>
	)
}
export default ProgressBar
