import { gql } from '@apollo/client'
export const CHANGEPASSWORD_MUTATION = gql`
	mutation ChangePassword($input: changePasswordInput!) {
		changePassword(input: $input) {
			status
			message
			errors
		}
	}
`
