import { gql } from '@apollo/client'

export const ADD_ADDITIONAL_FIELDS = gql`
	mutation Mutation($input: createInput!) {
		createAdditionalField(input: $input) {
			status
			message
			data
			errors
		}
	}
`
export const UPDATE_ADDITIONAL_FIELDS = gql`
	mutation ($fieldId: Int!, $input: createInput!) {
		updateAdditionalField(field_id: $fieldId, input: $input) {
			status
			message
			data
			errors
		}
	}
`
export const DELETE_ADDITIONAL_FIELDS = gql`
	mutation DeleteAdditionalField($fieldIds: [Int]) {
		deleteAdditionalField(field_ids: $fieldIds) {
			status
			message
			data
			errors
		}
	}
`
export const GET_ADDITIONAL_FIELDS_BY_SCOPE = gql`
	query GetAllAdditionalFieldsByScope($scope: String) {
		getAllAdditionalFieldsByScope(scope: $scope) {
			status
			message
			errors
			data
		}
	}
`
