import { gql } from '@apollo/client'
export const GET_CONTEXT_LIST = gql`
	query GetContext($pagination: PaginationInput) {
		getContextList(pagination: $pagination) {
			status
			message
			errors
			data
		}
	}
`
export const UPDATE_ORGANIZATION_CONTEXT = gql`
	mutation UpdateContext($input: updateContextInput!) {
		updateContext(input: $input) {
			status
			message
			errors
			data
		}
	}
`
export const CREATE_ORGANIZATION_CONTEXT = gql`
	mutation CreateContext($input: createContextInput!) {
		createContext(input: $input) {
			status
			message
			errors
			data
		}
	}
`
export const DELETE_ORGANIZATION_CONTEXT = gql`
	mutation DeleteContext($contextId: [Int]) {
		deleteContext(context_id: $contextId) {
			status
			message
			errors
		}
	}
`

export const FILE_UPLOADING_CONTEXT = gql`
	mutation Mutation($file: Upload!, $folderName: String, $oldFile: String) {
		uploadFile(file: $file, folder_name: $folderName, old_file: $oldFile) {
			data
			errors
			message
			status
		}
	}
`
