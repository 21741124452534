import React, { useRef, useState, useEffect } from 'react'
import { DATA } from './Data'
import PropTypes from 'prop-types'
import '../../../assets/css/PhoneSelector/index.css'
const PhoneSelector = ({
	labelName,
	currentCountry,
	onCountrySelect,
	onUSerInputChange,
	placeholder = '0123456789',
	onClick,
	onFocus,
	onBlur,
	autoFocus
}) => {
	const [showCountriesList, setShowCountriesList] = useState(false)
	const [country, setCountry] = useState(currentCountry)
	// remain in sync with data
	useEffect(() => {
		setCountry(currentCountry)
	}, [currentCountry])

	//collect validate and pass the user input to the callback function
	const HandleUserInput = (event) => {
		const numericValue = event.target.value.replace(/[^0-9]/g, '')
		onUSerInputChange({
			Number: numericValue
		})
	}
	const CountriesRef = useRef()

	// selected country flag,name and dialcode/countryCode is passed to callabck function
	const HandleCountrySelector = (country) => {
		onCountrySelect({
			countryCode: country.phoneCode,
			countryName: country.name,
			countryFlag: country.alpha2
		})
		setShowCountriesList(false)
	}

	// close the drop down on outside click
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				CountriesRef.current &&
				!CountriesRef.current.contains(event.target)
			) {
				setShowCountriesList(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<>
			<div className="form-group relative">
				{labelName && <label className="label">{labelName}</label>}
				<div
					ref={CountriesRef}
					className="input-outer relative w-full "
				>
					<div className="relative  h-8 rounded-sm border border-[#A3A2A0] px-2.5 ">
						<div className="flex items-center text-sm">
							<div
								className="flex cursor-pointer items-center"
								onClick={() =>
									setShowCountriesList((prev) => !prev)
								}
							>
								<div className="h-[22px] w-[22px]">
									<img
										src={require(
											`./images/${country.countryFlag}.png`
										)}
										width={'100%'}
										height={'100%'}
										className=" object-contain"
									/>
								</div>

								<div className="mx-1">
									{country.countryCode}
								</div>
								<div
									className={`phone-icon ${
										showCountriesList ? 'rotate-icon' : ''
									}`}
								></div>
							</div>
							<div className="flex-1">
								<input
									placeholder={placeholder}
									onChange={HandleUserInput}
									value={country.Number}
									onClick={onClick}
									onFocus={onFocus}
									onBlur={onBlur}
									autoFocus={autoFocus}
									type="text"
									className="form-control !h-[30px] !border-none pr-0 !outline-none "
								/>
							</div>
						</div>
					</div>
					<div
						className={`${
							showCountriesList ? 'block' : 'hidden'
						} absolute top-[31.5px] z-50 max-h-[200px] w-full  overflow-y-scroll  border border-gray-400 bg-white`}
					>
						<ul>
							{DATA.map((country) => {
								return (
									<li
										onClick={() =>
											HandleCountrySelector(country)
										}
										key={country.id}
										className="flex cursor-pointer px-2.5 py-[5px] text-sm hover:bg-[#F5F5F5]"
									>
										<div className="h-[22px] w-[22px]">
											<img
												src={require(
													`./images/${country.alpha2}.png`
												)}
												alt={country.name}
												width={'100%'}
												height={'100%'}
											/>
										</div>

										<span className="mx-1.5">
											{country.name}
										</span>

										<span> {country.phoneCode}</span>
									</li>
								)
							})}
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}
PhoneSelector.propTypes = {
	labelName: PropTypes.string,
	currentCountry: PropTypes.object,
	onCountrySelect: PropTypes.func,
	onUserInputChange: PropTypes.func,
	placeholder: PropTypes.string,
	onClick: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	autoFocus: PropTypes.bool
}
export default React.memo(PhoneSelector)
