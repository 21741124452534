import React, { useState } from 'react'

import classes from '../../../assets/css/Modals/index.module.css'
import IconButton from '../Buttons/Icon-button'

import { faPlus, faClose } from '@fortawesome/free-solid-svg-icons'
export default function ModalAuto({ ...props }) {
	const [extraContent, setExtraContent] = useState('')
	const addComponent = () => {
		setExtraContent(
			extraContent + '<div className="text-sm mr-3">ABC</div>'
		)
	}
	return (
		<>
			<div
				className={`${classes.modalAutoContainer} ${props.extraClass ? 'opacity-100' : 'opacity-0'}`}
			>
				<div className={`${classes.modalAutoContent}`}>
					<div className="px-4 pt-1">
						<div className="modal-header  box-border ">
							<div className={`${classes.modalHeader}`}>
								<h5 className="h5">{props.title}</h5>
								<IconButton
									type="button"
									className={classes.modalHeaderBtn}
									onClick={() => props.onClose()}
									children=""
									Icon={faClose}
									size={'lg'}
								/>
							</div>
						</div>
					</div>
					<div
						className="scroll-bar-css relative flex-auto"
						style={
							props.scrollable
								? {
										overflowY: 'scroll',
										maxHeight: props.maxHeight
											? props.maxHeight
											: '327px',
										minHeight: props.minHeight
											? props.minHeight
											: ''
									}
								: {
										maxHeight: props.maxHeight
											? props.maxHeight
											: '',
										minHeight: props.minHeight
											? props.minHeight
											: ''
									}
						}
					>
						<div className="modal-body p-4">
							<div className="relative flex-auto">
								{props.body}
							</div>
							{extraContent !== '' ? (
								<div className="flex overflow-auto py-2">
									{extraContent}
								</div>
							) : null}
						</div>
					</div>
					{/* <div className="modal-footer p-4">
                    <div className="flex items-center justify-end">
                        <Button className="btn btn-primary btn-large" type="button" children="Add Component" onClick={() => addComponent()}></Button>
                    </div>
                </div> */}

					<div className="px-4  ">
						<div className={classes.modalFooter}>
							<h5 className="h5 font-normal">Additional Text</h5>
							<IconButton
								Icon={faPlus}
								type="button"
								className="btn btn-primary btn-large flex gap-1.5"
								children="Add Component"
								onClick={() => addComponent()}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
