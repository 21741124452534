import { gql } from '@apollo/client'
export const CREATE_BASE = gql`
	mutation CreateBase($input: BaseInput!) {
		createBase(input: $input) {
			status
			message
			errors
			data
		}
	}
`
export const DELETE_BASE = gql`
	mutation DeleteBase($baseId: [Int]) {
		deleteBase(base_id: $baseId) {
			status
			message
			errors
			data
		}
	}
`
export const UPDATE_BASE = gql`
	mutation EditBase($baseId: Int, $input: BaseInput!) {
		editBase(base_id: $baseId, input: $input) {
			status
			message
			errors
			data
		}
	}
`
export const IMPORT_BASE = gql`
	mutation ImportKnowledgeBaseData($file: Upload!) {
		importKnowledgeBaseData(file: $file) {
			status
			message
			errors
			data
		}
	}
`
export const CREATE_CATEGORY = gql`
	mutation CreateCategoryList($input: categoryListInput!) {
		createCategoryList(input: $input) {
			status
			message
			data
			errors
		}
	}
`
export const EDIT_CATEGORY = gql`
	mutation EditCategoryList($input: editCategoryListInput!, $listId: Int) {
		editCategoryList(input: $input, list_id: $listId) {
			status
			message
			data
			errors
		}
	}
`
export const DELETE_CATEGORY = gql`
	mutation DeleteCategoryList($listId: [Int]) {
		deleteCategoryList(list_id: $listId) {
			status
			message
			errors
			data
		}
	}
`
export const IMPORT_LIST_CATEGORY = gql`
	mutation ImportCategoryListData($file: Upload!) {
		importCategoryListData(file: $file) {
			status
			message
			data
			errors
		}
	}
`
export const LOAD_FILE = gql`
	mutation ($file: Upload!) {
		loadFile(file: $file) {
			data
			status
			message
			errors
		}
	}
`
