import React, { useState } from 'react'
import AuthLayout from '../../Layouts/AuthLayout'
import FormHeader from '../../components/Common/FormHeader'
import FormBody from '../../components/Common/FormBody'
import Formfooter from '../../components/Common/FormFooter'
import Button from '../../components/ui/Buttons/Button'
import Icon from '../../components/ui/icon'
import ElasticTextBox from '../../components/ui/Elastic-textbox'
import TextBox from '../../components/ui/TextBox'
import { useMutation } from '@apollo/client'
import { CONTACT_US_MUTATION } from '../../utils/GraphQl/ContactUSMutation'
import { useNavigate } from 'react-router-dom'
import PageAlerts from '../PageAlerts'
import { useDispatch } from 'react-redux'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'

const Contact = () => {
	const [loader, setLoader] = useState(false)
	const [userData, setUserData] = useState({
		fullname: '',
		email: '',
		subject: '',
		message: ''
	})
	const dispatch = useDispatch()
	const [contactus] = useMutation(CONTACT_US_MUTATION)
	const navigate = useNavigate()
	const submitForm = async () => {
		try {
			setLoader(true)
			const response = await contactus({
				variables: {
					input: {
						fullname: userData.fullname,
						email: userData.email,
						subject: userData.subject,
						message: userData.message
					}
				}
			})
			let data = await response.data
			if (data.contact.status === 400) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'contact',
						type: 'danger',
						message: data.contact.errors.map((msg) => msg),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.contact.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'contact',
						type: 'danger',
						message: data.contact.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.contact.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'contact',
						type: 'danger',
						message: data.contact.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.contact.status === 200) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'contact',
						type: 'success',
						message: data.contact.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'contact' }))
					navigate('/')
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'contact',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const HandleChange = (key, value) => {
		setUserData((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}

	const HandleSubmit = (event) => {
		event.preventDefault()
		const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		let isValidEmail = EMAIL_REGEXP.test(userData.email)
		if (
			!userData.fullname ||
			!userData.email ||
			!isValidEmail ||
			!userData.subject ||
			!userData.message
		) {
			if (!userData.fullname) {
				dispatch(
					addAlerts({
						pageId: 'contact',
						type: 'danger',
						message: 'Please enter your full name.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!userData.email) {
				dispatch(
					addAlerts({
						pageId: 'contact',
						type: 'danger',
						message: 'Please enter your email address.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!isValidEmail) {
				dispatch(
					addAlerts({
						pageId: 'contact',
						type: 'danger',
						message: 'Please enter valid email address.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!userData.subject) {
				dispatch(
					addAlerts({
						pageId: 'contact',
						type: 'danger',
						message: 'Please enter your subject.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!userData.message) {
				dispatch(
					addAlerts({
						pageId: 'contact',
						type: 'danger',
						message: 'Please enter your message.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				dispatch(
					addAlerts({
						pageId: 'contact',
						type: 'danger',
						message:
							'Something went wrong. Please try again after sometime.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
		} else {
			submitForm()
		}
	}
	return (
		<>
			<PageAlerts pageId={'contact'} />
			<AuthLayout>
				<FormHeader
					title="ContactSupport"
					message="Welcome! Please use below form to contact."
				/>
				<FormBody>
					<TextBox
						id={'fullname'}
						value={userData.fullname}
						onChange={(data) => HandleChange('fullname', data)}
						labelName="Full Name"
						type={'text'}
						className="mb-4"
					/>

					<TextBox
						id={'email'}
						value={userData.email}
						onChange={(data) => HandleChange('email', data)}
						labelName="Email Address"
						type={'text'}
						className={'mb-4'}
					/>

					<TextBox
						id={'subject'}
						value={userData.subject}
						onChange={(data) => HandleChange('subject', data)}
						labelName="Subject"
						type={'text'}
						className={'mb-4'}
					/>

					<ElasticTextBox
						id="users_message"
						value={userData.message}
						labelName="Message"
						type={'text'}
						className="!mb-4"
						onChange={(data) => HandleChange('message', data)}
					/>

					<div className="mb-3">
						<p className="small-text">
							By clicking <strong>Submit</strong> below, you agree
							to our Terms and Conditions and Privacy Policy.
						</p>
					</div>

					{loader ? (
						<Button
							className="btn-large btn-primary btn-all flex  w-full cursor-not-allowed gap-2 "
							isIconBtn={true}
							onClick={() => console.log('')}
						>
							<span className="waiting-loader"> </span> Please
							Wait
						</Button>
					) : (
						<Button
							className="btn-large btn-primary btn-all flex w-full gap-2 p-1"
							onClick={HandleSubmit}
						>
							<Icon
								name="line-user-contact"
								width={'16px'}
								height={'16px'}
								className={'icon-fill-white'}
								viewBox={'0 0 24 24'}
							/>{' '}
							Submit
						</Button>
					)}
				</FormBody>
				<Formfooter
					message1="Already have an account"
					linktext1="Sign In"
					link1="/"
					message2="Don't have an account"
					linktext2="Create an Account"
					link2="register"
				/>
			</AuthLayout>
		</>
	)
}

export default Contact
