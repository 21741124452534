import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Editor from './Editor'
import '../../../assets/css/RichTextBox/index.css'
const RichTextbox = ({ onChange, value, labelName, setEditorRef }) => {
	const editorRef = useRef(null)

	useEffect(() => {
		if (setEditorRef && typeof setEditorRef === 'function') {
			setEditorRef(editorRef)
		}
	}, [setEditorRef])

	return (
		<div className="form-group">
			{labelName && <label className="label">{labelName}</label>}
			<div className="input-outer">
				<div>
					<Editor onChange={onChange} value={value} ref={editorRef} />
				</div>
			</div>
		</div>
	)
}

RichTextbox.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	labelName: PropTypes.string,
	setEditorRef: PropTypes.func
}

export default React.memo(RichTextbox)
