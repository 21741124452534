import React, { useState, useEffect } from 'react'
import {
	faCheck,
	faTriangleExclamation,
	faBook,
	faUniversalAccess
} from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../components/ui/Buttons/Icon-button'
import { useDispatch, useSelector } from 'react-redux'
import GLobalDrawer from '../GlobalDrawer'
import { removePage } from '../../store/SettingsSlice'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import ElasticTextBox from '../../components/ui/Elastic-textbox'
import { useMutation } from '@apollo/client'
import { UPDATE_MULTIPLE_SETTINGS } from '../../utils/GraphQl/SettingsMutation'
import { logoutUser } from '../../store/AuthSlice/AuthSlice'
import BtnTextbox from '../../components/ui/BtnTextbox'
import Checkbox from '../../components/ui/Form/Checkbox'
import useGlobalKeyPress from '../../hooks/globalKeyPress'
import { fetchRegisterSettings } from '../../store/RegisterSettings'
const RegisterSettings = () => {
	const authToken = useSelector((state) => state.Auth.token)
	const { data, loading, error } = useSelector(
		(state) => state.registerSettings
	)
	const [showDrawer, setShowDrawer] = useState(true)
	const [body, setBody] = useState(null)
	const dispatch = useDispatch()
	const [loader, setLoader] = useState(false)
	const [updateSettings] = useMutation(UPDATE_MULTIPLE_SETTINGS)
	const [verifyCheck, setVerifyCheck] = useState(false)
	const [options, setOptions] = useState([])
	const [FormData, setFormData] = useState({
		passwordPolicy: ''
	})
	useEffect(() => {
		if (loading) {
			setLoader(true)
		} else if (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'Register_settings',
					type: 'danger',
					message: 'Failed to fetch  settings.',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (data?.Domains?.status === 403) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'Register_settings',
					type: 'danger',
					message: data.Domains.errors[0],
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (!loading && !error && data) {
			setLoader(false)
			const { Domains, PasswordPolicy, verifyCheck } = data
			setOptions(Domains?.data?.value.split(','))
			setFormData({
				passwordPolicy: PasswordPolicy?.data?.value || ''
			})
			if (verifyCheck?.data?.value === 'true') {
				setVerifyCheck(true)
			} else {
				setVerifyCheck(false)
			}
		}
	}, [data, loading, error])

	const handleVerification = async () => {
		let transformedOptions = options?.join(',')
		try {
			setLoader(true)
			const response = await updateSettings({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						appsetting_allowed_email: {
							value: transformedOptions
						},
						appsetting_password_policy_v2: {
							value: FormData.passwordPolicy
						},
						appsetting_verify_email_check: {
							value: verifyCheck === true ? 'true' : 'false'
						}
					}
				}
			})
			let data = await response.data
			if (data.updateMultipleSettings.status === 400) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'Register_settings',
						type: 'danger',
						message: 'Not found',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateMultipleSettings.status === 403) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'Register_settings',
						type: 'danger',
						message: data.updateMultipleSettings.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateMultipleSettings.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'Register_settings',
						type: 'danger',
						message: data.updateMultipleSettings.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateMultipleSettings.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'Register_settings',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)

				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'Register_settings' }))
				}, 3000)
			} else if (data.updateMultipleSettings.status === 200) {
				setLoader(false)
				dispatch(fetchRegisterSettings())
				dispatch(
					addAlerts({
						pageId: 'Register_settings',
						type: 'success',
						message: 'Data updated successsfully',
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					handleDrawerClose()
				}, 3000)
			}
		} catch (error) {
			console.error('update failed', error)
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'Register_settings',
					type: 'danger',
					message: 'error',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	const HandleChange = (key, value) => {
		setFormData((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}
	const handleRemove = (data) => {
		let filteredData = options.filter((item) => item !== data)
		setOptions(filteredData)
	}
	const AddItem = (data) => {
		if (!data) {
			dispatch(
				addAlerts({
					pageId: 'Register_settings',
					type: 'danger',
					message: 'Please Enter a valid value',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else {
			setOptions((prev) => [...prev, data])
		}
	}

	const FORM = (
		<form
			encType="multipart/form-data"
			className=" flex w-[350px] flex-col justify-start"
		>
			<>
				<BtnTextbox
					label={'Acceptable Domains'}
					selectedItems={options}
					onItemAdd={(data) => AddItem(data)}
					onItemRemove={(item) => handleRemove(item)}
				/>

				<ElasticTextBox
					id="password_policy"
					value={FormData.passwordPolicy}
					labelName={'Password Policy'}
					onChange={(data) => HandleChange('passwordPolicy', data)}
				/>

				<div className="form-group">
					<label className="label"> Verify Check</label>
					<Checkbox
						id="01"
						label={verifyCheck ? 'Enable' : 'Disable'}
						checked={verifyCheck}
						onChange={() => setVerifyCheck((prev) => !prev)}
						className="check-square"
						iconClass="simple-checkmark"
					/>
				</div>
			</>
		</form>
	)
	const handleDrawerClose = () => {
		dispatch(removeAlerts({ pageId: 'Register_settings' }))
		dispatch(removePage())
	}
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])

	useEffect(() => {
		setBody(FORM)
	}, [showDrawer, options, FormData, verifyCheck])

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)

	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal ">{''}</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={() => handleVerification()}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)
	return (
		<>
			<GLobalDrawer
				label={'Register Settings'}
				onClose={handleDrawerClose}
				body={body}
				scrollable={true}
				overlay={''}
				size={'md'}
				showDrawer={true}
				drawerFooterBody={drawerFooterBody}
				loader={loader}
				drawerPosition={'right'}
				pageId={'Register_settings'}
			/>
		</>
	)
}

export default RegisterSettings
