import React, { useEffect, useState } from 'react'
import { Calendar } from 'primereact/calendar'
import PropTypes from 'prop-types'
import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/saga-blue/theme.css'
import '../../../assets/css/DatePicker/index.css'
const DatePicker = ({
	labelName,
	onDateChange,
	currentDate,
	...extraProps
}) => {
	const [selectedDate, setSelectedDate] = useState(currentDate)

	//pass the selected date to callback function (onDateChange)
	const handleDateChange = (event) => {
		const originalDate = new Date(event.value)
		const options = { year: 'numeric', month: 'short', day: 'numeric' }
		const formattedDate = originalDate.toLocaleDateString(
			undefined,
			options
		)
		setSelectedDate(formattedDate)
		onDateChange(formattedDate)
	}

	return (
		<div className="form-group">
			<label className="label">{labelName}</label>
			<div className="input-outer">
				<div className="input-container !font-interui relative">
					<Calendar
						value={new Date(selectedDate)}
						onChange={handleDateChange}
						dateFormat="dd M yy"
						placeholder="Choose date"
						showIcon
						{...extraProps}
					/>
				</div>
			</div>
		</div>
	)
}

DatePicker.propTypes = {
	labelName: PropTypes.string.isRequired,
	onDateChange: PropTypes.func.isRequired,
	// currentDate: PropTypes,
	extraProps: PropTypes.object
}

export default React.memo(DatePicker)
