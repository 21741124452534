import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import TextBox from '../../../components/ui/TextBox'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import Attachments from '../../../components/ui/Attachments'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import {
	CREATE_CATEGORY,
	LOAD_FILE
} from '../../../utils/GraphQl/KnowledgeBase'
import { logoutUser } from '../../../store/AuthSlice/AuthSlice'
import Simpledropdown from '../../../components/Dropdowns/SimpledropdownOptionKey'

import Button from '../../../components/ui/Buttons/Button'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const AddList = ({
	setIsAddList,
	setDATA,
	setRecodrsCount,
	setPageId,
	setLoader
}) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const [Files, setFiles] = useState([])
	const dispatch = useDispatch()
	const [createCategory] = useMutation(CREATE_CATEGORY)
	const [loadFile] = useMutation(LOAD_FILE)
	const [Keys, setKeys] = useState([])
	const [Formdata, setFormdata] = useState({
		name: '',
		type: '',
		keys: '',
		values: ''
	})
	const HandleLoadFile = async () => {
		try {
			setLoader(true)
			const response = await loadFile({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					file: Files[0]
				}
			})
			let data = await response.data
			let Bases = data?.loadFile
			if (Bases.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'add-list',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'add-list' }))
				}, 3000)
			} else if (Bases.status !== 200 && Bases.errors.length > 0) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'add-list',
						type: 'danger',
						message: Bases.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (Bases.status === 200) {
				setLoader(false)
				setKeys(Bases.data)
				dispatch(
					addAlerts({
						pageId: 'add-list',
						type: 'success',
						message: Bases.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'add-list',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const HandleCreateCategory = async () => {
		try {
			setLoader(true)
			const response = await createCategory({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						name: Formdata.name,
						json: Files[0],
						field_type: Formdata.type?.value,
						label_key: Formdata.keys.value,
						value_key: Formdata.values.value
					}
				}
			})
			let data = await response.data
			let Bases = data?.createCategoryList
			if (Bases.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'add-list',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'add-list' }))
				}, 3000)
			} else if (Bases.status !== 201 && Bases.errors.length > 0) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'add-list',
						type: 'danger',
						message: Bases.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (Bases.status === 201) {
				setLoader(false)
				let NewField = Bases.data
				setDATA((prev) => [
					...prev,
					{
						...NewField,
						field: NewField.json_data.length,
						type: NewField.field_type.replace('_', ' ')
					}
				])
				setRecodrsCount((prev) => prev + 1)
				dispatch(
					addAlerts({
						pageId: 'add-list',
						type: 'success',
						message: Bases.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					setShowDrawer(false)
					setScrollable(false)
					setScrollableFooter(false)
					setSize('md')
					setOverlay('')
					setBody(null)
					setIsAddList(false)
					dispatch(removeAlerts({ pageId: 'add-list' }))
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'add-list',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const HANDLEFORMDATA = (field, value) => {
		setFormdata((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}
	const OPTIONS = [
		{ label: 'Simpe Dropdown', value: 'simple_dropdown' },
		{ label: 'Multiselect Dropdown', value: 'multiselect_dropdown' },
		{ label: 'Checkbox', value: 'checkbox' },
		{ label: 'Radio', value: 'radio' }
	]
	const HandleSelectedFile = (file) => {
		if (file[0]?.name?.endsWith('.json')) {
			setFiles([file[0]])
		} else {
			dispatch(
				addAlerts({
					pageId: 'add-list',
					type: 'danger',
					message: 'Please upload a valid JSON file',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const RemoveFIle = (files) => {
		let FIlteredFiles = Files.filter((file) => file.name !== files.name)
		setFiles(FIlteredFiles)
	}
	useEffect(() => {
		setPageId('add-list')
	}, [])

	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setIsAddList(false)
	}

	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "> </h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={HandleCreateCategory}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') {
			handleDrawerClose()
		}
	}

	useGlobalKeyPress(handleKeyPress)
	return (
		<>
			<Drawer
				label="Add List"
				size={size}
				body={
					<>
						<form
							encType="multipart/form-data"
							className=" flex w-[350px] flex-col justify-start"
						>
							<>
								<div className="flex w-[350px] flex-col justify-start ">
									<TextBox
										id={'name'}
										labelName={'Name'}
										value={Formdata.name}
										onChange={(data) =>
											HANDLEFORMDATA('name', data)
										}
									/>

									<Simpledropdown
										label="Select Type"
										value={Formdata.type.label}
										onChange={(data) =>
											HANDLEFORMDATA('type', data)
										}
										options={OPTIONS}
									/>
									<div className="relative ">
										<Attachments
											accept={'.json'}
											id="import_jsondata_list"
											labelName={'Select Json File'}
											files={Files}
											onSelect={(file) =>
												HandleSelectedFile(file)
											}
											onRemove={RemoveFIle}
										/>
										<Button
											disabled={Files.length === 0}
											onClick={HandleLoadFile}
											children="Load File"
											positionClass="!absolute !right-0 !top-0 !mb-2.5 !mt-[30px]"
											className="btn-primary btn-large "
										/>
									</div>
								</div>
								<Simpledropdown
									label="Label Mapping"
									value={Formdata.keys.label}
									onChange={(data) =>
										HANDLEFORMDATA('keys', data)
									}
									options={Keys}
								/>
								<Simpledropdown
									label="Value Mapping"
									value={Formdata.values.label}
									onChange={(data) =>
										HANDLEFORMDATA('values', data)
									}
									options={Keys}
								/>
							</>
						</form>{' '}
					</>
				}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default AddList
