import React, { useEffect } from 'react'
import DataTableWrapper from '../../components/ui/DataTables'
import { useState } from 'react'
import { classNames } from 'primereact/utils'
import Button from '../../components/ui/Buttons/Button'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '../../components/ui/Buttons/Icon-button'
import {
	faPlus,
	faPen,
	faTrash,
	faFileArrowDown,
	faFileExport,
	faTriangleExclamation,
	faCheck,
	faBook
} from '@fortawesome/free-solid-svg-icons'
import AddList from './AddList'
import EditList from './EditList'
import ImportList from './ImportList'
import { gql, useApolloClient, useMutation } from '@apollo/client'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import { logoutUser } from '../../store/AuthSlice/AuthSlice'
import { DELETE_CATEGORY } from '../../utils/GraphQl/KnowledgeBase'
import GlobalDrawer from '../GlobalDrawer'
import { removePage } from '../../store/SettingsSlice'
import useGlobalKeyPress from '../../hooks/globalKeyPress'
import TableLayout from './TableLayout'
const GET_LISTS = gql`
	query GetAllCategoryLists($pagination: PaginationInput) {
		getAllCategoryLists(pagination: $pagination) {
			status
			message
			data
			errors
		}
	}
`
const ListPage = () => {
	let COLUMNS = [
		{
			selectionMode: 'multiple',
			isHidden: false,
			position: 'left',
			field: 'checkbox',
			headerStyle: { width: '2%' },
			style: { width: '2%' },
			className: 'chk-dt chk-primary',
			id: 'primary'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },

			header: 'Id',
			field: 'id'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'Name',
			field: 'name'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'Type',
			field: 'type'
		}
	]
	const [pageId, setPageId] = useState('list-category')
	const TABLE_LAYOUTS = useSelector(
		(state) => state.tableLayouts?.listPageLayout
	)
	const [showDrawer, setShowDrawer] = useState(true)
	const [body, setBody] = useState(null)
	const userdata = useSelector((state) => state.Auth)
	const [showAddList, setShowAddList] = useState(false)
	const [showEditList, setShowEditList] = useState(false)
	const [showImportList, setShowImportList] = useState(false)
	const [limit, setLimit] = useState(60)
	const [stopfetching, setStopFetching] = useState(false)
	const authToken = userdata.token
	const [Components, setComponents] = useState([])
	const [RecordsCount, setRecodrsCount] = useState()
	const [SelectedData, setSelectedData] = useState([])
	const [loader, setLoader] = useState(false)
	const client = useApolloClient()
	const dispatch = useDispatch()
	const [deleteCategory] = useMutation(DELETE_CATEGORY)
	const [columns, setColumns] = useState(COLUMNS)
	const [isLayout, setIsLayout] = useState(false)
	const dopdownOptions = ['All', 'Name', 'Type']
	useEffect(() => {
		if (TABLE_LAYOUTS.length > 0) {
			TABLE_LAYOUTS.forEach((data) => {
				if (data.table_name === 'table_list_page') {
					setColumns(data.structure)
					COLUMNS = data.structure
				}
			})
		}
	}, [TABLE_LAYOUTS])
	const fetchData = async () => {
		try {
			setLoader(true)
			const { data } = await client.query({
				query: GET_LISTS,
				variables: {
					pagination: {
						offset: 0,
						limit: limit
					}
				},
				context: {
					headers: {
						authorization: authToken
					}
				},
				fetchPolicy: 'network-only'
			})
			let bases = data?.getAllCategoryLists
			if (bases.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'list-category',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'list-category' }))
				}, 3100)
			} else if (bases.status === 204) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'list-category',
						type: 'danger',
						message: 'No data found',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (bases.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'list-category',
						type: 'danger',
						message: bases.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (bases.status === 200) {
				setLoader(false)
				let FinalData = bases.data.items
				setRecodrsCount(bases.data.totalCount)
				FinalData.forEach((item) => {
					if (
						!Components.find(
							(existingItem) => existingItem.id === item.id
						)
					) {
						setComponents((prev) => [
							...prev,
							{
								...item,
								field: item?.json_data?.length,
								type: item?.field_type.replace('_', ' ')
							}
						])
					}
				})
			} else if (bases.status === 403) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'list-category',
						type: 'danger',
						message: bases.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'list-category' }))
				}, 3100)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'list-category',
					type: 'danger',
					message: 'Something went wrong pleasse try again.',
					icon: faCheck,
					autoclose: 3000
				})
			)
		}
	}
	useEffect(() => {
		fetchData()
	}, [])

	const fetchAgain = async () => {
		try {
			// setLoader(true)
			const { data } = await client.query({
				query: GET_LISTS,
				variables: {
					pagination: {
						offset: limit,
						limit: 10
					}
				},
				context: {
					headers: {
						authorization: authToken
					}
				}
			})

			let bases = data?.getAllCategoryLists

			if (bases.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'list-category',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'list-category' }))
				}, 3100)
			} else if (bases.status === 204) {
				setLoader(false)
				setStopFetching(true)
			} else if (bases.status === 200) {
				setLoader(false)
				let FinalData = bases.data.items
				setRecodrsCount(bases.data.totalCount)
				FinalData.forEach((item) => {
					if (
						!Components.find(
							(existingItem) => existingItem.id === item.id
						)
					) {
						setComponents((prev) => [
							...prev,
							{
								...item,
								field: item?.fields?.split(',').length,
								type: item?.field_type.replace('_', ' ')
							}
						])
					}
				})
			} else if (bases.status === 403) {
				dispatch(
					addAlerts({
						pageId: 'list-category',
						type: 'danger',
						message: bases.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'list-category' }))
				}, 3100)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'list-category',
					type: 'danger',
					message: 'Something went wrong pleasse try again.',
					icon: faCheck,
					autoclose: 3000
				})
			)
		}
	}

	const HandleDeleteCategory = async (BASE_ID) => {
		setPageId('list-category')
		try {
			setLoader(true)
			const response = await deleteCategory({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					listId: BASE_ID
				}
			})
			let DeleteBase = await response.data.deleteCategoryList

			if (DeleteBase.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'list-category',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'list-category' }))
				}, 3000)
			} else if (
				DeleteBase.status !== 200 &&
				DeleteBase.errors.length > 0
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'list-category',
						type: 'danger',
						message: DeleteBase.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (DeleteBase.status === 200) {
				setLoader(false)
				fetchData()
				setSelectedData([])
				setRecodrsCount((prev) => prev - BASE_ID.length)
				dispatch(
					addAlerts({
						pageId: 'list-category',
						type: 'success',
						message: DeleteBase.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				const filteredComponents = Components.filter(
					(item) => !BASE_ID.includes(item.id)
				)
				setComponents(filteredComponents)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'list-category' }))
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'list-category',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	const template1 = {
		layout: 'CustomPaginator PrevPageLink PageLinks NextPageLink',
		CustomPaginator: (options) => {
			return (
				<span style={{ marginRight: 'auto' }}>
					Showing {options.first + 1} to{' '}
					{options.first + options.rows} of {options.totalRecords}{' '}
					entries
				</span>
			)
		},
		PrevPageLink: (options) => {
			options.className = classNames(options.className).replace(
				'p-disabled',
				''
			)
			return (
				<Button
					type={'button'}
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={options.onClick}
					// disabled={options.disabled}
					children={'Previous'}
				/>
			)
		},
		NextPageLink: (options) => {
			return (
				<Button
					type="button"
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={(event) => {
						if (!stopfetching) {
							fetchAgain()
							setLimit((prev) => prev + 10)
						}
						options.onClick(event)
					}}
					// disabled={options.disabled}
					children={'Next'}
				/>
			)
		},
		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page &&
					options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, {
					'p-disabled': true
				})

				return (
					<span className={className} style={{ userSelect: 'none' }}>
						...
					</span>
				)
			}
			const isCurrentPage = options.page === options.currentPage
			const linkClassName = classNames(options.className, {
				p: isCurrentPage
			})
			const linkStyle = isCurrentPage
				? {
						backgroundColor: '#7252d3',
						borderRadius: '0',
						color: '#fff'
					}
				: {
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: '#007bff'
					}
			return (
				<Button
					type="button"
					className={linkClassName}
					style={linkStyle}
					onClick={(event) => {
						if (!stopfetching) {
							fetchAgain()
							setLimit((prev) => prev + 10)
						}
						options.onClick(event)
					}}
					children={`${options.page + 1}`}
				/>
			)
		}
	}
	const paginatorLeft = (options) => {
		return (
			<span style={{ marginRight: 'auto' }}>
				Showing {options.first + 1} to{' '}
				{Math.min(options.first + options.rows, options.totalRecords)}{' '}
				entries from {RecordsCount}
			</span>
		)
	}
	const HandleAddData = () => {
		setShowAddList(true)
	}
	const HandleEditUser = () => {
		if (SelectedData.length < 1) {
			dispatch(
				addAlerts({
					pageId: 'list-category',
					type: 'danger',
					message: 'Please select field',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (SelectedData.length > 1) {
			dispatch(
				addAlerts({
					pageId: 'list-category',
					type: 'danger',
					message: 'Please select only one field',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (SelectedData.length === 1) {
			setShowEditList((prev) => !prev)
		}
	}
	const handleDeleteList = () => {
		if (SelectedData.length === 0) {
			dispatch(
				addAlerts({
					pageId: 'list-category',
					type: 'danger',
					message: 'Please slect data to delete',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else {
			let base_ids = []
			SelectedData.forEach((item) => base_ids.push(item.id))

			HandleDeleteCategory(base_ids)
		}
	}
	const HandleImportList = () => {
		setShowImportList(true)
	}
	const exportDataAsJson = () => {
		if (SelectedData.length < 1) {
			dispatch(
				addAlerts({
					pageId: 'list-category',
					type: 'danger',
					message: 'Please slect fields to download',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (SelectedData.length > 0) {
			const jsonData = JSON.stringify(SelectedData)
			const blob = new Blob([jsonData], { type: 'application/json' })
			const url = URL.createObjectURL(blob)
			const link = document.createElement('a')
			link.href = url
			link.download = 'exported_data.json'
			link.click()
			URL.revokeObjectURL(url)
		}
	}
	const HeaderButtons = ({ exportCSV }) => (
		<>
			<div className="flex flex-wrap items-center justify-start  gap-[3px]">
				<IconButton
					onClick={() => setIsLayout((prev) => !prev)}
					Icon={faPlus}
					size="sm"
					className="btn btn-large btn-primary flex gap-1.5"
				>
					Layout
				</IconButton>
				<IconButton
					onClick={HandleAddData}
					Icon={faPlus}
					size="sm"
					className="btn btn-large btn-primary flex gap-1.5"
				>
					Add List
				</IconButton>
				<IconButton
					disabled={SelectedData.length === 0}
					onClick={HandleEditUser}
					Icon={faPen}
					size={'sm'}
					className="btn btn-large btn-edit flex gap-1.5"
				>
					Edit List
				</IconButton>
				<IconButton
					disabled={SelectedData.length === 0}
					Icon={faTrash}
					size={'sm'}
					onClick={handleDeleteList}
					className="btn btn-large btn-danger flex gap-1.5"
				>
					Delete List
				</IconButton>

				<IconButton
					disabled={SelectedData.length === 0}
					Icon={faFileExport}
					size={'sm'}
					className="btn btn-large btn-primary flex gap-1.5"
					onClick={exportDataAsJson}
				>
					Export List
				</IconButton>

				<IconButton
					Icon={faFileArrowDown}
					onClick={HandleImportList}
					size={'sm'}
					className="btn btn-large btn-primary flex gap-1.5"
				>
					Import List
				</IconButton>
			</div>{' '}
		</>
	)
	const HandleData = (data) => {
		setSelectedData(data)
	}
	const FORM = (
		<>
			{isLayout && (
				<TableLayout
					setLoader={setLoader}
					setPageId={setPageId}
					setDrawerstate={setIsLayout}
					columns={columns}
					originalColumns={COLUMNS}
					setColumns={setColumns}
				/>
			)}

			{showAddList && (
				<AddList
					setIsAddList={setShowAddList}
					setDATA={setComponents}
					setRecodrsCount={setRecodrsCount}
					setPageId={setPageId}
					setLoader={setLoader}
				/>
			)}
			{showEditList && (
				<EditList
					setIsEditList={setShowEditList}
					SelectedData={SelectedData}
					setListSelectedData={setSelectedData}
					setData={Components}
					setPageId={setPageId}
					setLoader={setLoader}
				/>
			)}
			{showImportList && (
				<ImportList
					setIsImportList={setShowImportList}
					ExistingData={Components}
					setPageId={setPageId}
					setLoader={setLoader}
				/>
			)}
			<DataTableWrapper
				products={Components}
				HeaderButtons={HeaderButtons}
				options={dopdownOptions}
				columns={columns}
				onDataSelection={HandleData}
				template={template1}
				paginatorLeft={paginatorLeft}
				selectedData={SelectedData}
			/>
		</>
	)
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])
	useEffect(() => {
		setBody(FORM)
	}, [
		showDrawer,
		SelectedData,
		Components,
		showImportList,
		showEditList,
		columns,
		TABLE_LAYOUTS,
		showAddList,
		isLayout
	])
	const handleDrawerClose = () => {
		dispatch(removeAlerts({ pageId: 'file-upload-settings' }))
		dispatch(removePage())
	}
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal ">{''}</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				{/* <IconButton
					onClick={() => handleFileSettings()}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton> */}
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') {
			if (!showAddList && !showEditList && !isLayout && !showImportList) {
				handleDrawerClose()
			}
		}
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			<GlobalDrawer
				label={'List Page'}
				onClose={handleDrawerClose}
				body={body}
				scrollable={true}
				overlay={''}
				size={'70%'}
				showDrawer={true}
				drawerFooterBody={drawerFooterBody}
				loader={loader}
				scrollableFooter={false}
				drawerPosition={'right'}
				pageId={pageId}
			/>
		</>
	)
}
export default ListPage
