import React, { useEffect, useState } from 'react'
import { gql, useApolloClient, useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import { logoutUser } from '../../store/AuthSlice/AuthSlice'
import Button from '../../components/ui/Buttons/Button'
import SimpledropdownOptionKey from '../../components/Dropdowns/SimpledropdownOptionKey'
import { LIST_MAPPING_MUTATION } from '../../utils/GraphQl/ListMappingMutation'
import { LIST_MAPPING_USING_KEY_MUTATION } from '../../utils/GraphQl/ListMappingUsingKeyMutation'
import GlobalDrawer from '../GlobalDrawer'
import { removePage } from '../../store/SettingsSlice'
import {
	faCheck,
	faTriangleExclamation,
	faBook,
	faUniversalAccess
} from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../components/ui/Buttons/Icon-button'
import useGlobalKeyPress from '../../hooks/globalKeyPress'
const ListMapping = () => {
	const [showDrawer, setShowDrawer] = useState(true)
	const [body, setBody] = useState(null)
	const userdata = useSelector((state) => state.Auth)
	const authToken = userdata.token
	const client = useApolloClient()
	const GET_LISTS = gql`
		query ($keys: [String], $pagination: PaginationInput) {
			getMultipleSettings(keys: $keys) {
				status
				message
				errors
				data
			}
			getAllCategoryLists(pagination: $pagination) {
				status
				message
				data
				errors
			}
		}
	`
	const [options, setOptions] = useState([])
	const [loader, setLoader] = useState(false)
	const dispatch = useDispatch()
	const [selectedValues, setSelectedValues] = useState({})
	const [mapList] = useMutation(LIST_MAPPING_MUTATION)
	const [mapListUsingKey] = useMutation(LIST_MAPPING_USING_KEY_MUTATION)

	const fetchData = async () => {
		setLoader(true)
		try {
			const { data } = await client.query({
				query: GET_LISTS,
				variables: {
					keys: [
						'appsetting_knowledgebase_category_id',
						'appsetting_risk_catalogue_category_id',
						'appsetting_risk_catalogue_tags_id',
						'appsetting_additional_fields_scope_id',
						'appsetting_user_profile_identification_document_id',
						'appsetting_user_profile_relationship_id',
						'appsetting_user_profile_gender_id',
						'appsetting_user_profile_status_id',
						'appsetting_user_profile_title_id',
						'appsetting_user_profile_identification_ou3_group_id',
						'appsetting_user_profile_identification_ou2_group_id',
						'appsetting_user_profile_identification_ou1_group_id',
						'appsetting_user_profile_identification_reporting_manager_functional_id',
						'appsetting_user_profile_identification_reporting_manager_admin_id',
						'appsetting_user_profile_identification_clearence_id',
						'appsetting_user_profile_identification_grade_id'
					],
					pagination: {
						offset: 0,
						limit: 100000000
					}
				},
				context: {
					headers: {
						authorization: authToken
					}
				}
				// fetchPolicy: 'network-only'
			})
			let bases = data?.getAllCategoryLists
			let multipleSettings = data?.getMultipleSettings

			if (bases.status === 401 || multipleSettings.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'list-mapping',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'list-mapping' }))
				}, 3100)
			} else if (
				bases.status === 204 ||
				multipleSettings.status === 204
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'list-mapping',
						type: 'danger',
						message: 'No data found',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (
				bases.status === 403 ||
				multipleSettings.status === 403
			) {
				setLoader(false)
				if (bases.status === 403) {
					dispatch(
						addAlerts({
							pageId: 'list-mapping',
							type: 'danger',
							message: bases?.errors[0],
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
				}
			} else if (
				bases.status === 500 ||
				multipleSettings.status === 500
			) {
				setLoader(false)
				if (bases.status === 500) {
					dispatch(
						addAlerts({
							pageId: 'list-mapping',
							type: 'danger',
							message: bases.message,
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
				} else if (multipleSettings.status === 500) {
					dispatch(
						addAlerts({
							pageId: 'list-mapping',
							type: 'danger',
							message: multipleSettings.message,
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
				}
			} else if (bases.status === 200) {
				setLoader(false)
				let FinalData = bases.data.items
				FinalData.forEach((item) => {
					if (
						!options.find(
							(existingItem) => existingItem.value == item.id
						)
					) {
						setOptions((prev) => [
							...prev,
							{ label: item.name, value: item.id }
						])
					}
					multipleSettings?.data.forEach((setting) => {
						if (item.id == setting.value) {
							setSelectedValues((prev) => ({
								...prev,
								[setting['key']]: {
									label: item.name,
									value: item.id
								}
							}))
						}
					})
				})
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'list-mapping',
					type: 'danger',
					message: 'Something went wrong pleasse try again.',
					icon: faCheck,
					autoclose: 3000
				})
			)
		}
	}
	useEffect(() => {
		fetchData()
	}, [])

	const handleSubmit = async () => {
		try {
			if (!selectedValues.value || selectedValues.value === undefined) {
				dispatch(
					addAlerts({
						pageId: 'list-mapping',
						type: 'danger',
						message: 'Please select fist!',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
			setLoader(true)

			const response = await mapList({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					category_id: selectedValues.value
				}
			})

			const data = response.data
			let RES = data?.mapKnowledgeBaseList
			if (RES.status === 200) {
				setLoader(false)
				fetchData()
				dispatch(
					addAlerts({
						pageId: 'list-mapping',
						type: 'success',
						message: 'Settings  updated successfully',
						icon: faCheck,
						autoclose: 3000
					})
				)
			} else if (RES.status === 400) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'list-mapping',
						type: 'danger',
						message: RES?.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (RES.status === 401) {
				dispatch(
					addAlerts({
						pageId: 'list-mapping',
						type: 'danger',
						message: 'Session expired please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'list-mapping' }))
				})
			}
		} catch (error) {
			dispatch(
				addAlerts({
					pageId: 'list-mapping',
					type: 'danger',
					message: 'something went wront!',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const handleListMapping = async (key) => {
		try {
			if (
				!selectedValues[key].value ||
				selectedValues[key].value === undefined
			) {
				dispatch(
					addAlerts({
						pageId: 'list-mapping',
						type: 'danger',
						message: 'Please select fist!',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
			setLoader(true)

			const response = await mapListUsingKey({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					key: key,
					category_id: selectedValues[key].value
				}
			})

			const data = response.data
			let RES = data?.mapListUsingKey
			if (RES.status === 200) {
				setLoader(false)
				fetchData()
				dispatch(
					addAlerts({
						pageId: 'list-mapping',
						type: 'success',
						message: 'Settings  updated successfully',
						icon: faCheck,
						autoclose: 3000
					})
				)
			} else if (RES.status === 400) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'list-mapping',
						type: 'danger',
						message: RES?.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (RES.status === 401) {
				dispatch(
					addAlerts({
						pageId: 'list-mapping',
						type: 'danger',
						message: 'Session expired please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'list-mapping' }))
				})
			}
		} catch (error) {
			console.log('error =>', error)
			dispatch(
				addAlerts({
					pageId: 'list-mapping',
					type: 'danger',
					message: 'something went wront!',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const HandleSelectedValue = (data) => {
		setSelectedValues((prev) => ({
			...prev,
			...data
		}))
	}
	const FORM = (
		<>
			<form className="max-w-[800px]">
				<table className="listmapping w-full">
					<thead>
						<tr>
							<th className="">Fields</th>
							<th>Select List</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="w-[60%]">
								Knowledgebase/business-objective/category
							</td>
							<td className="w-[40%]">
								<SimpledropdownOptionKey
									label=""
									value={
										selectedValues.appsetting_knowledgebase_category_id
											? selectedValues
													?.appsetting_knowledgebase_category_id
													?.label
											: ''
									}
									options={options}
									className={'mb-0 '}
									onChange={(data) =>
										HandleSelectedValue({
											appsetting_knowledgebase_category_id:
												{
													label: data.label,
													value: data.value
												}
										})
									}
								/>
							</td>
							<td>
								<Button
									size="sm"
									type="button"
									onClick={handleSubmit}
									className="btn btn-large btn-primary flex gap-1.5"
								>
									Update
								</Button>
							</td>
						</tr>
						<tr>
							<td className="w-[60%]">
								Risk Management/Risk Catalogue/category
							</td>
							<td className="w-[40%]">
								<SimpledropdownOptionKey
									label=""
									value={
										selectedValues.appsetting_risk_catalogue_category_id
											? selectedValues
													?.appsetting_risk_catalogue_category_id
													?.label
											: ''
									}
									options={options}
									className={'mb-0 '}
									onChange={(data) =>
										HandleSelectedValue({
											appsetting_risk_catalogue_category_id:
												{
													label: data.label,
													value: data.value
												}
										})
									}
								/>
							</td>
							<td>
								<Button
									size="sm"
									type="button"
									onClick={() =>
										handleListMapping(
											'appsetting_risk_catalogue_category_id'
										)
									}
									className="btn btn-large btn-primary flex gap-1.5"
								>
									Update
								</Button>
							</td>
						</tr>
						<tr>
							<td className="w-[60%]">
								Risk Management/Risk Catalogue/tags
							</td>
							<td className="w-[40%]">
								<SimpledropdownOptionKey
									label=""
									value={
										selectedValues.appsetting_risk_catalogue_tags_id
											? selectedValues
													?.appsetting_risk_catalogue_tags_id
													?.label
											: ''
									}
									options={options}
									className={'mb-0 '}
									onChange={(data) =>
										HandleSelectedValue({
											appsetting_risk_catalogue_tags_id: {
												label: data.label,
												value: data.value
											}
										})
									}
								/>
							</td>
							<td>
								<Button
									size="sm"
									type="button"
									onClick={() =>
										handleListMapping(
											'appsetting_risk_catalogue_tags_id'
										)
									}
									className="btn btn-large btn-primary flex gap-1.5"
								>
									Update
								</Button>
							</td>
						</tr>
						<tr>
							<td className="w-[60%]">Additional Fields/Scope</td>
							<td className="w-[40%]">
								<SimpledropdownOptionKey
									label=""
									value={
										selectedValues.appsetting_additional_fields_scope_id
											? selectedValues
													?.appsetting_additional_fields_scope_id
													?.label
											: ''
									}
									options={options}
									className={'mb-0 '}
									onChange={(data) =>
										HandleSelectedValue({
											appsetting_additional_fields_scope_id:
												{
													label: data.label,
													value: data.value
												}
										})
									}
								/>
							</td>
							<td>
								<Button
									size="sm"
									type="button"
									onClick={() =>
										handleListMapping(
											'appsetting_additional_fields_scope_id'
										)
									}
									className="btn btn-large btn-primary flex gap-1.5"
								>
									Update
								</Button>
							</td>
						</tr>
						<tr>
							<td className="w-[60%]">
								User Profile/Personal Info/title
							</td>
							<td className="w-[40%]">
								<SimpledropdownOptionKey
									label=""
									value={
										selectedValues.appsetting_user_profile_title_id
											? selectedValues
													?.appsetting_user_profile_title_id
													?.label
											: ''
									}
									options={options}
									className={'mb-0 '}
									onChange={(data) =>
										HandleSelectedValue({
											appsetting_user_profile_title_id: {
												label: data.label,
												value: data.value
											}
										})
									}
								/>
							</td>
							<td>
								<Button
									size="sm"
									type="button"
									onClick={() =>
										handleListMapping(
											'appsetting_user_profile_title_id'
										)
									}
									className="btn btn-large btn-primary flex gap-1.5"
								>
									Update
								</Button>
							</td>
						</tr>
						<tr>
							<td className="w-[60%]">
								User Profile/Personal Info/gender
							</td>
							<td className="w-[40%]">
								<SimpledropdownOptionKey
									label=""
									value={
										selectedValues.appsetting_user_profile_gender_id
											? selectedValues
													?.appsetting_user_profile_gender_id
													?.label
											: ''
									}
									options={options}
									className={'mb-0 '}
									onChange={(data) =>
										HandleSelectedValue({
											appsetting_user_profile_gender_id: {
												label: data.label,
												value: data.value
											}
										})
									}
								/>
							</td>
							<td>
								<Button
									size="sm"
									type="button"
									onClick={() =>
										handleListMapping(
											'appsetting_user_profile_gender_id'
										)
									}
									className="btn btn-large btn-primary flex gap-1.5"
								>
									Update
								</Button>
							</td>
						</tr>
						<tr>
							<td className="w-[60%]">
								User Profile/Personal Info/status
							</td>
							<td className="w-[40%]">
								<SimpledropdownOptionKey
									label=""
									value={
										selectedValues.appsetting_user_profile_status_id
											? selectedValues
													?.appsetting_user_profile_status_id
													?.label
											: ''
									}
									options={options}
									className={'mb-0 '}
									onChange={(data) =>
										HandleSelectedValue({
											appsetting_user_profile_status_id: {
												label: data.label,
												value: data.value
											}
										})
									}
								/>
							</td>
							<td>
								<Button
									size="sm"
									type="button"
									onClick={() =>
										handleListMapping(
											'appsetting_user_profile_status_id'
										)
									}
									className="btn btn-large btn-primary flex gap-1.5"
								>
									Update
								</Button>
							</td>
						</tr>

						<tr>
							<td className="w-[60%]">
								User Profile/Personal Info/relationship
							</td>
							<td className="w-[40%]">
								<SimpledropdownOptionKey
									label=""
									value={
										selectedValues.appsetting_user_profile_relationship_id
											? selectedValues
													?.appsetting_user_profile_relationship_id
													?.label
											: ''
									}
									options={options}
									className={'mb-0 '}
									onChange={(data) =>
										HandleSelectedValue({
											appsetting_user_profile_relationship_id:
												{
													label: data.label,
													value: data.value
												}
										})
									}
								/>
							</td>
							<td>
								<Button
									size="sm"
									type="button"
									onClick={() =>
										handleListMapping(
											'appsetting_user_profile_relationship_id'
										)
									}
									className="btn btn-large btn-primary flex gap-1.5"
								>
									Update
								</Button>
							</td>
						</tr>
						<tr>
							<td className="w-[60%]">
								User Profile/Personal Info/identification
								document
							</td>
							<td className="w-[40%]">
								<SimpledropdownOptionKey
									label=""
									value={
										selectedValues.appsetting_user_profile_identification_document_id
											? selectedValues
													?.appsetting_user_profile_identification_document_id
													?.label
											: ''
									}
									options={options}
									className={'mb-0 '}
									onChange={(data) =>
										HandleSelectedValue({
											appsetting_user_profile_identification_document_id:
												{
													label: data.label,
													value: data.value
												}
										})
									}
								/>
							</td>
							<td>
								<Button
									size="sm"
									type="button"
									onClick={() =>
										handleListMapping(
											'appsetting_user_profile_identification_document_id'
										)
									}
									className="btn btn-large btn-primary flex gap-1.5"
								>
									Update
								</Button>
							</td>
						</tr>
						<tr>
							<td className="w-[60%]">
								User Profile/Buisness Info/grade
							</td>
							<td className="w-[40%]">
								<SimpledropdownOptionKey
									label=""
									value={
										selectedValues.appsetting_user_profile_identification_grade_id
											? selectedValues
													?.appsetting_user_profile_identification_grade_id
													?.label
											: ''
									}
									options={options}
									className={'mb-0 '}
									onChange={(data) =>
										HandleSelectedValue({
											appsetting_user_profile_identification_grade_id:
												{
													label: data.label,
													value: data.value
												}
										})
									}
								/>
							</td>
							<td>
								<Button
									size="sm"
									type="button"
									onClick={() =>
										handleListMapping(
											'appsetting_user_profile_identification_grade_id'
										)
									}
									className="btn btn-large btn-primary flex gap-1.5"
								>
									Update
								</Button>
							</td>
						</tr>
						<tr>
							<td className="w-[60%]">
								User Profile/Buisness Info/clearence
							</td>
							<td className="w-[40%]">
								<SimpledropdownOptionKey
									label=""
									value={
										selectedValues.appsetting_user_profile_identification_clearence_id
											? selectedValues
													?.appsetting_user_profile_identification_clearence_id
													?.label
											: ''
									}
									options={options}
									className={'mb-0 '}
									onChange={(data) =>
										HandleSelectedValue({
											appsetting_user_profile_identification_clearence_id:
												{
													label: data.label,
													value: data.value
												}
										})
									}
								/>
							</td>
							<td>
								<Button
									size="sm"
									type="button"
									onClick={() =>
										handleListMapping(
											'appsetting_user_profile_identification_clearence_id'
										)
									}
									className="btn btn-large btn-primary flex gap-1.5"
								>
									Update
								</Button>
							</td>
						</tr>
						{/* <tr>
							<td className="w-[60%]">
								User Profile/Buisness Info/Reporting Manager (Administrative)
							</td>
							<td className="w-[40%]">
								<SimpledropdownOptionKey
									label=""
									value={selectedValues.appsetting_user_profile_identification_reporting_manager_admin_id ? selectedValues?.appsetting_user_profile_identification_reporting_manager_admin_id?.label : ''}
									options={options}
									className={'mb-0 '}
									onChange={(data) =>
										HandleSelectedValue({
											appsetting_user_profile_identification_reporting_manager_admin_id: { label: data.label, value: data.value }
										})
									}
								/>
							</td>
							<td>
								<Button
									size="sm"
									type="button"
									onClick={() => handleListMapping('appsetting_user_profile_identification_reporting_manager_admin_id')}
									className="btn btn-large btn-primary flex gap-1.5"
								>
									Update
								</Button>
							</td>
						</tr> */}
						{/* <tr>
							<td className="w-[60%]">
								User Profile/Buisness Info/Reporting Manager (Administrative)
							</td>
							<td className="w-[40%]">
								<SimpledropdownOptionKey
									label=""
									value={selectedValues.appsetting_user_profile_identification_reporting_manager_functional_id ? selectedValues?.appsetting_user_profile_identification_reporting_manager_functional_id?.label : ''}
									options={options}
									className={'mb-0 '}
									onChange={(data) =>
										HandleSelectedValue({
											appsetting_user_profile_identification_reporting_manager_functional_id: { label: data.label, value: data.value }
										})
									}
								/>
							</td>
							<td>
								<Button
									size="sm"
									type="button"
									onClick={() => handleListMapping('appsetting_user_profile_identification_reporting_manager_functional_id')}
									className="btn btn-large btn-primary flex gap-1.5"
								>
									Update
								</Button>
							</td>
						</tr> */}
						<tr>
							<td className="w-[60%]">
								User Profile/Buisness Info/Ou1 (Group)
							</td>
							<td className="w-[40%]">
								<SimpledropdownOptionKey
									label=""
									value={
										selectedValues.appsetting_user_profile_identification_ou1_group_id
											? selectedValues
													?.appsetting_user_profile_identification_ou1_group_id
													?.label
											: ''
									}
									options={options}
									className={'mb-0 '}
									onChange={(data) =>
										HandleSelectedValue({
											appsetting_user_profile_identification_ou1_group_id:
												{
													label: data.label,
													value: data.value
												}
										})
									}
								/>
							</td>
							<td>
								<Button
									size="sm"
									type="button"
									onClick={() =>
										handleListMapping(
											'appsetting_user_profile_identification_ou1_group_id'
										)
									}
									className="btn btn-large btn-primary flex gap-1.5"
								>
									Update
								</Button>
							</td>
						</tr>
						<tr>
							<td className="w-[60%]">
								User Profile/Buisness Info/Ou2 (Group)
							</td>
							<td className="w-[40%]">
								<SimpledropdownOptionKey
									label=""
									value={
										selectedValues.appsetting_user_profile_identification_ou2_group_id
											? selectedValues
													?.appsetting_user_profile_identification_ou2_group_id
													?.label
											: ''
									}
									options={options}
									className={'mb-0 '}
									onChange={(data) =>
										HandleSelectedValue({
											appsetting_user_profile_identification_ou2_group_id:
												{
													label: data.label,
													value: data.value
												}
										})
									}
								/>
							</td>
							<td>
								<Button
									size="sm"
									type="button"
									onClick={() =>
										handleListMapping(
											'appsetting_user_profile_identification_ou2_group_id'
										)
									}
									className="btn btn-large btn-primary flex gap-1.5"
								>
									Update
								</Button>
							</td>
						</tr>
						<tr>
							<td className="w-[60%]">
								User Profile/Buisness Info/Ou3 (Group)
							</td>
							<td className="w-[40%]">
								<SimpledropdownOptionKey
									label=""
									value={
										selectedValues.appsetting_user_profile_identification_ou3_group_id
											? selectedValues
													?.appsetting_user_profile_identification_ou3_group_id
													?.label
											: ''
									}
									options={options}
									className={'mb-0 '}
									onChange={(data) =>
										HandleSelectedValue({
											appsetting_user_profile_identification_ou3_group_id:
												{
													label: data.label,
													value: data.value
												}
										})
									}
								/>
							</td>
							<td>
								<Button
									size="sm"
									type="button"
									onClick={() =>
										handleListMapping(
											'appsetting_user_profile_identification_ou3_group_id'
										)
									}
									className="btn btn-large btn-primary flex gap-1.5"
								>
									Update
								</Button>
							</td>
						</tr>
					</tbody>
				</table>
			</form>
		</>
	)
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])
	useEffect(() => {
		setBody(FORM)
	}, [showDrawer, selectedValues])
	const handleDrawerClose = () => {
		dispatch(removeAlerts({ pageId: 'list-mapping' }))
		dispatch(removePage())
	}
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal ">{''}</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				{/* <IconButton
					onClick={() => handleSubmit()}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton> */}
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			<GlobalDrawer
				label={'List Mapping'}
				onClose={handleDrawerClose}
				body={body}
				scrollable={true}
				overlay={''}
				size={'50%'}
				showDrawer={true}
				drawerFooterBody={drawerFooterBody}
				loader={loader}
				scrollableFooter={false}
				drawerPosition={'right'}
				pageId={'list-mapping'}
			/>
		</>
	)
}

export default ListMapping
