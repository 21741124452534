import { gql } from '@apollo/client'
export const UPLOAD_ATTACHMENTS = gql`
	mutation UpdateAdditionalFieldFiles(
		$input: UpdateProfileFieldFilesInput!
		$files: Upload
	) {
		updateAdditionalFieldFiles(input: $input, files: $files) {
			status
			message
			errors
			data
		}
	}
`
