import React, { useState, useRef, useEffect } from 'react'
import ProfileSection from './ProfileSection'
import image from '../../assets/img/avtar.jpg'
import Avatar from '../ui/Avatar'
import { useSelector } from 'react-redux'
import { API_BASE_URL } from '../../constants'
const Profile = () => {
	const [showProfile, setShowProfile] = useState(false)
	const userinfo = useSelector((state) => state.Auth?.userdata)
	const [About, setAbout] = useState('')
	let userphoto =
		userinfo?.user?.user_data?.personal_information?.profile_photo_url || ''
	let DESIGNATION =
		userinfo?.user?.user_data?.business_information?.designation
	let Company = userinfo?.user?.user_data?.business_information?.ou2_company
	useEffect(() => {
		if (DESIGNATION) {
			setAbout(DESIGNATION)
		}
		if (Company) {
			setAbout((prevAbout) => prevAbout + ' ' + Company)
		}
	}, [userinfo])

	const closeref = useRef()
	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (
				showProfile &&
				closeref.current &&
				!closeref.current.contains(event.target)
			) {
				setShowProfile(false)
			}
		}
		document.addEventListener('mousedown', handleOutsideClick)
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick)
		}
	}, [showProfile])
	return (
		<>
			<div
				className="dropdown dropdown-profile ml-6 cursor-pointer"
				ref={closeref}
			>
				<div onClick={() => setShowProfile((prev) => !prev)}>
					<Avatar
						image={
							userphoto ? `${API_BASE_URL}${userphoto}` : image
						}
						className={`online`}
						size="md"
					/>
				</div>
				{showProfile && (
					<div className="dropdown-menu dropdown-menu-end mt-10-f absolute right-5 top-20 bg-[#fff]">
						<div className="dropdown-menu-body">
							<Avatar
								image={
									userphoto
										? `${API_BASE_URL}${userphoto}`
										: image
								}
								className={`online mb-3`}
								size="xl"
							/>

							<h5 className="mb-1">{userinfo?.user?.fullname}</h5>
							<p className="fs-sm small-text">{About}</p>
							<ProfileSection setShowProfile={setShowProfile} />
						</div>
					</div>
				)}
			</div>
		</>
	)
}
export default React.memo(Profile)
