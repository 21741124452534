import React from 'react'

const DashboardTable = ({ items }) => {
	let Green = '/static/media/Arch-5-15.5a16250f3cba39ee8bb4940bd76099a2.svg'
	let Red = '/static/media/Arch-5-11.2faa37446fa81ea125186e3389ec8605.svg'
	let Yellow = '/static/media/Arch-5-12.07b46dc885700356560fbc8354f670db.svg'
	let RiskOwners = []
	let RiskFunction = []
	let images = []
	items.forEach((item) => {
		if (!RiskOwners.includes(item.risk_owner)) {
			RiskOwners.push(item.risk_owner)
		}
		if (!RiskFunction.includes(item.risk_function)) {
			RiskFunction.push(item.risk_function)
		}
		images.push(item.image)
		images.push(item.image1)
	})
	let RedCount = 0
	let GreenCount = 0
	let YellowCount = 0
	images.forEach((img) => {
		if (img === Red) {
			RedCount++
		}
		if (img === Green) {
			GreenCount++
		}
		if (img === Yellow) {
			YellowCount++
		}
	})

	return (
		<div className="flex min-h-10 w-full border">
			<div className="flex w-1/5 items-center  border-r px-1.5 py-1">
				<div className="">Risk Functions : {RiskFunction.length}</div>{' '}
			</div>
			<div className="flex w-1/5 items-center  border-r px-1.5 py-1">
				<div className="">Risk Owners : {RiskOwners.length}</div>{' '}
			</div>
			<div className="flex w-1/5 items-center  border-r px-1.5 py-1">
				<div className="">Green : {GreenCount}</div>
			</div>
			<div className="flex w-1/5 items-center  border-r px-1.5 py-1">
				<div className="">Red : {RedCount}</div>
			</div>
			<div className="flex w-1/5 items-center  border-r px-1.5 py-1">
				<div className=" ">Yellow : {YellowCount}</div>{' '}
			</div>
		</div>
	)
}

export default DashboardTable
