import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import ElasticTextBox from '../../../components/ui/Elastic-textbox'
import TextBox from '../../../components/ui/TextBox'
import RadioGroup from '../../../components/ui/Form/RadioGroup'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import { UPDATE_PROFILE_MUTATION } from '../../../utils/GraphQl/UpdateProfileMutation'
import { UPLOAD_ATTACHMENTS } from '../../../utils/GraphQl/UploadAttachments'
import Attachments from '../../../components/ui/Attachments'
import { addFields } from '../../../store/userProfileAdditionalFields'
import {
	logoutUser,
	updateAdditionalInfo
} from '../../../store/AuthSlice/AuthSlice'
import { useDispatch, useSelector } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import PageAlerts from '../../PageAlerts'
import CheckboxGroup from '../../../components/ui/Form/CheckboxGroup'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import ProgressBar from '../../ProgressBar'
import Simpledropdown from '../../../components/Dropdowns/Simpledropdown'
import FalseAttachments from '../../../components/ui/FalseAttachments'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
import MultiSelectdropdown from '../../../components/Dropdowns/MultiSelectdropdown'
import Datepicker from '../../../components/ui/Datepicker'
const updateKeysToLabels = (obj) => {
	return obj.map((ele) => {
		return { label: ele.key, value: ele.value }
	})
}
const AdditionalInfoDrawer = ({ show, setShow }) => {
	let Fields = useSelector((state) => state.additionalInfoFields.Fields)
	const userdata = useSelector((state) => state.Auth)
	const [loader, setLoader] = useState(false)
	let FIELDS = userdata?.userdata?.settings?.user_profile_additional_fields
	const userData =
		userdata?.userdata?.user?.user_data?.additional_information || {}
	const authToken = userdata?.token
	const UID = userdata?.userdata?.user?.user_id
	const [showDrawer, setShowDrawer] = useState(false)
	const [overlay, setOverlay] = useState('')
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(false)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [Components, setComponents] = useState([])
	const [formdata, setFormdata] = useState({})
	const dispatch = useDispatch()
	const [filesState, setFilesState] = useState({})
	const [ExistingFiles, setExistingFIles] = useState({})
	const [optionsData, setOptionsData] = useState([])
	const [optionsBackup, setOptionsbackup] = useState([])

	const [multiSelectData, setMultiSelectData] = useState({})
	const handleMultiSelectChange = (field, selectedItems) => {
		let form_data = selectedItems.map((item) => item.value).join(',')
		HANDLEFORMDATA(field, form_data)
		setMultiSelectData((prevData) => ({
			...prevData,
			[field]: selectedItems
		}))
	}

	useEffect(() => {
		if (Object.keys(multiSelectData).length > 0) {
			let newOptions = {}
			for (const option in optionsBackup) {
				let selectedValues = []
				if (multiSelectData[option]) {
					selectedValues = multiSelectData[option].map(
						(item) => item.value
					)
				}
				let new_options = optionsBackup[option].filter((ele) => {
					if (!selectedValues.includes(ele.value)) return ele
				})
				newOptions[option] = new_options
				setOptionsData(newOptions)
			}
		}
	}, [multiSelectData, optionsBackup])

	const handleAttachmentRemove = (key, name) => {
		const FilesData = ExistingFiles[key]
		let Filtered = FilesData.filter((item) => item !== name)
		setExistingFIles((prev) => {
			return {
				...prev,
				[key]: Filtered
			}
		})
	}
	const HANDLEFORMDATA = (field, value) => {
		setFormdata((prevdata) => ({
			...prevdata,
			[field]: value
		}))
	}
	useEffect(() => {
		if (FIELDS) {
			let data = FIELDS
			setComponents([...data])
			let initialFilesState = {}
			data.forEach((component) => {
				if (component.field_type === 'multi-select-drop-down') {
					setOptionsData((prevOptions) => ({
						...prevOptions,
						[component.field_name]: updateKeysToLabels(
							component.options
						)
					}))
					setOptionsbackup((prevOptions) => ({
						...prevOptions,
						[component.field_name]: updateKeysToLabels(
							component.options
						)
					}))
				}
				if (component.field_type === 'file-attachment') {
					initialFilesState[component.field_name] = userData
						? userData[component.field_name] || []
						: []
				}
			})
		}
		if (Fields) {
			setComponents([...Fields])
			let initialFilesState = {}
			Fields.forEach((component) => {
				if (component.field_type === 'multi-select-drop-down') {
					setOptionsData((prevOptions) => ({
						...prevOptions,
						[component.field_name]: updateKeysToLabels(
							component.options
						)
					}))
					setOptionsbackup((prevOptions) => ({
						...prevOptions,
						[component.field_name]: updateKeysToLabels(
							component.options
						)
					}))
				}
				if (component.field_type === 'file-attachment') {
					initialFilesState[component.field_name] = userData
						? userData[component.field_name] || []
						: []
				}
			})
		}
	}, [FIELDS, Fields])
	useEffect(() => {
		if (userData) {
			Object.keys(userData).forEach((item) => {
				if (Array.isArray(userData[item])) {
					setFilesState({})
					setExistingFIles((prevFiles) => ({
						...prevFiles,
						[item]: userData[item]
					}))
				}
				setFormdata((prevFormData) => ({
					...prevFormData,
					[item]: userData[item] || ' '
				}))
			})
		}
	}, [])

	useEffect(() => {
		if (userdata) {
			Object.keys(userData).forEach((item) => {
				if (optionsBackup && optionsBackup[item]) {
					let selectedData = []
					selectedData = optionsBackup[item].filter((ele) => {
						if (userData[item].split(',').includes(ele.value))
							return ele
					})
					handleMultiSelectChange(item, selectedData)
				}
			})
		}
	}, [userData, optionsBackup])

	const handleSelectedFile = (fieldName, files) => {
		setFilesState((prevState) => ({
			...prevState,
			[fieldName]: files[0] ? [files[0]] : []
		}))
		setExistingFIles((prevState) => ({
			...prevState,
			[fieldName]: []
		}))
	}

	const removeFile = (fieldName, fileToRemove) => {
		setFilesState((prevState) => ({
			...prevState,
			[fieldName]: (prevState[fieldName] || []).filter(
				(file) => file.name !== fileToRemove.name
			)
		}))
	}

	const generateFormFields = () => {
		return (
			<form
				encType="multipart/form-data"
				className=" flex w-[350px] flex-col justify-start"
				key={'additional_info'}
			>
				{Components.map((component, index) => {
					switch (component.field_type) {
						case 'textbox':
							return (
								<TextBox
									id={component.label}
									key={index}
									labelName={
										component.label || 'Default Label'
									}
									value={formdata[component.field_name]}
									onChange={(data) =>
										HANDLEFORMDATA(
											component.field_name,
											data
										)
									}
								/>
							)
						case 'elastic-textbox':
							return (
								<ElasticTextBox
									id={component.label}
									key={index}
									labelName={
										component.label || 'Default Label'
									}
									value={formdata[component.field_name] || ''}
									onChange={(data) =>
										HANDLEFORMDATA(
											component.field_name,
											data
										)
									}
								/>
							)
						case 'drop-down':
							return (
								<Simpledropdown
									key={index}
									label={component.label || 'Default Label'}
									value={formdata[component.field_name] || ''}
									onChange={(data) =>
										HANDLEFORMDATA(
											component.field_name,
											data
										)
									}
									options={component.options.map(
										(options) => options.value
									)}
								/>
							)
						case 'multi-select-drop-down':
							return (
								<MultiSelectdropdown
									key={component.field_name}
									label={component.label || 'Default Label'}
									remainingItems={
										optionsData[component.field_name] || []
									}
									selectedItems={
										multiSelectData[component.field_name] ||
										[]
									}
									onItemAdd={(item) =>
										handleMultiSelectChange(
											component.field_name,
											[
												...(multiSelectData[
													component.field_name
												] || []),
												item
											]
										)
									}
									onItemRemove={(item) =>
										handleMultiSelectChange(
											component.field_name,
											(
												multiSelectData[
													component.field_name
												] || []
											).filter(
												(i) => i.value !== item.value
											)
										)
									}
								/>
							)
						case 'radio':
							return (
								<div
									className="form-group"
									key={component.label + 'radio'}
								>
									<label className="label">
										{component.label}
									</label>
									<RadioGroup
										id={component.label}
										key={component.label}
										name={component.field_name}
										type="vertical"
										options={component.options.map(
											(comp) => {
												return {
													label: comp.key,
													value: comp.value,
													selected:
														formdata[
															component.field_name
														] === comp.value
												}
											}
										)}
										onChange={(data) =>
											HANDLEFORMDATA(
												component.field_name,
												data
											)
										}
										className="radio-circle"
									/>{' '}
								</div>
							)
						case 'date':
							return (
								<Datepicker
									key={index}
									labelName={component.label}
									onDateChange={(date) =>
										HANDLEFORMDATA(
											component.field_name,
											date
										)
									}
									currentDate={formdata[component.field_name]}
								/>
							)
						case 'checkbox':
							return (
								<div
									className="form-group"
									key={component.label + 'checkbox'}
								>
									<label className="label">
										{component.label}
									</label>
									<CheckboxGroup
										id={component.label}
										key={component.label}
										type="vertical"
										options={component.options.map(
											(comp) => {
												return {
													label: comp.key,
													value: comp.key,
													checked:
														formdata[
															component
																?.field_name
														]?.[comp.key]
												}
											}
										)}
										onChange={(item) =>
											HANDLEFORMDATA(
												component.field_name,
												item
											)
										}
									/>
								</div>
							)
						case 'file-attachment':
							return (
								<div key={index}>
									<Attachments
										id={component.field_name}
										key={index + ' ' + component.id}
										labelName={component.label}
										files={
											filesState[component.field_name] ||
											[]
										}
										onSelect={(file) =>
											handleSelectedFile(
												component.field_name,
												file
											)
										}
										onRemove={(file) =>
											removeFile(
												component.field_name,
												file
											)
										}
									/>
									{ExistingFiles[component.field_name] &&
										ExistingFiles[component.field_name].map(
											(item) => {
												const parts = item.split('/')
												const fileName =
													parts[parts.length - 1]
												return (
													<FalseAttachments
														key={fileName}
														filename={fileName}
														onFileRemove={() =>
															handleAttachmentRemove(
																[
																	component.field_name
																],
																item
															)
														}
													/>
												)
											}
										)}
								</div>
							)

						default:
							return null
					}
				})}
			</form>
		)
	}
	const [update] = useMutation(UPDATE_PROFILE_MUTATION)
	const [upload] = useMutation(UPLOAD_ATTACHMENTS)

	const handleUploads = async () => {
		try {
			setLoader(true)
			let FILES = []
			const Field = Object.keys(filesState).reduce((acc, item) => {
				FILES = [...FILES, ...filesState[item]]
				acc[item] = {
					new_files: filesState[item].map((file) => file.name),
					old_files: []
				}
				return acc
			}, {})
			const response = await upload({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						fields: Field,
						user_id: UID
					},
					files: FILES[0]
				}
			})
			let data = await response.data
			setLoader(false)
			let updateprofile = data.updateAdditionalFieldFiles
			if (updateprofile.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'updateadditionalinfo',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'updateadditionalinfo' }))
					dispatch(logoutUser())
				}, 3000)
			} else if (
				updateprofile.status !== 200 &&
				updateprofile.errors.length > 0
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'updateadditionalinfo',
						type: 'danger',
						message: updateprofile.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'updateadditionalinfo',
						type: 'success',
						message: updateprofile.message,
						icon: faCheck,
						autoclose: 3000
					})
				)

				setTimeout(() => {
					setShowDrawer(false)
					setScrollable(false)
					setScrollableFooter(false)
					setSize('md')
					setOverlay('')
					setShow(false)
					dispatch(
						updateAdditionalInfo(
							data.updateAdditionalFieldFiles.data
						)
					)
					dispatch(removeAlerts({ pageId: 'updateadditionalinfo' }))
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'updateadditionalinfo',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	const handleSubmit = async () => {
		try {
			setLoader(true)
			const response = await update({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						user_id: UID,
						data: {
							...formdata
						}
					}
				}
			})
			let data = await response.data
			let updateprofile = data.updateProfile
			if (updateprofile.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'updateadditionalinfo',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'updateadditionalinfo' }))
					dispatch(logoutUser())
				}, 3000)
			} else if (
				updateprofile.status !== 200 &&
				updateprofile.errors.length > 0
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'updateadditionalinfo',
						type: 'danger',
						message: updateprofile.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (updateprofile.status === 200) {
				setLoader(false)
				dispatch(updateAdditionalInfo(data.updateProfile.data))
				if (Object.keys(filesState).length > 0) {
					handleUploads()
				}
			}
		} catch (error) {
			console.log('err ', error)
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'updateadditionalinfo',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setShow(false)
	}
	useEffect(() => {
		setShowDrawer(show)
		setScrollable(show)
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', show)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [show])

	const drawerFooterBody = (
		<>
			<h5 className="font-normal ">{''}</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
					onClick={handleSubmit}
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			{loader && <ProgressBar />}
			<PageAlerts pageId={'updateadditionalinfo'} />
			<Drawer
				label="Additional Info"
				size={size}
				body={generateFormFields()}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default AdditionalInfoDrawer
