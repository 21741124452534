const MENU_DATA = [
	{
		id: 'user-dashboard',
		children: [
			{
				id: 'dashboard',
				title: 'Dashboard',
				icon: 'line-bar-chart',
				link: '/',
				data: []
			}
		]
	},
	{
		id: 'management',
		// Heading: 'Components',
		children: [
			{
				id: 'user-management',
				title: 'User Management',
				icon: 'line-profile-card',
				data: [{ link: '/userlist', name: 'User List' }]
			}
		]
	},
	{
		id: 'knowledgebase',
		// Heading: 'Components',
		children: [
			{
				id: 'knowledge-base',
				title: 'Knowledgebase',
				icon: 'line-profile-card',
				data: [
					{
						link: '/business-objectives',
						name: 'Business Objectives'
					}
				]
			}
		]
	},
	{
		id: 'settings',
		// Heading: 'Components',
		children: [
			{
				id: 'user-settings',
				title: 'Settings',
				icon: 'line-settings',
				data: [
					// {
					// 	link: '/dashboard/emailtemplates',
					// 	name: 'Email Template'
					// },

					{
						link: '/registersettings',
						name: 'Register Settings'
					},
					{
						link: '/contact-settings',
						name: 'Contact Settings'
					},
					{
						link: '/loginsettings',
						name: 'Login Settings'
					},
					{
						link: '/fileupload',
						name: 'File Settings'
					},
					{
						link: '/magiclinksettings',
						name: 'Magic Link Settings'
					},
					{ link: '/emailserver', name: 'Email Server' },

					{
						link: '/additional-fields',
						name: 'Additional Fields List'
					},

					{ link: '/emailtemplates', name: 'Email Templates' },
					{ link: '/listpage', name: 'List Page' },
					{ link: '/list-mapping', name: 'Listmapping Page' },
					{ link: '/risk-management', name: 'Risk Management' },
					{
						link: '/organization-context',
						name: 'Organization Context'
					},
					{
						link: '/risk-catalogue',
						name: 'Risk Catalogue'
					}
				]
			}
		]
	}
]
export default MENU_DATA
