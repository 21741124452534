import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import PageAlerts from '../../PageAlerts'
import ElasticTextbox from '../../../components/ui/Elastic-textbox'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import Simpledropdown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import MultiSelectdropdown from '../../../components/Dropdowns/MultiSelectdropdown'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import { CREATE_CATALOGUE } from '../../../utils/GraphQl/CatalogueMutations'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import ProgressBar from '../../ProgressBar'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const AddCatalogue = ({
	setDrawerstate,
	setDataTableData,
	categorySelectorData,
	tagSelectorData,
	fetchCategoryLists
}) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [loader, setLoader] = useState(false)
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const [create_catalogue] = useMutation(CREATE_CATALOGUE)
	const dispatch = useDispatch()
	const [lists, setLists] = useState([])
	// const [items, setItems] = useState([
	// 	{ label: 'Communication', value: 'Communication' },
	// 	{ label: 'Business', value: 'Business' },
	// 	{ label: 'Entertainment', value: 'Entertainment' },
	// 	{ label: 'Education', value: 'Education' },
	// 	{ label: 'Security', value: 'Security' }
	// ])
	useEffect(() => {
		fetchCategoryLists()
	}, [])
	const CATEGORIES = categorySelectorData.options_data
	const [items, setItems] = useState(tagSelectorData.options_data)
	const [Formdata, setFormdata] = useState({
		category: '',
		risk_heading: '',
		risk_description: '',
		tags: ''
	})
	useEffect(() => {
		let Values = lists.map((list) => list.value)
		HANDLEFORMDATA('tags', Values.join(','))
	}, [items, lists])
	const HANDLEFORMDATA = (field, value) => {
		setFormdata((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}
	const add = (data) => {
		setLists([...lists, data])
		setItems(items.filter((item) => item.label !== data.label))
	}
	const removeItem = (item) => {
		setLists((prevLists) =>
			prevLists.filter((listItem) => listItem.label !== item.label)
		)
		setItems([item, ...items])
	}

	const HandleSubmit = async () => {
		let Tags = Formdata?.tags?.split(',')
		try {
			setLoader(true)
			const response = await create_catalogue({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						tags: Tags,
						catalogue_category: Formdata.category?.value,
						risk_description: Formdata.risk_description,
						risk_heading: Formdata.risk_heading,
						catalogue_description: ''
					}
				}
			})
			setLoader(false)
			let data = await response?.data
			let createCatalogue = data?.createCatalogue
			if (createCatalogue.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'add_catalogue',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (
				createCatalogue.status !== 201 &&
				createCatalogue.errors.length > 0
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'add_catalogue',
						type: 'danger',
						message: createCatalogue.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (createCatalogue.status === 201) {
				setLoader(false)
				let CreatedCatalogue = createCatalogue?.data
				let category = await categorySelectorData.options_data.find(
					(item) =>
						item.value === CreatedCatalogue?.catalogue_category
				)?.label

				let newObject = {
					id: CreatedCatalogue?.id,
					catalogue_category: category,
					// catalogue_category: CreatedCatalogue?.catalogue_category,
					risk_description: CreatedCatalogue?.risk_description,
					risk_heading: CreatedCatalogue?.risk_heading,
					tags: CreatedCatalogue?.tags.join(',')
				}
				setDataTableData((prev = []) => [...prev, newObject])
				dispatch(
					addAlerts({
						pageId: 'add_catalogue',
						type: 'success',
						message: createCatalogue.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					handleDrawerClose()
				}, 3000)
			}
		} catch (error) {
			console.log('error ', error)
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'add_catalogue',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const FORM = (
		<form
			encType="multipart/form-data"
			className=" flex w-[350px] flex-col justify-start"
		>
			<>
				<div className="flex w-[350px] flex-col justify-start pr-1">
					{categorySelectorData ? (
						<>
							{categorySelectorData?.field_type ===
								'simple_dropdown' && (
								<Simpledropdown
									label={'Category'}
									options={categorySelectorData?.options_data}
									value={Formdata.category?.label}
									onChange={(data) =>
										HANDLEFORMDATA('category', data)
									}
								/>
							)}
						</>
					) : null}
					{/* <Simpledropdown
						label="Category"
						options={CATEGORIES}
						onChange={(data) => HANDLEFORMDATA('category', data)}
						value={Formdata.category?.label}
					/> */}
					<ElasticTextbox
						name="risk_heading"
						id="risk_heading"
						value={Formdata.risk_heading}
						labelName={'Risk Heading'}
						onChange={(data) =>
							HANDLEFORMDATA('risk_heading', data)
						}
					/>
					<ElasticTextbox
						name="risk_description"
						id="risk_description"
						value={Formdata.risk_description}
						labelName={'Risk Description'}
						onChange={(data) =>
							HANDLEFORMDATA('risk_description', data)
						}
					/>
					{tagSelectorData ? (
						<>
							{tagSelectorData?.field_type ===
								'multiselect_dropdown' && (
								<MultiSelectdropdown
									label="Tags"
									remainingItems={items}
									selectedItems={lists}
									onItemAdd={(item) => add(item)}
									onItemRemove={(item) => removeItem(item)}
								/>
							)}
						</>
					) : null}
					{/* <MultiSelectdropdown
						label="Tags"
						remainingItems={items}
						selectedItems={lists}
						onItemAdd={(item) => add(item)}
						onItemRemove={(item) => removeItem(item)}
					/> */}
				</div>
			</>
		</form>
	)
	const handleDrawerClose = () => {
		dispatch(removeAlerts({ pageId: 'add_catalogue' }))
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setDrawerstate(false)
	}
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])

	useEffect(() => {
		setBody(FORM)
	}, [
		showDrawer,
		Formdata,
		items,
		lists,
		categorySelectorData,
		tagSelectorData
	])

	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "> </h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={HandleSubmit}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)
	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)
	return (
		<>
			{loader && <ProgressBar />}
			<PageAlerts pageId={'add_catalogue'} />
			<Drawer
				label="Add Catalogue"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}

export default AddCatalogue
