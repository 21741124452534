import React, { useEffect, useState } from 'react'
import Radio from '../Radio'
import PropTypes from 'prop-types'
const RadioGroup = ({
	type = 'vertical',
	options,
	name,
	onChange,
	className,
	iconClass
}) => {
	const [selectedOption, setSelectedOption] = useState(null)
	useEffect(() => {
		options.map((option) => {
			if (option.selected === true) {
				setSelectedOption(option.value)
			}
		})
	})

	const handleChange = (value) => {
		setSelectedOption(value)
		onChange(value)
	}

	return (
		<div
			className={`relative flex list-none flex-wrap gap-[8px] ${type == 'horizontal' ? '' : 'flex-col'}`}
		>
			{options.map((option) => (
				<Radio
					key={option.value}
					id={option.value}
					name={name}
					label={option.label}
					checked={selectedOption === option.value}
					className={className}
					iconClass={iconClass}
					onChange={() => handleChange(option.value)}
					disabled={option.disabled}
				/>
			))}
		</div>
	)
}
RadioGroup.propTypes = {
	type: PropTypes.oneOf(['vertical', 'horizontal']),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			selected: PropTypes.bool,
			disabled: PropTypes.bool
		})
	).isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	iconClass: PropTypes.string
}
export default RadioGroup
