import React, { useState, useEffect } from 'react'
import FormBody from '../../components/Common/FormBody'
import TextBox from '../../components/ui/TextBox'
import Icon from '../../components/ui/icon'
import Button from '../../components/ui/Buttons/Button'
import { useMutation } from '@apollo/client'
import { CHANGEPASSWORD_MUTATION } from '../../utils/GraphQl/ChangePasswordMutation'
import { useNavigate } from 'react-router-dom'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import PageAlerts from '../PageAlerts'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../store/AuthSlice/AuthSlice'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import Card from '../../components/ui/Card'
const ChangePassword = () => {
	const { data, loading, error } = useSelector(
		(state) => state.registerSettings
	)
	const authToken = useSelector((state) => state.Auth.token)
	const [loader, setLoader] = useState(false)
	const [passwordPolicy, setPasswordPolicy] = useState(null)
	const dispatch = useDispatch()
	const [userData, setUserData] = useState({
		oldpassword: null,
		newpassword: null,
		confirmpassword: null
	})
	const [changepassword] = useMutation(CHANGEPASSWORD_MUTATION)
	const navigate = useNavigate()
	useEffect(() => {
		if (loading) {
			setLoader(true)
		} else if (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'Register_settings',
					type: 'danger',
					message: 'Failed to fetch  settings.',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (data.Domains.status === 403) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'Register_settings',
					type: 'danger',
					message: data.Domains.errors[0],
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (!loading && !error && data) {
			setLoader(false)
			const { Domains, PasswordPolicy, verifyCheck } = data

			setPasswordPolicy(PasswordPolicy?.data?.value ?? null)
		}
	}, [data, loading, error])
	const HandleChange = (key, value) => {
		setUserData((prevData) => ({
			...prevData,
			[key]: value
		}))
	}

	const handleVerification = async () => {
		try {
			setLoader(true)
			const response = await changepassword({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						password: userData.newpassword,
						confirm_password: userData.confirmpassword,
						old_password: userData.oldpassword
					}
				}
			})
			let data = await response.data
			if (data.changePassword.status === 400) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'changepassword',
						type: 'danger',
						message: data.changePassword.errors.map((msg) => msg),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.changePassword.status === 403) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'changepassword',
						type: 'danger',
						message: data.changePassword.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.changePassword.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'changepassword',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'changepassword' }))
				}, 3000)
			} else if (data.changePassword.status === 200) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'changepassword',
						type: 'success',
						message: data.changePassword.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'changepassword' }))
					navigate('/')
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			console.error('Change password failed', error)
			dispatch(
				addAlerts({
					pageId: 'changepassword',
					type: 'danger',
					message: 'something went wrong',
					icon: faCheck,
					autoclose: 3000
				})
			)
		}
	}
	const HandleSubmit = (event) => {
		event.preventDefault()
		// const PSW_REGEXP =
		// 	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/

		// let isValidOldPassword = PSW_REGEXP.test(userData.oldpassword)
		// let isValidNewPassword = PSW_REGEXP.test(userData.newpassword)
		const passwordPolicyRegeX = new RegExp(passwordPolicy)
		let isValidOldPassword = true
		let isValidNewPassword = true
		if (passwordPolicy) {
			isValidOldPassword = passwordPolicyRegeX.test(userData.oldpassword)
			isValidNewPassword = passwordPolicyRegeX.test(userData.newpassword)
		}
		if (
			!userData.oldpassword ||
			!isValidOldPassword ||
			!userData.newpassword ||
			!isValidNewPassword ||
			!userData.confirmpassword ||
			userData.newpassword !== userData.confirmpassword
		) {
			if (!userData.oldpassword) {
				dispatch(
					addAlerts({
						pageId: 'changepassword',
						type: 'danger',
						message: 'Please enter your old password.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!isValidOldPassword) {
				dispatch(
					addAlerts({
						pageId: 'changepassword',
						type: 'danger',
						message: 'Please enter a valid  password.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!userData.newpassword) {
				dispatch(
					addAlerts({
						pageId: 'changepassword',
						type: 'danger',
						message: 'Please enter your New password.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!isValidNewPassword) {
				dispatch(
					addAlerts({
						pageId: 'changepassword',
						type: 'danger',
						message: 'Please enter a valid New password.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!userData.confirmpassword) {
				dispatch(
					addAlerts({
						pageId: 'changepassword',
						type: 'danger',
						message: 'Please enter your confirm password.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (userData.password !== userData.confirmpassword) {
				dispatch(
					addAlerts({
						pageId: 'changepassword',
						type: 'danger',
						message: 'Both password should match.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				dispatch(
					addAlerts({
						pageId: 'changepassword',
						type: 'danger',
						message:
							'Something went wrong. Please try again after sometime.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
		} else {
			handleVerification()
		}
	}

	return (
		<Card>
			<PageAlerts pageId={'changepassword'} />
			<div className="flex w-fit items-center rounded-sm shadow-sm">
				<FormBody>
					<TextBox
						id={'old_password'}
						onChange={(data) => HandleChange('oldpassword', data)}
						labelName="Enter Old Password"
						type="password"
						placeholder=""
						className="mb-4"
					/>
					<TextBox
						id={'new_password'}
						onChange={(data) => HandleChange('newpassword', data)}
						labelName="Create New Password"
						type="password"
						placeholder=""
						className="mb-4"
					/>
					<TextBox
						id="confirm_password"
						onChange={(data) =>
							HandleChange('confirmpassword', data)
						}
						labelName="Confirm Password"
						type="password"
						placeholder=""
						className="mb-4"
					/>
					<div className="mb-4">
						<p className="small-text text-[#6e7985] ">
							By clicking <strong>Change Password</strong> below,
							you agree to our Terms and Conditions and Privacy
							Policy.
						</p>
					</div>

					{loader ? (
						<Button
							className="btn-large btn-primary btn-all flex  w-full cursor-not-allowed gap-2"
							isIconBtn={true}
							onClick={() => console.log('')}
						>
							<span className="waiting-loader"> </span> Please
							Wait
						</Button>
					) : (
						<Button
							className="btn-large btn-primary btn-all flex w-full gap-2 p-1"
							isIconBtn={true}
							onClick={HandleSubmit}
						>
							<Icon
								name="line-lock"
								width={'16px'}
								height={'16px'}
								className={'icon-fill-white'}
								viewBox={'0 0 24 24'}
							/>
							Change Password
						</Button>
					)}
				</FormBody>
			</div>
		</Card>
	)
}

export default ChangePassword
