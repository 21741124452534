import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import PageAlerts from '../../PageAlerts'
import TextBox from '../../../components/ui/TextBox'
import ElasticTextBox from '../../../components/ui/Elastic-textbox'
import SimpleDropDown from '../../../components/Dropdowns/Simpledropdown'
import Simpledropdown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import CountrySelector from '../../../components/ui/Countryselctor'
import PhoneSelector from '../../../components/ui/PhoneSelector'
import SearchableDatepicker from '../../../components/ui/Searchable-Datepicker'
import DatePicker from '../../../components/ui/Datepicker'
import { UPLOAD_ATTACHMENTS } from '../../../utils/GraphQl/UploadAttachments'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useMutation, useQuery, gql } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import { logoutUser } from '../../../store/AuthSlice/AuthSlice'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import SearchBar from '../../../components/ui/SearchBar'
import { UPDATE_PROFILE_MUTATION_ADMIN } from '../../../utils/GraphQl/UpdateProfileMutation'
import ImageEditor from '../../../components/ui/ImageEditor'
import ProgressBar from '../../ProgressBar'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
import Datepicker from '../../../components/ui/Datepicker'
import FalseAttachments from '../../../components/ui/FalseAttachments'
import Attachments from '../../../components/ui/Attachments'
import RadioGroup from '../../../components/ui/Form/RadioGroup'
import CheckboxGroup from '../../../components/ui/Form/CheckboxGroup'
import { GET_ADDITIONAL_FIELDS_BY_SCOPE } from '../../../utils/GraphQl/AdditionalFields'
import MultiSelectdropdown from '../../../components/Dropdowns/MultiSelectdropdown'
const updateKeysToLabels = (obj) => {
	return obj.map((ele) => {
		return { label: ele.key, value: ele.value }
	})
}
const EditUser = ({
	setDrawerstate,
	userData,
	users,
	setUsers,
	fetchUsers,
	setselectedUsers
}) => {
	const PERSONAL_INFO_DYNAMIC_FIELDS = useSelector(
		(state) => state.personalInfoDynamicFields
	)
	const BUSINESS_INFO_DYNAMIC_FIELDS = useSelector(
		(state) => state.businessInfoDynamicFields
	)
	let USERINFO = userData?.user_data
	const userdata = useSelector((state) => state.Auth)
	const authToken = userdata.token
	const UID = userData?.user_id
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const [loader, setLoader] = useState(false)
	const [search, setSearch] = useState('')
	const [staticDropFieldFields, setStaticDropDownFields] = useState({
		users: [],
		dual_citizenship: ['Yes', 'No']
	})
	useEffect(() => {
		let newUsers = users.map((user) => user.fullname)
		setStaticDropDownFields((prev) => {
			return {
				...prev,
				users: newUsers
			}
		})
	}, [])
	const [dynamicFields, setDynamicFields] = useState({
		title: [],
		gender: [],
		status: [],
		relation: [],
		document: [],
		grade: [],
		clearence: [],
		ou1_group: [],
		ou2_company: [],
		ou3_department: []
	})
	const [selectedDate, setSelectedDate] = useState({
		day: USERINFO?.personal_information?.dob?.day,
		month: USERINFO?.personal_information?.dob?.month,
		year: USERINFO?.personal_information?.dob?.year
	})
	const [selectedExpiryDate, setSelectedExpiryDate] = useState({
		day: USERINFO?.personal_information?.expiry_date?.day ?? '',
		month: USERINFO?.personal_information?.expiry_date?.month ?? '',
		year: USERINFO?.personal_information?.expiry_date?.year ?? ''
	})
	const [citizenship, setCitizenship] = useState()
	const [secondCitizenShip, setSecondCitizenShip] = useState(
		USERINFO?.personal_information?.citizenship || null
	)
	const [issueLocation, setIssueLocation] = useState(
		USERINFO?.personal_information?.issue_location || null
	)
	const [country, setCountry] = useState(
		USERINFO?.personal_information?.home_address_country || null
	)
	const [permenantCountry, setPermenantCountry] = useState(
		USERINFO?.personal_information?.permanent_address_country || null
	)
	const [Files, setFiles] = useState(null)
	const dispatch = useDispatch()
	const [joiningDate, setJoiningDate] = useState(
		USERINFO?.business_information?.date_of_joining
	)
	const [personalNumber, setpersonalNumber] = useState({
		countryCode:
			USERINFO?.personal_information?.personal_mobile_number
				?.countryCode || '+91',
		countryName:
			USERINFO?.personal_information?.personal_mobile_number
				?.countryName || 'india',
		countryFlag:
			USERINFO?.personal_information?.personal_mobile_number
				?.countryFlag || 'in',
		Number:
			USERINFO?.personal_information?.personal_mobile_number?.Number || ''
	})
	const UpdateFields = (obj) => {
		setpersonalNumber({
			...personalNumber,
			countryCode: obj.countryCode,
			countryName: obj.countryName,
			countryFlag: obj.countryFlag
		})
	}
	const setuserNumber = (obj) => {
		setpersonalNumber({
			...personalNumber,
			Number: obj.Number
		})
	}
	const [EmergencyNumber, setEmergencyNumber] = useState({
		countryCode:
			USERINFO?.personal_information?.emergency_contact_number
				?.countryCode || '+86',
		countryName:
			USERINFO?.personal_information?.emergency_contact_number
				?.countryName || 'china',
		countryFlag:
			USERINFO?.personal_information?.emergency_contact_number
				?.countryFlag || 'cn',
		Number:
			USERINFO?.personal_information?.emergency_contact_number?.Number ||
			''
	})
	const UpdateEmergencyCountryFields = (obj) => {
		setEmergencyNumber({
			...EmergencyNumber,
			countryCode: obj.countryCode,
			countryName: obj.countryName,
			countryFlag: obj.countryFlag
		})
	}
	const setEmergencyuserNumber = (obj) => {
		setEmergencyNumber({
			...EmergencyNumber,
			Number: obj.Number
		})
	}
	const HandleFieldChanges = (fieldname, value) => {
		setSelectedDate((prevState) => {
			return {
				...prevState,
				[fieldname]: value
			}
		})
	}
	const [Residency, setResidency] = useState(
		USERINFO?.personal_information?.residency ?? null
	)
	const HandleExpiryDateChanges = (fieldname, value) => {
		setSelectedExpiryDate((prevState) => {
			return {
				...prevState,
				[fieldname]: value
			}
		})
	}
	useEffect(() => {
		const updatedFormdata = { ...Formdata }
		let USER_DATA = USERINFO?.personal_information
		if (PERSONAL_INFO_DYNAMIC_FIELDS.title) {
			let title = PERSONAL_INFO_DYNAMIC_FIELDS.title.findIndex(
				(item) => item.value === USER_DATA?.title
			)
			if (title !== -1) {
				updatedFormdata.title =
					PERSONAL_INFO_DYNAMIC_FIELDS.title[title]
			}
			setDynamicFields((prev) => {
				return {
					...prev,
					title: PERSONAL_INFO_DYNAMIC_FIELDS.title
				}
			})
		}

		if (PERSONAL_INFO_DYNAMIC_FIELDS.relationShip) {
			let relation = PERSONAL_INFO_DYNAMIC_FIELDS.relationShip.findIndex(
				(item) => item.value === USER_DATA?.relationship
			)
			if (relation !== -1) {
				updatedFormdata.relationship =
					PERSONAL_INFO_DYNAMIC_FIELDS.relationShip[relation]
			}
			setDynamicFields((prev) => {
				return {
					...prev,
					relation: PERSONAL_INFO_DYNAMIC_FIELDS.relationShip
				}
			})
		}

		if (PERSONAL_INFO_DYNAMIC_FIELDS.gender) {
			let gender = PERSONAL_INFO_DYNAMIC_FIELDS.gender.findIndex(
				(item) => item.value === USER_DATA?.gender
			)
			if (gender !== -1) {
				updatedFormdata.gender =
					PERSONAL_INFO_DYNAMIC_FIELDS.gender[gender]
			}
			setDynamicFields((prev) => {
				return {
					...prev,
					gender: PERSONAL_INFO_DYNAMIC_FIELDS.gender
				}
			})
		}

		if (PERSONAL_INFO_DYNAMIC_FIELDS.identificationDocument) {
			let doc =
				PERSONAL_INFO_DYNAMIC_FIELDS.identificationDocument.findIndex(
					(item) => item.value === USER_DATA?.identification_document
				)
			if (doc !== -1) {
				updatedFormdata.identification_document =
					PERSONAL_INFO_DYNAMIC_FIELDS.identificationDocument[doc]
			}
			setDynamicFields((prev) => {
				return {
					...prev,
					document:
						PERSONAL_INFO_DYNAMIC_FIELDS.identificationDocument
				}
			})
		}

		if (PERSONAL_INFO_DYNAMIC_FIELDS.status) {
			let status = PERSONAL_INFO_DYNAMIC_FIELDS.status.findIndex(
				(item) => item.value === USER_DATA?.status
			)
			if (status !== -1) {
				updatedFormdata.status =
					PERSONAL_INFO_DYNAMIC_FIELDS.status[status]
			}
			setDynamicFields((prev) => {
				return {
					...prev,
					status: PERSONAL_INFO_DYNAMIC_FIELDS.status
				}
			})
		}
		setTimeout(() => {
			setFormdata(updatedFormdata)
		}, 300)
	}, [PERSONAL_INFO_DYNAMIC_FIELDS])
	useEffect(() => {
		const updatedFormdata = { ...Formdata }
		if (BUSINESS_INFO_DYNAMIC_FIELDS.grade) {
			let gradeIndex = BUSINESS_INFO_DYNAMIC_FIELDS.grade.findIndex(
				(item) => item.value === USERINFO?.business_information?.grade
			)
			if (gradeIndex !== -1) {
				updatedFormdata.grade =
					BUSINESS_INFO_DYNAMIC_FIELDS.grade[gradeIndex]
			}
			setDynamicFields((prev) => {
				return {
					...prev,
					grade: BUSINESS_INFO_DYNAMIC_FIELDS.grade
				}
			})
		}

		if (BUSINESS_INFO_DYNAMIC_FIELDS.clearence) {
			let clearenceIdx = BUSINESS_INFO_DYNAMIC_FIELDS.clearence.findIndex(
				(item) =>
					item.value === USERINFO?.business_information?.clearence
			)
			if (clearenceIdx !== -1) {
				updatedFormdata.clearence =
					BUSINESS_INFO_DYNAMIC_FIELDS.clearence[clearenceIdx]
			}
			setDynamicFields((prev) => {
				return {
					...prev,
					clearence: BUSINESS_INFO_DYNAMIC_FIELDS.clearence
				}
			})
		}
		if (BUSINESS_INFO_DYNAMIC_FIELDS.ou1) {
			let ou1Idx = BUSINESS_INFO_DYNAMIC_FIELDS.ou1.findIndex(
				(item) =>
					item.value === USERINFO?.business_information?.ou1_group
			)
			if (ou1Idx !== -1) {
				updatedFormdata.ou1_group =
					BUSINESS_INFO_DYNAMIC_FIELDS.ou1[ou1Idx]
			}
			setDynamicFields((prev) => {
				return {
					...prev,
					ou1_group: BUSINESS_INFO_DYNAMIC_FIELDS.ou1
				}
			})
		}

		if (BUSINESS_INFO_DYNAMIC_FIELDS.ou2) {
			let ou2Idx = BUSINESS_INFO_DYNAMIC_FIELDS.ou2.findIndex(
				(item) =>
					item.value === USERINFO?.business_information?.ou2_company
			)
			if (ou2Idx !== -1) {
				updatedFormdata.ou2_company =
					BUSINESS_INFO_DYNAMIC_FIELDS.ou2[ou2Idx]
			}
			setDynamicFields((prev) => {
				return {
					...prev,
					ou2_company: BUSINESS_INFO_DYNAMIC_FIELDS.ou1
				}
			})
		}

		if (BUSINESS_INFO_DYNAMIC_FIELDS.ou3) {
			let ou3Idx = BUSINESS_INFO_DYNAMIC_FIELDS.ou3.findIndex(
				(item) =>
					item.value ===
					USERINFO?.business_information?.ou3_department
			)
			if (ou3Idx !== -1) {
				updatedFormdata.ou3_department =
					BUSINESS_INFO_DYNAMIC_FIELDS.ou3[ou3Idx]
			}
			setDynamicFields((prev) => {
				return {
					...prev,
					ou3_department: BUSINESS_INFO_DYNAMIC_FIELDS.ou1
				}
			})
		}

		setFormdata(updatedFormdata)
	}, [BUSINESS_INFO_DYNAMIC_FIELDS])

	const HandleJoiningDateChange = (date) => {
		setJoiningDate(date)
	}
	const [workCountry, setWorkCountry] = useState(
		USERINFO?.business_information?.work_address_country || null
	)
	const [businessCountry, setBusinessCountry] = useState(
		USERINFO?.business_information?.business_address_country || null
	)
	const [workPhoneNumber, setworkPhoneNumber] = useState({
		countryCode:
			USERINFO?.business_information?.work_phone_number?.countryCode ||
			'+91',
		countryName:
			USERINFO?.business_information?.work_phone_number?.countryName ||
			'india',
		countryFlag:
			USERINFO?.business_information?.work_phone_number?.countryFlag ||
			'in',
		Number: USERINFO?.business_information?.work_phone_number?.Number || ''
	})
	const UpdateFields1 = (obj) => {
		setworkPhoneNumber({
			...workPhoneNumber,
			countryCode: obj.countryCode,
			countryName: obj.countryName,
			countryFlag: obj.countryFlag
		})
	}
	const setuserNumber1 = (obj) => {
		setworkPhoneNumber({
			...workPhoneNumber,
			Number: obj.Number
		})
	}
	const [Formdata, setFormdata] = useState({
		about: USERINFO?.personal_information?.about || '',
		title: USERINFO?.personal_information?.title || '',
		fullname: USERINFO?.personal_information?.fullname || '',
		age: USERINFO?.personal_information?.age || '',
		status: USERINFO?.personal_information?.status || '',
		gender: USERINFO?.personal_information?.gender || '',
		personal_email_id:
			USERINFO?.personal_information?.personal_email_id || '',
		personal_mobile_number:
			USERINFO?.personal_information?.personal_mobile_number || '',
		dual_citizenship:
			USERINFO?.personal_information?.dual_citizenship || '',
		identification_document:
			USERINFO?.personal_information?.identification_document || '',
		id_number: USERINFO?.personal_information?.id_number || '',
		home_address: USERINFO?.personal_information?.home_address || '',
		permenant_address:
			USERINFO?.personal_information?.permenant_address || '',
		home_address_city:
			USERINFO?.personal_information?.home_address_city || '',
		home_address_pin_code:
			USERINFO?.personal_information?.home_address_pin_code || '',
		home_address_state:
			USERINFO?.personal_information?.home_address_state || '',
		permanent_address_city:
			USERINFO?.personal_information?.permanent_address_city || '',
		permanent_address_pin_code:
			USERINFO?.personal_information?.permanent_address_pin_code || '',
		permanent_address_state:
			USERINFO?.personal_information?.permanent_address_state || '',
		emergency_contact_name:
			USERINFO?.personal_information?.emergency_contact_name || '',
		relationship: USERINFO?.personal_information?.relationship || '',
		emergency_contact_email:
			USERINFO?.personal_information?.emergency_contact_email || '',
		employee_id: USERINFO?.business_information?.employee_id || '',
		grade: USERINFO?.business_information?.grade || '',
		designation: USERINFO?.business_information?.designation || '',
		role: USERINFO?.business_information?.role || '',
		clearence: USERINFO?.business_information?.clearence || '',
		reporting_manager_functional:
			USERINFO?.business_information?.reporting_manager_functional || '',
		reporting_manager_administrative:
			USERINFO?.business_information?.reporting_manager_administrative ||
			'',
		ou1_group: USERINFO?.business_information?.ou1_group || '',
		ou2_company: USERINFO?.business_information?.ou2_company || '',
		ou3_department: USERINFO?.business_information?.ou3_department || '',
		work_email_id: USERINFO?.business_information?.work_email_id || '',
		work_address: USERINFO?.business_information?.work_address || '',
		business_address:
			USERINFO?.business_information?.business_address || '',
		work_address_city:
			USERINFO?.business_information?.work_address_city || '',
		work_address_pin_code:
			USERINFO?.business_information?.work_address_pin_code || '',
		work_address_state:
			USERINFO?.business_information?.work_address_state || '',
		business_address_city:
			USERINFO?.business_information?.business_address_city || '',
		business_address_pin_code:
			USERINFO?.business_information?.business_address_pin_code || '',
		business_address_state:
			USERINFO?.business_information?.business_address_state || '',
		linked_in: USERINFO?.social_information?.linked_in || '',
		whatsapp: USERINFO?.social_information?.whatsapp || '',
		github: USERINFO?.social_information?.github || '',
		telegram: USERINFO?.social_information?.telegram || ''
	})

	const HANDLEFORMDATA = (field, value) => {
		setFormdata({
			...Formdata,
			[field]: value
		})
	}
	const [FIELDS, setFIELDS] = useState([])
	const [filesState, setFilesState] = useState({})
	const [ExistingFiles, setExistingFIles] = useState({})
	const [optionsData, setOptionsData] = useState([])
	const [optionsBackup, setOptionsbackup] = useState([])
	const [multiSelectData, setMultiSelectData] = useState({})
	const [upload] = useMutation(UPLOAD_ATTACHMENTS)

	const handleMultiSelectChange = (field, selectedItems) => {
		let form_data = selectedItems.map((item) => item.value).join(',')
		HANDLEFORMDATA(field, form_data)
		setMultiSelectData((prevData) => ({
			...prevData,
			[field]: selectedItems
		}))
	}

	useEffect(() => {
		if (Object.keys(multiSelectData).length > 0) {
			let newOptions = {}
			for (const option in optionsBackup) {
				let selectedValues = []
				if (multiSelectData[option]) {
					selectedValues = multiSelectData[option].map(
						(item) => item.value
					)
				}
				let new_options = optionsBackup[option].filter((ele) => {
					if (!selectedValues.includes(ele.value)) return ele
				})
				newOptions[option] = new_options
				setOptionsData(newOptions)
			}
		}
	}, [multiSelectData, optionsBackup])

	const handleAttachmentRemove = (key, name) => {
		const FilesData = ExistingFiles[key]
		let Filtered = FilesData.filter((item) => item !== name)
		setExistingFIles((prev) => {
			return {
				...prev,
				[key]: Filtered
			}
		})
	}
	const handleSelectedFile = (fieldName, files) => {
		setFilesState((prevState) => ({
			...prevState,
			[fieldName]: files[0] ? [files[0]] : []
		}))
		setExistingFIles((prevState) => ({
			...prevState,
			[fieldName]: []
		}))
	}

	const removeFile = (fieldName, fileToRemove) => {
		setFilesState((prevState) => ({
			...prevState,
			[fieldName]: (prevState[fieldName] || []).filter(
				(file) => file.name !== fileToRemove.name
			)
		}))
	}

	useEffect(() => {
		if (FIELDS) {
			let data = FIELDS
			let initialFilesState = {}
			data.forEach((component) => {
				if (component.field_type === 'multi-select-drop-down') {
					setOptionsData((prevOptions) => ({
						...prevOptions,
						[component.field_name]: updateKeysToLabels(
							component.options
						)
					}))
					setOptionsbackup((prevOptions) => ({
						...prevOptions,
						[component.field_name]: updateKeysToLabels(
							component.options
						)
					}))
				}
				if (component.field_type === 'file-attachment') {
					initialFilesState[component.field_name] = userData
						?.user_data?.additional_information
						? userData[component.field_name] || []
						: []
				}
			})
		}
	}, [FIELDS])
	useEffect(() => {
		if (userData?.user_data?.additional_information) {
			Object.keys(userData?.user_data?.additional_information).forEach(
				(item) => {
					if (
						Array.isArray(
							userData?.user_data?.additional_information[item]
						)
					) {
						setFilesState({})
						setExistingFIles((prevFiles) => ({
							...prevFiles,
							[item]: userData?.user_data?.additional_information[
								item
							]
						}))
					}
					setFormdata((prevFormData) => ({
						...prevFormData,
						[item]: userData[item] || ' '
					}))
				}
			)
		}
	}, [])

	useEffect(() => {
		if (userData?.user_data?.additional_information) {
			Object.keys(userData?.user_data?.additional_information).forEach(
				(item) => {
					if (optionsBackup && optionsBackup[item]) {
						let selectedData = []
						selectedData = optionsBackup[item].filter((ele) => {
							if (
								userData?.user_data?.additional_information[
									item
								]
									.split(',')
									.includes(ele.value)
							)
								return ele
						})
						handleMultiSelectChange(item, selectedData)
					}
				}
			)
		}
	}, [userData, optionsBackup])

	const [FilteredComponents, setFilteredComponents] = useState([
		{
			component: 'elastictextbox',
			id: 'about',
			label: 'About '
		},
		{
			component: 'dynamic-dropdown',
			id: 'title',
			label: 'Title ',
			list: 'title'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'fullname',
			label: 'Full Name'
		},
		{
			label: 'DOB',
			id: 'dob',
			component: 'DOB'
		},

		{
			component: 'textbox',
			type: 'number',
			id: 'age',
			label: 'Age '
		},
		{
			component: 'dynamic-dropdown',
			id: 'status',
			label: 'Status ',
			list: 'status'
		},
		{
			component: 'dynamic-dropdown',
			id: 'gender',
			label: 'Gender ',
			list: 'gender'
		},

		{
			type: 'text',
			component: 'textbox',
			id: 'personal_email_id',
			label: 'Personal Email ID'
		},
		{
			label: 'Personal Mobile Number',
			id: 'personal_mobile_number',
			component: 'Personal Mobile Number'
		},
		{
			label: 'CitizenShip',
			id: 'citizenShip',
			component: 'citizenship'
		},
		{
			component: 'residency',
			id: 'residency',
			label: 'Residency'
		},
		{
			component: 'dropdown',
			id: 'dual_citizenship',
			label: 'Dual Citizenship ',
			list: 'dual_citizenship'
		},
		{
			label: 'second citizenship',
			id: 'second_citizenship',
			component: 'second citizenship'
		},
		{
			component: 'dynamic-dropdown',
			id: 'identification_document',
			label: 'Identification Document',
			list: 'document'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'id_number',
			label: 'ID Number'
		},
		{
			label: 'expiry date',
			id: 'expiry_date',
			component: 'expiry date'
		},
		{
			label: 'issue location',
			id: 'issue_location',
			component: 'issue location'
		},
		{
			component: 'elastictextbox',
			id: 'home_address',
			label: 'Home Address '
		},
		{
			component: 'elastictextbox',
			id: 'permenant_address',
			label: 'Permenant Address '
		},

		{
			type: 'text',
			component: 'textbox',
			id: 'home_address_city',
			label: 'Home Address City'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'home_address_pin_code',
			label: 'Home Address Pin Code'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'home_address_state',
			label: 'Home Address State'
		},
		{
			label: 'homecountry',
			id: 'home_address_country',
			component: 'homecountry'
		},

		{
			type: 'text',
			component: 'textbox',
			id: 'permanent_address_city',
			label: 'Permenant Address City'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'permanent_address_pin_code',
			label: 'Permenant Address Pin Code'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'permanent_address_state',
			label: 'Permenant Address State'
		},
		{
			label: 'permenantcountry',
			id: 'permanent_address_country',
			component: 'permenantcountry'
		},

		{
			type: 'text',
			component: 'textbox',
			id: 'emergency_contact_name',
			label: 'Emergency Contact Name'
		},
		{
			component: 'dynamic-dropdown',
			id: 'relationship',
			label: 'Relationship ',
			list: 'relation'
		},
		{
			label: 'emergencynumber',
			id: 'emergency_contact_number',
			component: 'emergencynumber'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'emergency_contact_email',
			label: 'Emergency Contact Email'
		},

		{
			type: 'text',
			component: 'textbox',
			id: 'employee_id',
			label: 'Employee ID'
		},
		{
			component: 'dynamic-dropdown',
			id: 'grade',
			label: 'Grade ',
			list: 'grade'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'designation',
			label: 'Designation'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'role',
			label: 'Role'
		},

		{
			label: 'dateofjoining',
			id: 'date_of_joining',
			component: 'dateofjoining'
		},
		{
			component: 'dynamic-dropdown',
			id: 'clearence',
			label: 'Clearence ',
			list: 'clearence'
		},

		{
			component: 'dropdown',
			list: 'users',
			id: 'reporting_manager_administrative',
			label: 'Reporting Manager (Administrative) '
		},
		{
			component: 'dropdown',
			list: 'users',
			id: 'reporting_manager_functional',
			label: 'Reporting Manager (Functional) '
		},

		{
			component: 'dynamic-dropdown',
			id: 'ou1_group',
			label: 'Ou1 (Group) ',
			list: 'ou1_group'
		},
		{
			component: 'dynamic-dropdown',
			id: 'ou2_company',
			label: 'Ou2 (Company) ',
			list: 'ou2_company'
		},
		{
			component: 'dynamic-dropdown',
			id: 'ou3_department',
			label: 'Ou3 (Department)',
			list: 'ou3_department'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'work_email_id',
			label: 'Work eMail ID'
		},
		{
			label: 'Work Mobile Number',
			id: 'Work Mobile Number',
			component: 'Work Mobile Number'
		},
		{
			component: 'elastictextbox',
			id: 'work_address',
			label: 'Work Address'
		},
		{
			component: 'elastictextbox',
			id: 'business_address',
			label: 'Business Address'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'work_address_city',
			label: 'Work City'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'work_address_pin_code',
			label: 'Work Pincode'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'work_address_state',
			label: 'Work State'
		},

		{
			label: 'workcountry',
			id: 'workcountry',
			component: 'workcountry'
		},

		{
			type: 'text',
			component: 'textbox',
			id: 'business_address_city',
			label: 'Business City'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'business_address_pin_code',
			label: 'Business Pincode'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'business_address_state',
			label: 'Business State'
		},
		{
			label: 'country',
			id: 'country',
			component: 'country'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'linked_in',
			label: 'LinkedIn'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'whatsapp',
			label: 'Whatsapp'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'github',
			label: 'Github'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'telegram',
			label: 'Telegram'
		},
		{
			component: 'Select image',
			id: 'telegram',
			label: 'Telegram'
		}
	])
	const [components, setComponents] = useState([
		{
			component: 'elastictextbox',
			id: 'about',
			label: 'About '
		},
		{
			component: 'dynamic-dropdown',
			id: 'title',
			label: 'Title ',
			list: 'title'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'fullname',
			label: 'Full Name'
		},
		{
			label: 'DOB',
			id: 'dob',
			component: 'DOB'
		},

		{
			component: 'textbox',
			type: 'number',
			id: 'age',
			label: 'Age '
		},
		{
			component: 'dynamic-dropdown',
			id: 'status',
			label: 'Status ',
			list: 'status'
		},
		{
			component: 'dynamic-dropdown',
			id: 'gender',
			label: 'Gender ',
			list: 'gender'
		},

		{
			type: 'text',
			component: 'textbox',
			id: 'personal_email_id',
			label: 'Personal Email ID'
		},
		{
			label: 'Personal Mobile Number',
			id: 'personal_mobile_number',
			component: 'Personal Mobile Number'
		},
		{
			label: 'CitizenShip',
			id: 'citizenShip',
			component: 'citizenship'
		},
		{
			component: 'residency',
			id: 'residency',
			label: 'Residency'
		},
		{
			component: 'dropdown',
			id: 'dual_citizenship',
			label: 'Dual Citizenship ',
			list: 'dual_citizenship'
		},
		{
			label: 'second citizenship',
			id: 'second_citizenship',
			component: 'second citizenship'
		},
		{
			component: 'dynamic-dropdown',
			id: 'identification_document',
			label: 'Identification Document',
			list: 'document'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'id_number',
			label: 'ID Number'
		},
		{
			label: 'expiry date',
			id: 'expiry_date',
			component: 'expiry date'
		},
		{
			label: 'issue location',
			id: 'issue_location',
			component: 'issue location'
		},
		{
			component: 'elastictextbox',
			id: 'home_address',
			label: 'Home Address '
		},
		{
			component: 'elastictextbox',
			id: 'permenant_address',
			label: 'Permenant Address '
		},

		{
			type: 'text',
			component: 'textbox',
			id: 'home_address_city',
			label: 'Home Address City'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'home_address_pin_code',
			label: 'Home Address Pin Code'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'home_address_state',
			label: 'Home Address State'
		},
		{
			label: 'homecountry',
			id: 'home_address_country',
			component: 'homecountry'
		},

		{
			type: 'text',
			component: 'textbox',
			id: 'permanent_address_city',
			label: 'Permenant Address City'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'permanent_address_pin_code',
			label: 'Permenant Address Pin Code'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'permanent_address_state',
			label: 'Permenant Address State'
		},
		{
			label: 'permenantcountry',
			id: 'permanent_address_country',
			component: 'permenantcountry'
		},

		{
			type: 'text',
			component: 'textbox',
			id: 'emergency_contact_name',
			label: 'Emergency Contact Name'
		},
		{
			component: 'dynamic-dropdown',
			id: 'relationship',
			label: 'Relationship ',
			list: 'relation'
		},
		{
			label: 'emergencynumber',
			id: 'emergency_contact_number',
			component: 'emergencynumber'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'emergency_contact_email',
			label: 'Emergency Contact Email'
		},

		{
			type: 'text',
			component: 'textbox',
			id: 'employee_id',
			label: 'Employee ID'
		},
		{
			component: 'dynamic-dropdown',
			id: 'grade',
			label: 'Grade ',
			list: 'grade'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'designation',
			label: 'Designation'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'role',
			label: 'Role'
		},

		{
			label: 'dateofjoining',
			id: 'date_of_joining',
			component: 'dateofjoining'
		},
		{
			component: 'dynamic-dropdown',
			id: 'clearence',
			label: 'Clearence ',
			list: 'clearence'
		},

		{
			component: 'dropdown',
			list: 'users',
			id: 'reporting_manager_administrative',
			label: 'Reporting Manager (Administrative) '
		},
		{
			component: 'dropdown',
			list: 'users',
			id: 'reporting_manager_functional',
			label: 'Reporting Manager (Functional) '
		},

		{
			component: 'dynamic-dropdown',
			id: 'ou1_group',
			label: 'Ou1 (Group) ',
			list: 'ou1_group'
		},
		{
			component: 'dynamic-dropdown',
			id: 'ou2_company',
			label: 'Ou2 (Company) ',
			list: 'ou2_company'
		},
		{
			component: 'dynamic-dropdown',
			id: 'ou3_department',
			label: 'Ou3 (Department)',
			list: 'ou3_department'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'work_email_id',
			label: 'Work eMail ID'
		},
		{
			label: 'Work Mobile Number',
			id: 'Work Mobile Number',
			component: 'Work Mobile Number'
		},
		{
			component: 'elastictextbox',
			id: 'work_address',
			label: 'Work Address'
		},
		{
			component: 'elastictextbox',
			id: 'business_address',
			label: 'Business Address'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'work_address_city',
			label: 'Work City'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'work_address_pin_code',
			label: 'Work Pincode'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'work_address_state',
			label: 'Work State'
		},

		{
			label: 'workcountry',
			id: 'workcountry',
			component: 'workcountry'
		},

		{
			type: 'text',
			component: 'textbox',
			id: 'business_address_city',
			label: 'Business City'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'business_address_pin_code',
			label: 'Business Pincode'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'business_address_state',
			label: 'Business State'
		},
		{
			label: 'country',
			id: 'country',
			component: 'country'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'linked_in',
			label: 'LinkedIn'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'whatsapp',
			label: 'Whatsapp'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'github',
			label: 'Github'
		},
		{
			type: 'text',
			component: 'textbox',
			id: 'telegram',
			label: 'Telegram'
		},
		{
			component: 'Select image',
			id: 'telegram',
			label: 'Telegram'
		}
	])
	const HandleSearch = (value) => {
		setSearch(value)
		if (value) {
			const filteredComponents = FilteredComponents.filter((com) =>
				com.label.toLowerCase().includes(value.toLowerCase())
			)
			setComponents(filteredComponents)
		} else {
			setComponents(FilteredComponents)
		}
	}
	function calculateAge(selectedDate) {
		const { day, month, year } = selectedDate

		if (!day || !month || !year) {
			return
		}

		const currentDate = new Date()
		const currentDay = currentDate.getDate()
		const currentMonth = currentDate.getMonth() + 1
		const currentYear = currentDate.getFullYear()

		let age = currentYear - year

		if (
			currentMonth < month ||
			(currentMonth === month && currentDay < day)
		) {
			age--
		}

		HANDLEFORMDATA('age', age)
	}

	useEffect(() => {
		calculateAge(selectedDate)
	}, [selectedDate])

	function getMonthNumber(monthName) {
		const months = {
			Jan: 1,
			Feb: 2,
			Mar: 3,
			Apr: 4,
			May: 5,
			Jun: 6,
			Jul: 7,
			Aug: 8,
			Sep: 9,
			Oct: 10,
			Nov: 11,
			Dec: 12
		}
		return months[monthName]
	}

	function getMonthNumberFromNameOrValue(month) {
		if (typeof month === 'string') {
			return getMonthNumber(month)
		}
		return month
	}

	selectedDate.month = getMonthNumberFromNameOrValue(selectedDate.month)
	const { data, loading, error } = useQuery(
		gql`
			${GET_ADDITIONAL_FIELDS_BY_SCOPE}
		`,
		{
			variables: {
				scope: 'user-profile'
			},
			context: {
				headers: {
					authorization: authToken
				}
			}
		}
	)
	const UserProfileAdditionalFields =
		data?.getAllAdditionalFieldsByScope?.data?.items

	useEffect(() => {
		if (UserProfileAdditionalFields) {
			setFIELDS(UserProfileAdditionalFields)
			UserProfileAdditionalFields.map((item) => {
				if (item.field_type === 'elastic-textbox') {
					setFilteredComponents((prev) => {
						return [
							...prev,
							{
								component: 'elastictextbox',
								id: item.field_name,
								label: item.label
							}
						]
					})
					setComponents((prev) => {
						return [
							...prev,
							{
								component: 'elastictextbox',
								id: item.field_name,
								label: item.label
							}
						]
					})
				} else if (item.field_type === 'textbox') {
					setFilteredComponents((prev) => {
						return [
							...prev,
							{
								type: item.type,
								component: item.field_type,
								id: item.field_name,
								label: item.label
							}
						]
					})
					setComponents((prev) => {
						return [
							...prev,
							{
								type: item.type,
								component: item.field_type,
								id: item.field_name,
								label: item.label
							}
						]
					})
				} else if (item.field_type === 'drop-down') {
					setStaticDropDownFields((prev) => {
						return {
							...prev,
							[item.field_name]: item.options.map(
								(options) => options.value
							)
						}
					})
					setFilteredComponents((prev) => {
						return [
							...prev,
							{
								component: 'dropdown',
								list: item.field_name,
								id: item.field_name,
								label: item.label
							}
						]
					})
					setComponents((prev) => {
						return [
							...prev,
							{
								component: 'dropdown',
								list: item.field_name,
								id: item.field_name,
								label: item.label
							}
						]
					})
				} else if (item.field_type === 'multi-select-drop-down') {
					setFilteredComponents((prev) => {
						return [
							...prev,
							{
								component: 'multi-select-drop-down',
								id: item.field_name,
								label: item.label
							}
						]
					})
					setComponents((prev) => {
						return [
							...prev,
							{
								component: 'multi-select-drop-down',
								id: item.field_name,
								label: item.label
							}
						]
					})
				} else if (item.field_type === 'date') {
					setFilteredComponents((prev) => {
						return [
							...prev,
							{
								component: 'date',
								id: item.field_name,
								label: item.label
							}
						]
					})
					setComponents((prev) => {
						return [
							...prev,
							{
								component: 'date',
								id: item.field_name,
								label: item.label
							}
						]
					})
				} else if (item.field_type === 'file-attachment') {
					setFilteredComponents((prev) => {
						return [
							...prev,
							{
								component: 'file-attachment',
								id: item.field_name,
								label: item.label
							}
						]
					})
					setComponents((prev) => {
						return [
							...prev,
							{
								component: 'file-attachment',
								id: item.field_name,
								label: item.label
							}
						]
					})
				} else if (item.field_type === 'radio') {
					setFilteredComponents((prev) => {
						return [
							...prev,
							{
								component: 'radio',
								id: item.field_name,
								label: item.label,
								list: item.options
							}
						]
					})
					setComponents((prev) => {
						return [
							...prev,
							{
								component: 'radio',
								id: item.field_name,
								label: item.label,
								list: item.options
							}
						]
					})
				} else if (item.field_type === 'checkbox') {
					setFilteredComponents((prev) => {
						return [
							...prev,
							{
								component: 'checkbox',
								id: item.field_name,
								label: item.label,
								list: item.options
							}
						]
					})
					setComponents((prev) => {
						return [
							...prev,
							{
								component: 'checkbox',
								id: item.field_name,
								label: item.label,
								list: item.options
							}
						]
					})
				}
			})
		}
	}, [data, error, loading])

	useEffect(() => {
		if (USERINFO?.additional_information) {
			Object.keys(USERINFO?.additional_information).forEach(
				async (info) => {
					setFormdata((prevFormData) => ({
						...prevFormData,
						[info]: USERINFO?.additional_information[info]
					}))
				}
			)
		}
	}, [UserProfileAdditionalFields, USERINFO, data])

	useEffect(() => {
		setBody(FORM)
	}, [
		Formdata,
		selectedDate,
		personalNumber,
		EmergencyNumber,
		workPhoneNumber,
		Files,
		components,
		selectedExpiryDate
	])
	const handleUploads = async () => {
		try {
			setLoader(true)
			let FILES = []
			const Field = Object.keys(filesState).reduce((acc, item) => {
				FILES = [...FILES, ...filesState[item]]
				acc[item] = {
					new_files: filesState[item].map((file) => file.name),
					old_files: []
				}
				return acc
			}, {})
			const response = await upload({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						fields: Field,
						user_id: UID
					},
					files: FILES[0]
				}
			})
			let data = await response.data
			setLoader(false)
			return data
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'updateadditionalinfo',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	const [update] = useMutation(UPDATE_PROFILE_MUTATION_ADMIN)
	const handleUpdate = async () => {
		try {
			setLoader(true)
			const response = await update({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						user_id: UID,
						data: {
							...Formdata,
							title: Formdata?.title.value ?? '',
							status: Formdata?.status?.value ?? '',
							gender: Formdata?.gender?.value ?? '',
							relationship: Formdata?.relationship?.value ?? '',
							identification_document:
								Formdata?.identification_document?.value ?? '',
							grade: Formdata?.grade?.value ?? '',
							clearence: Formdata?.clearence?.value ?? '',
							ou1_group: Formdata?.ou1_group?.value ?? '',
							ou2_company: Formdata?.ou2_company?.value ?? '',
							ou3_department:
								Formdata?.ou3_department?.value ?? '',
							residency: Residency,
							expiry_date: selectedExpiryDate,
							dob: selectedDate,
							personal_mobile_number: personalNumber,
							citizenship: citizenship,
							second_citizenship: secondCitizenShip,
							issue_location: issueLocation,
							home_address_country: country,
							permanent_address_country: permenantCountry,
							emergency_contact_number: EmergencyNumber,
							date_of_joining: joiningDate,
							work_address_country: workCountry,
							business_address_country: businessCountry,
							work_phone_number: workPhoneNumber,
							profile_photo: Files ? Files : []
						}
					}
				}
			})
			let data = await response.data
			let updateprofile = data?.editProfileAdmin
			if (updateprofile.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'editusers',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'editusers' }))
				}, 3000)
			} else if (
				updateprofile.status !== 200 &&
				(updateprofile?.errors?.length > 0 || updateprofile.message)
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'editusers',
						type: 'danger',
						message:
							updateprofile?.errors?.map((err) => err) ??
							updateprofile.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (updateprofile.status === 200) {
				const Field = Object.keys(filesState)
				let isFile = false

				if (Field.length > 0) {
					Field.forEach((key) => {
						if (filesState[key]) {
							isFile = true
						}
					})
				}

				if (isFile) {
					try {
						let File_Upload = await handleUploads()
						if (
							File_Upload?.updateAdditionalFieldFiles.status ===
							200
						) {
							let AdditionalInfo =
								File_Upload?.updateAdditionalFieldFiles?.data
							setLoader(false)
							fetchUsers()
							dispatch(
								addAlerts({
									pageId: 'editusers',
									type: 'success',
									message: updateprofile.message,
									icon: faCheck,
									autoclose: 3000
								})
							)

							setUsers((prevUsers) => {
								return prevUsers.map((prevUser) => {
									if (
										prevUser.id ===
										updateprofile.data.user_id
									) {
										const updatedUser = {
											...prevUser,
											gender: Formdata.gender,
											fullname: Formdata.fullname,
											user_data: {
												personal_information:
													AdditionalInfo?.personal_information,
												business_information:
													AdditionalInfo?.business_information,
												social_information:
													AdditionalInfo?.social_information,
												additional_information:
													AdditionalInfo?.additional_information
											}
										}

										setselectedUsers([{ ...updatedUser }])

										return updatedUser
									}
									return prevUser
								})
							})

							setTimeout(() => {
								setShowDrawer(false)
								setScrollable(false)
								setScrollableFooter(false)
								setSize('md')
								setOverlay('')
								setBody(null)
								dispatch(removeAlerts({ pageId: 'editusers' }))
							}, 3000)
						} else if (
							File_Upload?.updateAdditionalFieldFiles.status !==
								200 &&
							(File_Upload?.updateAdditionalFieldFiles?.errors
								.length > 0 ||
								File_Upload?.updateAdditionalFieldFiles
									?.message)
						) {
							dispatch(
								addAlerts({
									pageId: 'editusers',
									type: 'danger',
									message:
										File_Upload.updateAdditionalFieldFiles.errors?.join(
											', '
										) ??
										File_Upload.updateAdditionalFieldFiles
											.message,
									icon: faTriangleExclamation,
									autoclose: 3000
								})
							)
						}
					} catch (error) {
						console.error('File upload failed', error)
						dispatch(
							addAlerts({
								pageId: 'editusers',
								type: 'danger',
								message:
									'File upload failed. Please try again.',
								icon: faTriangleExclamation,
								autoclose: 3000
							})
						)
					}
				} else {
					setLoader(false)
					fetchUsers()
					dispatch(
						addAlerts({
							pageId: 'editusers',
							type: 'success',
							message: updateprofile.message,
							icon: faCheck,
							autoclose: 3000
						})
					)
					setTimeout(() => {
						setShowDrawer(false)
						setScrollable(false)
						setScrollableFooter(false)
						setSize('md')
						setOverlay('')
						setBody(null)
						dispatch(removeAlerts({ pageId: 'editusers' }))
						setUsers((prevUsers) =>
							prevUsers.map((prevUser) => {
								if (
									prevUser.id === updateprofile.data.user_id
								) {
									return {
										...prevUser,
										user_data: updateprofile.data,
										gender: Formdata.gender,
										fullname: Formdata.fullname
									}
								}
								return prevUser
							})
						)
					}, 3000)
				}
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'editusers',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	const FORM = (
		<form
			encType="multipart/form-data"
			className=" flex w-[350px] flex-col justify-start"
		>
			<SearchBar
				key={'search'}
				value={search}
				onChange={(data) => HandleSearch(data)}
				labelName={'Search Component'}
			/>
			{components.map((com, index) => {
				const { component, id, label } = com
				switch (component) {
					case 'checkbox':
						return (
							<div
								className="form-group"
								key={label + 'checkbox'}
							>
								<label className="label">{label}</label>
								<CheckboxGroup
									id={label}
									key={label}
									type="vertical"
									options={com?.list?.map((comp) => {
										return {
											label: comp.key,
											value: comp.key,
											checked: Formdata[id]?.[comp.key]
										}
									})}
									onChange={(item) =>
										HANDLEFORMDATA(id, item)
									}
								/>
							</div>
						)
					case 'radio':
						return (
							<div className="form-group" key={label + 'radio'}>
								<label className="label">{label}</label>
								<RadioGroup
									id={label}
									key={label}
									name={id}
									type="vertical"
									options={com?.list?.map((comp) => {
										return {
											label: comp.key,
											value: comp.value,
											selected:
												Formdata[id] === comp.value
										}
									})}
									onChange={(data) =>
										HANDLEFORMDATA(id, data)
									}
									className="radio-circle"
								/>{' '}
							</div>
						)
					case 'elastictextbox':
						return (
							<ElasticTextBox
								id={id}
								key={label}
								onChange={(data) => HANDLEFORMDATA(id, data)}
								value={Formdata[id] ?? ''}
								labelName={label}
							/>
						)
					case 'file-attachment':
						return (
							<div key={index}>
								<Attachments
									id={id}
									key={index + ' ' + id}
									labelName={label}
									files={filesState[id] || []}
									onSelect={(file) =>
										handleSelectedFile(id, file)
									}
									onRemove={(file) => removeFile(id, file)}
								/>
								{ExistingFiles[id] &&
									ExistingFiles[id].map((item) => {
										const parts = item.split('/')
										const fileName = parts[parts.length - 1]
										return (
											<FalseAttachments
												key={fileName}
												filename={fileName}
												onFileRemove={() =>
													handleAttachmentRemove(
														[id],
														item
													)
												}
											/>
										)
									})}
							</div>
						)
					case 'date':
						return (
							<Datepicker
								key={index}
								labelName={label}
								onDateChange={(date) =>
									HANDLEFORMDATA(id, date)
								}
								currentDate={Formdata[id]}
							/>
						)

					case 'multi-select-drop-down':
						return (
							<MultiSelectdropdown
								key={id}
								label={label ?? 'Default Label'}
								remainingItems={optionsData[id] ?? []}
								selectedItems={multiSelectData[id] ?? []}
								onItemAdd={(item) =>
									handleMultiSelectChange(id, [
										...(multiSelectData[id] || []),
										item
									])
								}
								onItemRemove={(item) =>
									handleMultiSelectChange(
										id,
										(multiSelectData[id] || []).filter(
											(i) => i.value !== item.value
										)
									)
								}
							/>
						)
					case 'dynamic-dropdown':
						return (
							<Simpledropdown
								key={label}
								label={label}
								onChange={(data) => HANDLEFORMDATA(id, data)}
								value={Formdata[id].label}
								options={dynamicFields[com.list]}
							/>
						)
					case 'textbox':
						return (
							<TextBox
								readOnly={id == 'age'}
								id={label}
								name={label}
								key={label}
								onChange={(data) => HANDLEFORMDATA(id, data)}
								value={Formdata[id]}
								labelName={label}
							/>
						)
					case 'dropdown':
						return (
							<SimpleDropDown
								key={label}
								label={label}
								value={Formdata[id]}
								options={staticDropFieldFields[com.list]}
								onChange={(data) => HANDLEFORMDATA(id, data)}
							/>
						)

					case 'DOB':
						return (
							<SearchableDatepicker
								key={label}
								label="DOB"
								day={selectedDate.day}
								month={selectedDate.month}
								year={selectedDate.year}
								onChange={(fieldname, value) =>
									HandleFieldChanges(fieldname, value)
								}
							/>
						)
					case 'expiry date':
						return (
							<SearchableDatepicker
								label="Expiry Date"
								key={'Expiry Date'}
								day={selectedExpiryDate.day}
								month={selectedExpiryDate.month}
								year={selectedExpiryDate.year}
								onChange={(fieldname, value) =>
									HandleExpiryDateChanges(fieldname, value)
								}
							/>
						)
					case 'Personal Mobile Number':
						return (
							<PhoneSelector
								key={label}
								labelName={'Personal Mobile Number'}
								currentCountry={personalNumber}
								onCountrySelect={(obj) => UpdateFields(obj)}
								onUSerInputChange={(obj) => setuserNumber(obj)}
							/>
						)
					case 'Work Mobile Number':
						return (
							<PhoneSelector
								key={label}
								labelName={'Work Phone Number'}
								currentCountry={workPhoneNumber}
								onCountrySelect={(obj) => UpdateFields1(obj)}
								onUSerInputChange={(obj) => setuserNumber1(obj)}
							/>
						)
					case 'citizenship':
						return (
							<CountrySelector
								key={label}
								defaultCountry={citizenship}
								label="Citizenship"
								onChange={(data) => setCitizenship(data)}
							/>
						)
					case 'second citizenship':
						return (
							<CountrySelector
								key={label}
								defaultCountry={secondCitizenShip}
								label="Second Citizenship"
								onChange={(data) => setSecondCitizenShip(data)}
							/>
						)
					case 'residency':
						return (
							<CountrySelector
								key={label}
								defaultCountry={Residency}
								label="Residency"
								onChange={(data) => setResidency(data)}
							/>
						)

					case 'issue location':
						return (
							<CountrySelector
								key={label}
								defaultCountry={issueLocation}
								label="Issue Location"
								onChange={(data) => setIssueLocation(data)}
							/>
						)
					case 'homecountry':
						return (
							<CountrySelector
								key={label}
								defaultCountry={country}
								label="Home Address Country"
								onChange={(data) => setCountry(data)}
							/>
						)
					case 'permenantcountry':
						return (
							<CountrySelector
								key={label}
								defaultCountry={permenantCountry}
								label="Permenant Address Country"
								onChange={(data) => setPermenantCountry(data)}
							/>
						)
					case 'emergencynumber':
						return (
							<PhoneSelector
								key={label}
								labelName={'Emergency Contact  Number'}
								currentCountry={EmergencyNumber}
								onCountrySelect={(obj) =>
									UpdateEmergencyCountryFields(obj)
								}
								onUSerInputChange={(obj) =>
									setEmergencyuserNumber(obj)
								}
							/>
						)
					case 'dateofjoining':
						return (
							<DatePicker
								key={label}
								labelName={'Date of joining'}
								onDateChange={(date) =>
									HandleJoiningDateChange(date)
								}
								currentDate={joiningDate}
							/>
						)
					case 'workcountry':
						return (
							<CountrySelector
								key={label}
								defaultCountry={workCountry}
								label="Work Country"
								onChange={(data) => setWorkCountry(data)}
							/>
						)
					case 'country':
						return (
							<CountrySelector
								key={label}
								defaultCountry={businessCountry}
								label="Business Country"
								onChange={(data) => setBusinessCountry(data)}
							/>
						)
					case 'Select image':
						return (
							<ImageEditor
								id={'user_iamge_byadmin'}
								key={'user-image'}
								onImageCrop={(data) => setFiles(data)}
							/>
						)
				}
			})}
		</form>
	)
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setDrawerstate(false)
	}
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])

	// useEffect(() => {
	// 	setBody(FORM)
	// }, [showDrawer, Formdata,fields])
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "> </h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>
				<IconButton
					onClick={handleUpdate}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') {
			handleDrawerClose()
		}
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			<PageAlerts pageId={'editusers'} />
			{loader && <ProgressBar />}
			<Drawer
				label="Edit User"
				size={size}
				body={FORM}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default EditUser
