import React from 'react'
import FormBanner from '../../components/Common/FormBanner'

const AuthLayout = ({ children }) => {
	return (
		<div
			className="page-sign block h-screen py-0"
			style={{
				backgroundImage:
					'linear-gradient(to right, #0F2C59 40%, #2747b5 100%)'
			}}
		>
			<div className="flex h-full">
				<FormBanner />
				<div className="col-wrapper md:w-7/12 lg:w-5/12 xl:w-4/12">
					<div className="card card-sign">{children}</div>
				</div>
			</div>
		</div>
	)
}

export default AuthLayout
