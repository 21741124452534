import { gql } from '@apollo/client'
export const UPDATE_PROFILE_MUTATION = gql`
	mutation Mutation($input: UpdateProfileInput!) {
		updateProfile(input: $input) {
			status
			message
			data
			errors
		}
	}
`

export const UPDATE_PROFILE_MUTATION_ADMIN = gql`
	mutation Mutation($input: UpdateProfileInput!) {
		editProfileAdmin(input: $input) {
			status
			message
			data
			errors
		}
	}
`
