import { gql } from '@apollo/client'
export const CREATE_USER_MUTATION = gql`
	mutation CreateUser($input: CreateUserInput!) {
		createUser(input: $input) {
			status
			message
			errors
			data {
				user_id
				email
				active
				role
				created_at
				updated_at
			}
		}
	}
`
