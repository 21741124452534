import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import PageAlerts from '../../PageAlerts'
import TextBox from '../../../components/ui/TextBox'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import { CREATE_ORGANIZATION_CONTEXT } from '../../../utils/GraphQl/OrganizationContext'
import { logoutUser } from '../../../store/AuthSlice/AuthSlice'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'

import ProgressBar from '../../ProgressBar'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const Add = ({ setDrawerstate, setDataTableData }) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [loader, setLoader] = useState(false)
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const dispatch = useDispatch()
	const [create_organization_context] = useMutation(
		CREATE_ORGANIZATION_CONTEXT
	)
	const [Formdata, setFormdata] = useState({
		organization_name: '',
		information_completion: ''
	})
	const HANDLEFORMDATA = (field, value) => {
		setFormdata((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}
	function formatDate(dateString) {
		const date = new Date(dateString)
		const day = String(date.getDate()).padStart(2, '0')
		const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
		const year = date.getFullYear()

		return `${day}-${month}-${year}`
	}
	const HandleSubmit = async () => {
		const badgeColors = [
			'badge-success300',
			'badge-warning300',
			'badge-danger300',
			'badge-complete300',
			'badge-primary300'
		]
		const randomIndex = Math.floor(Math.random() * badgeColors.length)
		const color = badgeColors[randomIndex]

		try {
			setLoader(true)
			const response = await create_organization_context({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						organization_name: Formdata.organization_name,
						data: {
							organization_name: Formdata.organization_name
						}
					}
				}
			})
			setLoader(false)
			let data = await response?.data
			let createContext = data?.createContext
			if (createContext.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'add_organization_context',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(
						removeAlerts({ pageId: 'add_organization_context' })
					)
				}, 3000)
			} else if (
				createContext.status !== 201 &&
				createContext.errors.length > 0
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'add_organization_context',
						type: 'danger',
						message: createContext.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (createContext.status === 201) {
				let updatedAt = formatDate(createContext?.data?.updated_at)
				setDataTableData((prev) => {
					return [
						...prev,
						{
							...Formdata,
							id: createContext?.data?.id,
							color: color,
							last_updated_date: updatedAt
						}
					]
				})
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'add_organization_context',
						type: 'success',
						message: createContext.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					handleDrawerClose()
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'add_organization_context',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	// const HandleSubmit = () => {

	// 	dispatch(
	// 		addAlerts({
	// 			pageId: 'add_organization_context',
	// 			type: 'success',
	// 			message: 'Data Added Successfully',
	// 			icon: faCheck,
	// 			autoclose: 3000
	// 		})
	// 	)
	// 	setTimeout(() => {
	// 		handleDrawerClose()
	// 	}, 3000)
	// }

	const FORM = (
		<form
			encType="multipart/form-data"
			className=" flex w-[350px] flex-col justify-start"
		>
			<>
				<div className="flex w-[350px] flex-col justify-start pr-1">
					<TextBox
						name={'organization_name'}
						id={'organization_name'}
						value={Formdata.organization_name}
						labelName={'Organization Name'}
						onChange={(data) =>
							HANDLEFORMDATA('organization_name', data)
						}
					/>
				</div>
			</>
		</form>
	)
	const handleDrawerClose = () => {
		dispatch(removeAlerts({ pageId: 'add_organization_context' }))
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setDrawerstate(false)
	}
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])

	useEffect(() => {
		setBody(FORM)
	}, [showDrawer, Formdata])

	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "> </h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={HandleSubmit}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)
	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)
	return (
		<>
			{loader && <ProgressBar />}
			<PageAlerts pageId={'add_organization_context'} />
			<Drawer
				label="Add"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}

export default Add
