import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import '../../../assets/css/TextBox/index.css'
import { DATA } from './Data'
const CountrySelector = ({ label, onChange, defaultCountry }) => {
	const [countries, setCountries] = useState(DATA)
	const defaultCountryIndex = countries.findIndex(
		(country) =>
			country?.alpha2?.toLowerCase() ===
			defaultCountry?.alpha2?.toLowerCase()
	)
	const [selectedCountry, setSelectedCountry] = useState(
		defaultCountryIndex !== -1
			? countries[defaultCountryIndex]
			: countries[103]
	)
	const [filterText, setFilterText] = useState('')
	const [focus, setFocus] = useState(false)
	const [showDropdown, setShowDropdown] = useState(false)
	const divRef = useRef(null)
	const closeRef = useRef(null)

	const handleContentChange = (event) => {
		setFilterText(event.target.innerText)
	}

	const toggleDropdown = () => {
		setShowDropdown((prev) => !prev)
	}

	const handleCountryClick = (country) => {
		let newCountry = {
			country: country.name,
			flag: country.alpha2,
			alpha2: country.alpha2
		}
		setSelectedCountry(country)
		onChange(newCountry)
		setFocus(true)
		setFilterText('')
		setShowDropdown(false)
	}

	useEffect(() => {
		onChange(selectedCountry)
		const handleClickOutside = (event) => {
			if (closeRef.current && !closeRef.current.contains(event.target)) {
				setShowDropdown(false)
				setFocus(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<div className="form-group relative">
			{label && <label className="label">{label}</label>}
			<div ref={closeRef} style={{ position: 'relative' }}>
				<div
					className={`form-control font-interui flex items-center focus:border-[#A3A2A0] ${focus ? '!border !border-solid !border-black' : ''}`}
					onClick={toggleDropdown}
					tabIndex={0}
				>
					<div className="relative top-[3px] flex w-full items-center gap-[6px]">
						<div className="flex !h-6 w-6 items-center">
							<img
								src={require(
									`./images/${selectedCountry.alpha2}.png`
								)}
								alt="image"
								width={'20.5px'}
								height={'20px'}
							/>
						</div>
						<div className="flex w-full items-center focus:border-none focus:outline-none">
							<div
								className="w-full  focus:border-none focus:outline-none"
								contentEditable={true}
								ref={divRef}
								onInput={handleContentChange}
								suppressContentEditableWarning={true}
							>
								{selectedCountry.name}
							</div>
							<div className="relative right-[0px] top-[-1px] h-[9px] w-[9px] origin-center rotate-45 border-2 border-l-0 border-t-0 border-[#555]"></div>
						</div>
					</div>
				</div>
				{showDropdown && (
					<div className="absolute top-[31px] z-50 max-h-[200px] w-full overflow-x-hidden overflow-y-scroll border border-solid border-[#a3a2a0] bg-white">
						{countries
							.filter((country) =>
								country.name
									.toLowerCase()
									.includes(filterText.toLowerCase())
							)
							.map((country, index) => (
								<div
									key={index}
									onClick={() => handleCountryClick(country)}
									className="font-interui flex h-8 w-full cursor-pointer items-center px-[10px] py-[5px] hover:bg-[#0000000d]"
								>
									<div className="font-interui flex items-center gap-[6px]">
										<div className="flex h-5 w-6 items-center">
											<img
												src={require(
													`./images/${country.alpha2}.png`
												)}
												alt={country.country}
												width={'20.5px'}
												height={'20px'}
												style={{
													height: '20px !important'
												}}
											/>
										</div>
										<span className="text-[14px] font-normal text-[#41505f]">
											{country.name}
										</span>
									</div>
								</div>
							))}
					</div>
				)}
			</div>
		</div>
	)
}

CountrySelector.propTypes = {
	label: PropTypes.string,
	onChange: PropTypes.func,
	defaultCountry: PropTypes.object
}

export default React.memo(CountrySelector)
