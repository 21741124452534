import { gql } from '@apollo/client'

export const CREATE_EMAIL_TEMPLATE = gql`
	mutation CreateTemplate($input: TemplateInput!, $attachments: Upload) {
		createTemplate(input: $input, attachments: $attachments) {
			status
			message
			data
			errors
		}
	}
`

export const DELETE_TEMPLATE_MUTATION = gql`
	mutation DeleteTemplate($templateId: [Int]) {
		deleteTemplate(template_id: $templateId) {
			status
			message
			data
			errors
		}
	}
`
export const EDIT_TEMPLATE_MUTATION = gql`
	mutation EditTemplate(
		$input: TemplateInput!
		$templateId: Int
		$attachments: Upload
	) {
		editTemplate(
			input: $input
			template_id: $templateId
			attachments: $attachments
		) {
			status
			message
			data
			errors
		}
	}
`
