import React from 'react'
import { Link } from 'react-router-dom'
const SidebarHeader = ({ link = '' }) => {
	return (
		<div className=" flex h-[72px] items-center border-b border-b-[#f3f5f9]  px-5 ">
			<Link to="/" className="sidebar-logo !text-[24px]">
				Risk Wise
			</Link>
		</div>
	)
}
export default SidebarHeader
