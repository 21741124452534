import React from 'react'
import { Link } from 'react-router-dom'
function GeneralFooter() {
	return (
		<>
			<div className="main-footer !z-[1111] !flex max-w-[100%] !items-end py-6 !pb-[9.5px] !pt-[33.5px]">
				<span>© 2023. Risk Wise. All Rights Reserved.</span>
				<span>
					Created by: <Link className="text-link">ThinkFirm</Link>
				</span>
			</div>
		</>
	)
}
export default React.memo(GeneralFooter)
