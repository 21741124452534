import React from 'react'
import { Link } from 'react-router-dom'
const GeneralHeader = (props) => {
	return (
		<>
			<div className="mb-3 flex items-center justify-between ">
				<div>
					<ol className="breadcrumb fs-sm flex">
						<li className="breadcrumb-item">
							<Link to={props.link}>{props.pageParent}</Link>
						</li>
						<li
							className="breadcrumb-item active"
							aria-current="page"
						>
							{props.pageName}
						</li>
					</ol>
					<h4 className="main-title mt-0">{props.headerTitle}</h4>
					<div className="grid grid-cols-12 gap-3">
						<div className="col-span-9">
							<div className="main-text">
								<p>{props.headerDescription}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default React.memo(GeneralHeader)
