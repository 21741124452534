import { gql } from '@apollo/client'

export const LIST_MAPPING_USING_KEY_MUTATION = gql`
	mutation ($key: String, $category_id: Int) {
		mapListUsingKey(key: $key, category_id: $category_id) {
			status
			message
			errors
			data
		}
	}
`
