import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AlertWrapper from '../../components/ui/Alerts/AlertWrapper'
import Alert from '../../components/ui/Alerts'
import { removeAlerts } from '../../store/AlertSlice'
const PageAlerts = React.memo(({ pageId }) => {
	const dispatch = useDispatch()
	const alertsForPage = useSelector((state) => state.Alerts[pageId])

	const ALERTS = useMemo(() => {
		if (Array.isArray(alertsForPage)) {
			return [...alertsForPage].sort((a, b) => b.id - a.id)
		}
		return []
	}, [alertsForPage])

	if (!alertsForPage) {
		return null
	}
	return (
		<AlertWrapper key={`alertWrapper-${pageId}`}>
			{ALERTS.map((notification) => (
				<div key={notification.id}>
					<Alert
						className={`alert-${notification.type}`}
						message={notification.message}
						Icon={notification.icon}
						autoClose={notification.autoclose}
						onClose={() =>
							dispatch(
								removeAlerts({
									pageId: pageId,
									notificationId: notification.id
								})
							)
						}
					/>
				</div>
			))}
		</AlertWrapper>
	)
})

export default PageAlerts
