import React, { useEffect, useState } from 'react'
import Checkbox from '../Checkbox'
import PropTypes from 'prop-types'
const CheckboxGroup = ({
	type = 'vertical',
	options,
	onChange,
	className,
	iconClass
}) => {
	const [checkedItems, setCheckedItems] = useState({})
	// create an object for keeping track of checked and unchecked items
	useEffect(() => {
		options.map((option) => {
			if (option.checked === true) {
				setCheckedItems((prev) => {
					const newCheckedItems = {
						...prev,
						[option.value]: option.checked
					}
					return newCheckedItems
				})
				// setCheckedItems({ option[value]: true} )
			}
		})
	}, [options])
	// this will get called whe the checked state changes
	const handleChange = (value) => {
		setCheckedItems((prev) => {
			const newCheckedItems = { ...prev, [value]: !prev[value] }
			onChange(newCheckedItems)
			return newCheckedItems
		})
	}

	return (
		<div
			className={`relative flex list-none  flex-wrap gap-[8px] whitespace-pre-line ${type == 'horizontal' ? '' : 'flex-col'}`}
		>
			{options.map((option) => (
				<Checkbox
					key={option.value}
					id={option.value}
					label={option.label}
					checked={!!checkedItems[option.value]}
					className={className}
					iconClass={iconClass}
					onChange={() => handleChange(option.value)}
					disabled={option.disabled}
				/>
			))}
		</div>
	)
}
CheckboxGroup.propTypes = {
	type: PropTypes.oneOf(['vertical', 'horizontal']),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			checked: PropTypes.bool,
			disabled: PropTypes.bool
		})
	).isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	iconClass: PropTypes.string
}
export default CheckboxGroup
