import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import '../../../assets/css/Alerts/index.css'
const Alert = React.memo(
	({ className, autoClose = null, onClose, message, Icon = null }) => {
		const [timer, setTimer] = useState(autoClose ? autoClose / 1000 : null)
		const [dateString, setDateString] = useState('')
		const [timeString, setTimeString] = useState('')

		useEffect(() => {
			const interval = setInterval(() => {
				if (timer !== null) {
					setTimer((prevTimer) => prevTimer - 1)
				}
			}, 1000)
			return () => clearInterval(interval)
		}, [timer])

		useEffect(() => {
			if (timer === 0 && autoClose !== null) {
				onClose()
			}
		}, [timer, autoClose, onClose])

		useEffect(() => {
			const currentDate = new Date()
			const monthName = currentDate.toLocaleString('en-US', {
				month: 'short'
			})
			const day = currentDate.getDate()
			const year = currentDate.getFullYear()
			const finalDate = `${day} ${monthName} ${year}`
			let hours = currentDate.getHours()
			const minutes = currentDate.getMinutes()
			const seconds = currentDate.getSeconds()
			const amPm = hours >= 12 ? 'PM' : 'AM'
			hours = hours % 12 || 12
			hours = hours < 10 ? '0' + hours : hours
			const currentTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds} ${amPm}`
			setDateString(finalDate)
			setTimeString(currentTime)
		}, [])

		return (
			<div className="alert-annimate">
				<div className={`alerts ${className}`}>
					<div className="flex gap-1">
						{Icon && (
							<div
								className="text-[13px] "
								data-testid="alert-icon"
							>
								<FontAwesomeIcon icon={Icon} />
							</div>
						)}
						<div>{dateString}</div>
						<div>{timeString}</div>
						<div>
							<span className="flex-2">{message}</span>
						</div>
					</div>
					<div className="flex items-center gap-2.5">
						<button className="" onClick={onClose}>
							<FontAwesomeIcon icon={faXmark} size="lg" />
						</button>
						<div className="relative flex">
							{autoClose && (
								<div className="timer rotating-border relative flex h-5 w-5 items-center justify-center px-2.5 py-2.5">
									{timer}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
)
Alert.propTypes = {
	className: PropTypes.string,
	autoClose: PropTypes.number,
	onClose: PropTypes.func.isRequired,
	message: PropTypes.string.isRequired,
	Icon: PropTypes.object
}
export default Alert
