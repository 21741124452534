import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import '../../../assets/css/TextBox/index.css'
const TextBox = ({
	OnKeyPress,
	id,
	labelName,
	value,
	onChange,
	placeholder,
	type,
	className,
	readOnly,
	error,
	success,
	disable,
	steps,
	min,
	max,
	onClick,
	onFocus,
	onBlur,
	autoFocus,
	name,
	...props
}) => {
	const [cursor, setCursor] = useState(null)
	const ref = useRef()

	const HandleChange = (event) => {
		onChange(event.target.value)
		setCursor(event.target.selectionStart)
	}

	useEffect(() => {
		const input = ref.current
		if (input.type !== 'number' && cursor !== null) {
			input.setSelectionRange(cursor, cursor)
		}
	}, [cursor, value])

	const handleKeyPress = (e) => {
		if (OnKeyPress) {
			OnKeyPress(e)
		}
	}

	useEffect(() => {
		if (type === 'number') {
			const isValidNumber = !isNaN(value)
			if (!isValidNumber) {
				onChange('')
			}
		}
		// else if (type === 'email') {
		// 	const isValidEmail = /\S+@\S+\.\S+/.test(value)
		// 	if (!isValidEmail) {
		// 		onChange('')
		// 	}
		// }
	}, [value, type])
	return (
		<>
			<div className={`form-group ${className}`}>
				{labelName && (
					<label htmlFor={id} className="label">
						{labelName}
					</label>
				)}
				<div className={`input-outer`}>
					<input
						spellCheck="false"
						id={id}
						name={name}
						ref={ref}
						type={type}
						placeholder={placeholder}
						className="form-control"
						onChange={HandleChange}
						value={value}
						readOnly={readOnly}
						step={steps}
						onKeyPress={handleKeyPress}
						disabled={disable}
						min={min}
						max={max}
						onClick={onClick}
						onFocus={onFocus}
						onBlur={onBlur}
						autoFocus={autoFocus}
						{...props}
					/>
					{error && <p className="danger300">{error}</p>}
					{success && <p className="success300">{success}</p>}
				</div>
			</div>
		</>
	)
}

TextBox.propTypes = {
	OnKeyPress: PropTypes.func,
	id: PropTypes.string.isRequired,
	labelName: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	name: PropTypes.string,
	readOnly: PropTypes.bool,
	error: PropTypes.string,
	success: PropTypes.string,
	disable: PropTypes.bool,
	steps: PropTypes.number,
	min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onClick: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	autoFocus: PropTypes.bool
}

// Default Props
TextBox.defaultProps = {
	type: 'text',
	readOnly: false
}

export default React.memo(TextBox)
