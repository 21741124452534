import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import PageAlerts from '../../PageAlerts'
import TextBox from '../../../components/ui/TextBox'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import Button from '../../../components/ui/Buttons/Button'
import { useMutation } from '@apollo/client'
import { RESET_USER_PASSWORD_MUTATION } from '../../../utils/GraphQl/ResetUserPasswordMutation'
import { logoutUser } from '../../../store/AuthSlice/AuthSlice'
import ProgressBar from '../../ProgressBar'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const ResetPassword = ({ setDrawerstate, USERDATA }) => {
	// console.log('userdata ==>',USERDATA);
	const { data, loading, error } = useSelector(
		(state) => state.registerSettings
	)
	const [passwordPolicy, setPasswordPolicy] = useState(null)
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [loader, setLoader] = useState(false)
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const dispatch = useDispatch()
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [idArray, setIdArray] = useState([])
	useEffect(() => {
		if (loading) {
			setLoader(true)
		} else if (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'Register_settings',
					type: 'danger',
					message: 'Failed to fetch  settings.',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (data.Domains.status === 403) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'Register_settings',
					type: 'danger',
					message: data.Domains.errors[0],
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (!loading && !error && data) {
			setLoader(false)
			const { Domains, PasswordPolicy, verifyCheck } = data

			setPasswordPolicy(PasswordPolicy?.data?.value ?? null)
		}
	}, [data, loading, error])
	useEffect(() => {
		USERDATA.map((data) => {
			idArray.push(`${data.user_id}`)
		})
	}, [USERDATA])

	const FORM = (
		<form
			encType="multipart/form-data"
			className="flex w-[350px] flex-col justify-start"
		>
			<>
				<div className="flex w-[350px] flex-col justify-start ">
					<TextBox
						name={'password'}
						id={'password'}
						value={password}
						labelName={'Password'}
						onChange={(data) => setPassword(data)}
					/>
					<TextBox
						name={'confirm_pasword'}
						id={'confirm_pasword'}
						value={confirmPassword}
						labelName={'Confirm password'}
						onChange={(data) => setConfirmPassword(data)}
					/>
				</div>
			</>
		</form>
	)
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setDrawerstate(false)
	}
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])

	useEffect(() => {
		setBody(FORM)
	}, [showDrawer, password, confirmPassword])
	const [reset] = useMutation(RESET_USER_PASSWORD_MUTATION)
	const handleVerification = async () => {
		try {
			setLoader(true)
			const response = await reset({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						user_id: idArray,
						password: password
					}
				}
			})
			let data = await response.data
			let ResetPassword = data.resetPasswordAdmin
			if (ResetPassword.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'resetuserpassword',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'updatepersonalinfo' }))
				}, 3000)
			} else if (
				ResetPassword.status !== 200 &&
				ResetPassword.errors.length > 0
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'resetuserpassword',
						type: 'danger',
						message: ResetPassword.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'resetuserpassword',
						type: 'success',
						message: ResetPassword.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					setShowDrawer(false)
					setScrollable(false)
					setScrollableFooter(false)
					setSize('md')
					setOverlay('')
					setBody(null)
					dispatch(removeAlerts({ pageId: 'resetuserpassword' }))
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'resetuserpassword',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		const passwordPolicyRegeX = new RegExp(passwordPolicy)
		let isValidPassword = true
		let isValidConfirmPassword = true
		if (passwordPolicy) {
			isValidPassword = passwordPolicyRegeX.test(password)
			isValidConfirmPassword = passwordPolicyRegeX.test(confirmPassword)
		}
		if (
			!password ||
			!isValidPassword ||
			!confirmPassword ||
			!isValidConfirmPassword ||
			password !== confirmPassword
		) {
			if (!password) {
				dispatch(
					addAlerts({
						pageId: 'resetuserpassword',
						type: 'danger',
						message: 'Please enter password.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!isValidPassword) {
				dispatch(
					addAlerts({
						pageId: 'resetuserpassword',
						type: 'danger',
						message: 'Please enter a valid  password.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!confirmPassword) {
				dispatch(
					addAlerts({
						pageId: 'resetuserpassword',
						type: 'danger',
						message: 'Please enter confirm password.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!isValidConfirmPassword) {
				dispatch(
					addAlerts({
						pageId: 'resetuserpassword',
						type: 'danger',
						message: 'Please enter a valid Confirm password.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (password !== confirmPassword) {
				dispatch(
					addAlerts({
						pageId: 'resetuserpassword',
						type: 'danger',
						message: 'Both password should match.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				dispatch(
					addAlerts({
						pageId: 'resetuserpassword',
						type: 'danger',
						message:
							'Something went wrong. Please try again after sometime.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
		} else {
			handleVerification()
		}
	}
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal ">{''}</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>
				{loader ? (
					<Button
						className="btn btn-success btn-large flex cursor-not-allowed  gap-1.5"
						isIconBtn={true}
						onClick={handleDrawerClose}
					>
						<span className="waiting-loader"> </span> Please Wait
					</Button>
				) : (
					<IconButton
						onClick={handleSubmit}
						Icon={faUniversalAccess}
						size={'sm'}
						className="btn btn-success btn-large flex gap-[6px]"
					>
						Submit
					</IconButton>
				)}
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') {
			handleDrawerClose()
		}
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			{loader && <ProgressBar />}
			<PageAlerts pageId={'resetuserpassword'} />
			<Drawer
				label="Reset Password"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default ResetPassword
