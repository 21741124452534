import React, { useEffect, useState, useRef } from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../../assets/css/DropDowns/index.css'
const BtnTextbox = ({
	selectedItems,
	onItemRemove,
	onItemAdd,
	label,
	className,
	autoComplete = 'off',
	onFocus,
	onBlur,
	placeholder = 'Add values',
	autoFocus
}) => {
	const [value, setValue] = useState('')
	const dropDownRef = useRef()
	const handleRemoveItem = (item) => {
		onItemRemove(item)
	}
	const handleInputChange = (event) => {
		setValue(event.target.value)
	}
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropDownRef.current &&
				!dropDownRef.current.contains(event.target)
			) {
				// setShowDropDown(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])
	const handleAddData = (event) => {
		event.preventDefault()
		onItemAdd(value)
		setValue('')
	}
	return (
		<div className={`form-group ${className}`}>
			{label && <label className="label">{label}</label>}
			<div className="input-outer">
				<div className="multiDrop" ref={dropDownRef}>
					<div className="multiDropInput">
						{selectedItems.length > 0 &&
							selectedItems.map((item, index) => (
								<div
									key={item}
									className={`TagItem lastChild ${index === selectedItems.length - 1 ? 'no-margin' : ''}`}
									style={{
										borderBottom:
											index === selectedItems.length - 1
												? '1px solid rgb(163, 162, 160)'
												: 'none',
										marginBottom:
											index === selectedItems.length - 1
												? '0px'
												: undefined
									}}
								>
									<span>{item}</span>
									<p
										onClick={() => handleRemoveItem(item)}
										className="TagItemRemove"
									>
										<FontAwesomeIcon icon={faTimes} />
									</p>
								</div>
							))}
						<div className="container relative flex w-full">
							<input
								type="text"
								className="form-control form-control-1 multiInput"
								placeholder={placeholder}
								value={value}
								onChange={handleInputChange}
								autoComplete={autoComplete}
								onFocus={onFocus}
								onBlur={onBlur}
								autoFocus={autoFocus}
							/>
							<button
								children="Add"
								className="btn-primary btn-large !rounded-none border-b-0 border-r-0 active:!border "
								onClick={handleAddData}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BtnTextbox
