import { gql } from '@apollo/client'

// Define your GraphQL mutation
export const LOGIN_MUTATION = gql`
	mutation Login($input: UserLoginInput!) {
		login(input: $input) {
			status
			message
			data {
				authToken
				user
				settings
			}
			errors
		}
	}
`
export const UPDATE_ADDITIONAL_FIELDS = gql`
	mutation Mutation($userId: Int) {
		viewProfile(user_id: $userId) {
			status
			message
			data
			errors
		}
	}
`
