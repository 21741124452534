import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import PageAlerts from '../../PageAlerts'
import ProgressBar from '../../ProgressBar'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import Simpledropdown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import EditLoadedRisk from './EditLoadedRisk'
import {
	faBook,
	faEdit,
	faPlus,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useApolloClient } from '@apollo/client'
import { GET_CATALOGUE_LIST } from '../../../utils/GraphQl/CatalogueMutations'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Button from '../../../components/ui/Buttons/Button'
import TextBox from '../../../components/ui/TextBox'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const CATEGORIES = [
	{ label: 'Select', value: 'Select' },
	{ label: 'Natural Disaster', value: 'natural_disaster' },
	{ label: 'Technological Risks', value: 'technological_risks' },
	{ label: 'Health Risks', value: 'health_risks' },
	{ label: 'Environmental Risks', value: 'environmental_risks' },
	{ label: 'Economic Risks', value: 'economic_risks' },
	{ label: 'Operational Risks', value: 'operational_risks' },
	{ label: 'Financial Risks', value: 'financial_risks' },
	{ label: 'Strategic Risks', value: 'strategic_risks' },
	{ label: 'Safety Risks', value: 'safety_risks' },
	{ label: 'Political Risks', value: 'political_risks' },
	{ label: 'Social Risks', value: 'social_risks' },
	{ label: 'Project Risks', value: 'project_risks' }
]
const TAGS = [
	{ label: 'Select', value: 'Select' },
	{ label: 'Communication', value: 'communication' },
	{ label: 'Business', value: 'business' },
	{ label: 'Entertainment', value: 'entertainment' },
	{ label: 'Education', value: 'education' },
	{ label: 'Security', value: 'security' }
]
const AddRisk = ({ setDrawerstate, setOriginalData, OriginalData }) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [loader, setLoader] = useState(false)
	const [size, setSize] = useState('60%')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [searchInput, setSearchInput] = useState('')
	const [catalogue, setCatalogues] = useState([])
	const [AllData, setAllData] = useState([])
	const [FilteredData, setFilteredData] = useState([])
	const [EditCatalogue, setEditCatalogue] = useState(false)
	const client = useApolloClient()
	const [selectedData, setSelectedData] = useState([])
	const [FormData, setFormData] = useState({
		category: { label: 'Select', value: 'Select' },
		tag: { label: 'Select', value: 'Select' }
	})

	const fetchCatalogue = async () => {
		try {
			setLoader(true)
			const { data } = await client.query({
				query: GET_CATALOGUE_LIST,
				variables: {
					pagination: {
						offset: 0,
						limit: 100000
					}
				},
				context: {
					headers: {
						authorization: authToken
					}
				},
				fetchPolicy: 'network-only'
			})
			let listCatalogues = data?.listCatalogues

			if (listCatalogues.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'delete_catalogue',
						type: 'danger',
						message: 'Session expired please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'delete_catalogue' }))
				}, 3100)
			} else if (listCatalogues.status === 204) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'delete_catalogue',
						type: 'danger',
						message: 'No Data Found',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (listCatalogues.status === 200) {
				setLoader(false)
				let fetchedData = listCatalogues?.data?.items.map((item) => {
					let obj = {
						id: item.id,
						catalogue_category: item.catalogue_category,
						risk_description: item.risk_description,
						risk_heading: item.risk_heading,
						tags: Array.isArray(item.tags)
							? item?.tags?.join(',')
							: item?.tags
					}
					return obj
				})
				setAllData(fetchedData)
				// setCatalogues(fetchedData)
				// setFilteredData(fetchedData)
			} else if (listCatalogues.status === 403) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'delete_catalogue',
						type: 'danger',
						message: listCatalogues.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'delete_catalogue' }))
				}, 3100)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'delete_catalogue',
					type: 'danger',
					message: 'Something went wrong please try again.',
					icon: faCheck,
					autoclose: 3000
				})
			)
		}
	}

	useEffect(() => {
		setTimeout(() => {
			fetchCatalogue()
		}, 500)
	}, [])

	useEffect(() => {
		if (searchInput.length === 0) {
			setCatalogues(FilteredData)
		}
	}, [searchInput, FilteredData, catalogue])

	const LoadCatalogueData = () => {
		const filteredCatalogues = AllData.filter(
			(catalogue) =>
				catalogue.catalogue_category === FormData.category?.value
		)
		setFilteredData(filteredCatalogues)
		setCatalogues(filteredCatalogues)
		setFormData({
			...FormData,
			tag: { label: 'Select', value: 'Select' }
		})
	}

	const LoadDataByTags = () => {
		const filteredCatalogues = AllData.filter((catalogue) => {
			const tagsArray = catalogue.tags.split(',')
			return tagsArray.includes(FormData.tag?.value)
		})
		setFilteredData(filteredCatalogues)
		setCatalogues(filteredCatalogues)
		setFormData({
			...FormData,
			category: { label: 'Select', value: 'Select' }
		})
	}
	const HandleSearch = () => {
		const filtered = catalogue.filter((catalogue) => {
			return (
				catalogue.catalogue_category
					.toLowerCase()
					.includes(searchInput.toLowerCase()) ||
				catalogue.risk_heading
					.toLowerCase()
					.includes(searchInput.toLowerCase()) ||
				catalogue.risk_description
					.toLowerCase()
					.includes(searchInput.toLowerCase()) ||
				catalogue.tags.toLowerCase().includes(searchInput.toLowerCase())
			)
		})
		setCatalogues(filtered)
	}
	const HandleChnages = (key, value) => {
		setFormData({
			...FormData,
			[key]: value
		})
	}

	const dispatch = useDispatch()
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setDrawerstate(false)
		dispatch(removeAlerts({ pageId: 'add_risk' }))
	}

	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])

	const HandleAddRisk = (item) => {
		let PRODUCTS = [...OriginalData]
		let newRisk = {
			id: PRODUCTS.length + 1000,
			category: item.category,
			risk_heading: item.risk_heading,
			risk_description: item.risk_description,
			position: PRODUCTS.length,
			risk_function: '',
			risk_owner: '',
			inherent_rating: '',
			expand_data: [
				{
					label: 'Risk Heading',
					data: ''
				},
				{
					label: 'Risk Description',
					data: ''
				},
				{
					label: 'Risk Function',
					data: ''
				},
				{ label: 'Risk Owner', data: '' }
			],
			treatment_plan: [],
			image: '',
			image1: '',
			residual_rating: ''
		}
		PRODUCTS.push(newRisk)
		dispatch(
			addAlerts({
				pageId: 'add_risk',
				type: 'success',
				message: 'Risk Added successfully',
				icon: faCheck,
				autoclose: 3000
			})
		)
		setOriginalData(PRODUCTS)
		// localStorage.setItem('products', JSON.stringify(PRODUCTS))
	}

	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "> </h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>
			</div>
		</>
	)
	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)

	const FindLabel = (key) => {
		let Element = CATEGORIES.filter((item) => item.value === key)
		if (Element.length > 0) {
			return Element[0].label
		}
	}

	return (
		<>
			<PageAlerts pageId={'add_risk'} />
			<Drawer
				label="Add Risk"
				size={size}
				body={
					<>
						{EditCatalogue && (
							<EditLoadedRisk
								setShowModal={setEditCatalogue}
								selectedData={selectedData}
								setOriginalData={setOriginalData}
								OriginalData={OriginalData}
							/>
						)}
						<div className="">
							<div className="flex flex-wrap items-center justify-between gap-2 ">
								<div className="flex gap-1 ">
									<div className="flex items-center gap-1">
										<Simpledropdown
											value={FormData.tag?.label}
											options={TAGS}
											label="Select Tag"
											onChange={(data) =>
												HandleChnages('tag', data)
											}
										/>
										<Button
											children={'Load'}
											onClick={LoadDataByTags}
											className="btn-primary btn-large mt-5 h-8"
										/>
									</div>
									<div className="flex items-center gap-1 ">
										<Simpledropdown
											value={FormData.category?.label}
											options={CATEGORIES}
											label="Select Catalogue"
											onChange={(data) =>
												HandleChnages('category', data)
											}
										/>
										<Button
											children={'Load'}
											onClick={LoadCatalogueData}
											className="btn-primary btn-large mt-5 h-8"
										/>
									</div>
								</div>
								<div className="mt-[30px] flex gap-1">
									<TextBox
										labelName={''}
										id={'search-catalogue'}
										onChange={(data) =>
											setSearchInput(data)
										}
										value={searchInput}
									/>
									<Button
										className="btn-large btn-primary h-8"
										children={'Search'}
										onClick={() => HandleSearch()}
									/>
								</div>
							</div>
							<div className="relative mt-3">
								<div className="flex justify-between">
									<div></div>
								</div>
								<div className={` `}>
									<div className="flex flex-col gap-2">
										{catalogue.map((catalogue) => (
											<div
												className="flex border py-2 pl-2 pr-1 shadow-md"
												key={catalogue.id}
											>
												<div className="w-full">
													<h5>
														{catalogue.risk_heading}
													</h5>
													<p>
														{FindLabel(
															catalogue.catalogue_category
														)}
													</p>
													<p>
														{
															catalogue.risk_description
														}
													</p>
												</div>
												<div className="flex w-[75px] flex-grow-0 gap-1 self-start">
													<IconButton
														onClick={() =>
															HandleAddRisk(
																catalogue
															)
														}
														Icon={faPlus}
														size={'sm'}
														className="btn btn-large btn-primary flex gap-[6px]"
													/>
													<IconButton
														onClick={() => {
															setSelectedData([
																{ ...catalogue }
															])
															setEditCatalogue(
																(prev) => !prev
															)
														}}
														Icon={faEdit}
														size={'sm'}
														className="btn btn-large btn-edit flex gap-[6px]"
													/>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</>
				}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}

export default AddRisk
