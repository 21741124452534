import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
const ProtectedRoute = ({ children }) => {
	const authState = useSelector((state) => state.Auth)
	let user = authState.isLoggedIn
	if (!user) {
		return <Navigate to="/" />
	}
	return children
}

export default ProtectedRoute
