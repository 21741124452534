import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { UPDATE_ADDITIONAL_FIELDS } from '../../../utils/GraphQl/LoginMutation'
import { useSelector, useDispatch } from 'react-redux'
import { addFields } from '../../../store/userProfileAdditionalFields'
const AdditionalInfo = () => {
	let Fields = useSelector((state) => state.additionalInfoFields.Fields)
	const dispatch = useDispatch()
	const [loader, setLoader] = useState(false)
	const additionalinfo = useSelector(
		(state) => state.Auth?.userdata?.user?.user_data?.additional_information
	)
	let UID = useSelector(
		(state) => state.Auth?.userdata?.user?.user_data?.user_id
	)
	const authToken = useSelector((state) => state.Auth.token)
	const [get_additionalFields] = useMutation(UPDATE_ADDITIONAL_FIELDS)
	const GET_FIELDS = async () => {
		try {
			setLoader(true)
			const response = await get_additionalFields({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					userId: UID
				},
				fetchPolicy: 'network-only'
			})
			let data = await response.data
			let updateprofile = data.viewProfile
			if (updateprofile.status === 200) {
				let FIELDS =
					updateprofile?.data?.settings
						?.user_profile_additional_fields
				let data = FIELDS
				dispatch(addFields(data))
			}
		} catch (error) {
			console.log('err ', error)
			setLoader(false)
		}
	}
	useEffect(() => {
		GET_FIELDS()
	}, [])
	const userdata = useSelector((state) => state.Auth)
	let FIELDS = userdata?.userdata?.settings?.user_profile_additional_fields
	useEffect(() => {
		if (FIELDS) {
			let data = FIELDS
			dispatch(addFields(data))
		}
	}, [FIELDS])

	return (
		<div>
			<div className="bg-[#f7f7f7] px-[15px] py-[10px]">
				<div className="multipleinputs " key={'additional-fields'}>
					<div className="grid grid-cols-12 gap-x-1 md:grid-cols-12">
						{Fields.length > 0 &&
							Fields.map((item, index) => (
								<div
									className="col-span-6 w-[95%]"
									key={`${item.label}-${index}`}
								>
									<div className="">
										<div className="form-group">
											<label className="label">
												{item.label ? item.label : ''}
											</label>
											<div className="text-display">
												<div className="p">
													{additionalinfo &&
													additionalinfo[
														item?.field_name
													] ? (
														Array.isArray(
															additionalinfo[
																item?.field_name
															]
														) ? (
															<p>
																{
																	additionalinfo[
																		item
																			?.field_name
																	][0].split(
																		'/'
																	)[2]
																}
															</p>
														) : typeof additionalinfo[
																item?.field_name
														  ] === 'object' ? (
															Object.entries(
																additionalinfo[
																	item
																		?.field_name
																]
															).map(
																([
																	key,
																	value
																]) => (
																	<div
																		key={
																			key
																		}
																	>
																		<p className="!inline-block">
																			{value ===
																			true
																				? key
																				: ''}
																		</p>
																	</div>
																)
															)
														) : (
															<p>
																{
																	additionalinfo[
																		item
																			?.field_name
																	]
																}
															</p>
														)
													) : (
														''
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default React.memo(AdditionalInfo)
