import React from 'react'
import SidebarHeader from './SidebarHeader'
import SidebarBody from './SidebarBody'
import SidebarFooter from './SidebarFooter'
import Header from '../Header/Header'
import '../../assets/css/Landingpage/index.css'
const Sidebar = ({ data, onClick }) => {
	return (
		<div className="">
			<div className="sidebar relative overflow-auto">
				<SidebarHeader />
				<SidebarBody DATA={data} onClick={onClick} />
				<SidebarFooter />
			</div>
			<Header />
		</div>
	)
}
export default React.memo(Sidebar)
