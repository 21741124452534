import React, { useEffect, useState } from 'react'
import ElasticTextBox from '../../components/ui/Elastic-textbox'
import { useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { UPDATE_MULTIPLE_SETTINGS } from '../../utils/GraphQl/SettingsMutation'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import { logoutUser } from '../../store/AuthSlice/AuthSlice'
import GLobalDrawer from '../GlobalDrawer'
import IconButton from '../../components/ui/Buttons/Icon-button'
import { removePage } from '../../store/SettingsSlice'
import { fetchContactUsSettings } from '../../store/ContactusSettings'
import {
	faCheck,
	faTriangleExclamation,
	faBook,
	faUniversalAccess
} from '@fortawesome/free-solid-svg-icons'
import Checkbox from '../../components/ui/Form/Checkbox'
import useGlobalKeyPress from '../../hooks/globalKeyPress'
const ContactUsSettings = () => {
	const [loader, setLoader] = useState(false)
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [body, setBody] = useState(null)
	const dispatch = useDispatch()
	const [updateSettings] = useMutation(UPDATE_MULTIPLE_SETTINGS)
	const [adminList, setAdminList] = useState('')
	const [saveData, setSaveData] = useState(false)
	const [enquiery, setEnquiery] = useState(false)
	const { data, loading, error } = useSelector(
		(state) => state.contactusSettings
	)
	useEffect(() => {
		if (loading) {
			// setLoader(true)
		} else if (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'contact_settings',
					type: 'danger',
					message: 'Failed to fetch  settings.',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (!error && !loading && data?.AdminList?.status === 403) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'contact_settings',
					type: 'danger',
					message: data?.AdminList?.errors[0],
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (!loading && !error && data) {
			setLoader(false)
			const { AdminList, SaveData, ContactEnquiery } = data
			setAdminList(AdminList?.data?.value || '')
			if (ContactEnquiery?.data?.value === 'true') {
				setEnquiery(true)
			} else {
				setEnquiery(false)
			}
			if (SaveData?.data?.value === 'true') {
				setSaveData(true)
			} else {
				setSaveData(false)
			}
		}
	}, [data, loading, error])

	const handleVerification = async () => {
		try {
			setLoader(true)
			const response = await updateSettings({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						appsetting_admin_contact_email: {
							value: adminList
						},
						appsetting_save_form_data: {
							value: saveData === true ? 'true' : 'false'
						},
						appsetting_submit_contact_enquiry_email: {
							value: enquiery === true ? 'true' : 'false'
						}
					}
				}
			})
			let data = await response.data

			if (data.updateMultipleSettings.status === 400) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'contact_settings',
						type: 'danger',
						message: 'Not found',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateMultipleSettings.status === 403) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'contact_settings',
						type: 'danger',
						message: data.updateMultipleSettings.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateMultipleSettings.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'contact_settings',
						type: 'danger',
						message: data.updateMultipleSettings.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateMultipleSettings.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'contact_settings',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)

				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'contact_settings' }))
				}, 3000)
			} else if (data.updateMultipleSettings.status === 200) {
				setLoader(false)
				dispatch(fetchContactUsSettings())
				dispatch(
					addAlerts({
						pageId: 'contact_settings',
						type: 'success',
						message: 'Data updated successsfully',
						icon: faCheck,
						autoclose: 3000
					})
				)
			}
			setTimeout(() => {
				dispatch(removeAlerts({ pageId: 'contact_settings' }))
				handleDrawerClose()
			}, 3000)
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'updateEmailSettings',
					type: 'danger',
					message: 'error',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])
	useEffect(() => {
		setBody(FORM)
	}, [showDrawer, adminList, enquiery, saveData])
	const handleDrawerClose = () => {
		dispatch(removePage())
		dispatch(removeAlerts({ pageId: 'contact_settings' }))
	}

	const FORM = (
		<form className="w-[350px]">
			<ElasticTextBox
				id="admin-list"
				value={adminList}
				labelName={'Admin Emails List'}
				onChange={(data) => setAdminList(data)}
			/>

			<div className="form-group">
				<label className="label"> Save Form Data</label>
				<Checkbox
					id="01"
					label={saveData ? 'Enable' : 'Disable'}
					checked={saveData}
					onChange={() => setSaveData((prev) => !prev)}
					className="check-square"
					iconClass="simple-checkmark"
				/>
			</div>
			<div className="form-group">
				<label className="label">Submit Contact Enquiry</label>
				<Checkbox
					id="001"
					label={enquiery ? 'Enable' : 'Disable'}
					checked={enquiery}
					onChange={() => setEnquiery((prev) => !prev)}
					className="check-square"
					iconClass="simple-checkmark"
				/>
			</div>
		</form>
	)

	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal ">{''}</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={() => handleVerification()}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			<GLobalDrawer
				label={'Contact Settings'}
				onClose={handleDrawerClose}
				body={body}
				scrollable={true}
				overlay={''}
				size={'md'}
				showDrawer={true}
				drawerFooterBody={drawerFooterBody}
				loader={loader}
				scrollableFooter={false}
				drawerPosition={'right'}
				pageId={'contact_settings'}
			/>
		</>
	)
}

export default ContactUsSettings
