import React, { useState, useRef, useEffect } from 'react'
import Icon from '../ui/icon'
import { Link } from 'react-router-dom'
import '../../assets/css/TextBox/index.css'
const SidebarToggle = () => {
	const [isFocused, setIsFocused] = useState(false)
	const divRef = useRef()

	const handleFocusChange = () => {
		setIsFocused((prevIsFocused) => !prevIsFocused)
	}

	function toggle(e) {
		e.preventDefault()
		const rootElement = document.getElementById('root')
		if (window.matchMedia('(min-width: 992px)').matches) {
			rootElement.classList.toggle('sidebar-hide')
		} else {
			rootElement.classList.toggle('sidebar-show')
		}
	}
	const handleClickOutside = (event) => {
		if (divRef.current && !divRef.current.contains(event.target)) {
			divRef.current.style.outline = '1px solid #ccd2da'
			divRef.current.style.backgroundColor = '#f3f5f9'
		}
	}

	useEffect(() => {
		document.addEventListener('click', handleClickOutside)
		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [])

	return (
		<>
			<Link
				id="menuSidebar"
				className="menu-link me-lg-4 me-3 mr-6"
				onClick={toggle}
			>
				<Icon
					name="line-toggle"
					className="icon-fill-grey200"
					viewBox={'0 0 22 20'}
				/>
			</Link>
			<div
				className={`form-search me-auto outline outline-1 outline-[#ccd2da]`}
				ref={divRef}
				onClick={() => {
					divRef.current.style.outline = '1px solid black'
					divRef.current.style.backgroundColor = '#fff'
				}}
			>
				<input
					type="text"
					style={{
						backgroundColor: 'rgba(0,0,0,0)',
						borderRadius: '0px !important'
					}}
					className="form-control !border-0 focus:!border-0 focus:!outline-none active:!border-0"
					placeholder="Search"
					onFocus={handleFocusChange}
					onBlur={handleFocusChange}
				/>
				<Icon
					name="line-search"
					width={'18px'}
					height={'18px'}
					className="icon-fill-grey200 "
					viewBox={'0 0 22 20'}
				/>
			</div>
		</>
	)
}
export default React.memo(SidebarToggle)
