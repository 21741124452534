import { useState, useEffect } from 'react'
import DataTableWrapper from '../../../components/ui/DataTables'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import React from 'react'
import EditHoldingCompanies from '../AddHoldingCompanies'
import Button from '../../../components/ui/Buttons/Button'
import { faClose, faEdit } from '@fortawesome/free-solid-svg-icons'

import AddHoldingCompanies from '../AddHoldingCompanies'
const ExistingHoldingCompanies = ({ originalData, setSelectedParentsData }) => {
	const [HoldingCompanies, setHoldingCompanies] = useState([])
	const [selectedData, setSelectedData] = useState([])
	const [showEditHoldingCompanies, setShowEditHoldingCompanies] =
		useState(false)
	const [showHoldingCompanies, setShowHoldingCompanies] = useState(false)
	const dopdownOptions = ['All']
	useEffect(() => {
		const newHoldingCompanies = []
		if (Array.isArray(originalData)) {
			if (originalData.length > 0) {
				originalData?.forEach((item) => {
					item?.data?.corporate_structure?.holding_companies?.forEach(
						(obj) => {
							if (
								!newHoldingCompanies.some(
									(subsidiary) => subsidiary.id === obj.id
								)
							) {
								newHoldingCompanies.push(obj)
							}
						}
					)
				})
				setHoldingCompanies((prev) => {
					const filteredPrev = prev.filter(
						(prevObj) =>
							!newHoldingCompanies.some(
								(newObj) => newObj.id === prevObj.id
							)
					)
					return [...filteredPrev, ...newHoldingCompanies]
				})
			}
		}
	}, [originalData])

	const handleFilter = (rowData) => {
		setSelectedParentsData((prev) => {
			if (!prev[0]?.data?.corporate_structure?.holding_companies) {
				return prev
			}
			const filteredHoldingCompanies =
				prev[0]?.data?.corporate_structure?.holding_companies.filter(
					(subsidiary) => subsidiary.id !== rowData.id
				)
			const updatedFirstElement = {
				...prev[0],
				data: {
					...prev[0]?.data,
					corporate_structure: {
						...prev[0]?.data?.corporate_structure,
						holding_companies: filteredHoldingCompanies
					}
				}
			}
			const newState = [updatedFirstElement, ...prev.slice(1)]
			let FilteredHoldingCompanies = HoldingCompanies.filter(
				(subsidiary) => subsidiary.id != rowData.id
			)
			setHoldingCompanies(FilteredHoldingCompanies)
			return newState
		})
	}
	const ActionBodyTemplate = (rowData) => {
		return (
			<div className="flex justify-between">
				<div className="">
					<IconButton
						type="button"
						className={
							'relative top-0.5 flex h-5 w-6 items-center justify-center rounded-sm border bg-transparent text-sm text-grey-200 hover:bg-transparent hover:text-grey-500'
						}
						onClick={(e) => {
							e.preventDefault()
							setSelectedData([{ ...rowData }])
							setShowEditHoldingCompanies(true)
						}}
						children=""
						Icon={faEdit}
						size={'sm'}
					/>
				</div>
				<div>
					<IconButton
						type="button"
						className={
							'relative top-0.5 flex h-5 w-6 items-center justify-center rounded-sm border bg-transparent text-sm text-red-500 hover:bg-transparent hover:text-red-700'
						}
						onClick={(e) => {
							e.preventDefault()
							handleFilter(rowData)
						}}
						children=""
						Icon={faClose}
						size={'lg'}
					/>
				</div>
			</div>
		)
	}

	let COLUMNS = [
		{
			sortable: true,
			header: 'Name ',
			field: 'holding_company_name',
			style: { width: '20%' },
			headerStyle: { width: '20%' }
		},

		// {
		// 	sortable: true,
		// 	header: 'Attachment',
		// 	field: 'subsidiary_attachment',
		// 	style: { width: '12%' },
		// 	headerStyle: { width: '12%' }
		// },
		{
			sortable: true,
			header: 'Attachment Type',
			field: 'holding_company_doctype',
			style: { width: 'auto' },
			headerStyle: { width: 'auto' }
		},

		{
			// sortable: true,
			header: ' Action',
			// field: 'status',
			style: { width: '70px' },
			body: ActionBodyTemplate,
			headerStyle: { width: '70px' }
		}
	]

	const HandleData = (data) => {
		setSelectedData(data)
	}

	const HeaderButtons = ({ exportCSV }) => (
		<>
			<Button
				className="btn-primary btn-large w-fit"
				children={'Add Holding Companies'}
				onClick={() => setShowHoldingCompanies(true)}
			/>
		</>
	)

	return (
		<div>
			<div className="h5 border-b pb-1">Holding Companies</div>
			{showEditHoldingCompanies && (
				<EditHoldingCompanies
					selectedData={originalData}
					type="edit"
					setSelectedData={setSelectedParentsData}
					setShowModal={setShowEditHoldingCompanies}
					rowData={selectedData}
				/>
			)}
			{showHoldingCompanies && (
				<AddHoldingCompanies
					type="add"
					setSelectedData={setSelectedParentsData}
					setShowModal={setShowHoldingCompanies}
				/>
			)}

			<DataTableWrapper
				showHeader={true}
				paginator={false}
				products={HoldingCompanies}
				HeaderButtons={HeaderButtons}
				options={dopdownOptions}
				columns={COLUMNS}
				onDataSelection={HandleData}
				selectedData={selectedData}
			/>
		</div>
	)
}
export default ExistingHoldingCompanies
