import React, { useState, useEffect, useRef } from 'react'
import Drawer from '../../../components/ui/drawers'
import TextBox from '../../../components/ui/TextBox'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import RichTextbox from '../../../components/ui/Rich-textbox'
import { logoutUser } from '../../../store/AuthSlice/AuthSlice'
import FalseAttachments from '../../../components/ui/FalseAttachments'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import { EDIT_TEMPLATE_MUTATION } from '../../../utils/GraphQl/Templates'
import SimpleDropDown from '../../../components/Dropdowns/Simpledropdown'
import Attachments from '../../../components/ui/Attachments'
import ElasticTextbox from '../../../components/ui/Elastic-textbox'
import Button from '../../../components/ui/Buttons/Button'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const EditTemplate = ({
	setDrawerstate,
	TemplateData,
	FetchTemplates,
	setLoader,
	setPageId,
	setSelectedTemplate
}) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [Files, setFiles] = useState([])
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [size, setSize] = useState('40%')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const dispatch = useDispatch()
	const [EditTemplate] = useMutation(EDIT_TEMPLATE_MUTATION)
	const [editorText, setEditorText] = useState('')
	const [editorRef, setEditorRef] = useState(null)
	const [responseAttacahments, setResponseAttachments] = useState([])
	const [Formdata, setFormdata] = useState({
		templatename: TemplateData?.template_name || '',
		from: TemplateData?.sender_email || '',
		textformat: TemplateData?.is_html === true ? 'HTML' : 'TEXT' || '',
		sender: TemplateData?.sender_name || '',
		subject: TemplateData?.subject || '',
		body: '',
		variable: ''
	})
	const [Text, setText] = useState('')
	const elasticTextBoxRef = useRef(null)
	const HandleEditTemplate = async () => {
		setPageId('editTemplate')
		try {
			setLoader(true)
			const response = await EditTemplate({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					templateId: TemplateData.id,
					attachments: Files[0],
					oldAttachments: responseAttacahments,
					input: {
						template_name: Formdata.templatename,
						subject: Formdata.subject,
						sender_email: Formdata.from,
						sender_name: Formdata.sender,
						is_html: Formdata.textformat === 'HTML' ? true : false,

						body:
							Formdata.textformat === 'HTML'
								? Formdata.body
								: Text
					}
				}
			})
			let data = await response.data
			let editTemplate = data.editTemplate
			if (editTemplate.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'editTemplate',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'editTemplate' }))
				}, 3000)
			} else if (editTemplate.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'editTemplate',
						type: 'danger',
						message: editTemplate.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'editTemplate' }))
				}, 3000)
			} else if (
				editTemplate.status !== 200 &&
				editTemplate.errors.length > 0
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'editTemplate',
						type: 'danger',
						message: editTemplate.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (editTemplate.status === 200) {
				setSelectedTemplate([{ ...editTemplate.data }])
				setLoader(false)
				FetchTemplates()
				dispatch(
					addAlerts({
						pageId: 'editTemplate',
						type: 'success',
						message: editTemplate.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					setShowDrawer(false)
					setScrollable(false)
					setScrollableFooter(false)
					setSize('25%')
					setOverlay('')
					setBody(null)
					dispatch(removeAlerts({ pageId: 'editTemplate' }))
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'editTemplate',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	useEffect(() => {
		if (TemplateData?.is_html === false) {
			setText(TemplateData?.body || '')
		} else {
			setEditorText(TemplateData?.body || '')
		}
	}, [])
	const HANDLEFORMDATA = (field, value) => {
		setFormdata((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}
	const handleAddVariable = (event) => {
		event.preventDefault()
		let selectedOption = Formdata.variable
		let val = selectedOption.toUpperCase().replaceAll(' ', '_')
		let option = `%%${val}%%`
		if (Formdata.textformat === 'HTML') {
			if (editorRef && editorRef.current) {
				const quillRef = editorRef.current.getQuillRef()
				const editor = quillRef.editor
				if (editor.getLength() === 0) {
					editor.insertText(0, option, 'user')
				} else {
					const cursorPosition = editor.getSelection(true).index
					editor.insertText(cursorPosition, option, 'user')
					editor.setSelection(cursorPosition + option.length, 0)
				}
			}
		} else {
			if (elasticTextBoxRef && elasticTextBoxRef.current) {
				const cursorPosition = elasticTextBoxRef.current.selectionStart
				const currentValue = elasticTextBoxRef.current.value
				const newValue =
					currentValue.substring(0, cursorPosition) +
					option +
					currentValue.substring(cursorPosition)
				elasticTextBoxRef.current.value = newValue
				elasticTextBoxRef.current.setSelectionRange(
					cursorPosition + option.length,
					cursorPosition + option.length
				)
				elasticTextBoxRef.current.dispatchEvent(new Event('input'))
			}
		}
	}
	const HandleSelectedFile = (file) => {
		setFiles([file[0]])
	}
	const RemoveFIle = (files) => {
		let FIlteredFiles = Files.filter((file) => file.name !== files.name)
		setFiles(FIlteredFiles)
	}
	const HandleEditordata = (data) => {
		setEditorText(data)
	}
	useEffect(() => {
		if (Formdata.textformat === 'TEXT') {
			const text = editorText.replace(
				/<!--[\s\S]*?-->|<[^>]*>|&nbsp;/g,
				''
			)
			HANDLEFORMDATA('body', text)
		} else if (Formdata.textformat === 'HTML') {
			HANDLEFORMDATA('body', editorText)
		}
	}, [editorText, Formdata.textformat, Text])
	const HandleText = (data) => {
		setText(data)
	}
	const handleAttachmentRemove = (name) => {
		const attachments = responseAttacahments.filter(
			(attachment) => attachment !== name
		)
		setResponseAttachments(attachments)
	}

	const FORM = (
		<form
			encType="multipart/form-data"
			className="flex w-full flex-col justify-start"
		>
			<div className="flex w-full flex-col justify-start ">
				<TextBox
					id={'template_name'}
					name={'template_name'}
					value={Formdata.templatename}
					labelName={'Template Name '}
					onChange={(data) => HANDLEFORMDATA('templatename', data)}
				/>
				<SimpleDropDown
					label="Select Format"
					options={['HTML', 'TEXT']}
					onChange={(data) => HANDLEFORMDATA('textformat', data)}
					value={Formdata.textformat}
				/>

				<TextBox
					id={'from'}
					name="from"
					value={Formdata.from}
					labelName={'From '}
					onChange={(data) => HANDLEFORMDATA('from', data)}
				/>
				<TextBox
					id={'sender'}
					name={'sender'}
					value={Formdata.sender}
					labelName={'Sender'}
					onChange={(data) => HANDLEFORMDATA('sender', data)}
				/>
				<TextBox
					id={'subject'}
					name={'Subject'}
					value={Formdata.subject}
					labelName={'Subject'}
					onChange={(data) => HANDLEFORMDATA('subject', data)}
				/>
				{Formdata.textformat === 'HTML' ? (
					<RichTextbox
						value={editorText}
						setEditorRef={setEditorRef}
						labelName={'Body'}
						onChange={HandleEditordata}
					/>
				) : (
					<ElasticTextbox
						id="plan_text"
						ref={elasticTextBoxRef}
						value={Text}
						onChange={HandleText}
						labelName="Body"
					/>
				)}
				<div className="flex w-full gap-1.5">
					<SimpleDropDown
						className="flex-1"
						label="Add Variable"
						value={Formdata.variable}
						onChange={(data) => HANDLEFORMDATA('variable', data)}
						options={[
							'Fullname',
							'Email',
							'Subject',
							'Message',
							'Login Link',
							'Verify Link',
							'Reset Password Link',
							'Magic Link'
						]}
					/>
					<Button
						onClick={handleAddVariable}
						children="Add"
						className="btn btn-primary btn-large !mb-[10px] !mt-[30px]"
					/>
				</div>
				<Attachments
					id="edit-emailtemplate"
					labelName={'Add Attachment'}
					files={Files}
					onSelect={HandleSelectedFile}
					onRemove={RemoveFIle}
				/>
				{responseAttacahments.length > 0 &&
					responseAttacahments.map((attachment) => {
						return (
							<FalseAttachments
								filename={attachment.split('/')[2]}
								onFileRemove={() =>
									handleAttachmentRemove(attachment)
								}
							/>
						)
					})}
			</div>
		</form>
	)
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('25%')
		setOverlay('')
		setBody(null)
		setDrawerstate(false)
	}

	useEffect(() => {
		if (TemplateData?.attachments.length > 0) {
			setResponseAttachments([...TemplateData.attachments])
		}
	}, [])
	useEffect(() => {
		setBody(FORM)
	}, [showDrawer, Formdata, editorText, Files, responseAttacahments])

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)

	const drawerFooterBody = (
		<>
			<h5>{}</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={HandleEditTemplate}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>{' '}
		</>
	)

	return (
		<>
			<Drawer
				label="Edit Template"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}

export default EditTemplate
