import UserList from '../../UserList'
import ChangePassword from '../../ChangePassword'
import AccountSettings from '../../AccountSettings/index.jsx'
import HelpCenter from '../../HelpCenter/index.jsx'
import NotificationList from '../../NotificationList/index.jsx'
import BusinessObjectives from '../../BusinessObjectives/index.jsx'
import RiskManagement from '../../RiskManagement/index.jsx'
import OrganizationContext from '../../OrganizationContext'
import RiskCatalogue from '../../RiskCatalogue/index.jsx'
export const routes = [
	{
		path: '/',
		pageName: 'Welcome Screen',
		component: () => <div></div>,
		headerTitle: (userName) => `Welcome! ${userName}`,
		pageParent: 'Dashboard',
		headerDescription:
			'A dashboard is a way of displaying various types of visual data in one place. Usually, a dashboard is intended to convey different, but related information in an easy-to-digest form.'
	},
	{
		path: '/userlist',
		pageName: 'User List',
		component: UserList,
		headerTitle: 'User List',
		pageParent: 'User Management',
		headerDescription:
			'A user is a casual name given to an individual who interacts with a website, online service, app, or platform in any way. For example, the user of a website is someone who visits the site. The user of a mobile app is someone who downloads the mobile app.'
	},
	{
		// path: '/emailserver',
		pageName: 'Email Server',
		component: () => <></>,
		headerTitle: 'Email Server',
		pageParent: 'Settings',
		headerDescription:
			'An email server is usually completely invisible to you as a user, but it works around the clock to make sure you can send and receive emails as needed. You can think of a mail server as your personal mail carrier and inbox, all in one!'
	},

	{
		path: '/accountsetting',
		pageName: 'Account Setting',
		component: AccountSettings,
		headerTitle: 'Account Setting',
		pageParent: 'Settings',
		headerDescription:
			'The Account settings section is where you can access all of your settings, including your organization details, billing information, password and security settings, account users, verified email addresses, and more.'
	},
	{
		// path: '/magiclinksettings',
		pageName: 'Magic Link Settings',
		component: () => <></>,
		headerTitle: 'Magic Link Settings',
		pageParent: 'Settings',
		headerDescription:
			'Magic link settings allow you to configure and enable the use of magic links for user authentication in your application'
	},
	{
		path: '/changepassword',
		pageParent: 'Change Password',
		pageName: 'Change Password',
		component: ChangePassword,
		headerTitle: 'Change Password',
		headerDescription:
			'You can change your password for security reasons or reset it if you forget it. Your Google Account password is used to access many Google products, like Gmail and YouTube.'
	},
	{
		path: '/helpcenter',
		pageName: 'Help Center',
		pageParent: 'Help Center',
		component: HelpCenter,
		headerTitle: 'Help Center',
		headerDescription:
			'The help center is designed to be simple and intuitive, so users can quickly find the answers they need. It is broken down into categories that make it easy to navigate, and it includes a search bar that allows users to find specific topics quickly.'
	},
	{
		pageParent: 'Notificaton List',
		path: '/notificationslist',
		pageName: 'Notification List',
		component: NotificationList,
		headerTitle: 'Notification List',
		headerDescription:
			"A notification is a message that Android displays outside your app's UI to provide the user with reminders, communication from other people, or other timely information from your app. Users can tap the notification to open your app or take an action directly from the notification."
	},
	{
		// path: '/additional-fields',
		pageName: 'Additional Fields',
		component: () => <></>,
		headerTitle: 'Additional Fields List',
		pageParent: 'Settings',
		headerDescription:
			"Admins can tailor the display, sort order, and visibility of each field, catering to specific administrative needs. This streamlined page simplifies the process of adapting and maintaining the application's data structure, enhancing administrative control and flexibility"
	},
	{
		// path: '/registersettings',
		pageName: 'Register Settings',
		component: () => <></>,
		headerTitle: 'Register Settings',
		pageParent: 'Settings',
		headerDescription:
			'A Register Settings page is a component within a web application where administrators or users with appropriate permissions can configure and manage settings related to user registration'
	},
	{
		// path: '/loginsettings',
		pageName: 'Login Settings',
		component: () => <></>,
		headerTitle: 'Login Settings ',
		pageParent: 'Settings',
		headerDescription:
			'A Login Settings page in a web application allows administrators or users with appropriate permissions to configure and manage settings related to user authentication and login processes '
	},
	{
		// path: '/fileupload',
		pageName: 'File Settings',
		component: () => <></>,
		headerTitle: 'File Settings ',
		pageParent: 'Settings',
		headerDescription:
			'File uploading settings typically refer to the configuration options available for controlling the process of uploading files to a website or application. These settings are important for ensuring that file uploads are secure, efficient, and meet the requirements of the system.'
	},
	{
		// path: '/contact-settings',
		pageName: 'Contact Settings',
		component: () => <></>,
		headerTitle: 'Contact Settings ',
		pageParent: 'Settings',
		headerDescription:
			'A Contact Us Settings page in a web application allows administrators or users with appropriate permissions to configure and manage settings related to the contact form or contact information displayed on the website.'
	},
	{
		// path: '/emailtemplates',
		pageName: 'Email Templates',
		component: () => <></>,
		headerTitle: 'Email Templates',
		pageParent: 'Settings',
		headerDescription:
			'Email Templates page is a feature within a web application that allows users to manage and customize pre-defined email templates used for various communication purposes'
	},
	{
		// path: '/listpage',
		pageName: 'List Page',
		component: () => <></>,
		headerTitle: 'List Page',
		pageParent: 'Settings',
		headerDescription: `The List page showcases a collection of lists and enables Admin's to  manage them by adding, editing, or deleting lists as needed, fostering efficient organization and customization of content.`
	},
	{
		// path: '/list-mapping',
		pageName: 'ListMapping Page',
		component: () => <></>,
		headerTitle: 'ListMapping Page',
		pageParent: 'Settings',
		headerDescription: `The ListMapping page facilitates the categorization and alignment of various components with their corresponding categories, streamlining the organization and presentation of information for enhanced clarity and accessibility.`
	},
	{
		path: '/risk-management',
		pageName: 'Risk Management',
		component: RiskManagement,
		headerTitle: 'Risk Management',
		pageParent: 'Settings',
		headerDescription: `Risk management is the process of identifying, assessing, and prioritizing risks, followed by the coordinated application of resources to minimize, control, and monitor the impact of potential events or situations. It's a crucial aspect of decision-making in various domains, including business, finance, project management, and even everyday life`
	},
	{
		path: '/business-objectives',
		pageName: 'Business Objectives',
		component: BusinessObjectives,
		headerTitle: 'Business Objectives',
		pageParent: 'Knowledgebase',
		headerDescription:
			"The Business Objectives page offers a succinct overview of your company's mission-critical targets, serving as a roadmap for achieving long-term success and guiding stakeholders towards shared organizational goals. "
	},
	{
		path: '/organization-context',
		pageName: 'Organization  Context',
		component: OrganizationContext,
		headerTitle: 'Organization  Context',
		pageParent: 'Settings',
		headerDescription:
			'Organization Context Page currently is in development '
	},
	{
		path: '/risk-catalogue',
		pageName: 'Risk Catalogue',
		component: RiskCatalogue,
		headerTitle: 'Risk Catalogue',
		pageParent: 'Settings',
		headerDescription: 'Risk Catalogue Page currently is in development '
	}
]
