import React from 'react'

const AccountSettings = () => {
	return (
		<>
			<p>Account Settings</p>
		</>
	)
}

export default AccountSettings
