import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import { IMPORT_LIST_CATEGORY } from '../../../utils/GraphQl/KnowledgeBase'
import Attachments from '../../../components/ui/Attachments'
import { logoutUser } from '../../../store/AuthSlice/AuthSlice'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'

const ImportList = ({
	setIsImportList,
	ExistingData,
	setPageId,
	setLoader
}) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const dispatch = useDispatch()
	const [Files, setFiles] = useState([])
	const [importList] = useMutation(IMPORT_LIST_CATEGORY)

	const HandleSelectedFile = (file) => {
		if (file[0]?.name?.endsWith('.json')) {
			setFiles([file[0]])
		} else {
			dispatch(
				addAlerts({
					pageId: 'importusers',
					type: 'danger',
					message: 'Please upload a valid CSV file',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const RemoveFIle = (files) => {
		let FIlteredFiles = Files.filter((file) => file.name !== files.name)
		setFiles(FIlteredFiles)
	}
	const HandleUploadBase = async () => {
		try {
			setLoader(true)
			const response = await importList({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					file: Files[0]
				}
			})
			let data = await response.data
			let Bases = data?.importCategoryListData
			if (Bases.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'import_list_category',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'import_list_category' }))
				}, 3000)
			} else if (Bases.status !== 200 && Bases.errors.length > 0) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'import_list_category',
						type: 'danger',
						message: Bases.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (Bases.status === 200) {
				setLoader(false)
				let uploadedData = Bases.data
				console.log('UploadedData=>', uploadedData)
				uploadedData.forEach((item) => {
					ExistingData.push({
						...item,
						field: item.json_data.length,
						type: item.field_type.replace('_', ' ')
					})
				})
				dispatch(
					addAlerts({
						pageId: 'import_list_category',
						type: 'success',
						message: Bases.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					setShowDrawer(false)
					setScrollable(false)
					setScrollableFooter(false)
					setSize('md')
					setOverlay('')
					setBody(null)
					setIsImportList(false)
					dispatch(removeAlerts({ pageId: 'import_list_category' }))
				}, 3000)
			}
		} catch (error) {
			console.log('ERROR ', error)
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'import_list_category',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	const FORM = (
		<>
			<form
				encType="multipart/form-data"
				className=" flex w-[350px] flex-col justify-start"
			>
				<>
					<Attachments
						accept={'.json'}
						id="import_json_list"
						labelName={'Select File'}
						files={Files}
						onSelect={HandleSelectedFile}
						onRemove={RemoveFIle}
					/>
				</>
			</form>
		</>
	)
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setIsImportList(false)
	}
	useEffect(() => {
		setPageId('import_list_category')
	}, [])

	useEffect(() => {
		setBody(FORM)
	}, [showDrawer, Files])
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "> </h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={HandleUploadBase}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') {
			handleDrawerClose()
		}
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			<Drawer
				label="Import List"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default ImportList
