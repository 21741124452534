import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import PageAlerts from '../../PageAlerts'
import TextBox from '../../../components/ui/TextBox'
import ElasticTextBox from '../../../components/ui/Elastic-textbox'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import MultiSelectdropdown from '../../../components/Dropdowns/MultiSelectdropdown'
import RadioGroup from '../../../components/ui/Form/RadioGroup'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import { CREATE_BASE } from '../../../utils/GraphQl/KnowledgeBase'
import Attachments from '../../../components/ui/Attachments'
import { logoutUser } from '../../../store/AuthSlice/AuthSlice'
import ProgressBar from '../../ProgressBar'
import Simpledropdown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import CheckboxGroup from '../../../components/ui/Form/CheckboxGroup'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'

const Add = ({ setIsAddList, setState, fetchedFields }) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [loader, setLoader] = useState(false)
	const [size, setSize] = useState('25%')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const dispatch = useDispatch()
	const [CreateBases] = useMutation(CREATE_BASE)
	const [Files, setFiles] = useState([])
	const [lists, setLists] = useState([])
	const [items, setItems] = useState(fetchedFields?.options_data)
	const [multiOptions, setMultiOptions] = useState('')
	const [checkedItems, setCheckItems] = useState({})

	useEffect(() => {
		let new_items = lists.map((item) => item.value)
		let result_string = new_items.join(',')
		setMultiOptions(result_string)
	}, [items, lists])

	const add = (data) => {
		setLists([...lists, data])
		setItems(items.filter((item) => item.label !== data.label))
	}
	const removeItem = (item) => {
		setLists((prevLists) =>
			prevLists.filter((listItem) => listItem.label !== item.label)
		)
		setItems([item, ...items])
	}
	const [Formdata, setFormdata] = useState({
		json: '',
		category: '',
		heading: '',
		description: '',
		quantMetric: '',
		example: '',
		icon: '',
		dropdown: '',
		radio: ''
	})
	const HANDLEFORMDATA = (field, value) => {
		setFormdata((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}
	const HandleSelectedFile = (file) => {
		if (file.length > 0) {
			if (file[0].type === 'image/png') {
				setFiles([...file])
			} else {
				dispatch(
					addAlerts({
						pageId: 'add_bases',
						type: 'danger',
						message: 'Please select png file.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
		}
	}
	const RemoveFIle = (files) => {
		let FIlteredFiles = Files.filter((file) => file.name !== files.name)
		setFiles(FIlteredFiles)
	}

	const CreateBase = async () => {
		try {
			setLoader(true)
			const response = await CreateBases({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						metric: Formdata.quantMetric,
						heading: Formdata.heading,
						example: Formdata.example,
						description: Formdata.description,
						icon: Files[0],
						category:
							fetchedFields.field_type === 'simple_dropdown'
								? Formdata.dropdown?.value
								: fetchedFields?.field_type ===
									  'multiselect_dropdown'
									? multiOptions
									: fetchedFields?.field_type === 'checkbox'
										? checkedItems
										: fetchedFields?.field_type === 'radio'
											? Formdata.radio
											: ''
					}
				}
			})
			let data = await response.data
			let Bases = data?.createBase
			if (Bases.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'add_bases',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'add_bases' }))
				}, 3000)
			} else if (Bases.status !== 201 && Bases.errors.length > 0) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'add_bases',
						type: 'danger',
						message: Bases.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (Bases.status === 201) {
				setLoader(false)
				let NewField = Bases.data

				setState((prev) => [
					...prev,
					{
						...NewField,
						category: NewField?.category
					}
				])
				dispatch(
					addAlerts({
						pageId: 'add_bases',
						type: 'success',
						message: Bases.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					setShowDrawer(false)
					setScrollable(false)
					setScrollableFooter(false)
					setSize('25%')
					setOverlay('')
					setBody(null)
					setIsAddList(false)
					dispatch(removeAlerts({ pageId: 'add_bases' }))
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'add_bases',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	const handleCheckedItems = (item) => {
		const CheckedKeys = Object.keys(item).filter((key) => item[key])
		const values = CheckedKeys.join(', ')
		// console.log(values)
		setCheckItems(values)
	}
	const FORM = (
		<form
			encType="multipart/form-data"
			className=" flex w-full flex-col justify-start"
		>
			<>
				<div className="flex w-full flex-col justify-start ">
					{fetchedFields?.field_type === 'simple_dropdown' && (
						<Simpledropdown
							label={'Category'}
							options={fetchedFields?.options_data}
							value={Formdata.dropdown?.label}
							onChange={(data) =>
								HANDLEFORMDATA('dropdown', data)
							}
						/>
					)}
					{fetchedFields?.field_type === 'multiselect_dropdown' && (
						<MultiSelectdropdown
							label="Category"
							remainingItems={items}
							selectedItems={lists}
							onItemAdd={(item) => add(item)}
							onItemRemove={(item) => removeItem(item)}
						/>
					)}
					{fetchedFields?.field_type === 'checkbox' && (
						<div className="form-group">
							<label className="label">{'Category '}</label>
							<CheckboxGroup
								type="vertical"
								options={fetchedFields?.options_data}
								onChange={handleCheckedItems}
							/>
						</div>
					)}
					{fetchedFields?.field_type === 'radio' && (
						<div className="form-group">
							<label className="label">Category</label>
							<RadioGroup
								name="gender"
								type="vertical"
								options={fetchedFields?.options_data}
								onChange={(data) =>
									HANDLEFORMDATA('radio', data)
								}
								className="radio-circle"
							/>{' '}
						</div>
					)}
					<TextBox
						id={'heading_data'}
						labelName={'Heading'}
						value={Formdata.heading}
						onChange={(data) => HANDLEFORMDATA('heading', data)}
					/>
					<ElasticTextBox
						id="description"
						value={Formdata.description}
						labelName={'Description'}
						onChange={(data) => HANDLEFORMDATA('description', data)}
					/>
					<TextBox
						id={'quantmetric'}
						labelName={'QuantMetric'}
						value={Formdata.quantMetric}
						onChange={(data) => HANDLEFORMDATA('quantMetric', data)}
					/>
					<ElasticTextBox
						id="example"
						value={Formdata.example}
						labelName={'Example'}
						onChange={(data) => HANDLEFORMDATA('example', data)}
					/>

					<Attachments
						accept={'.png'}
						id="add-icon"
						labelName={'Add Icon'}
						files={Files}
						onSelect={HandleSelectedFile}
						onRemove={RemoveFIle}
					/>
				</div>
			</>
		</form>
	)
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('25%')
		setOverlay('')
		setBody(null)
		setIsAddList(false)
	}
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])

	useEffect(() => {
		setBody(FORM)
	}, [showDrawer, checkedItems, Formdata, multiOptions, items, lists, Files])
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "> </h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={CreateBase}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') {
			handleDrawerClose()
		}
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			{loader && <ProgressBar />}
			<PageAlerts pageId={'add_bases'} />
			<Drawer
				label="Add Business Objective"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default Add
