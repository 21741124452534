import React from 'react'
import GeneralFooter from './GeneralFooter'
import GeneralHeader from './GeneralHeader'
import '../../assets/css/Card/index.css'
const MainPage = ({ children, ...props }) => {
	function toggle(e) {
		e.preventDefault()
		const rootElement = document.getElementById('root')
		if (window.matchMedia('(min-width: 992px)').matches) {
			rootElement.classList.toggle('sidebar-hide')
		} else {
			rootElement.classList.toggle('sidebar-show')
		}
	}
	return (
		<>
			<div className=" main-backdrop " onClick={toggle}></div>
			<div className="main main-app p-6 pb-0 ">
				<div className="main-outer pb-2">
					<GeneralHeader {...props} />
					{children}
				</div>
				<GeneralFooter />
			</div>
		</>
	)
}
export default React.memo(MainPage)
