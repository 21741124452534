import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthLayout from '../../Layouts/AuthLayout'
import FormHeader from '../../components/Common/FormHeader'
import Formfooter from '../../components/Common/FormFooter'
import FormBody from '../../components/Common/FormBody'
import TextBox from '../../components/ui/TextBox'
import Icon from '../../components/ui/icon'
import Button from '../../components/ui/Buttons/Button'
import { REGISTER_MUTATION } from '../../utils/GraphQl/RegisterMutation'
import { useMutation } from '@apollo/client'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import PageAlerts from '../PageAlerts'
import { useDispatch } from 'react-redux'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'

const Register = () => {
	const navigate = useNavigate()
	const [register] = useMutation(REGISTER_MUTATION)
	const [loader, setLoader] = useState(false)
	const [userData, setUserData] = useState({
		email: null,
		password: null,
		fullname: null
	})
	const dispatch = useDispatch()
	const handleRegister = async () => {
		try {
			setLoader(true)
			const response = await register({
				variables: {
					input: {
						fullname: userData.fullname,
						email: userData.email,
						password: userData.password
					}
				}
			})
			let data = await response.data
			if (data.register.errors) {
				setLoader(false)

				dispatch(
					addAlerts({
						pageId: 'register',
						type: 'danger',
						message: data.register.errors,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.register.status === 429) {
				setLoader(false)

				dispatch(
					addAlerts({
						pageId: 'register',
						type: 'danger',
						message: data.register.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				setLoader(false)

				dispatch(
					addAlerts({
						pageId: 'register',
						type: 'success',
						message:
							'Registration completed successfully. You can use your credentials to login.',
						icon: faCheck,
						autoclose: 3000
					})
				)

				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'register' }))
					navigate('/')
				}, 3000)
			}
		} catch (error) {
			console.error('Register failed', error)
			setLoader(false)
			setTimeout(() => {
				dispatch(
					addAlerts({
						pageId: 'register',
						type: 'danger',
						message: 'Something went wrong',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}, 100)
		}
	}
	const HandleChange = (key, value) => {
		setUserData((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}
	const HandleSubmit = (event) => {
		event.preventDefault()
		const PSW_REGEXP =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/
		const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

		let isValidEmail = EMAIL_REGEXP.test(userData.email ?? '')
		let isValidPassword = PSW_REGEXP.test(userData.password ?? '')

		if (
			!userData.email ||
			!isValidEmail ||
			!userData.password ||
			!isValidPassword ||
			!userData.fullname
		) {
			if (!userData.email) {
				dispatch(
					addAlerts({
						pageId: 'register',
						type: 'danger',
						message: 'Please enter your email address.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!isValidEmail) {
				dispatch(
					addAlerts({
						pageId: 'register',
						type: 'danger',
						message: 'Please enter valid email address.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!userData.password) {
				dispatch(
					addAlerts({
						pageId: 'register',
						type: 'danger',
						message: 'Please enter your password.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!isValidPassword) {
				dispatch(
					addAlerts({
						pageId: 'register',
						type: 'danger',
						message: 'Please enter a valid password.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!userData.fullname) {
				dispatch(
					addAlerts({
						pageId: 'register',
						type: 'danger',
						message: 'Please enter your full name.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				dispatch(
					addAlerts({
						pageId: 'register',
						type: 'danger',
						message:
							'Something went wrong. Please try again after sometime.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
		} else {
			handleRegister()
		}
	}
	return (
		<>
			<PageAlerts pageId={'register'} />
			<AuthLayout>
				<FormHeader
					title="Sign Up"
					message="It's free to signup and only takes a minute."
				/>
				<FormBody>
					<TextBox
						id={'email_address'}
						name={'email_address'}
						onChange={(data) => HandleChange('email', data)}
						labelName={'Email Address'}
						type={'text'}
						className={'mb-4'}
					/>
					<TextBox
						id={'password'}
						name={'password'}
						onChange={(data) => HandleChange('password', data)}
						labelName={'Password'}
						type="password"
						className={'mb-4'}
					/>
					<TextBox
						id={'fullname'}
						name={'fullname'}
						onChange={(data) => HandleChange('fullname', data)}
						labelName={'Full Name'}
						type={'text'}
						className={'mb-4'}
					/>

					<div className="mb-4">
						<p className="small-text ">
							By clicking <strong>Create Account</strong> below,
							you agree to our Terms and Conditions and Privacy
							Policy.
						</p>
					</div>

					{loader ? (
						<Button
							className="btn-large btn-primary btn-all flex  w-full cursor-not-allowed gap-2"
							isIconBtn={true}
							onClick={() => console.log('')}
						>
							<span className="waiting-loader"> </span> Please
							Wait
						</Button>
					) : (
						<Button
							className="btn-large btn-primary btn-all flex w-full gap-2 p-1"
							isIconBtn={true}
							onClick={HandleSubmit}
						>
							<Icon
								name="line-signup"
								width={'16px'}
								height={'16px'}
								className={'icon-fill-white'}
								viewBox={'0 0 24 24'}
							/>{' '}
							Create Account
						</Button>
					)}
				</FormBody>
				<Formfooter
					message1="Already have an account"
					linktext1="Sign In"
					link1="/"
					message2="Need assistance or have questions"
					linktext2="Contact Support"
					link2="contact-us"
				/>
			</AuthLayout>
		</>
	)
}
export default Register
