import React, { useEffect, useState } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import DashboardLayout from '../../Layouts/DashboardLayout'
import MENU_DATA from './Menudata'
import ViewProfile from '../viewprofile'
import { useSelector } from 'react-redux'
import MainPage from '../../components/Sidebar/mainPage.jsx'
import PopUpDocUpdate from '../PopUpDocUpdate/index.jsx'
import PopUpProfileUpdate from '../PopUpProfileUpdate/index.jsx'
import ProtectedRoute from './ProtectedRoute/index.jsx'
import { routes } from './Routes/index.jsx'
import GlobalComponent from './GlobalComponent/index.jsx'
const Dashboard = () => {
	const data = useSelector((state) => state.Auth)
	const globalPath = useSelector((state) => state?.SettingsDrawer?.path)
	const isAdmin = data?.userdata?.user?.role === 'admin'
	const [ROUTES, setROUTES] = useState([])
	const FORCEUPDATE = data?.userdata?.forceUpdate
	const navigate = useNavigate()
	const FORCEUPDATEDOC = data?.userdata?.forceUpdateDoc
	const modifiedMenuData = isAdmin
		? MENU_DATA
		: MENU_DATA.filter(
				(item) =>
					item.id !== 'management' &&
					item.id !== 'settings' &&
					item.id !== 'knowledgebase'
			)
	useEffect(() => {
		setROUTES(
			isAdmin
				? routes
				: routes.filter(
						(route) =>
							![
								'/userlist',
								'/Addadditionalfields',
								'/mandatory-fields',
								'/additional-fields',
								'/emailserver',
								'/business-objectives',
								'/risk-management',
								'/list-mapping',
								'/listpage',
								'/emailtemplates',
								'/contact-settings',
								'/fileupload',
								'/loginsettings',
								'/registersettings'
							].includes(route.path)
					)
		)
	}, [FORCEUPDATE, isAdmin])

	const currentPath = useLocation().pathname
	useEffect(() => {
		let temp = false
		routes.forEach((route) => {
			if (route.path === currentPath) {
				temp = true
			}
		})
		if (!temp && currentPath !== '/userprofile') {
			navigate('/')
		}
		return () => {}
	}, [currentPath])

	return (
		<>
			{FORCEUPDATE && <PopUpProfileUpdate />}
			{FORCEUPDATEDOC && <PopUpDocUpdate />}
			<DashboardLayout menudata={modifiedMenuData}>
				<Routes>
					{ROUTES.map((route, index) => (
						<Route
							key={index}
							path={route.path}
							element={
								<ProtectedRoute>
									<MainPage
										pageName={route.pageName}
										pageParent={route.pageParent}
										link={route.path}
										headerTitle={
											typeof route.headerTitle ===
											'function'
												? route.headerTitle(
														data?.userdata?.user
															?.fullname
													)
												: route.headerTitle
										}
										headerDescription={
											route.headerDescription
										}
									>
										<route.component />
									</MainPage>
								</ProtectedRoute>
							}
						/>
					))}
					<Route
						path="/userprofile"
						element={
							<>
								{' '}
								<MainPage
									pageName={'User Profile'}
									pageParent={'User Profile'}
									link={'/userprofile'}
									headerDescription={`A user is a casual name given to an individual who interacts with a website, online service, app, or platform in any way. For example, the user of a website is someone who visits the site. The user of a mobile app is someone who downloads the mobile app`}
									headerTitle={'User Profile'}
								>
									<ViewProfile />
								</MainPage>
							</>
						}
					/>
				</Routes>
			</DashboardLayout>

			<GlobalComponent globalPathsArray={globalPath} />
		</>
	)
}
export default React.memo(Dashboard)
