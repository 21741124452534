import React from 'react'
import DataTableWrapper from '../../components/ui/DataTables'
import { gql, useApolloClient, useMutation } from '@apollo/client'
import { useState, useEffect } from 'react'
import { classNames } from 'primereact/utils'
import Button from '../../components/ui/Buttons/Button'
import { useDispatch, useSelector } from 'react-redux'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import IconButton from '../../components/ui/Buttons/Icon-button'
import { DELETE_TEMPLATE_MUTATION } from '../../utils/GraphQl/Templates'
import { FetchedTemplates, DeleteTemplate } from '../../store/TemplateSlice'
import { logoutUser } from '../../store/AuthSlice/AuthSlice'
import AddTemplate from './AddTemplate'
import EditTemplate from './EditTemplate'
import GlobalDrawer from '../GlobalDrawer'
import { removePage } from '../../store/SettingsSlice'
import TableLayout from './TableLayout'
import {
	faTrash,
	faEdit,
	faCheck,
	faTriangleExclamation,
	faBook,
	faUniversalAccess,
	faPlus
} from '@fortawesome/free-solid-svg-icons'
import useGlobalKeyPress from '../../hooks/globalKeyPress'
const GET_TEMPLATES = gql`
	query GetAllTemplates($pagination: PaginationInput) {
		getAllTemplates(pagination: $pagination) {
			status
			message
			errors
			data
		}
	}
`

const EmailTemplates = () => {
	let COLUMNS = [
		{
			selectionMode: 'multiple',
			isHidden: false,
			position: 'left',
			field: 'checkbox',
			headerStyle: { width: '2%' },
			style: { width: '2%' },
			className: 'chk-dt chk-primary',
			id: 'primary'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'left',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'Id',
			field: 'id'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'left',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },

			header: 'Sender Email',
			field: 'sender_email'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'left',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'Template Name',
			field: 'template_name'
		},

		{
			sortable: true,
			isHidden: false,
			position: 'left',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'Sender Name',
			field: 'sender_name'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'left',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'Type',
			field: 'type'
		}
	]
	const TABLE_LAYOUTS = useSelector(
		(state) => state.tableLayouts?.emailTemplateLayout
	)
	const Templates = useSelector((state) => state.Templates.Templates)
	const [showDrawer, setShowDrawer] = useState(true)
	const [pageId, setPageId] = useState('emailTemplates')
	const [body, setBody] = useState(null)
	const [loader, setLoader] = useState(false)
	const userdata = useSelector((state) => state.Auth)
	const authToken = userdata.token
	const [isAddTemplate, setIsAddTemplate] = useState(false)
	const [isEditTemplate, setIsEditTemplate] = useState(false)
	const [SelectedTemplate, setSelectedTemplate] = useState([])
	const dispatch = useDispatch()
	const [DelateTemplate] = useMutation(DELETE_TEMPLATE_MUTATION)
	const client = useApolloClient()
	const [columns, setColumns] = useState([...COLUMNS])
	const [isLayout, setIsLayout] = useState(false)
	useEffect(() => {
		if (TABLE_LAYOUTS.length > 0) {
			TABLE_LAYOUTS.forEach((data) => {
				if (data.table_name === 'table_email_template') {
					setColumns(data.structure)
					COLUMNS = data.structure
				}
			})
		}
	}, [TABLE_LAYOUTS])
	const FetchTemplates = async () => {
		try {
			setLoader(true)
			const { data } = await client.query({
				query: GET_TEMPLATES,
				variables: {
					pagination: {
						offset: 0,
						limit: 100
					}
				},
				context: {
					headers: {
						authorization: authToken
					}
				}
				// fetchPolicy: 'network-only'
			})
			let templates = data.getAllTemplates
			if (templates.status === 401) {
				setLoader(false)

				dispatch(
					addAlerts({
						pageId: 'emailTemplates',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'emailTemplates' }))
				}, 3100)
			} else if (templates.status === 204) {
			} else if (templates.status === 200) {
				setLoader(false)
				let FetchedItems = templates.data.items
				dispatch(FetchedTemplates(FetchedItems))
			} else if (templates.status === 403) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'emailTemplates',
						type: 'danger',
						message: templates.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'emailTemplates' }))
				}, 3100)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'emailTemplates',
					type: 'danger',
					message: 'Something went wrong pleasse try again.',
					icon: faCheck,
					autoclose: 3000
				})
			)
		}
	}
	useEffect(() => {
		FetchTemplates()
	}, [])

	const DeleteSelectedTemplate = async (ID) => {
		setPageId('emailTemplates')
		try {
			setLoader(true)
			const response = await DelateTemplate({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					templateId: ID
				}
			})
			let data = await response.data
			let DeleteTemplates = data.deleteTemplate
			if (DeleteTemplates.status === 400) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'emailTemplates',
						type: 'danger',
						message: DeleteTemplates.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (DeleteTemplates.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'emailTemplates',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'emailTemplates' }))
				}, 3000)
			} else if (
				DeleteTemplates.status !== 200 &&
				DeleteTemplates.errors.length > 0
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'emailTemplates',
						type: 'danger',
						message: DeleteTemplates.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (DeleteTemplates.status === 200) {
				setSelectedTemplate([])
				dispatch(DeleteTemplate(SelectedTemplate[0]))
				FetchTemplates()
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'emailTemplates',
						type: 'success',
						message: DeleteTemplates.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'emailTemplates' }))
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'emailTemplates',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const dopdownOptions = ['All']

	const template1 = {
		layout: 'CustomPaginator PrevPageLink PageLinks NextPageLink',
		CustomPaginator: (options) => {
			return (
				<span style={{ marginRight: 'auto' }}>
					Showing {options.first + 1} to{' '}
					{options.first + options.rows} of {options.totalRecords}{' '}
					entries
				</span>
			)
		},
		PrevPageLink: (options) => {
			options.className = classNames(options.className).replace(
				'p-disabled',
				''
			)
			return (
				<Button
					type={'button'}
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={options.onClick}
					// disabled={options.disabled}
					children={'Previous'}
				/>
			)
		},
		NextPageLink: (options) => {
			return (
				<Button
					type="button"
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={(event) => {
						// if (!stopfetching) {
						// 	fetchAgain()
						// 	setLimit((prev) => prev + 10)
						// }
						options.onClick(event)
					}}
					// disabled={options.disabled}
					children={'Next'}
				/>
			)
		},

		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page &&
					options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, {
					'p-disabled': true
				})

				return (
					<span className={className} style={{ userSelect: 'none' }}>
						...
					</span>
				)
			}
			const isCurrentPage = options.page === options.currentPage
			const linkClassName = classNames(options.className, {
				p: isCurrentPage
			})
			const linkStyle = isCurrentPage
				? {
						backgroundColor: '#7252d3',
						borderRadius: '0',
						color: '#fff'
					}
				: {
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: '#007bff'
					}
			return (
				<Button
					type="button"
					className={linkClassName}
					style={linkStyle}
					onClick={(event) => {
						// if (!stopfetching) {
						// 	fetchAgain()
						// 	setLimit((prev) => prev + 10)
						// }
						options.onClick(event)
					}}
					children={`${options.page + 1}`}
				/>
			)
		}
	}
	const paginatorLeft = (options) => {
		return (
			<span style={{ marginRight: 'auto' }}>
				Showing {options.first + 1} to{' '}
				{Math.min(options.first + options.rows, options.totalRecords)}{' '}
				entries from {options.totalRecords}
			</span>
		)
	}
	const HandleData = (data) => {
		setSelectedTemplate(data)
	}
	const HandleAddTemplate = () => {
		setIsAddTemplate((prev) => !prev)
	}
	const HandleEditTemplate = () => {
		if (SelectedTemplate.length <= 0) {
			dispatch(
				addAlerts({
					pageId: 'emailTemplates',
					type: 'danger',
					message: 'Please select Template!',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (SelectedTemplate.length > 1) {
			dispatch(
				addAlerts({
					pageId: 'emailTemplates',
					type: 'danger',
					message: 'Please select only one Template!',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (SelectedTemplate.length === 1) {
			setIsEditTemplate((prev) => !prev)
		}
	}
	const HandleDeleteTemplate = () => {
		if (SelectedTemplate.length <= 0) {
			dispatch(
				addAlerts({
					pageId: 'emailTemplates',
					type: 'danger',
					message: 'Please select Template!',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (SelectedTemplate.length > 1) {
			dispatch(
				addAlerts({
					pageId: 'emailTemplates',
					type: 'danger',
					message: 'Please select only one Template!',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (SelectedTemplate.length === 1) {
			let id = SelectedTemplate[0].id
			DeleteSelectedTemplate(id)
		}
	}
	const HeaderButtons = ({ exportCSV }) => (
		<div className="flex flex-wrap items-center justify-start  gap-[3px]">
			<IconButton
				onClick={() => setIsLayout((prev) => !prev)}
				Icon={faPlus}
				size="sm"
				className="btn btn-large btn-primary flex gap-1.5"
			>
				Layout
			</IconButton>{' '}
			<IconButton
				onClick={HandleAddTemplate}
				Icon={faCheck}
				size="sm"
				className="btn btn-large btn-primary flex gap-1.5"
			>
				Add Template
			</IconButton>{' '}
			<IconButton
				onClick={HandleEditTemplate}
				Icon={faEdit}
				disabled={SelectedTemplate.length === 0}
				size={'sm'}
				className="btn btn-large btn-edit flex gap-1.5"
			>
				Edit Template
			</IconButton>
			<IconButton
				onClick={HandleDeleteTemplate}
				disabled={SelectedTemplate.length === 0}
				Icon={faTrash}
				size={'sm'}
				className="btn btn-large btn-danger flex gap-1.5"
			>
				Delete Template
			</IconButton>
		</div>
	)
	const FORM = (
		<>
			{isLayout && (
				<TableLayout
					setDrawerstate={setIsLayout}
					setLoader={setLoader}
					setPageId={setPageId}
					columns={columns}
					originalColumns={COLUMNS}
					setColumns={setColumns}
				/>
			)}
			{isAddTemplate && (
				<AddTemplate
					setDrawerstate={setIsAddTemplate}
					setLoader={setLoader}
					setPageId={setPageId}
				/>
			)}
			{isEditTemplate && (
				<EditTemplate
					setDrawerstate={setIsEditTemplate}
					TemplateData={SelectedTemplate[0]}
					FetchTemplates={FetchTemplates}
					setLoader={setLoader}
					setPageId={setPageId}
					setSelectedTemplate={setSelectedTemplate}
				/>
			)}
			<DataTableWrapper
				products={Templates}
				HeaderButtons={HeaderButtons}
				options={dopdownOptions}
				columns={columns}
				onDataSelection={HandleData}
				template={template1}
				paginatorLeft={paginatorLeft}
				selectedData={SelectedTemplate}
			/>
		</>
	)
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])
	useEffect(() => {
		setBody(FORM)
	}, [
		showDrawer,
		SelectedTemplate,
		isEditTemplate,
		isAddTemplate,
		columns,
		TABLE_LAYOUTS,
		Templates,
		isLayout
	])
	const handleDrawerClose = () => {
		dispatch(removeAlerts({ pageId: 'setPageId' }))
		dispatch(removePage())
	}
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal ">{''}</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				{/* <IconButton
					onClick={handleDrawerClose}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton> */}
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') {
			if (!isAddTemplate && !isEditTemplate && !isLayout) {
				handleDrawerClose()
			}
		}
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			<GlobalDrawer
				label={'Email Templates'}
				onClose={handleDrawerClose}
				body={body}
				scrollable={true}
				overlay={''}
				size={'80%'}
				showDrawer={true}
				drawerFooterBody={drawerFooterBody}
				loader={loader}
				scrollableFooter={false}
				drawerPosition={'right'}
				pageId={pageId}
			/>
		</>
	)
}
export default EmailTemplates
