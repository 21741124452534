import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import PageAlerts from '../../PageAlerts'
import TextBox from '../../../components/ui/TextBox'
import ElasticTextBox from '../../../components/ui/Elastic-textbox'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import { UPDATE_BASE } from '../../../utils/GraphQl/KnowledgeBase'
import Attachments from '../../../components/ui/Attachments'
import { logoutUser } from '../../../store/AuthSlice/AuthSlice'
import Simpledropdown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import MultiSelectdropdown from '../../../components/Dropdowns/MultiSelectdropdown'
import CheckboxGroup from '../../../components/ui/Form/CheckboxGroup'
import RadioGroup from '../../../components/ui/Form/RadioGroup'
import FalseAttachments from '../../../components/ui/FalseAttachments'
import { API_BASE_URL } from '../../../constants'
import ProgressBar from '../../ProgressBar'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const Edit = ({
	setIsEditList,
	baseData,
	setBOSelectedData,
	ExistingData,
	fetchedFields
}) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [loader, setLoader] = useState(false)
	const [size, setSize] = useState('25%')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const dispatch = useDispatch()
	const [Files, setFiles] = useState([])
	const [updateBase] = useMutation(UPDATE_BASE)
	const [multiOptions, setMultiOptions] = useState('')
	const [checkedItems, setCheckItems] = useState({})
	const [FalseAttachment, setFalseAttachment] = useState([])
	const [Formdata, setFormdata] = useState({
		category: baseData[0].category || '',
		heading: baseData[0].heading || '',
		description: baseData[0].description || '',
		quantMetric: baseData[0].metric || '',
		example: baseData[0].example || '',
		radio: ''
	})
	const [CheckedOptions, setCheckedOptions] = useState([])
	const [RadioOptions, setRadioOptions] = useState([])
	const [lists, setLists] = useState([])
	const [items, setItems] = useState(fetchedFields?.options_data)
	const [dropDownval, setDropDownValue] = useState([])
	useEffect(() => {
		if (fetchedFields?.field_type === 'multiselect_dropdown') {
			let arr = []
			if (baseData[0].category.includes(',')) {
				arr = baseData[0].category.split(',')
			} else {
				arr.push(baseData[0].category)
			}
			fetchedFields?.options_data.map((json, index) => {
				if (arr.includes(json.value)) {
					if (!lists.find((item) => item.value === json.value)) {
						setLists((prevItems) => [...prevItems, json])
					}
				}
			})
			let filtered = items.filter((obj) => {
				return !baseData[0].category.split(',').includes(obj.value)
			})

			setItems(filtered)
		} else if (fetchedFields?.field_type === 'simple_dropdown') {
			let value = baseData[0].category
			let arr = []
			if (value.includes(',')) {
				arr = value.split(',')
			} else {
				arr.push(value)
			}
			fetchedFields?.json_data.map((option) => {
				if (option.value === arr[0]) {
					setDropDownValue(option)
				}
			})
		} else if (fetchedFields?.field_type === 'checkbox') {
			let value = baseData[0].category
			let arr = []
			if (value.includes(',')) {
				arr = value.split(',')
			} else {
				arr.push(value)
			}
			let fields = fetchedFields?.json_data.map((json, index) => {
				return {
					...json,
					checked: arr.includes(json.value)
				}
			})
			setCheckedOptions((prev) => [...prev, ...fields])
			setCheckItems(value)
		} else if (fetchedFields?.field_type === 'radio') {
			let value = baseData[0].category
			let arr = []
			if (value.includes(',')) {
				arr = value.split(',')
			} else {
				arr.push(value)
			}
			let fields = fetchedFields?.json_data.map((json, index) => {
				return {
					...json,
					selected: json.value === arr[0]
				}
			})
			setRadioOptions((prev) => [...fields])
			setFormdata({
				...Formdata,
				radio: value
			})
		}
	}, [fetchedFields])

	useEffect(() => {
		let new_items = lists.map((item) => item.value)
		let result_string = new_items.join(',')
		setMultiOptions(result_string)
	}, [items])
	const add = (data) => {
		setLists([...lists, data])
		setItems(items.filter((item) => item.label !== data.label))
	}
	const removeItem = (item) => {
		setLists((prevLists) =>
			prevLists.filter((listItem) => listItem.label !== item.label)
		)
		setItems([item, ...items])
	}
	useEffect(() => {
		if (baseData[0].icon.length > 0) {
			setFalseAttachment([baseData[0].icon])
		}
	}, [])
	const handleAttachmentRemove = (name) => {
		setFalseAttachment([])
	}
	const HANDLEFORMDATA = (field, value) => {
		setFormdata((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}
	const HandleSelectedFile = (file) => {
		if (file.length > 0) {
			if (file[0].type === 'image/png') {
				setFiles([...file])
			} else {
				dispatch(
					addAlerts({
						pageId: 'Edit_base',
						type: 'danger',
						message: 'Please select a PNG file.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
		}
	}
	const RemoveFIle = (files) => {
		let FIlteredFiles = Files.filter((file) => file.name !== files.name)
		setFiles(FIlteredFiles)
	}
	const HandleRadioOption = (value) => {
		let fields = fetchedFields?.json_data.map((json, index) => {
			return {
				...json,
				selected: json.value === value
			}
		})
		setRadioOptions((prev) => [...fields])
		HANDLEFORMDATA('radio', value)
	}

	const HandleUpdateBase = async () => {
		try {
			setLoader(true)
			const response = await updateBase({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					baseId: baseData[0].id,
					input: {
						metric: Formdata.quantMetric,
						heading: Formdata.heading,
						example: Formdata.example,
						description: Formdata.description,
						category:
							fetchedFields.field_type === 'simple_dropdown'
								? dropDownval?.value
								: fetchedFields?.field_type ===
									  'multiselect_dropdown'
									? multiOptions
									: fetchedFields?.field_type === 'checkbox'
										? checkedItems
										: fetchedFields?.field_type === 'radio'
											? Formdata.radio
											: '',
						...(Files.length > 0 && { icon: Files[0] })
					}
				}
			})
			let data = await response.data
			let Bases = data?.editBase
			if (Bases.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'Edit_base',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'Edit_base' }))
				}, 3000)
			} else if (Bases.status !== 200 && Bases.errors.length > 0) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'Edit_base',
						type: 'danger',
						message: Bases.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (Bases.status === 200) {
				setLoader(false)
				let UpdatedField = Bases.data
				let index = ExistingData.findIndex(
					(element) => element.id === UpdatedField.id
				)
				let newElement = {
					...UpdatedField
				}
				ExistingData[index] = newElement

				dispatch(
					addAlerts({
						pageId: 'Edit_base',
						type: 'success',
						message: Bases.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setBOSelectedData([newElement])
				setTimeout(() => {
					setShowDrawer(false)
					setScrollable(false)
					setScrollableFooter(false)
					setSize('25%')
					setOverlay('')
					setBody(null)
					setIsEditList(false)
					dispatch(removeAlerts({ pageId: 'Edit_base' }))
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'Edit_base',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	const HandleCheck = (data) => {
		const checkedKeys = Object.keys(data).filter((key) => data[key])
		const keysString = checkedKeys.join(',')
		setCheckItems(keysString)
	}
	const handleFileName = (fileName) => {
		let name = fileName.split('/')[2]
		return name
	}
	const FORM = (
		<form
			encType="multipart/form-data"
			className=" flex w-full flex-col justify-start"
		>
			<>
				<div className="flex w-full flex-col justify-start ">
					{fetchedFields?.field_type === 'simple_dropdown' && (
						<Simpledropdown
							label={'Category'}
							options={fetchedFields?.json_data}
							value={dropDownval?.label}
							onChange={(data) => setDropDownValue(data)}
						/>
					)}
					{fetchedFields?.field_type === 'multiselect_dropdown' && (
						<MultiSelectdropdown
							label="Category"
							remainingItems={items}
							selectedItems={lists}
							onItemAdd={(item) => add(item)}
							onItemRemove={(item) => removeItem(item)}
						/>
					)}
					{fetchedFields?.field_type === 'checkbox' && (
						<div className="form-group">
							<label className="label">{'Category '}</label>
							<CheckboxGroup
								type="vertical"
								options={CheckedOptions}
								onChange={HandleCheck}
							/>
						</div>
					)}

					{fetchedFields?.field_type === 'radio' && (
						<div className="form-group">
							<label className="label">Category</label>
							<RadioGroup
								name="gender"
								type="vertical"
								options={RadioOptions}
								onChange={HandleRadioOption}
								className="radio-circle"
							/>{' '}
						</div>
					)}
					<TextBox
						id={'heading'}
						labelName={'Heading'}
						value={Formdata.heading}
						onChange={(data) => HANDLEFORMDATA('heading', data)}
					/>
					<ElasticTextBox
						id="description"
						value={Formdata.description}
						labelName={'Description'}
						onChange={(data) => HANDLEFORMDATA('description', data)}
					/>
					<TextBox
						id={'quant_matric'}
						labelName={'QuantMetric'}
						value={Formdata.quantMetric}
						onChange={(data) => HANDLEFORMDATA('quantMetric', data)}
					/>
					<ElasticTextBox
						id="expample"
						value={Formdata.example}
						labelName={'Example'}
						onChange={(data) => HANDLEFORMDATA('example', data)}
					/>
					<Attachments
						accept={'.png'}
						id="add-icon"
						labelName={'Change Icon'}
						files={Files}
						onSelect={HandleSelectedFile}
						onRemove={RemoveFIle}
					/>
					{Files.length === 0 &&
						FalseAttachment.length > 0 &&
						FalseAttachment.map((attachment) => {
							const filename = attachment.split('/')[2]
							const truncatedFilename =
								filename.length > 30
									? filename.substring(0, 30) + '....'
									: filename

							return (
								<FalseAttachments
									filename={truncatedFilename}
									onFileRemove={() =>
										handleAttachmentRemove(attachment)
									}
								/>
							)
						})}

					<div className="my-2 min-w-[140px] max-w-[140px]">
						<img
							className=""
							src={
								baseData[0].icon
									? `${API_BASE_URL}/${baseData[0].icon}`
									: ''
							}
							alt=""
							style={{
								width: '100%',
								height: 'auto',
								maxWidth: '100%'
							}}
						/>
					</div>
				</div>
			</>
		</form>
	)
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('25%')
		setOverlay('')
		setBody(null)
		setIsEditList(false)
	}
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])

	useEffect(() => {
		setBody(FORM)
	}, [
		showDrawer,
		Formdata,
		Files,
		lists,
		items,
		multiOptions,
		dropDownval,
		CheckedOptions,
		RadioOptions,
		FalseAttachment
	])

	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "> </h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={HandleUpdateBase}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') {
			handleDrawerClose()
		}
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			{loader && <ProgressBar />}
			<PageAlerts pageId={'Edit_base'} />
			<Drawer
				label="Edit Business Objective"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default Edit
