import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import TextBox from '../../../components/ui/TextBox'
import {
	faBook,
	faUniversalAccess,
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import { UPDATE_PROFILE_MUTATION } from '../../../utils/GraphQl/UpdateProfileMutation'
import { updateSocialInfo } from '../../../store/AuthSlice/AuthSlice'
import { useDispatch, useSelector } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import PageAlerts from '../../PageAlerts'
import { logoutUser } from '../../../store/AuthSlice/AuthSlice'
import ProgressBar from '../../ProgressBar'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const SocialInfoDrawer = ({ show, setShow }) => {
	const USER = useSelector((state) => state.Auth)
	const [loader, setLoader] = useState(false)
	const authToken = USER?.token
	const socialinfo = USER?.userdata?.user?.user_data?.social_information
	const [showDrawer, setShowDrawer] = useState(false)
	const [overlay, setOverlay] = useState('')
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(false)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const dispatch = useDispatch()
	const [Formdata, setFormdata] = useState({
		linkedin: socialinfo?.linked_in,
		whatsapp: socialinfo?.whatsapp,
		github: socialinfo?.whatsapp,
		telegram: socialinfo?.telegram
	})
	const HANDLEFORMDATA = (field, value) => {
		setFormdata((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}

	const [update] = useMutation(UPDATE_PROFILE_MUTATION)

	const handleSubmit = async () => {
		try {
			setLoader(true)
			const response = await update({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						user_id: USER?.userdata?.user?.user_id,
						data: {
							telegram: Formdata.telegram,
							github: Formdata.github,
							whatsapp: Formdata.whatsapp,
							linked_in: Formdata.linkedin
						}
					}
				}
			})
			let data = await response.data
			let updateprofile = data.updateProfile
			if (updateprofile.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'updatesocialinfo',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'updatesocialinfo' }))
				}, 3000)
			} else if (
				updateprofile.status !== 200 &&
				updateprofile.errors.length > 0
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'updatesocialinfo',
						type: 'danger',
						message: updateprofile.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'updatesocialinfo',
						type: 'success',
						message: updateprofile.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					setShowDrawer(false)
					setScrollable(false)
					setScrollableFooter(false)
					setSize('md')
					setOverlay('')
					setBody(null)
					setShow(false)
					dispatch(updateSocialInfo(data.updateProfile.data))
					dispatch(removeAlerts({ pageId: 'updatesocialinfo' }))
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'updatesocialinfo',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	const FORM = (
		<form
			encType="multipart/form-data"
			className=" flex w-[350px] flex-col justify-start"
		>
			<>
				<div className="flex w-[350px] flex-col justify-start pr-1">
					<TextBox
						id="linked_in"
						name={'linked_in'}
						value={Formdata.linkedin}
						labelName={'LinkedIn'}
						onChange={(data) => HANDLEFORMDATA('linkedin', data)}
					/>
					<TextBox
						id="whatsapp"
						name={'whatsapp'}
						value={Formdata.whatsapp}
						labelName={'Whatsapp'}
						onChange={(data) => HANDLEFORMDATA('whatsapp', data)}
					/>
					<TextBox
						id="github"
						name={'github'}
						value={Formdata.github}
						labelName={'Github'}
						onChange={(data) => HANDLEFORMDATA('github', data)}
					/>
					<TextBox
						id="telegram"
						name={'telegram'}
						value={Formdata.telegram}
						labelName={'Telegram'}
						onChange={(data) => HANDLEFORMDATA('telegram', data)}
					/>
				</div>
			</>
		</form>
	)
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setShow(false)
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)

	useEffect(() => {
		setShowDrawer(show)
		setScrollable(show)
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', show)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [show])

	useEffect(() => {
		setBody(FORM)
	}, [show, Formdata])
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal ">{''}</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={() => handleSubmit()}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)
	return (
		<>
			<PageAlerts pageId={'updatesocialinfo'} />
			{loader && <ProgressBar />}
			<Drawer
				label="Social Info"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}

export default SocialInfoDrawer
