import React from 'react'
import Icon from '../../../components/ui/icon'
import { useSelector } from 'react-redux'
const SocialInfo = () => {
	const socialinfo = useSelector(
		(state) => state.Auth?.userdata?.user?.user_data?.social_information
	)
	const user = {
		linkedin: socialinfo?.linked_in,
		whatsapp: socialinfo?.whatsapp,
		github: socialinfo?.github,
		telegram: socialinfo?.telegram
	}
	return (
		<div>
			<div className="bg-[#f7f7f7] px-[15px] py-[10px]">
				<div className="multipleinputs grid grid-cols-12 ">
					<div className="col-span-12 sm:col-span-6 md:col-span-6">
						<div className="form-group">
							<div className="label flex gap-1">
								<label>LinkedIn</label>
								<Icon
									name={'social-linkedin-square'}
									width={'17px'}
									height={'17px'}
									className="icon-fill-grey300"
									viewBox={'0 0 23 23'}
								/>
							</div>
							<div className="text-display">
								<p>{user.linkedin}</p>
							</div>
						</div>
					</div>
					<div className="col-span-12  sm:col-span-6 md:col-span-6">
						<div className="form-group">
							<div className="label flex gap-1">
								<label> Whatsapp</label>
								<Icon
									name={'social-whatsapp'}
									width={'17px'}
									height={'17px'}
									className="icon-fill-grey300"
									viewBox={'0 0 23 23'}
								/>
							</div>
							<div className="text-display">
								<p>{user.whatsapp}</p>
							</div>
						</div>
					</div>
					<div className="col-span-12 sm:col-span-6 md:col-span-6">
						<div className="form-group">
							<div className="label flex gap-1">
								<label>Github</label>
								<Icon
									name={'social-github'}
									width={'17px'}
									height={'17px'}
									className="icon-fill-grey300"
									viewBox={'0 0 23 23'}
								/>
							</div>
							<div className="text-display">
								<p>{user.github}</p>
							</div>
						</div>
					</div>
					<div className="col-span-12 sm:col-span-6 md:col-span-6">
						<div className="form-group">
							<div className="label flex gap-1">
								<label className="">Telegram</label>
								<Icon
									name={'social-telegram'}
									width={'17px'}
									height={'17px'}
									className="icon-fill-grey300"
									viewBox={'0 0 23 23'}
								/>
							</div>
							<div className="text-display">
								<p>{user.telegram}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SocialInfo
