import { useState, useEffect } from 'react'
import DataTableWrapper from '../../../components/ui/DataTables'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import React from 'react'
import EditSubsidiaries from '../Subsidries'
import Button from '../../../components/ui/Buttons/Button'
import { faClose, faEdit } from '@fortawesome/free-solid-svg-icons'
import Subsidries from '../Subsidries'
const ExistingSubsidaries = ({ originalData, setSelectedParentsData }) => {
	const [Subsidiaries, setSubsidiaries] = useState([])
	const [selectedData, setSelectedData] = useState([])
	const [showEditSubsidiaries, setShowEditSubsidiaries] = useState(false)
	const [showSubsidries, setShowSubsidries] = useState(false)
	const dopdownOptions = ['All']
	useEffect(() => {
		const newSubsidaries = []
		if (Array.isArray(originalData)) {
			if (originalData.length > 0) {
				originalData?.forEach((item) => {
					item?.data?.corporate_structure?.subsidiaries?.forEach(
						(obj) => {
							if (
								!newSubsidaries.some(
									(subsidiary) => subsidiary.id === obj.id
								)
							) {
								newSubsidaries.push(obj)
							}
						}
					)
				})
				setSubsidiaries((prev) => {
					const filteredPrev = prev.filter(
						(prevObj) =>
							!newSubsidaries.some(
								(newObj) => newObj.id === prevObj.id
							)
					)
					return [...filteredPrev, ...newSubsidaries]
				})
			}
		}
	}, [originalData])

	const handleFilter = (rowData) => {
		setSelectedParentsData((prev) => {
			if (!prev[0]?.data?.corporate_structure?.subsidiaries) {
				return prev
			}
			const filteredSubsidiaries =
				prev[0]?.data?.corporate_structure?.subsidiaries.filter(
					(subsidiary) => subsidiary.id != rowData.id
				)
			const updatedFirstElement = {
				...prev[0],
				data: {
					...prev[0]?.data,
					corporate_structure: {
						...prev[0].corporate_structure,
						subsidiaries: filteredSubsidiaries
					}
				}
			}
			const newState = [updatedFirstElement, ...prev.slice(1)]
			let newSubsidaries = Subsidiaries.filter(
				(subsidiary) => subsidiary.id != rowData.id
			)
			setSubsidiaries(newSubsidaries)
			return newState
		})
	}
	const ActionBodyTemplate = (rowData) => {
		return (
			<div className="flex justify-between">
				<div className="">
					<IconButton
						type="button"
						className={
							'relative top-0.5 flex h-5 w-6 items-center justify-center rounded-sm border bg-transparent text-sm text-grey-200 hover:bg-transparent hover:text-grey-500'
						}
						onClick={(e) => {
							e.preventDefault()
							setSelectedData([{ ...rowData }])
							setShowEditSubsidiaries(true)
						}}
						children=""
						Icon={faEdit}
						size={'sm'}
					/>
				</div>
				<div>
					<IconButton
						type="button"
						className={
							'relative top-0.5 flex h-5 w-6 items-center justify-center rounded-sm border bg-transparent text-sm text-red-500 hover:bg-transparent hover:text-red-700'
						}
						onClick={(e) => {
							e.preventDefault()
							handleFilter(rowData)
						}}
						children=""
						Icon={faClose}
						size={'lg'}
					/>
				</div>
			</div>
		)
	}
	let COLUMNS = [
		{
			sortable: true,
			header: 'Name ',
			field: 'subsidiary_name',
			style: { width: '20%' },
			headerStyle: { width: '20%' }
		},

		// {
		// 	sortable: true,
		// 	header: 'Attachment',
		// 	field: 'subsidiary_attachment',
		// 	style: { width: '12%' },
		// 	headerStyle: { width: '12%' }
		// },
		{
			sortable: true,
			header: 'Attachment Type',
			field: 'subsidiary_doctype',
			style: { width: 'auto' },
			headerStyle: { width: 'auto' }
		},

		{
			// sortable: true,
			header: ' Action',
			// field: 'status',
			style: { width: '70px' },
			body: ActionBodyTemplate,
			headerStyle: { width: '70px' }
		}
	]

	const HandleData = (data) => {
		setSelectedData(data)
	}

	const HeaderButtons = ({ exportCSV }) => (
		<>
			<Button
				className="btn-primary btn-large mt-1 w-fit"
				children={'Add Subsidiaries'}
				onClick={() => setShowSubsidries(true)}
			/>
		</>
	)

	return (
		<div>
			<div className="h5 border-b pb-1">Subsidiaries</div>
			{showSubsidries && (
				<Subsidries
					setShowModal={setShowSubsidries}
					selectedData={selectedData}
					setSelectedData={setSelectedParentsData}
					type="add"
				/>
			)}
			{showEditSubsidiaries && (
				<EditSubsidiaries
					selectedData={originalData}
					type="edit"
					setSelectedData={setSelectedParentsData}
					setShowModal={setShowEditSubsidiaries}
					rowData={selectedData}
				/>
			)}
			<DataTableWrapper
				showHeader={true}
				paginator={false}
				products={Subsidiaries}
				HeaderButtons={HeaderButtons}
				options={dopdownOptions}
				columns={COLUMNS}
				onDataSelection={HandleData}
				selectedData={selectedData}
			/>
		</div>
	)
}
export default ExistingSubsidaries
