import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types' // Import PropTypes for type validation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
const Attachments = ({
	labelName,
	files,
	onSelect,
	onRemove,
	id,
	accept,
	capture,
	multiple,
	disabled,
	required,
	name
}) => {
	const [selectedFiles, setSelectedFiles] = useState(files)

	// selected files will be passed to the callback function(onSelect)
	const handleFileChange = (event) => {
		const files = Array.from(event.target.files)
		// setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
		onSelect(files)
	}

	// pass the file to callback function (onRemove)
	const handleRemove = (file) => {
		onRemove(file)
	}

	// remain in sync with data
	useEffect(() => {
		setSelectedFiles(files)
	}, [files])

	return (
		<>
			<div className="form-group">
				{labelName && <label className="label">{labelName}</label>}
				<div className="input-outer">
					<label htmlFor={id} className="btn btn-large btn-primary">
						Choose File
					</label>
					<input
						id={id}
						type="file"
						className="hidden"
						onChange={handleFileChange}
						multiple={multiple}
						accept={accept}
						capture={capture}
						disabled={disabled}
						required={required}
						name={name}
					/>
				</div>
				{selectedFiles.length > 0 && (
					<div>
						{selectedFiles.map((file, index) => (
							<div
								key={index}
								className="mt-[5px] flex items-center justify-between border border-solid border-[#eee] bg-[#f5f5f5]"
							>
								<div className="px-2 py-0.5">{file.name}</div>
								<div
									data-testid="cancelButton"
									className="flex h-[28PX] w-[28px] cursor-pointer items-center justify-center bg-[#dc3545] text-[#fff]"
									onClick={() => handleRemove(file)}
								>
									<FontAwesomeIcon icon={faTimes} />
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		</>
	)
}

// PropTypes declaration
Attachments.propTypes = {
	labelName: PropTypes.string,
	files: PropTypes.array.isRequired,
	onSelect: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired,
	accept: PropTypes.string,
	capture: PropTypes.oneOf(['user', 'environment']),
	multiple: PropTypes.bool,
	disabled: PropTypes.bool,
	required: PropTypes.bool
}

export default Attachments
