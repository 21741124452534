import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IconButton = ({
	className = 'btn-primary',
	Icon,
	children,
	disabled,
	onClick,
	size,
	btnsize = 'btn-large'
}) => {
	const disabledClass = '!cursor-not-allowed btn flex gap-1.5 ' + btnsize
	return (
		<button
			className={disabled ? disabledClass : className}
			onClick={onClick}
			disabled={disabled}
		>
			{Icon && <FontAwesomeIcon icon={Icon} size={size} />}
			{children}
		</button>
	)
}

IconButton.defaultProps = {
	className: 'primary-300',
	size: '1x'
}

export default IconButton

// Disable Btn
// import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// const IconButton = ({
// 	className = 'btn-primary',
// 	Icon,
// 	children,
// 	disabled,
// 	onClick,
// 	size,
// 	btnsize = 'btn-large',
// 	positionClass
// }) => {
// 	let combinedClassname='flex gap-1.5' +' '+className +" "+ btnsize+' '+ positionClass
// 	// const disabledClass = '!cursor-not-allowed btn flex gap-1.5 ' + btnsize
// 	const disabledClass =
// 		'!cursor-not-allowed !opacity-[0.7] ' + combinedClassname
// 	return (
// 		<button
// 		className={
// 			disabled ? disabledClass : combinedClassname + ' ' + positionClass
// 		}
// 			// className={disabled ? disabledClass : className}
// 			// className={className}
// 			onClick={onClick}
// 			disabled={disabled}
// 		>
// 			{Icon && <FontAwesomeIcon icon={Icon} size={size} />}
// 			{children}
// 		</button>
// 	)
// }

// IconButton.defaultProps = {
// 	className: 'primary-300',
// 	size: '1x'
// }

// export default IconButton
