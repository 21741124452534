import { gql } from '@apollo/client'
// Define your GraphQL mutation
export const LOGOUT_MUTATION = gql`
	mutation Login {
		logout {
			message
			data
			errors
		}
	}
`
