import React, { useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { Tooltip } from 'primereact/tooltip'
import { FilterMatchMode } from 'primereact/api'
import { InputText } from 'primereact/inputtext'
import '../../../assets/css/DataTables/index.css'
import SimpleDropDown from '../../Dropdowns/Simpledropdown'
const DataTableWrapper = React.forwardRef(
	(
		{
			paginator = true,
			showHeader = true,
			getexpandrows,
			onRowToggle,
			products,
			HeaderButtons,
			options,
			columns,
			onDataSelection,
			template,
			paginatorLeft,
			selectedData,
			className,
			handleRowExpansion,
			...props
		},
		tableRef
	) => {
		const [selectedColumn, setSelectedColumn] = useState('All')
		const [dropDownVal, setDropDownVal] = useState('')
		const [selectedProducts, setSelectedProducts] = useState(selectedData)
		const [expandedRows, setExpandedRows] = useState(null)
		const toast = useRef(null)
		const dt = useRef(null)
		// search the columns on user input
		const [filters, setFilters] = useState({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS }
		})
		const [globalFilterValue, setGlobalFilterValue] = useState('')
		const allowExpansion = (rowData) => {
			return rowData.expand_data.length > 0
		}
		// download columns data
		const exportCSV = (selectionOnly) => {
			// let data=dt.current.props.selection;
			console.log('')
		}
		// search items on user input
		const onGlobalFilterChange = (event) => {
			const value = event.target.value
			setFilters((prevFilters) => {
				return {
					...prevFilters,
					global: {
						value: value,
						matchMode: FilterMatchMode.CONTAINS
					}
				}
			})
			setGlobalFilterValue(value)
			filterColumn(value, selectedColumn)
		}
		const filterColumn = (value, field) => {
			if (value && value.trim() !== '') {
				setTimeout(() => {
					if (field !== 'All') {
						const filterValue = value ? value.toLowerCase() : ''
						dt.current.filter(
							filterValue,
							field.toLowerCase(),
							value ? 'contains' : 'equals'
						)
					}
				}, 200)
			}
		}
		// this is for css
		useEffect(() => {
			const svgElement = document.querySelector(
				'.p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon svg'
			)
			if (svgElement) {
				svgElement.setAttribute('stroke-width', '2')
			}
		}, [])
		// search the value in dropdown
		const handleChange = (value) => {
			setDropDownVal(value)
			setSelectedColumn(value)
		}
		// pass data to callback function
		const HandleProduct = (event) => {
			onDataSelection(event.value)
		}

		useEffect(() => {
			setSelectedProducts(selectedData)
		}, [selectedData])

		const renderHeader = () => {
			return (
				<div className="flex flex-wrap justify-between gap-y-4 md:flex-col lg:flex-row">
					<div className="w-full md:w-full md:flex-wrap lg:w-auto">
						<HeaderButtons exportCSV={exportCSV} />
					</div>
					<div className="table-search flex w-full items-center md:w-full lg:w-auto">
						<label
							className="label"
							style={{ margin: '0 10px 0 0' }}
						>
							Search :
						</label>
						<div className="search-textbox">
							<div className="input-outer">
								<InputText
									type="search"
									className="form-control"
									placeholder="Search..."
									name=""
									value={globalFilterValue}
									onChange={onGlobalFilterChange}
									onInput={(e) =>
										filterColumn(
											e.target.value,
											selectedColumn
										)
									}
								/>
							</div>
						</div>
						<div className="search-dropdown">
							<div className="input-outer">
								<SimpleDropDown
									label=""
									options={options}
									Margin={0}
									onChange={handleChange}
									value={dropDownVal}
								/>
							</div>
						</div>
					</div>
				</div>
			)
		}
		React.useImperativeHandle(
			tableRef,
			() => ({
				getColumnsInfo: () => {
					return dt.current
				}
			}),
			[]
		)

		const rowToggle = (e) => {
			setExpandedRows(e.data)
			getexpandrows(e.data)
		}
		return (
			<div className="border">
				<Toast ref={toast} />
				<Tooltip target=".export-buttons>button" position="bottom" />
				<DataTable
					tableStyle={{ width: '100%' }}
					ref={dt}
					expandedRows={expandedRows}
					onRowToggle={rowToggle}
					rowExpansionTemplate={handleRowExpansion}
					paginatorTemplate={template}
					paginatorLeft={paginatorLeft}
					rows={10}
					value={products}
					selection={selectedProducts}
					paginator={paginator ? paginator : null}
					onSelectionChange={(e) => HandleProduct(e)}
					header={showHeader ? renderHeader() : null}
					dataKey="id"
					scrollable
					filters={filters}
					className={className}
					{...props}
				>
					{columns.map((column, index) =>
						!column?.isHidden ||
						(column?.isHidden && column?.isHidden === false) ? (
							<Column
								key={index}
								expander={column?.expander && allowExpansion}
								selectionMode={column?.selectionMode}
								sortable={column?.sortable}
								header={column?.header}
								field={column?.field}
								style={column?.style}
								headerStyle={column?.headerStyle}
								bodyStyle={{
									textAlign: column?.position ?? 'left'
								}}
								body={column?.body}
							/>
						) : null
					)}
				</DataTable>
			</div>
		)
	}
)
export default DataTableWrapper
