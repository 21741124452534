import { createSlice } from '@reduxjs/toolkit'
const InitialState = {
	path: []
}
const settingsSlice = createSlice({
	name: 'settings page slice',
	initialState: InitialState,
	reducers: {
		addPage: (state, action) => {
			state.path = action.payload
		},
		removePage: (state, action) => {
			state.path = []
		}
	}
})
export const { addPage, removePage } = settingsSlice.actions
export const settingsSliceReducer = settingsSlice.reducer
