import React, { useEffect } from 'react'
import RegisterSettings from '../../RegisterSettings'
import ContactUsSettings from '../../ContactUsSettings'
import LoginSettings from '../../LoginSettings'
import FileUploadSettings from '../../FIleUploadSettings'
import ExpireMagicLink from '../../ExpieryMagicLink'
import EmailServer from '../../EmailServer'
import EmailTemplates from '../../EmailTemplates'
import AdditionalFieldsAdded from '../../AdditionalFieldsAdded'
import ListMapping from '../../ListMapping'
import ListPage from '../../ListPage'

const GlobalComponent = ({ globalPathsArray }) => {
	useEffect(() => {
		const handleScrollPosition = () => {
			if (globalPathsArray && globalPathsArray.length > 0) {
				const scrollPosition = window.pageYOffset
				document.body.classList.add('scroll-disabled')
				document.body.style.top = `-${scrollPosition}px`
			} else {
				document.body.classList.remove('scroll-disabled')
				const scrollPosition = parseInt(
					document.body.style.top || '0',
					10
				)
				document.body.style.top = ''
				window.scrollTo(0, -scrollPosition)
			}
		}

		handleScrollPosition()
	}, [globalPathsArray])

	return (
		<>
			{globalPathsArray?.includes('/registersettings') && (
				<RegisterSettings />
			)}
			{globalPathsArray?.includes('/contact-settings') && (
				<ContactUsSettings />
			)}
			{globalPathsArray?.includes('/loginsettings') && <LoginSettings />}
			{globalPathsArray?.includes('/fileupload') && (
				<FileUploadSettings />
			)}
			{globalPathsArray?.includes('/magiclinksettings') && (
				<ExpireMagicLink />
			)}
			{globalPathsArray?.includes('/emailserver') && <EmailServer />}
			{globalPathsArray?.includes('/emailtemplates') && (
				<EmailTemplates />
			)}
			{globalPathsArray?.includes('/additional-fields') && (
				<AdditionalFieldsAdded />
			)}
			{globalPathsArray?.includes('/list-mapping') && <ListMapping />}
			{globalPathsArray?.includes('/listpage') && <ListPage />}
		</>
	)
}

export default GlobalComponent
