import { gql } from '@apollo/client'
export const RESET_USER_PASSWORD_MUTATION = gql`
	mutation ResetPasswordAdmin($input: resetPasswordAdminInput!) {
		resetPasswordAdmin(input: $input) {
			status
			message
			errors
		}
	}
`
