import React, { useState } from 'react'
import Modal from '../../components/ui/modals'
import TextBox from '../../components/ui/TextBox'
import { useMutation } from '@apollo/client'
import { UPDATE_PROFILE_MUTATION } from '../../utils/GraphQl/UpdateProfileMutation'
import PageAlerts from '../PageAlerts'
import { logoutUser, updateUserExpiry } from '../../store/AuthSlice/AuthSlice'
import { useDispatch, useSelector } from 'react-redux'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import SimpleDropDown from '../../components/Dropdowns/Simpledropdown'
import DatePicker from '../../components/ui/Datepicker'
import IconButton from '../../components/ui/Buttons/Icon-button'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
const PopUpDocUpdate = () => {
	const DATA = useSelector((state) => state.Auth)
	const identitfication_documents = [
		'Passport',
		'National ID',
		'Tax Number',
		'Corporate Registration'
	]
	const userdata = useSelector((state) => state.Auth)
	let user_details = userdata?.userdata?.user?.user_data
	const [showModal, setShowModal] = useState(true)
	const dispatch = useDispatch()
	const initialFormData = {
		identification_document:
			user_details.personal_information.identification_document,
		id_number: user_details.personal_information.id_number,
		expiry_date: user_details.personal_information.expiry_date
	}
	const [updateprofile] = useMutation(UPDATE_PROFILE_MUTATION)
	const [formdata, setFormdata] = useState(initialFormData)

	const SubmitForm = async () => {
		try {
			const response = await updateprofile({
				context: {
					headers: {
						authorization: DATA?.token
					}
				},
				variables: {
					input: {
						user_id: DATA?.userdata?.user?.user_id,
						data: formdata
					}
				}
			})
			const data = response.data
			if (data.updateProfile.status === 200) {
				dispatch(
					addAlerts({
						pageId: 'popup',
						type: 'success',
						message: 'Profile updated successfully',
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(updateUserExpiry(data.updateProfile.data))
				}, 3000)
			} else if (data.updateProfile.status === 400) {
				dispatch(
					addAlerts({
						pageId: 'popup',
						type: 'danger',
						message: data.updateProfile.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateProfile.status === 401) {
				dispatch(
					addAlerts({
						pageId: 'popup',
						type: 'danger',
						message: 'Session expired please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'popup' }))
				})
			}
		} catch (err) {
			// console.error('Error:', err)
			dispatch(
				addAlerts({
					pageId: 'popup',
					type: 'danger',
					message: 'Something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const HANDLEFIELDS = (key, value) => {
		setFormdata({
			...formdata,
			[key]: value
		})
	}
	const handleSubmit = () => {
		for (const field in formdata) {
			if (formdata[field] === '') {
				dispatch(
					addAlerts({
						pageId: 'popup',
						type: 'danger',
						message: 'Please filla all fields',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				return
			}
			if (field === 'expiry_date') {
				let expiry_date = formdata[field]
				expiry_date = new Date(expiry_date).getTime()
				let today_date = new Date().getTime()
				if (expiry_date <= today_date) {
					dispatch(
						addAlerts({
							pageId: 'popup',
							type: 'danger',
							message: 'Expiry Date Must be greater than today',
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
					return
				}
			}
		}
		return SubmitForm()
	}
	// const handleModalClose = () => {
	// 	if (showDashboard) {
	// 	}
	// }

	const footer = (
		<>
			<div className="modal-footer flex  items-center justify-between border-t  ">
				<h5 className="invisible font-normal">{'T'}</h5>
				<div className="flex items-center justify-end gap-[3px]">
					<IconButton
						Icon={faSave}
						className="btn-success btn-large flex gap-1.5"
						type="button"
						onClick={handleSubmit}
						children="Save Changes"
					/>
				</div>
			</div>
		</>
	)

	const [currentDate, setCurrentDate] = useState(
		initialFormData.expiry_date || null
	)
	const HandleDateChange = (date) => {
		setCurrentDate(date)
		HANDLEFIELDS('expiry_date', date)
	}

	const xlBody = (
		<form className="min-w-[400px]">
			<SimpleDropDown
				label="Identification Document"
				value={formdata.identification_document}
				onChange={(value) =>
					HANDLEFIELDS('identification_document', value)
				}
				options={identitfication_documents}
			/>
			<TextBox
				id={'id_number'}
				name={'id_number'}
				value={formdata.id_number}
				labelName={'ID Number'}
				onChange={(data) => HANDLEFIELDS('id_number', data)}
				type={'text'}
			/>
			<DatePicker
				labelName="Expiry Date"
				onDateChange={(date) => HandleDateChange(date)}
				currentDate={currentDate}
			/>
		</form>
	)

	const closeHandler = () => {}
	return (
		<>
			<PageAlerts pageId={'popup'} />
			<div>
				<Modal
					modalSize={'xl'}
					title={'Please Update Doc Details'}
					body={xlBody}
					onClose={() => closeHandler}
					overLay={'custom'}
					showModal={showModal}
					className={'!z-[1000]'}
					scrollable={true}
					Footer={footer}
				/>
			</div>
		</>
	)
}
export default PopUpDocUpdate
