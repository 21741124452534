import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom' // Import useLocation from react-router-dom
import { useMutation } from '@apollo/client'
import { APPROVE_MAGICLINK_MUTATION } from '../../utils/GraphQl/MagicLinkMutation'
import { useDispatch } from 'react-redux'
import { loginUser } from '../../store/AuthSlice/AuthSlice'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
const MagicLink = () => {
	const [approveUser] = useMutation(APPROVE_MAGICLINK_MUTATION)
	const [loader, setLoader] = useState(false)
	const location = useLocation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	// Extract the token from the URL using URLSearchParams
	const token = new URLSearchParams(location.search).get('token')
	useEffect(() => {
		handleVerification()
	}, [])
	const handleVerification = async () => {
		setLoader(true)
		try {
			const response = await approveUser({
				variables: {
					input: {
						token: token
					}
				}
			})
			let data = await response.data

			if (data.approveMagicLink.status === 200) {
				setLoader(false)
				dispatch(loginUser(data.approveMagicLink.data))
				navigate('/')
			}
			if (data.approveMagicLink.status === 400) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'approvelink',
						type: 'danger',
						message: data.approveMagicLink.errors.map((msg) => msg),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.approveMagicLink.status === 429) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'approvelink',
						type: 'danger',
						message: data.approveMagicLink.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'approvelink' }))
					navigate('/sendmagiclink')
				}, 3000)
			} else if (data.approveMagicLink.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'approvelink',
						type: 'danger',
						message: data.approveMagicLink.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.approveMagicLink.status !== 200) {
				setLoader(false)
				return
			}
		} catch (error) {
			console.error(' failed', error)
		}
	}
	return (
		<div className="bg-primary200 flex h-screen w-full items-center justify-center">
			{/* <div className="flex h-40 w-40 items-center justify-center rounded-full border-2 border-solid border-green-400 "></div> */}
		</div>
	)
}
export default MagicLink
