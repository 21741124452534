import React from 'react'
import Icon from '../../components/ui/icon'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logoutUser } from '../../store/AuthSlice/AuthSlice'
import { useApolloClient, useMutation } from '@apollo/client'
import { LOGOUT_MUTATION } from '../../utils/GraphQl/LogoutMutation'
const ProfileSection = ({ setShowProfile }) => {
	const authToken = useSelector((state) => state.Auth?.token)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const apolloClient = useApolloClient()
	const [logoutMutation] = useMutation(LOGOUT_MUTATION, {
		onCompleted: () => {
			apolloClient.clearStore()
		}
	})
	const HandleLogout = () => {
		logoutMutation({
			context: {
				headers: {
					authorization: authToken
				}
			}
		})
			.then((response) => {
				// console.log('Logout response:', response)
			})
			.catch((error) => {
				// console.error('Logout error:', error)
			})
		dispatch(logoutUser())
		navigate('/')
	}

	return (
		<>
			<nav className="nav">
				<Link
					to="/userprofile"
					className="text-sm"
					onClick={() => setShowProfile(false)}
				>
					<Icon
						className="icon-fill-grey200 mr-3"
						name="line-profile-card"
						width={'20px'}
						height={'20px'}
						viewBox={'0 0 27 23'}
					/>{' '}
					View Profile
				</Link>
				<Link
					to="/changepassword"
					className="text-sm"
					onClick={() => setShowProfile(false)}
				>
					<Icon
						className="icon-fill-grey200 mr-3"
						name="line-lock-dot"
						width={'20px'}
						height={'20px'}
						viewBox={'0 0 27 23'}
					/>
					Change Password
				</Link>
				<Link
					to="/accountsetting"
					className="text-sm"
					onClick={() => setShowProfile(false)}
				>
					<Icon
						className="icon-fill-grey200 mr-3"
						name="line-user-settings"
						width={'20px'}
						height={'20px'}
						viewBox={'0 0 27 23'}
					/>
					Account Settings
				</Link>
			</nav>

			<nav className="nav">
				<Link
					to="/helpcenter"
					className="text-sm"
					onClick={() => setShowProfile(false)}
				>
					<Icon
						className="icon-fill-grey200 mr-3"
						name="line-help"
						width={'20px'}
						height={'20px'}
						viewBox={'0 0 27 23'}
					/>
					Help Center
				</Link>
				<Link className="text-sm" onClick={HandleLogout}>
					<Icon
						className="icon-fill-grey200 mr-3"
						name="line-signout"
						width={'20px'}
						height={'20px'}
						viewBox={'0 0 27 23'}
					/>{' '}
					Log Out
				</Link>
			</nav>
		</>
	)
}
export default React.memo(ProfileSection)
