import { createSlice } from '@reduxjs/toolkit'
const AlertSlice = createSlice({
	name: 'alerts',
	initialState: {},
	reducers: {
		addAlerts: (state, action) => {
			const { pageId, type, message, icon, autoclose } = action.payload
			if (!state[pageId]) {
				state[pageId] = []
			}
			const newAlert = {
				id: new Date().getTime(),
				type,
				message,
				icon,
				autoclose
			}
			state[pageId].unshift(newAlert)
		},
		removeAlerts: (state, action) => {
			const { pageId, notificationId } = action.payload
			if (state[pageId] && notificationId) {
				state[pageId] = state[pageId].filter(
					(notification) => notification.id !== notificationId
				)
			} else if (state[pageId]) {
				state[pageId] = []
			}
		}
	}
})
export const { addAlerts, removeAlerts, clearAllAlerts } = AlertSlice.actions
export default AlertSlice.reducer
