import React from 'react'
import IconButton from '../Buttons/Icon-button'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import classes from '../../../assets/css/Drawers/index.module.css'
const DrawerHeader = (props) => {
	return (
		<>
			<div className={classes.drawerHeader}>
				<h5 id="drawer-label">{props.label ? props.label : 'TEST'}</h5>
				<IconButton
					type="button"
					className={classes.drawerHeaderBtn}
					onClick={() => props.onClose()}
					children=""
					Icon={faClose}
					size={'lg'}
				/>
			</div>
		</>
	)
}

export default React.memo(DrawerHeader)
