import React, { useEffect, useState } from 'react'
import Modal from '../../components/ui/modals'
import PageAlerts from '../PageAlerts'
import { useSelector } from 'react-redux'
import IconButton from '../../components/ui/Buttons/Icon-button'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import List from '../../components/ui/List'

const PopUpProfileUpdate = () => {
	const DATA = useSelector((state) => state.Auth)
	const [listData, setListData] = useState([])
	const FIELDS = DATA?.userdata?.settings?.mandatory_remaining_fields
	const [showModal, setShowModal] = useState(true)
	const Navigate = useNavigate()
	useEffect(() => {
		FIELDS?.forEach((field) => {
			let str = field.replace(/_/g, ' ')
			let words = str.split(' ')
			let capitalizedWords = words.map(
				(word) => word.charAt(0).toUpperCase() + word.slice(1)
			)
			let capitalizedStr = capitalizedWords.join(' ')
			setListData((prevListData) => [
				...prevListData,
				{ text: capitalizedStr }
			])
		})
	}, [])

	const handleSubmit = () => {
		Navigate('/userProfile')
		setShowModal(false)
	}

	const footer = (
		<>
			<div className="modal-footer flex  items-center justify-between border-t  ">
				<h5 className="invisible font-normal">{'T'}</h5>
				<div className="flex items-center justify-end gap-[3px]">
					<IconButton
						Icon={faSave}
						className="btn-success btn-large flex gap-1.5"
						type="button"
						onClick={handleSubmit}
						children="Update Profile"
					/>
				</div>
			</div>
		</>
	)

	const xlBody = (
		<div className="w-[350px]">
			<List
				items={listData}
				// title="Unordered List"
				description="Fill All The Fields Mentioned Below"
				listType="ul"
			/>
		</div>
	)

	const closeHandler = () => {}
	return (
		<>
			<PageAlerts pageId={'popup'} />
			<div>
				<Modal
					modalSize={'lg'}
					title={'Please Update the required fields'}
					body={xlBody}
					onClose={() => closeHandler}
					overLay={'custom'}
					showModal={showModal}
					className={'!z-[1000]'}
					maxHeight={'250px'}
					minHeight={'250px'}
					scrollable={true}
					Footer={footer}
				/>
			</div>
		</>
	)
}
export default PopUpProfileUpdate
