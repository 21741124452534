import React, { useEffect, useState } from 'react'
import DataTableWrapper from '../../components/ui/DataTables'
import IconButton from '../../components/ui/Buttons/Icon-button'
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons'
import Button from '../../components/ui/Buttons/Button'
import { classNames } from 'primereact/utils'
import Form from './Form'
import image1 from '../../assets/img/Arch-5-15.svg'
import image2 from '../../assets/img/Arch-5-11.svg'
import image3 from '../../assets/img/Arch-5-12.svg'
import image4 from '../../assets/img/Arch-5-13.svg'
import image5 from '../../assets/img/Arch-5-14.svg'
import EditColumns from './EditColumns'
import ArrangeOrder from './ArrangeOrder'
import { useDispatch } from 'react-redux'
import { addAlerts } from '../../store/AlertSlice'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import PageAlerts from '../PageAlerts'
import DashboardTable from './DashboardTable'
import Card from '../../components/ui/Card'
import AddRisk from './AddRisk'
let DUMMY_PRODUCTS = [
	{
		id: '1000',
		risk_heading: 'Vulnerability to Phishing Attacks in Cybersecurity',
		risk_description:
			'Phishing attacks represent a significant and pervasive cybersecurity threat, exploiting human factors to breach organizational defenses. By masquerading as legitimate entities, attackers deceive individuals into divulging sensitive information, such as login credentials, financial data, or personal identification details. The consequences of successful phishing attacks can be severe, ranging from data breaches and financial loss to compromised security and reputational damage.',
		risk_function: 'Enterprise Risk and Certifications',
		risk_owner: 'Tarun gupta',
		inherent_rating: 'High',
		position: 0,
		expand_data: [
			{
				label: 'Risk Heading',
				data: 'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.'
			},
			{
				label: 'Risk Description',
				data: 'Morbi nec velit at mi eleifend vulputate nec a libero.'
			},
			{
				label: 'Risk Function',
				data: 'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.'
			},
			{ label: 'Risk Owner', data: 'John Doe' }
		],
		treatment_plan: [],
		image: image1,
		image1: image2,
		residual_rating: 'Medium'
	},
	{
		id: '1001',
		risk_heading:
			'introduction of new rules or legislation and will effect from ',
		risk_description:
			'Sed fermentum sollicitudin enim, a volutpat ante eleifend vitae.',
		risk_function: 'Function 2',
		risk_owner: 'Jane Smith',
		inherent_rating: 'Medium',
		position: 1,
		expand_data: [
			{
				label: 'Risk Heading',
				data: 'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.'
			},
			{
				label: 'Risk Description',
				data: 'Morbi nec velit at mi eleifend vulputate nec a libero.'
			},
			{
				label: 'Risk Function',
				data: 'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.'
			},
			{ label: 'Risk Owner', data: 'John Doe' }
		],
		treatment_plan: [],
		image: image2,
		image1: image1,
		residual_rating: 'Low'
	},
	{
		id: '1002',
		risk_heading:
			'The impact of political decisions and changes in regulation',
		risk_description:
			'Quisque a velit eget nunc viverra lacinia. Sed vel nunc sem.',
		risk_function: ' stay positive ',
		risk_owner: 'David Brown',
		inherent_rating: 'Low',
		position: 2,
		expand_data: [
			{
				label: 'Risk Heading',
				data: 'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.'
			},
			{
				label: 'Risk Description',
				data: 'Morbi nec velit at mi eleifend vulputate nec a libero.'
			},
			{
				label: 'Risk Function',
				data: 'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.'
			},
			{ label: 'Risk Owner', data: 'John Doe' }
		],
		treatment_plan: [],
		image1: image4,
		image: image3,
		residual_rating: 'High'
	},
	{
		id: '1003',
		risk_heading:
			'Cultural risk involves the effects of cultural diversity on a group of people ',
		risk_description:
			'Vivamus ut nisi in lectus vehicula tincidunt. Curabitur nec lacus ac magna vehicula tempor.',
		risk_function: 'Function 3',
		risk_owner: 'Sophia Garcia',
		inherent_rating: 'Medium',
		position: 3,
		treatment_plan: [],
		expand_data: [
			{
				label: 'Risk Heading',
				data: 'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.'
			},
			{
				label: 'Risk Description',
				data: 'Morbi nec velit at mi eleifend vulputate nec a libero.'
			},
			{
				label: 'Risk Function',
				data: 'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.'
			},
			{ label: 'Risk Owner', data: 'John Doe' }
		],
		image1: image5,
		image: image4,
		residual_rating: 'Medium'
	},
	{
		id: '1004',
		risk_heading: 'Asset-specific or company-specific uncertainty',
		risk_description:
			'Vivamus ut nisi in lectus vehicula tincidunt. Curabitur nec lacus ac magna vehicula tempor.',
		risk_function: 'Function 4',
		risk_owner: 'Sophia Garcia',
		inherent_rating: 'Medium',
		position: 4,
		treatment_plan: [],
		expand_data: [
			{
				label: 'Risk Heading',
				data: 'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.'
			},
			{
				label: 'Risk Description',
				data: 'Morbi nec velit at mi eleifend vulputate nec a libero.'
			},
			{
				label: 'Risk Function',
				data: 'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.'
			},
			{ label: 'Risk Owner', data: 'John Doe' }
		],
		image1: image3,
		image: image5,
		residual_rating: 'Medium'
	}
]
const PRODUCTS = JSON.parse(localStorage.getItem('products')) ?? DUMMY_PRODUCTS
PRODUCTS.forEach((element, key) => {
	if (!element['treatment_plan']) {
		element['treatment_plan'] = []
	}
	PRODUCTS[key] = element
})
const imageBodyTemplate = (rowData) => {
	// console.log("Rowdata ",rowData)
	// console.log(typeof(rowData.image))
	return (
		<img
			src={rowData.image}
			alt="Risk"
			height={'66px'}
			width={'66px'}
			className="ml-[20px]"
		/>
	)
}
const imageBodyTemplate1 = (rowData) => {
	return (
		<img
			src={rowData.image1}
			alt="Risk"
			height={'66px'}
			width={'66px'}
			className="ml-[20px]"
		/>
	)
}
const originalColumns = [
	{ style: { maxWidth: '2%' }, expander: true, field: 'expander' },
	{
		selectionMode: 'multiple',
		isHidden: false,
		position: 'left',
		field: 'checkbox',
		headerStyle: { width: '2%' },
		style: { width: '2%' },
		className: 'chk-dt chk-primary',
		id: 'primary'
	},
	{
		sortable: true,
		isHidden: false,
		position: 'left',
		header: 'Risk Id',
		field: 'id',
		style: { width: '6%', minWidth: '6%' },
		headerStyle: { width: '6%', minWidth: '6%' }
	},
	{
		isHidden: false,
		position: 'left',
		sortable: true,
		header: 'Risk Heading',
		field: 'risk_heading',
		style: { width: '25%' },
		headerStyle: { width: '25%' }
	},
	{
		sortable: true,
		isHidden: false,
		position: 'left',
		header: 'Risk Description',
		field: 'risk_description',
		style: { width: '25%' },
		headerStyle: { width: '25%' }
	},
	{
		sortable: true,
		isHidden: false,
		position: 'left',
		header: 'Risk Function',
		field: 'risk_function',
		style: { width: '10%' },
		headerStyle: { width: '10%' }
	},
	{
		sortable: true,
		isHidden: false,
		position: 'left',
		header: ' Risk Owner',
		field: 'risk_owner',
		style: { width: '10%' },
		headerStyle: { width: '10%' }
	},
	{
		sortable: true,
		isHidden: false,
		position: 'center',
		header: 'Inherent Rating',
		field: 'inherent_rating',
		body: imageBodyTemplate,
		style: { width: '10%', minWidth: '10%' },
		headerStyle: { width: '10%', minWidth: '10%' }
	},
	{
		sortable: true,
		isHidden: false,
		position: 'center',
		header: 'Residual Rating',
		field: 'residual_rating',
		body: imageBodyTemplate1,
		style: { width: '10%', minWidth: '10%' },
		headerStyle: { width: '10%', minWidth: '10%' }
	}
]
let columnsData = JSON.parse(localStorage.getItem('columns')) ?? originalColumns
columnsData = columnsData.map((column) => {
	if (column.field === 'inherent_rating') {
		column.body = imageBodyTemplate1
	} else if (column.field === 'residual_rating') {
		column.body = imageBodyTemplate
	}
	return column
})
const RiskManagement = () => {
	if (Array.isArray(PRODUCTS)) {
		let positionExists = false
		PRODUCTS.some((product) => {
			if (product.hasOwnProperty('position')) {
				positionExists = true
				return true
			}
			return false
		})

		if (!positionExists) {
			localStorage.setItem('products', JSON.stringify(DUMMY_PRODUCTS))
		}
	}
	const [OriginalData, setOriginalData] = useState(PRODUCTS)
	const [selectedData, setSelectedData] = useState([])
	const [isEdit, setIsEdit] = useState(false)
	const [isArrangeOrder, setIsArrangeOrder] = useState(false)
	const [addRisk, setAddRisk] = useState(false)
	const dopdownOptions = ['All']
	const [showDrawer, setShowDrawer] = useState(false)
	const [columns, setColumns] = useState([...columnsData])
	const dispatch = useDispatch()
	useEffect(() => {
		const handleScrollPosition = () => {
			if (addRisk || isArrangeOrder || isEdit || showDrawer) {
				const scrollPosition = window.pageYOffset
				document.body.classList.add('scroll-disabled')
				document.body.style.top = `-${scrollPosition}px`
			} else {
				document.body.classList.remove('scroll-disabled')
				const scrollPosition = parseInt(
					document.body.style.top || '0',
					10
				)
				document.body.style.top = ''
				window.scrollTo(0, -scrollPosition)
			}
		}
		setTimeout(() => {
			document.body.classList.remove('scroll-disabled')
		}, 300)

		handleScrollPosition()
	}, [addRisk, isArrangeOrder, isEdit, showDrawer])
	const HandleData = (data) => {
		const toggleClassAndBorder = (tr) => {
			const hasDatatableRowExpansion = tr.classList.contains(
				'p-datatable-row-expansion'
			)
			const previousSibling = tr.previousElementSibling

			if (hasDatatableRowExpansion && previousSibling) {
				// previousSibling.style.border = 'none'
				previousSibling.classList.add('TOGGLE')
				// tr.classList.add('TOGGLE')
			} else {
				tr.classList.remove('TOGGLE')
			}
		}
		const trElements = document.querySelectorAll(
			'.risk-management-wrapper tbody tr'
		)
		trElements.forEach((tr) => {
			setTimeout(() => {
				toggleClassAndBorder(tr)
			}, 10)
		})
		setSelectedData(data)
	}
	const handleEditColumns = () => {
		if (selectedData.length < 1) {
			dispatch(
				addAlerts({
					pageId: 'riskmanagement',
					type: 'danger',
					message: 'Please select any row ',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (selectedData.length > 1) {
			dispatch(
				addAlerts({
					pageId: 'riskmanagement',
					type: 'danger',
					message: 'Please select only one row ',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else {
			setIsEdit((prev) => !prev)
		}
	}
	const HeaderButtons = ({ exportCSV }) => (
		<div className="flex items-center justify-start gap-[3px]">
			<div className="flex flex-wrap items-center justify-start  gap-[3px]">
				<IconButton
					onClick={() => setShowDrawer((prev) => !prev)}
					Icon={faPlus}
					size="sm"
					className="btn btn-large btn-primary flex gap-1.5"
				>
					Layout
				</IconButton>
				<IconButton
					onClick={() => setAddRisk((prev) => !prev)}
					Icon={faPlus}
					size="sm"
					className="btn btn-large btn-primary flex gap-1.5"
				>
					Add Risk
				</IconButton>
				<IconButton
					disabled={selectedData.length === 0}
					onClick={handleEditColumns}
					Icon={faPen}
					size={'sm'}
					className="btn btn-large btn-edit flex gap-1.5"
				>
					Edit
				</IconButton>
				<IconButton
					disabled={OriginalData.length === 0}
					onClick={() => setIsArrangeOrder((prev) => !prev)}
					Icon={faPen}
					size={'sm'}
					className="btn btn-large btn-edit flex gap-1.5"
				>
					Arrange Order
				</IconButton>
			</div>
		</div>
	)
	const template1 = {
		layout: 'CustomPaginator PrevPageLink PageLinks NextPageLink',
		CustomPaginator: (options) => {
			return (
				<span style={{ marginRight: 'auto' }}>
					Showing {options.first + 1} to{' '}
					{options.first + options.rows} of {options.totalRecords}{' '}
					entries
				</span>
			)
		},
		PrevPageLink: (options) => {
			options.className = classNames(options.className).replace(
				'p-disabled',
				''
			)
			return (
				<Button
					type={'button'}
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={options.onClick}
					// disabled={options.disabled}
					children={'Previous'}
				/>
			)
		},
		NextPageLink: (options) => {
			return (
				<Button
					type="button"
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={(event) => {
						// if (!stopfetching) {
						// 	fetchAgain()
						// 	setLimit((prev) => prev + 10)
						// }
						options.onClick(event)
					}}
					// disabled={options.disabled}
					children={'Next'}
				/>
			)
		},

		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page &&
					options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, {
					'p-disabled': true
				})

				return (
					<span className={className} style={{ userSelect: 'none' }}>
						...
					</span>
				)
			}
			const isCurrentPage = options.page === options.currentPage
			const linkClassName = classNames(options.className, {
				p: isCurrentPage
			})
			const linkStyle = isCurrentPage
				? {
						backgroundColor: '#7252d3',
						borderRadius: '0',
						color: '#fff'
					}
				: {
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: '#007bff'
					}
			return (
				<Button
					type="button"
					className={linkClassName}
					style={linkStyle}
					onClick={(event) => {
						// if (!stopfetching) {
						// 	fetchAgain()
						// 	setLimit((prev) => prev + 10)
						// }
						options.onClick(event)
					}}
					children={`${options.page + 1}`}
				/>
			)
		}
	}
	const paginatorLeft = (options) => {
		return (
			<span style={{ marginRight: 'auto' }}>
				Showing {options.first + 1} to{' '}
				{Math.min(options.first + options.rows, options.totalRecords)}{' '}
				entries from {options.totalRecords}
			</span>
		)
	}
	const handleRowExpansion = (data) => {
		const hiddenColumns = columns.filter(
			(column) => column.isHidden === true
		)
		const hiddenKeys = hiddenColumns.map((column) => column.field)
		return (
			<div className="pb-3 pl-[47px] pr-3 pt-0">
				<div className="w-full border border-b-0">
					<div className="head flex w-full border-b ">
						<div className="flex w-[50%] ">
							<div className="set-border w-[150px] px-2.5 py-[11.5px] font-semibold">
								Label
							</div>
							<div className="w-full px-2.5 py-[11.5px] font-semibold ">
								Data
							</div>
						</div>
						<div className="flex w-[50%] border-l">
							<div className="set-border w-[150px] px-2.5 py-[11.5px] font-semibold">
								Label
							</div>
							<div className="w-full px-2.5 py-[11.5px] font-semibold ">
								Data
							</div>
						</div>
					</div>
					<div className="flex w-full flex-wrap ">
						{data.expand_data.map((item) => (
							<div
								key={item.label}
								className="custom-table-body table-body flex w-[50%] cursor-pointer border-b"
								onClick={() => console.log(item.label)}
							>
								<div className="set-border w-[150px] px-2.5  py-[11.5px] font-semibold capitalize">
									{item.label}
								</div>
								<div className="w-full px-2.5 py-[11.5px]">
									{item.data}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		)
	}
	const [expandedRows, setExpandedRows] = useState([])

	useEffect(() => {
		const toggleClassAndBorder = (tr) => {
			const hasDatatableRowExpansion = tr.classList.contains(
				'p-datatable-row-expansion'
			)
			const previousSibling = tr.previousElementSibling
			if (hasDatatableRowExpansion && previousSibling) {
				// previousSibling.style.border = 'none'
				previousSibling.classList.add('TOGGLE')
				// tr.classList.add('TOGGLE')
			} else {
				tr.classList.remove('TOGGLE')
			}
		}
		const trElements = document.querySelectorAll(
			'.risk-management-wrapper tbody tr'
		)
		trElements.forEach((tr) => {
			toggleClassAndBorder(tr)
		})
	}, [expandedRows])

	const getExpandRows = (data) => {
		setExpandedRows(data)
	}
	return (
		<>
			<div className="risk-management-wrapper">
				{showDrawer && (
					<Form
						setDrawerstate={setShowDrawer}
						columns={[...columns]}
						originalColumns={originalColumns}
						setColumns={setColumns}
					/>
				)}
				{isEdit && (
					<EditColumns
						setDrawerstate={setIsEdit}
						selectedData={selectedData}
						PRODUCTS={OriginalData}
						setSelectedData={setSelectedData}
					/>
				)}
				{isArrangeOrder && (
					<ArrangeOrder
						setDrawerstate={setIsArrangeOrder}
						PRODUCTS={OriginalData}
						setOrigoinalData={setOriginalData}
					/>
				)}
				{addRisk && (
					<AddRisk
						setDrawerstate={setAddRisk}
						setOriginalData={setOriginalData}
						OriginalData={OriginalData}
					/>
				)}

				<PageAlerts pageId={'riskmanagement'} />
				<Card className="mb-2">
					<DashboardTable items={PRODUCTS} />
				</Card>
				<Card>
					<DataTableWrapper
						products={OriginalData}
						HeaderButtons={HeaderButtons}
						options={dopdownOptions}
						columns={columns}
						getexpandrows={getExpandRows}
						onDataSelection={HandleData}
						template={template1}
						paginatorLeft={paginatorLeft}
						selectedData={selectedData}
						handleRowExpansion={handleRowExpansion}
					/>
				</Card>
			</div>
		</>
	)
}

export default React.memo(RiskManagement)
