import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import '../../../assets/css/DropDowns/index.css'
const MultiSelectDropdown = ({
	selectedItems,
	remainingItems,
	onItemRemove,
	onItemAdd,
	label,
	className,
	autoComplete = 'off',
	onFocus,
	onBlur,
	placeholder = 'Select',
	autoFocus
}) => {
	const [showDropDown, setShowDropDown] = useState(false)
	const [searchValue, setSearchValue] = useState('')
	const dropDownRef = useRef()

	const handleToggleDropDown = () => {
		setShowDropDown((prev) => !prev)
	}

	const handleSelectItem = (item) => {
		onItemAdd(item)
		setShowDropDown(false)
	}

	const handleRemoveItem = (item) => {
		onItemRemove(item)
	}

	const handleInputChange = (event) => {
		setSearchValue(event.target.value)
	}

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropDownRef.current &&
				!dropDownRef.current.contains(event.target)
			) {
				setShowDropDown(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<div className={`form-group ${className}`}>
			{label && <label className="label">{label}</label>}
			<div className="input-outer">
				<div className="multiDrop" ref={dropDownRef}>
					<div className="multiDropInput">
						{selectedItems.length > 0 &&
							selectedItems.map((item) => (
								<div key={item.label} className="TagItem">
									<span>{item.label}</span>
									<p
										onClick={() => handleRemoveItem(item)}
										className="TagItemRemove"
									>
										<FontAwesomeIcon icon={faTimes} />
									</p>
								</div>
							))}
						<input
							type="text"
							className="form-control form-control-1 multiInput"
							placeholder={placeholder}
							onClick={handleToggleDropDown}
							value={searchValue}
							onChange={handleInputChange}
							autoComplete={autoComplete}
							onFocus={onFocus}
							onBlur={onBlur}
							autoFocus={autoFocus}
						/>
					</div>
					{showDropDown && (
						<div className="dropBox">
							<div className="listitem" data-index-id="0">
								{searchValue.length === 0 &&
									remainingItems.length > 0 &&
									remainingItems.map((item) => (
										<div
											key={item.label}
											className="ms-Suggestions-item"
											onClick={() =>
												handleSelectItem(item)
											}
										>
											{item.label}
										</div>
									))}
								{searchValue.length !== 0 &&
									remainingItems
										.filter((item) =>
											item.label
												.toLowerCase()
												.includes(
													searchValue.toLowerCase()
												)
										)
										.map((item) => (
											<div
												key={item.label}
												className="ms-Suggestions-item"
												data-id="0"
												onClick={() =>
													handleSelectItem(item)
												}
											>
												{item.label}
											</div>
										))}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

MultiSelectDropdown.propTypes = {
	selectedItems: PropTypes.arrayOf(
		PropTypes.shape({
			// id: PropTypes.number.isRequired,
			label: PropTypes.string.isRequired
		})
	).isRequired,
	remainingItems: PropTypes.arrayOf(
		PropTypes.shape({
			// id: PropTypes.number.isRequired,
			label: PropTypes.string.isRequired
		})
	).isRequired,
	onItemRemove: PropTypes.func.isRequired,
	onItemAdd: PropTypes.func.isRequired,
	label: PropTypes.string,
	autoComplete: PropTypes.oneOf(['on', 'off']),
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	autoFocus: PropTypes.bool
}

export default MultiSelectDropdown
