import React, { useRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const Editor = ({ onChange, value }, ref) => {
	const quillRef = useRef()

	useImperativeHandle(ref, () => ({
		getQuillRef: () => quillRef.current
	}))

	const modules = {
		toolbar: [
			['bold', 'italic', 'underline', 'strike'],
			[{ list: 'ordered' }, { list: 'bullet' }],
			[{ color: [] }, { background: [] }],
			['link']
		]
	}

	const formats = [
		'bold',
		'italic',
		'underline',
		'strike',
		'list',
		'bullet',
		'color',
		'background',
		'link'
	]

	const handleKeyPress = (event) => {
		if (event.key === 'Alt') {
			const isEmpty =
				quillRef.current.getLength() === 1 &&
				quillRef.current.getText().trim() === ''
			if (isEmpty) {
				const editorContent = quillRef.current.getContents()
				editorContent.ops[0].attributes = {
					class: 'empty-paragraph-style'
				}
				quillRef.current.setContents(editorContent)
			}
		}
	}

	const handleTextChange = (content) => {
		onChange(content)
	}

	return (
		<ReactQuill
			theme="snow"
			modules={modules}
			formats={formats}
			value={value}
			onChange={handleTextChange}
			onKeyPress={handleKeyPress}
			ref={quillRef}
		/>
	)
}

// Editor.propTypes = {
// 	onChange: PropTypes.func.isRequired,
// 	value: PropTypes.string.isRequired
// }

export default React.forwardRef(Editor)
