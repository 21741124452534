import React, { useState } from 'react'
import Modal from '../../../components/ui/modals'
import ElasticTextbox from '../../../components/ui/Elastic-textbox'
import { gql, useQuery } from '@apollo/client'
import {
	faSave,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { useDispatch } from 'react-redux'
import { addAlerts } from '../../../store/AlertSlice'
import { Button } from 'primereact/button'
import { useSelector } from 'react-redux'
import client from '../../../utils/GraphQl/apolloClient'
const PromptModal = ({ setShowModal, modalType, HANDLEEditColumn }) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [overLay, setOverLay] = React.useState('')
	const [showPromptModal, setShowPromptModal] = useState(true)
	const [scrollable, setScrollable] = React.useState(true)
	const [loader, setLoader] = useState(false)
	const dispatch = useDispatch()
	const [Formdata, setFormdata] = useState({
		context: '',
		prompt: '',
		response: ''
	})
	const HandleFormData = (key, value) => {
		setFormdata((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}
	const handleModalClose = () => {
		setShowModal(false)
		setScrollable(false)
		setShowPromptModal(false)
		setOverLay('false')
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		HANDLEEditColumn(modalType, Formdata.response)
		handleModalClose()
	}

	const askAIquery = gql`
		query ($content: String) {
			askAI(content: $content) {
				data
				errors
				status
				message
			}
		}
	`

	function extractDetailsFromPrompt(prompt) {
		const regex = /{(.*?)}/g
		let results = ''
		let match
		while ((match = regex.exec(prompt))) {
			results += match[1]
		}
		return results
	}

	async function generateRandomText() {
		setLoader(true)
		let context = Formdata.context
		let prompt = Formdata.prompt

		if (
			prompt.includes('{') &&
			prompt.includes('=') &&
			prompt.includes('}')
		) {
			let detail = extractDetailsFromPrompt(prompt)
			prompt = prompt.replace('{' + detail + '}', '')
			let risk_key = detail.split('=')[0].replace('-', '_')
			let risk_ids = detail.split('=')[1]
			let idType = 'array'
			if (risk_ids === 'all') {
				idType = 'string'
			}
			risk_ids = risk_ids.split(',')
			const riskData = JSON.parse(localStorage.getItem('products'))
			if (riskData) {
				riskData.forEach((element) => {
					if (idType === 'string') {
						prompt += `\n` + element[risk_key]
					} else if (risk_ids.includes(element.id)) {
						prompt += `\n` + element[risk_key]
					}
				})
			}
		}

		const content = context + ' ' + prompt
		const { data } = await client.query({
			query: askAIquery,
			variables: {
				content: content
			},
			context: {
				headers: {
					authorization: authToken
				}
			}
		})

		const response = data.askAI
		if (response.status === 200) {
			setLoader(false)
			return response.data
		} else if (response.status !== 200 && response?.errors?.length > 0) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'riskmanagement_editcolumns',
					type: 'danger',
					message: response?.errors[0],
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (response.status !== 200 && response?.errors?.length == 0) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'riskmanagement_editcolumns',
					type: 'danger',
					message: response?.message,
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	async function aiHandler(e) {
		e.preventDefault()
		if (Formdata.context.length < 1) {
			dispatch(
				addAlerts({
					pageId: 'riskmanagement_editcolumns',
					type: 'danger',
					message: 'Please add context ',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (Formdata.prompt.length < 1) {
			dispatch(
				addAlerts({
					pageId: 'riskmanagement_editcolumns',
					type: 'danger',
					message: 'Please add prompt ',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else {
			try {
				let value = await generateRandomText()
				HandleFormData('response', value?.trim(' '))
			} catch (error) {
				console.error('Error in aiHandler:', error)
			}
		}
	}

	const FORM = (
		<div className="w-[350px]">
			<ElasticTextbox
				id="Context"
				labelName="Context"
				value={Formdata.context}
				onChange={(data) => HandleFormData('context', data)}
			/>

			<ElasticTextbox
				id="Prompt"
				labelName="Prompt"
				value={Formdata.prompt}
				onChange={(data) => HandleFormData('prompt', data)}
			/>
			{loader ? (
				<Button
					className="btn-large btn-primary btn-all gap-2"
					// isIconBtn={true}
					disabled={loader}
					onClick={() => console.log('')}
				>
					<span className="waiting-loader"> </span> Generating....
				</Button>
			) : (
				<Button
					onClick={aiHandler}
					// disabled={!Formdata.context || !Formdata.prompt}
					children="Ask Ai"
					className="btn-large btn-primary "
				/>
			)}
			<ElasticTextbox
				// readOnly={!Formdata.response}
				id="Response"
				labelName="Response"
				value={Formdata.response}
				onChange={(data) => HandleFormData('response', data)}
			/>
		</div>
	)
	const footer = (
		<>
			<div className="modal-footer flex  items-center justify-between border-t  ">
				<h5 className="invisible font-normal">{'T'}</h5>
				<div className="flex items-center justify-end gap-[3px]">
					<IconButton
						// disabled={!Formdata.response}
						Icon={faSave}
						className="btn-success btn-large flex gap-1.5"
						type="button"
						onClick={handleSubmit}
						children="Use"
					/>
				</div>
			</div>
		</>
	)

	return (
		<div>
			<Modal
				modalSize={'lg'}
				title={`Prompt For ${modalType === 'risk_description' ? 'Risk Description' : 'Risk Heading'}`}
				onClose={handleModalClose}
				body={FORM}
				overLay={overLay}
				showModal={showPromptModal}
				position="!top-[36%] !left-[30%] !z-[99999]"
				className="z-[9999]"
				maxHeight={'270px'}
				scrollable={scrollable}
				Footer={footer}
			/>
		</div>
	)
}

export default PromptModal
