import React from 'react'
import Sidebar from '../../components/Sidebar'
const DashboardLayout = ({ menudata, onClick, children }) => {
	return (
		<div className="dashbord layout">
			<Sidebar data={menudata} onClick={onClick} />
			{children}
		</div>
	)
}

export default React.memo(DashboardLayout)
