import React, { useState } from 'react'
import Modal from '../../../components/ui/modals'
import TextBox from '../../../components/ui/TextBox'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../../components/ui/Buttons/Icon-button'
const EditCommittieMembers = ({
	setShowModal,
	setSelectedData,
	type,
	rowData
}) => {
	const [overLay, setOverLay] = React.useState('')
	const [showPromptModal, setShowPromptModal] = useState(true)
	const [scrollable, setScrollable] = React.useState(true)
	const [CommitteeData, setCommitteeData] = useState(
		type == 'edit_chairman' || type == 'edit_member'
			? rowData[0]
			: {
					committee_name: '',
					committee_overview: '',
					committee_attachment: '',
					committee_doctype: ''
				}
	)
	const handleModalClose = () => {
		setShowModal(false)
		setScrollable(false)
		setShowPromptModal(false)
		setOverLay('false')
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		if (type === 'edit_chairman') {
			setSelectedData((prev) => {
				const newData = [...prev]
				if (newData[0]?.data?.corporate_structure?.committee) {
					newData[0].data.corporate_structure.committee =
						newData[0]?.data?.corporate_structure?.committee?.map(
							(committee) => {
								const chairmans = committee?.chairmans || []
								const index = chairmans.findIndex(
									(chairman) => chairman.id == rowData[0].id
								)
								chairmans[index] = {
									...chairmans[index],
									...CommitteeData
								}
								if (index) {
									return {
										...committee,
										...CommitteeData,
										chairmans: chairmans
									}
								}
								return committee
							}
						)
				}
				return newData
			})
		} else if (type == 'edit_member') {
			setSelectedData((prev) => {
				const newData = [...prev]
				if (newData[0]?.data?.corporate_structure?.committee) {
					newData[0].data.corporate_structure.committee =
						newData[0]?.data?.corporate_structure?.committee?.map(
							(committee) => {
								const members = committee.members || []
								const index = members.findIndex(
									(chairman) => chairman.id == rowData[0].id
								)
								members[index] = {
									...members[index],
									...CommitteeData
								}
								if (index) {
									return {
										...committee,
										...CommitteeData,
										members: members
									}
								}
								return committee
							}
						)
				}
				return newData
			})
		}
		handleModalClose()
	}

	const handleCommitteeData = (key, value) => {
		setCommitteeData((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}

	const FORM = (
		<div className="w-[350px]">
			<div>
				<TextBox
					name={`full_name`}
					id={`full_name`}
					value={CommitteeData.full_name}
					labelName="Full Name"
					onChange={(data) => handleCommitteeData('full_name', data)}
				/>
				<TextBox
					name={`email`}
					id={`email`}
					value={CommitteeData.email}
					labelName="Email"
					onChange={(data) => handleCommitteeData('email', data)}
				/>
				<TextBox
					name={`designation`}
					id={`designation`}
					value={CommitteeData.designation}
					labelName="Designation"
					onChange={(e) => handleCommitteeData('designation', e)}
				/>
				<TextBox
					name={`phone`}
					id={`phone`}
					value={CommitteeData.phone}
					labelName="Phone"
					onChange={(e) => handleCommitteeData('phone', e)}
				/>
			</div>
		</div>
	)
	const footer = (
		<>
			<div className="modal-footer flex  items-center justify-between border-t  ">
				<h5 className="invisible font-normal">{'T'}</h5>
				<div className="flex items-center justify-end gap-[3px]">
					<IconButton
						Icon={faSave}
						className="btn-success btn-large flex gap-1.5"
						type="button"
						onClick={handleSubmit}
						children="Save"
					/>
				</div>
			</div>
		</>
	)

	return (
		<div>
			<Modal
				modalSize={'lg'}
				title={
					type == 'edit_member'
						? 'Edit selected committe member'
						: 'Edit selected Chairman member'
				}
				onClose={handleModalClose}
				body={FORM}
				overLay={overLay}
				showModal={showPromptModal}
				position="!top-[36%] !left-[30%] !z-[99999]"
				className="z-[9999]"
				scrollable={scrollable}
				Footer={footer}
			/>
		</div>
	)
}

export default EditCommittieMembers
