import { gql } from '@apollo/client'
export const ENABLE_USER_MUTATION = gql`
	mutation EnableUser($userId: JSON) {
		enableUser(user_id: $userId) {
			status
			message
			errors
		}
	}
`
