import { gql } from '@apollo/client'

// Define your GraphQL mutation
export const MAGICLINK_MUTATION = gql`
	mutation SendMagicLink($input: magicLinkInput!) {
		sendMagicLink(input: $input) {
			status
			message
			errors
		}
	}
`
export const APPROVE_MAGICLINK_MUTATION = gql`
	mutation ApproveMagicLink($input: verifyEmailInput!) {
		approveMagicLink(input: $input) {
			status
			message
			data {
				authToken
				user
				settings
			}
			errors
		}
	}
`
