import React from 'react'

// Social Icons
import SocialGoogle from '../../../assets/svg-icons/social/Google'
import SocialMessage from '../../../assets/svg-icons/social/Message'
import SocialWindow from '../../../assets/svg-icons/social/Window'
import SocialInstagram from '../../../assets/svg-icons/social/Instagram'
import SocialMessenger from '../../../assets/svg-icons/social/Messenger'
import SocialLinkedIn from '../../../assets/svg-icons/social/LinkedIn'
import SocialLinkedInSquare from '../../../assets/svg-icons/social/LinkedInSquare'
import SocialGithub from '../../../assets/svg-icons/social/Github'
import SocialTwitter from '../../../assets/svg-icons/social/Twitter'
import SocialTelegram from '../../../assets/svg-icons/social/Telegram'
import SocialWhatsapp from '../../../assets/svg-icons/social/Whatsapp'
// Line Icons
import Lock from '../../../assets/svg-icons/line/Lock'
import LockDot from '../../../assets/svg-icons/line/LockDot'
import PhoneLock from '../../../assets/svg-icons/line/PhoneLock'
import Settings from '../../../assets/svg-icons/line/Settings'
import UserSettings from '../../../assets/svg-icons/line/UserSettings'
import UserContact from '../../../assets/svg-icons/line/UserContact'
import Signup from '../../../assets/svg-icons/line/Signup'
import Signout from '../../../assets/svg-icons/line/Signout'
import Toggle from '../../../assets/svg-icons/line/Toggle'
import Search from '../../../assets/svg-icons/line/Search'
import Bell from '../../../assets/svg-icons/line/Bell'
import Help from '../../../assets/svg-icons/line/Help'
import AddSquare from '../../../assets/svg-icons/line/AddSquare'
import EditFile from '../../../assets/svg-icons/line/EditFile'
import DownloadFile from '../../../assets/svg-icons/line/DownloadFile'
import Gallery from '../../../assets/svg-icons/line/Gallery'
import ProfileCard from '../../../assets/svg-icons/line/ProfileCard'
import BarChart from '../../../assets/svg-icons/line/BarChart'
import PieChart from '../../../assets/svg-icons/line/PieChart'
import BriefCase from '../../../assets/svg-icons/line/Briefcase'
import Wifi from '../../../assets/svg-icons/line/Wifi'
import Trash from '../../../assets/svg-icons/line/Trash'
import VerificationCheckmark from '../../../assets/svg-icons/line/VerificationCheckmark'
import NotificationList from '../../../assets/svg-icons/line/NotificationList'

const iconRegistry = {
	'social-google': SocialGoogle,
	'social-message': SocialMessage,
	'social-window': SocialWindow,
	'social-instagram': SocialInstagram,
	'social-messenger': SocialMessenger,
	'social-linkedin': SocialLinkedIn,
	'social-linkedin-square': SocialLinkedInSquare,
	'social-github': SocialGithub,
	'social-twitter': SocialTwitter,
	'social-telegram': SocialTelegram,
	'social-whatsapp': SocialWhatsapp,
	'line-lock': Lock,
	'line-lock-dot': LockDot,
	'line-phone-lock': PhoneLock,
	'line-settings': Settings,
	'line-user-settings': UserSettings,
	'line-user-contact': UserContact,
	'line-signup': Signup,
	'line-signout': Signout,
	'line-toggle': Toggle,
	'line-search': Search,
	'line-bell': Bell,
	'line-help': Help,
	'line-add-square': AddSquare,
	'line-edit-file': EditFile,
	'line-download-file': DownloadFile,
	'line-gallery': Gallery,
	'line-profile-card': ProfileCard,
	'line-bar-chart': BarChart,
	'line-pie-chart': PieChart,
	'line-brief-case': BriefCase,
	'line-wifi': Wifi,
	'line-trash': Trash,
	'line-notification-list': NotificationList,
	'line-email-verifaction': VerificationCheckmark
}

export default iconRegistry
