import React, { useState, useEffect } from 'react'
import Modal from '../../../components/ui/modals'
import TextBox from '../../../components/ui/TextBox'
import Attachment from '../../../components/ui/Attachments'
import KeyValueDropDown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { useSelector } from 'react-redux'
import FalseAttachment from '../../../components/ui/FalseAttachments'
import { useMutation } from '@apollo/client'
import { FILE_UPLOADING_CONTEXT } from '../../../utils/GraphQl/OrganizationContext'
const subSidary_file_type = [
	{
		label: 'Subsidary Certificate',
		value: 'Subsidary Certificate'
	},
	{
		label: 'MOA',
		value: 'memorandum of association'
	},
	{ label: 'Trade License', value: 'trade license' },
	{
		label: 'Ownership of Shareholding Documents',
		value: 'ownership of shareholding documents'
	}
]
const Subsidries = ({ setShowModal, setSelectedData, type, rowData }) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [overLay, setOverLay] = React.useState('')
	const [showPromptModal, setShowPromptModal] = useState(true)
	const [scrollable, setScrollable] = React.useState(true)
	const [upload_file] = useMutation(FILE_UPLOADING_CONTEXT)
	const [subSidary_file, setSubsidary_file] = useState([])
	const [SubSidaryData, setSubSidaryData] = useState(
		type == 'edit'
			? rowData[0]
			: {
					subsidiary_name: '',
					subsidiary_doctype: '',
					subsidiary_supporting_document: ''
				}
	)
	useEffect(() => {
		if (type == 'edit') {
			if (rowData) {
				let value = rowData[0]?.subsidiary_doctype
				if (value) {
					let index = subSidary_file_type.findIndex(
						(item) => item.value === value
					)
					handleSubsidaryData(
						'subsidiary_doctype',
						subSidary_file_type[index]
					)
				}
			}
		}
	}, [])
	const handleModalClose = () => {
		setShowModal(false)
		setScrollable(false)
		setShowPromptModal(false)
		setOverLay('false')
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		if (type == 'add') {
			let newSubSidaryData = {
				...SubSidaryData,
				subsidiary_doctype:
					SubSidaryData?.subsidiary_doctype?.value ??
					SubSidaryData?.subsidiary_doctype
			}
			setSelectedData((prev) => {
				const baseSubsidiary = prev[0]?.data?.corporate_structure || {}
				const existingSubsidiaries = Array.isArray(
					baseSubsidiary.subsidiaries
				)
					? baseSubsidiary.subsidiaries
					: []
				const updatedSubsidiary = [
					...existingSubsidiaries,
					{
						id: existingSubsidiaries.length + 10,
						...newSubSidaryData
					}
				]
				const updatedFirstElement = {
					...prev[0],
					data: {
						...prev[0]?.data,
						corporate_structure: {
							...baseSubsidiary,
							subsidiaries: updatedSubsidiary
						}
					}
				}
				return [updatedFirstElement, ...prev.slice(1)]
			})
		} else if (type == 'edit') {
			setSelectedData((prev) => {
				const newData = [...prev]
				if (newData[0]?.data?.corporate_structure?.subsidiaries) {
					let index =
						newData[0]?.data?.corporate_structure?.subsidiaries?.findIndex(
							(subsidary) => subsidary.id == rowData[0].id
						)
					if (index !== -1) {
						const updatedSubsidary = {
							...newData[0]?.data?.corporate_structure
								?.subsidiaries[index],
							...SubSidaryData,
							subsidiary_doctype:
								SubSidaryData?.subsidiary_doctype?.value ??
								SubSidaryData?.subsidiary_doctype
						}
						newData[0].data.corporate_structure.subsidiaries[
							index
						] = updatedSubsidary
					}
				}
				return newData
			})
		}
		handleModalClose()
	}
	const handleSelectedDocument = async (file) => {
		setSubsidary_file([...file])
		let variable = {
			folderName: 'subsidiary-supporting-document',
			oldFile: SubSidaryData?.subsidiary_supporting_document,
			file: file[0]
		}
		try {
			const response = await upload_file({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: variable
			})
			let data = await response?.data
			let uploadFile = data?.uploadFile
			if (uploadFile.status === 200) {
				let value = uploadFile.data
				handleSubsidaryData('subsidiary_supporting_document', value)
			} else if (
				uploadFile.status !== 200 &&
				uploadFile.errors.length > 0
			) {
				return
			}
		} catch (err) {
			console.log('err')
		}
	}

	const handleRemoveDocument = (file) => {
		handleSubsidaryData('subsidiary_supporting_document', '')
		setSubsidary_file([])
	}
	const handleSubsidaryData = (key, value) => {
		setSubSidaryData((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}
	const FORM = (
		<div className="w-[350px]">
			<TextBox
				name={'subsidiary_name'}
				id={'subsidiary_name'}
				value={SubSidaryData.subsidiary_name}
				labelName={'Subsidary Name'}
				onChange={(data) =>
					handleSubsidaryData('subsidiary_name', data)
				}
			/>
			<Attachment
				labelName={'Supporting Document'}
				id="sub_sidary_doc"
				onSelect={(file) => handleSelectedDocument(file)}
				onRemove={(file) => handleRemoveDocument(file)}
				files={subSidary_file}
			/>
			{SubSidaryData?.subsidiary_supporting_document &&
				subSidary_file.length == 0 && (
					<FalseAttachment
						filename={
							SubSidaryData?.subsidiary_supporting_document.split(
								'/'
							)[2]
						}
						onFileRemove={() =>
							handleSubsidaryData(
								'subsidiary_supporting_document',
								''
							)
						}
					/>
				)}
			<KeyValueDropDown
				options={subSidary_file_type}
				label="Document Type"
				value={SubSidaryData?.subsidiary_doctype?.label}
				onChange={(data) =>
					handleSubsidaryData('subsidiary_doctype', data)
				}
			/>
		</div>
	)
	const footer = (
		<>
			<div className="modal-footer flex  items-center justify-between border-t  ">
				<h5 className="invisible font-normal">{'T'}</h5>
				<div className="flex items-center justify-end gap-[3px]">
					<IconButton
						Icon={faSave}
						className="btn-success btn-large flex gap-1.5"
						type="button"
						onClick={handleSubmit}
						children="Use"
					/>
				</div>
			</div>
		</>
	)
	return (
		<div>
			<Modal
				modalSize={'lg'}
				title={type == 'add' ? 'Add New Subsidiary' : 'Edit Subsidiary'}
				onClose={handleModalClose}
				body={FORM}
				overLay={overLay}
				showModal={showPromptModal}
				position="!top-[36%] !left-[30%] !z-[99999]"
				className="z-[9999]"
				scrollable={scrollable}
				Footer={footer}
			/>
		</div>
	)
}

export default Subsidries
