import { createSlice } from '@reduxjs/toolkit'
import client from '../../utils/GraphQl/apolloClient'
const user = localStorage.getItem('data')
const parsedData = user ? JSON.parse(user) : null
// console.log('parsed data', parsedData)
const initialState = {
	isLoggedIn: parsedData?.authToken.length > 50 ? true : false,
	token: parsedData?.authToken || null,
	userdata: parsedData,
	notifications: parsedData?.user?.notifications || []
}
const AuthSlice = createSlice({
	name: 'authentication',
	initialState: initialState,
	reducers: {
		loginUser: (state, action) => {
			const data = action.payload
			// console.log('login data => ',data)
			const user = {
				notifications: data?.user?.notifications,
				token: data?.authToken,
				update:
					data?.settings?.force_update_profile_settings === 'true' &&
					data?.settings?.mandatory_remaining_fields?.length > 0,
				update_doc: data?.settings?.document_expired === true
			}
			state.token = user.token
			state.isLoggedIn = true
			state.userEmail = user.email
			delete data?.user?.user_data?.personal_information['profile_photo']
			state.userdata = data
			state.notifications = user.notifications
			state.userdata = {
				...data,
				forceUpdate: user.update,
				forceUpdateDoc: user.update_doc
			}
			let jsonuser = JSON.stringify({
				...data,
				forceUpdate: user.update,
				forceUpdateDoc: user.update_doc
			})
			localStorage.setItem('data', jsonuser)
		},
		logoutUser: (state, action) => {
			client.clearStore()
			state.isLoggedIn = false
			state.userdata = ''
			state.notifications = []
			state.token = ''
			localStorage.removeItem('data')
		},
		updateuser: (state, action) => {
			// let user_info=action.payload;
			// console.log('userdtaa',action.payload)
			state.userdata.user.user_data = action.payload
			state.userdata.forceUpdate = false
			state.forceUpdate = false
			const existingData = localStorage.getItem('data')
			const parsedData = existingData ? JSON.parse(existingData) : {}
			// console.log('paresd data =>',parsedData);
			parsedData.user.user_data = action.payload
			parsedData.forceUpdate = false
			const updatedData = JSON.stringify(parsedData)
			localStorage.setItem('data', updatedData)
		},
		updateUserExpiry: (state, action) => {
			state.userdata = { ...state.userdata, forceUpdateDoc: false }
			state.forceUpdateDoc = false
			const data = action.payload
			const existingData = localStorage.getItem('data')
			const parsedData = existingData ? JSON.parse(existingData) : {}
			parsedData.user.user_data.personal_information =
				data.personal_information
			parsedData.forceUpdateDoc = false
			state.userdata.user.user_data.personal_information =
				data.personal_information
			state.userdata.forceUpdate =
				data.settings?.mandatory_remaining_fields?.length > 0 &&
				data?.settings?.force_update_profile_settings === 'true'
			const updatedData = JSON.stringify(parsedData)
			localStorage.setItem('data', updatedData)
		},
		updatePersonalInfo: (state, action) => {
			const data = action.payload
			// console.log('REDUX Update User USER', data)
			const existingData = localStorage.getItem('data')
			const parsedData = existingData ? JSON.parse(existingData) : {}
			parsedData.settings = data.settings
			parsedData.forceUpdate =
				data.settings?.mandatory_remaining_fields?.length > 0 &&
				data?.settings?.force_update_profile_settings === 'true'
			parsedData.user.user_data.personal_information =
				data.personal_information
			state.userdata.settings = data?.settings
			state.userdata.forceUpdate =
				data.settings?.mandatory_remaining_fields?.length > 0 &&
				data?.settings?.force_update_profile_settings === 'true'
			state.userdata.user.user_data.personal_information =
				data.personal_information
			const updatedData = JSON.stringify(parsedData)
			localStorage.setItem('data', updatedData)
		},
		updateBiusinessInfo: (state, action) => {
			const data = action.payload
			// console.log('REDUX BUSINESS DATA', data)
			const existingData = localStorage.getItem('data')
			const parsedData = existingData ? JSON.parse(existingData) : {}
			parsedData.settings = data.settings
			parsedData.forceUpdate =
				data.settings?.mandatory_remaining_fields?.length > 0 &&
				data?.settings?.force_update_profile_settings === 'true'
			parsedData.user.user_data.business_information =
				data.business_information
			state.userdata.settings = data.settings
			state.userdata.forceUpdate =
				data.settings?.mandatory_remaining_fields?.length > 0 &&
				data?.settings?.force_update_profile_settings === 'true'
			state.userdata.user.user_data.business_information =
				data.business_information
			const updatedData = JSON.stringify(parsedData)
			localStorage.setItem('data', updatedData)
		},
		updateSocialInfo: (state, action) => {
			const data = action.payload
			// console.log('REDUX SOCIAL DATA', data)
			const existingData = localStorage.getItem('data')
			const parsedData = existingData ? JSON.parse(existingData) : {}
			parsedData.settings = data.settings
			parsedData.forceUpdate =
				data.settings?.mandatory_remaining_fields?.length > 0 &&
				data?.settings?.force_update_profile_settings === 'true'
			parsedData.user.user_data.social_information =
				data.social_information
			state.userdata.settings = data.settings
			state.userdata.forceUpdate =
				data.settings?.mandatory_remaining_fields?.length > 0 &&
				data?.settings?.force_update_profile_settings === 'true'
			state.userdata.user.user_data.social_information =
				data.social_information
			const updatedData = JSON.stringify(parsedData)
			localStorage.setItem('data', updatedData)
		},
		updateAdditionalInfo: (state, action) => {
			const data = action.payload
			// console.log('REDUX ADDITIONAL DATA', data)
			const existingData = localStorage.getItem('data')
			const parsedData = existingData ? JSON.parse(existingData) : {}
			parsedData.forceUpdate =
				data.settings?.mandatory_remaining_fields?.length > 0 &&
				data?.settings?.force_update_profile_settings === 'true'
			parsedData.settings.force_update_profile_settings =
				data?.settings?.force_update_profile_settings
			parsedData.settings.mandatory_remaining_fields =
				data.settings?.mandatory_remaining_fields
			// parsedData.settings.user_profile_additional_fields= data.settings.user_profile_additional_fields
			parsedData.user.user_data.additional_information =
				data.additional_information
			state.userdata.settings.mandatory_remaining_fields =
				data?.settings?.mandatory_remaining_fields
			state.userdata.settings.force_update_profile_settings =
				data?.settings?.force_update_profile_settings
			state.userdata.forceUpdate =
				data.settings?.mandatory_remaining_fields?.length > 0 &&
				data?.settings?.force_update_profile_settings === 'true'
			state.userdata.user.user_data.additional_information =
				data?.additional_information
			const updatedData = JSON.stringify(parsedData)
			localStorage.setItem('data', updatedData)
		},
		updatePersonalEmail: (state, action) => {
			const data = action.payload
			// console.log('redx', data)
			const existingData = localStorage.getItem('data')
			const parsedData = existingData ? JSON.parse(existingData) : {}
			parsedData.user.user_data = data
			state.userdata.user.user_data = data
			const updatedData = JSON.stringify(parsedData)
			localStorage.setItem('data', updatedData)
		},
		updateWorkEmail: (state, action) => {
			const data = action.payload
			// console.log('redx', data)
			const existingData = localStorage.getItem('data')
			const parsedData = existingData ? JSON.parse(existingData) : {}
			parsedData.user.user_data = data
			state.userdata.user.user_data = data
			const updatedData = JSON.stringify(parsedData)
			localStorage.setItem('data', updatedData)
		}
	}
})
export const {
	loginUser,
	logoutUser,
	updateuser,
	updatePersonalInfo,
	updateBiusinessInfo,
	updateSocialInfo,
	updateAdditionalInfo,
	updateWorkEmail,
	updatePersonalEmail,
	updateUserExpiry
} = AuthSlice.actions
export const AuthReducer = AuthSlice.reducer
