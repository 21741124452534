import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { gql } from '@apollo/client'
import client from '../../utils/GraphQl/apolloClient'
export const fetchRegisterSettings = createAsyncThunk(
	'registerSettings/fetchRegisterSettings',
	async (_, { getState }) => {
		try {
			const authToken = getState().Auth.token
			const query = gql`
				query RegisterSettings {
					Domains: getSetting(key: "appsetting_allowed_email") {
						data
						status
						errors
						message
					}
					PasswordPolicy: getSetting(
						key: "appsetting_password_policy_v2"
					) {
						data
						status
						errors
						message
					}
					verifyCheck: getSetting(
						key: "appsetting_verify_email_check"
					) {
						data
						status
						errors
						message
					}
				}
			`
			const { data } = await client.query({
				query,
				context: { headers: { authorization: authToken } },
				fetchPolicy: 'network-only'
			})
			return data
		} catch (error) {
			throw new Error('Failed to fetch Register settings')
		}
	}
)

const registerSettingsSlice = createSlice({
	name: 'registerSettings',
	initialState: {
		data: null,
		loading: false,
		error: null
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchRegisterSettings.pending, (state) => {
				state.loading = true
			})
			.addCase(fetchRegisterSettings.fulfilled, (state, action) => {
				state.loading = false
				state.data = action.payload
				state.error = null
			})
			.addCase(fetchRegisterSettings.rejected, (state, action) => {
				state.loading = false
				state.error = action.error.message
			})
	}
})

export default registerSettingsSlice.reducer
