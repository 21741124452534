import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import CountrySelector from '../../../components/ui/Countryselctor'
import PhoneSelector from '../../../components/ui/PhoneSelector'
import DatePicker from '../../../components/ui/Datepicker'
import { useMutation } from '@apollo/client'
import { UPDATE_PROFILE_MUTATION } from '../../../utils/GraphQl/UpdateProfileMutation'
import { updatePersonalInfo } from '../../../store/AuthSlice/AuthSlice'
import { useDispatch, useSelector } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import PageAlerts from '../../PageAlerts'
import { logoutUser } from '../../../store/AuthSlice/AuthSlice'
import ImageEditor from '../../../components/ui/ImageEditor'
import ProgressBar from '../../ProgressBar'
import SearchableDatepicker from '../../../components/ui/Searchable-Datepicker'
import {
	faBook,
	faUniversalAccess,
	faTriangleExclamation,
	faCheck
} from '@fortawesome/free-solid-svg-icons'
import Simpledropdown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import useDateRangeRestrictions from '../../../components/CustomHooks/useDateRangeRestrictions'
import globalKeyPress from '../../../hooks/globalKeyPress'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const TextBox = React.lazy(() => import('../../../components/ui/TextBox'))
const SimpleDropDown = React.lazy(
	() => import('../../../components/Dropdowns/Simpledropdown')
)
const ElasticTextBox = React.lazy(
	() => import('../../../components/ui/Elastic-textbox')
)

const PersonalInfoDrawer = ({ show, setShow }) => {
	const USER = useSelector((state) => state.Auth)
	const DYNAMIC_FIELDS = useSelector(
		(state) => state.personalInfoDynamicFields
	)
	const { minDate, maxDate } = useDateRangeRestrictions()
	const [loader, setLoader] = useState(false)
	const personalinfo = USER?.userdata?.user?.user_data?.personal_information
	const [showDrawer, setShowDrawer] = useState(false)
	const [overlay, setOverlay] = useState('')
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(false)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const [Title, setTitle] = useState([])
	const [Gender, setGender] = useState([])
	const [Relation, setRelation] = useState([])
	const [Status, setStatus] = useState([])
	const [Document, setDOcument] = useState([])
	const [selectedDate, setSelectedDate] = useState({
		day: personalinfo?.dob?.day || '',
		month: personalinfo?.dob?.month || '',
		year: personalinfo?.dob?.year || ''
	})
	const [selectedExpiryDate, setSelectedExpiryDate] = useState({
		day: personalinfo?.expiry_date?.day || '',
		month: personalinfo?.expiry_date?.month || '',
		year: personalinfo?.expiry_date?.year || ''
	})
	const [citizenship, setCitizenship] = useState(
		personalinfo?.citizenship || null
	)
	const [secondCitizenShip, setSecondCitizenShip] = useState(
		personalinfo?.second_citizenship || null
	)
	const [issueLocation, setIssueLocation] = useState(
		personalinfo?.issue_location || null
	)
	const [country, setCountry] = useState(
		personalinfo?.home_address_country || null
	)
	const [permenantCountry, setPermenantCountry] = useState(
		personalinfo?.permanent_address_country || null
	)
	const [Residency, setResidency] = useState(personalinfo?.residency || null)
	const [Files, setFiles] = useState(null)
	const dispatch = useDispatch()
	const [Formdata, setFormdata] = useState({
		about: personalinfo?.about || '',
		title: personalinfo?.title || '',
		fullname: personalinfo?.fullname || '',
		age: personalinfo?.age || '',
		status: personalinfo?.status || '',
		gender: personalinfo?.gender || '',
		personalemailid: personalinfo?.personal_email_id || '',
		dualcitizenship: personalinfo?.dual_citizenship || '',
		identificationdocument: personalinfo?.identification_document || '',
		idnumber: personalinfo?.id_number || '',
		homeaddress: personalinfo?.home_address || '',
		permenantaddress: personalinfo?.permenant_address || '',
		city: personalinfo?.home_address_city || '',
		pincode: personalinfo?.home_address_pin_code || '',
		state: personalinfo?.home_address_state || '',
		relation: personalinfo?.relationship || '',
		emergencycontactname: personalinfo?.emergency_contact_name || '',
		emergencyemail: personalinfo?.emergency_contact_email || '',
		city1: personalinfo?.permanent_address_city || '',
		pincode1: personalinfo?.permanent_address_pin_code || '',
		state1: personalinfo?.permanent_address_state || '',
		securityclearance: personalinfo?.security_clearance || ''
	})

	const [currentDate, setCurrentDate] = useState(
		personalinfo?.expiry_date || null
	)
	const [dob, setDob] = useState(personalinfo?.dob || null)
	const [personalNumber, setpersonalNumber] = useState({
		countryCode: personalinfo?.personal_mobile_number?.countryCode || '+91',
		countryName:
			personalinfo?.personal_mobile_number?.countryName || 'india',
		countryFlag: personalinfo?.personal_mobile_number?.countryFlag || 'in',
		Number: personalinfo?.personal_mobile_number?.Number || ''
	})
	const UpdateFields = (obj) => {
		setpersonalNumber({
			...personalNumber,
			countryCode: obj.countryCode,
			countryName: obj.countryName,
			countryFlag: obj.countryFlag
		})
	}
	const setuserNumber = (obj) => {
		setpersonalNumber({
			...personalNumber,
			Number: obj.Number
		})
	}
	const [EmergencyNumber, setEmergencyNumber] = useState({
		countryCode:
			personalinfo?.emergency_contact_number?.countryCode || '+86',
		countryName:
			personalinfo?.emergency_contact_number?.countryName || 'china',
		countryFlag:
			personalinfo?.emergency_contact_number?.countryFlag || 'cn',
		Number: personalinfo?.emergency_contact_number?.Number || ''
	})
	const UpdateEmergencyCountryFields = (obj) => {
		setEmergencyNumber({
			...EmergencyNumber,
			countryCode: obj.countryCode,
			countryName: obj.countryName,
			countryFlag: obj.countryFlag
		})
	}
	const setEmergencyuserNumber = (obj) => {
		setEmergencyNumber({
			...EmergencyNumber,
			Number: obj.Number
		})
	}
	useEffect(() => {
		const updatedFormdata = { ...Formdata }
		if (DYNAMIC_FIELDS.title) {
			let title = DYNAMIC_FIELDS.title.findIndex(
				(item) => item.value === personalinfo?.title
			)
			if (title !== -1) {
				updatedFormdata.title = DYNAMIC_FIELDS.title[title]
			}
			setTitle(DYNAMIC_FIELDS.title)
		}

		if (DYNAMIC_FIELDS.relationShip) {
			let relation = DYNAMIC_FIELDS.relationShip.findIndex(
				(item) => item.value === personalinfo?.relationship
			)
			if (relation !== -1) {
				updatedFormdata.relation = DYNAMIC_FIELDS.relationShip[relation]
			}
			setRelation(DYNAMIC_FIELDS.relationShip)
		}

		if (DYNAMIC_FIELDS.gender) {
			let gender = DYNAMIC_FIELDS.gender.findIndex(
				(item) => item.value === personalinfo?.gender
			)
			if (gender !== -1) {
				updatedFormdata.gender = DYNAMIC_FIELDS.gender[gender]
			}
			setGender(DYNAMIC_FIELDS.gender)
		}

		if (DYNAMIC_FIELDS.identificationDocument) {
			let doc = DYNAMIC_FIELDS.identificationDocument.findIndex(
				(item) => item.value === personalinfo?.identification_document
			)
			if (doc !== -1) {
				updatedFormdata.identificationdocument =
					DYNAMIC_FIELDS.identificationDocument[doc]
			}
			setDOcument(DYNAMIC_FIELDS.identificationDocument)
		}

		if (DYNAMIC_FIELDS.status) {
			let status = DYNAMIC_FIELDS.status.findIndex(
				(item) => item.value === personalinfo?.status
			)
			if (status !== -1) {
				updatedFormdata.status = DYNAMIC_FIELDS.status[status]
			}
			setStatus(DYNAMIC_FIELDS.status)
		}
		setTimeout(() => {
			setFormdata(updatedFormdata)
		}, 300)
	}, [DYNAMIC_FIELDS])

	const HandleFieldChanges = (fieldname, value) => {
		setSelectedDate((prevState) => {
			return {
				...prevState,
				[fieldname]: value
			}
		})
	}
	const HandleExpiryDateChanges = (fieldname, value) => {
		setSelectedExpiryDate((prevState) => {
			return {
				...prevState,
				[fieldname]: value
			}
		})
	}

	const HANDLEFORMDATA = (field, value) => {
		setFormdata({
			...Formdata,
			[field]: value
		})
	}
	function calculateAge(selectedDate) {
		const { day, month, year } = selectedDate

		if (!day || !month || !year) {
			return
		}

		const currentDate = new Date()
		const currentDay = currentDate.getDate()
		const currentMonth = currentDate.getMonth() + 1
		const currentYear = currentDate.getFullYear()

		let age = currentYear - year

		if (
			currentMonth < month ||
			(currentMonth === month && currentDay < day)
		) {
			age--
		}

		HANDLEFORMDATA('age', age)
	}

	useEffect(() => {
		calculateAge(selectedDate)
	}, [selectedDate])

	function getMonthNumber(monthName) {
		const months = {
			Jan: 1,
			Feb: 2,
			Mar: 3,
			Apr: 4,
			May: 5,
			Jun: 6,
			Jul: 7,
			Aug: 8,
			Sep: 9,
			Oct: 10,
			Nov: 11,
			Dec: 12
		}
		return months[monthName]
	}

	function getMonthNumberFromNameOrValue(month) {
		if (typeof month === 'string') {
			return getMonthNumber(month)
		}
		return month
	}

	selectedDate.month = getMonthNumberFromNameOrValue(selectedDate.month)
	const HandleDateChange = (date) => {
		setCurrentDate(date)
	}

	useEffect(() => {
		HANDLEFORMDATA('age', personalinfo?.age)
	}, [personalinfo])
	useEffect(() => {
		setBody(FORM)
	}, [
		Formdata,
		selectedDate,
		selectedExpiryDate,
		personalNumber,
		EmergencyNumber,
		Files,
		personalinfo,
		DYNAMIC_FIELDS,
		personalinfo
	])

	const [update] = useMutation(UPDATE_PROFILE_MUTATION)
	const handleLogin = async () => {
		if (!Formdata.about) {
			dispatch(
				addAlerts({
					pageId: 'updatepersonalinfo',
					type: 'danger',
					message: 'About Field Should not be Empty',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
			return
		}

		try {
			setLoader(true)

			const response = await update({
				context: {
					headers: {
						authorization: USER?.token
					}
				},
				variables: {
					input: {
						user_id: USER?.userdata?.user?.user_id,
						data: {
							about: Formdata.about?.value,
							title: Formdata.title?.value,
							fullname: Formdata.fullname,
							dob: selectedDate,
							age: Formdata.age,
							status: Formdata.status?.value,
							gender: Formdata.gender?.value,
							personal_email_id: Formdata.personalemailid,
							personal_mobile_number: personalNumber,
							citizenship: citizenship,
							residency: Residency,
							dual_citizenship: Formdata.dualcitizenship,
							second_citizenship: secondCitizenShip,
							identification_document:
								Formdata.identificationdocument?.value,
							id_number: Formdata.idnumber,
							expiry_date: selectedExpiryDate,
							// expiry_date: currentDate,
							issue_location: issueLocation,
							home_address: Formdata.homeaddress,
							home_address_city: Formdata.city,
							home_address_pin_code: Formdata.pincode,
							home_address_state: Formdata.state,
							home_address_country: country,
							permanent_address_country: permenantCountry,
							permenant_address: Formdata.permenantaddress,
							permanent_address_city: Formdata.city1,
							permanent_address_state: Formdata.state1,
							permanent_address_pin_code: Formdata.pincode1,
							permanant_address_country: permenantCountry,
							relationship: Formdata.relation?.value,
							emergency_contact_name:
								Formdata.emergencycontactname,
							emergency_contact_number: EmergencyNumber,
							emergency_contact_email: Formdata.emergencyemail,
							security_clearance: Formdata.securityclearance,
							profile_photo: Files ? Files : []
						}
					}
				}
			})
			let data = await response.data
			let updateprofile = data?.updateProfile
			if (updateprofile.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'updatepersonalinfo',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'updatepersonalinfo' }))
				}, 3000)
			} else if (
				updateprofile.status !== 200 &&
				updateprofile.errors.length > 0
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'updatepersonalinfo',
						type: 'danger',
						message: updateprofile.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'updatepersonalinfo',
						type: 'success',
						message: updateprofile.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				dispatch(updatePersonalInfo(data.updateProfile.data))
				setTimeout(() => {
					setShowDrawer(false)
					setScrollable(false)
					setScrollableFooter(false)
					setSize('md')
					setOverlay('')
					setBody(null)
					setShow(false)
					dispatch(removeAlerts({ pageId: 'updatepersonalinfo' }))
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'updatepersonalinfo',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	const FORM = (
		<>
			<form
				encType="multipart/form-data"
				className=" flex w-[350px] flex-col justify-start"
			>
				<ElasticTextBox
					id="about"
					value={Formdata.about}
					onChange={(data) => HANDLEFORMDATA('about', data)}
					labelName={'About '}
				/>
				<Simpledropdown
					options={Title}
					label="Title"
					value={Formdata?.title?.label}
					onChange={(data) => HANDLEFORMDATA('title', data)}
				/>

				<TextBox
					name={'full_name'}
					id={'full_name'}
					labelName={'Full Name'}
					type={'text'}
					value={Formdata.fullname}
					onChange={(data) => HANDLEFORMDATA('fullname', data)}
				/>
				<SearchableDatepicker
					label="DOB"
					day={selectedDate.day}
					month={selectedDate.month}
					year={selectedDate.year}
					onChange={(fieldname, value) =>
						HandleFieldChanges(fieldname, value)
					}
				/>

				<TextBox
					name={'age'}
					readOnly={true}
					id={'age'}
					labelName={'Age'}
					type={'number'}
					value={Formdata.age}
					onChange={(data) => HANDLEFORMDATA('age', data)}
				/>
				<Simpledropdown
					options={Status}
					label="Status"
					value={Formdata?.status?.label}
					onChange={(data) => HANDLEFORMDATA('status', data)}
				/>

				<Simpledropdown
					options={Gender}
					label="Gender"
					value={Formdata?.gender?.label}
					onChange={(data) => HANDLEFORMDATA('gender', data)}
				/>

				<TextBox
					name={'age'}
					id={'age'}
					value={Formdata.personalemailid}
					labelName={'Personal Email ID'}
					onChange={(data) => HANDLEFORMDATA('personalemailid', data)}
					type={'text'}
				/>
				<PhoneSelector
					labelName={'Personal Mobile Number'}
					currentCountry={personalNumber}
					onCountrySelect={(obj) => UpdateFields(obj)}
					onUSerInputChange={(obj) => setuserNumber(obj)}
				/>
				<CountrySelector
					defaultCountry={citizenship}
					label="Citizenship"
					onChange={(data) => setCitizenship(data)}
				/>
				<CountrySelector
					defaultCountry={Residency}
					label="Residency"
					onChange={(data) => setResidency(data)}
				/>
				<SimpleDropDown
					label="Dual Citizenship"
					onChange={(data) => HANDLEFORMDATA('dualcitizenship', data)}
					value={Formdata.dualcitizenship}
					options={['Yes', 'No']}
				/>
				<CountrySelector
					defaultCountry={secondCitizenShip}
					label="Second Citizenship"
					onChange={(data) => setSecondCitizenShip(data)}
				/>
				<Simpledropdown
					options={Document}
					label="Identification Document"
					value={Formdata?.identificationdocument?.label}
					onChange={(value) =>
						HANDLEFORMDATA('identificationdocument', value)
					}
				/>
				{Formdata.identificationdocument && (
					<TextBox
						name={'id_number'}
						id={'id_number'}
						value={Formdata.idnumber}
						labelName={'ID Number'}
						onChange={(data) => HANDLEFORMDATA('idnumber', data)}
						type={'text'}
					/>
				)}
				{Formdata.identificationdocument && (
					<SearchableDatepicker
						label="Expiry Date"
						key={'Expiry Date'}
						day={selectedExpiryDate.day}
						month={selectedExpiryDate.month}
						year={selectedExpiryDate.year}
						onChange={(fieldname, value) =>
							HandleExpiryDateChanges(fieldname, value)
						}
					/>
				)}

				<CountrySelector
					defaultCountry={issueLocation}
					label="Issue Location"
					onChange={(data) => setIssueLocation(data)}
				/>
				<ElasticTextBox
					id="home_address"
					value={Formdata.homeaddress}
					onChange={(data) => HANDLEFORMDATA('homeaddress', data)}
					labelName={'Home Address '}
				/>
				<ElasticTextBox
					id="permanent_address"
					value={Formdata.permenantaddress}
					onChange={(data) =>
						HANDLEFORMDATA('permenantaddress', data)
					}
					labelName={'Permenant Address '}
				/>
				<TextBox
					name={'home_city'}
					id={'home_city'}
					value={Formdata.city}
					labelName={'Home Address City'}
					onChange={(data) => HANDLEFORMDATA('city', data)}
					type={'text'}
				/>
				<TextBox
					name={'pin_code'}
					id={'pin_code'}
					value={Formdata.pincode}
					labelName={'Home Address Pin Code'}
					onChange={(data) => HANDLEFORMDATA('pincode', data)}
					type={'text'}
				/>
				<TextBox
					name={'state_home'}
					id={'state_home'}
					value={Formdata.state}
					labelName={'Home Address State'}
					onChange={(data) => HANDLEFORMDATA('state', data)}
					type={'text'}
				/>
				<CountrySelector
					defaultCountry={country}
					label="Home Address Country"
					onChange={(data) => setCountry(data)}
				/>

				<TextBox
					id={'home_city'}
					name={'home_city'}
					value={Formdata.city1}
					labelName={'Permenant Address City'}
					onChange={(data) => HANDLEFORMDATA('city1', data)}
					type={'text'}
				/>
				<TextBox
					name={'home_pincode'}
					id={'home_pincode'}
					value={Formdata.pincode1}
					labelName={'Permenant Address Pin Code'}
					onChange={(data) => HANDLEFORMDATA('pincode1', data)}
					type={'text'}
				/>
				<TextBox
					name={'work_stat'}
					id={'work_stat'}
					value={Formdata.state1}
					labelName={'Permenant Address State'}
					onChange={(data) => HANDLEFORMDATA('state1', data)}
					type={'text'}
				/>
				<CountrySelector
					defaultCountry={permenantCountry}
					label="Permenant Address Country"
					onChange={(data) => setPermenantCountry(data)}
				/>

				<TextBox
					name={'emergency_name'}
					id={'emergency_name'}
					value={Formdata.emergencycontactname}
					labelName={'Emergency Contact Name'}
					onChange={(data) =>
						HANDLEFORMDATA('emergencycontactname', data)
					}
					type={'text'}
				/>
				<Simpledropdown
					options={Status}
					label="Status"
					value={Formdata?.status?.label}
					onChange={(data) => HANDLEFORMDATA('status', data)}
				/>

				<SimpleDropDown
					label="Relationship"
					onChange={(data) => HANDLEFORMDATA('relation', data)}
					value={Formdata?.relation?.label}
					options={Relation}
				/>

				<PhoneSelector
					labelName={'Emergency Contact  Number'}
					currentCountry={EmergencyNumber}
					onCountrySelect={(obj) => UpdateEmergencyCountryFields(obj)}
					onUSerInputChange={(obj) => setEmergencyuserNumber(obj)}
				/>
				<TextBox
					name={'emergency_email'}
					id={'emergency_email'}
					value={Formdata.emergencyemail}
					labelName={'Emergency Contact Email'}
					onChange={(data) => HANDLEFORMDATA('emergencyemail', data)}
					type={'text'}
				/>
				<ImageEditor
					id="useer_selected_image"
					onImageCrop={(data) => setFiles(data)}
				/>
			</form>
		</>
	)

	const handleDrawerClose = () => {
		setShowDrawer(false)
		setTimeout(() => {
			setScrollable(false)
			setScrollableFooter(false)
			setSize('md')
			setOverlay('')
			setBody(null)
			setShow(false)
		}, 100)
	}

	useEffect(() => {
		setBody(FORM)
		setCurrentDate(personalinfo?.expiry_date)
	}, [show, Files])

	useEffect(() => {
		setShowDrawer(show)
		setScrollable(show)
		if (!show) {
			handleDrawerClose()
		}

		setTimeout(() => {
			document.body.classList.toggle('no-scroll', show)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [show])

	useEffect(() => {
		setShowDrawer(show)
		setScrollable(show)
	}, [])

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)

	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "> </h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={() => handleLogin()}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)
	return (
		<>
			{loader && <ProgressBar />}
			<PageAlerts pageId={'updatepersonalinfo'} />

			<Drawer
				label="Personal Info"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default React.memo(PersonalInfoDrawer)
