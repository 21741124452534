import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import client from '../../utils/GraphQl/apolloClient'
import { GET_MULTIPLE_CATEGORY_LISTS } from '../../utils/GraphQl/CatalogueMutations'
import { GET_MULTIPLE_SETTINGS } from '../../utils/GraphQl/SettingsMutation/index.js'
export const fetchPersonalInfoDynamicFields = createAsyncThunk(
	'personalinfoDynamic/fetchPersonalInfoDynamicFields',
	async (_, { getState, dispatch, rejectWithValue }) => {
		try {
			const authToken = getState().Auth.token

			const { data: settingsData } = await client.query({
				query: GET_MULTIPLE_SETTINGS,
				variables: {
					keys: [
						'appsetting_user_profile_title_id',
						'appsetting_user_profile_status_id',
						'appsetting_user_profile_gender_id',
						'appsetting_user_profile_relationship_id',
						'appsetting_user_profile_identification_document_id'
					]
				},
				context: { headers: { authorization: authToken } },
				fetchPolicy: 'network-only'
			})

			const multipleSettings = settingsData?.getMultipleSettings
			if (multipleSettings.status !== 200) {
				return rejectWithValue('Failed to fetch settings')
			}

			const ids = []
			let titleId, statusId, genderId, relationId, documentId

			multipleSettings.data.forEach((item) => {
				if (item.key === 'appsetting_user_profile_title_id')
					titleId = Number(item.value)
				if (item.key === 'appsetting_user_profile_status_id')
					statusId = Number(item.value)
				if (item.key === 'appsetting_user_profile_gender_id')
					genderId = Number(item.value)
				if (item.key === 'appsetting_user_profile_relationship_id')
					relationId = Number(item.value)
				if (
					item.key ===
					'appsetting_user_profile_identification_document_id'
				)
					documentId = Number(item.value)

				ids.push(Number(item.value))
			})

			const { data: categoryData } = await client.query({
				query: GET_MULTIPLE_CATEGORY_LISTS,
				variables: { ids },
				context: { headers: { authorization: authToken } },
				fetchPolicy: 'network-only'
			})

			const getMultipleCategoryLists =
				categoryData?.getMultipleCategoryLists
			if (getMultipleCategoryLists.status !== 200) {
				return rejectWithValue('Failed to fetch category lists')
			}

			const categoryListData = getMultipleCategoryLists.data

			categoryListData.forEach((element) => {
				if (element.id === titleId)
					dispatch(addTitle(element?.options_data))
				if (element.id === statusId)
					dispatch(addStatus(element?.options_data))
				if (element.id === genderId)
					dispatch(addGender(element?.options_data))
				if (element.id === relationId)
					dispatch(addRelationShip(element?.options_data))
				if (element.id === documentId)
					dispatch(addDocument(element?.options_data))
			})

			return categoryListData
		} catch (error) {
			return rejectWithValue(
				'Failed to fetch personal info dynamic fields'
			)
		}
	}
)

const InitialState = {
	title: [],
	gender: [],
	status: [],
	identificationDocument: [],
	relationShip: [],
	loading: false,
	error: null
}

const PersonalinfoDynamicFields = createSlice({
	name: 'personalinfoDynamic',
	initialState: InitialState,
	reducers: {
		addTitle: (state, action) => {
			state.title = action.payload
		},
		addGender: (state, action) => {
			state.gender = action.payload
		},
		addStatus: (state, action) => {
			state.status = action.payload
		},
		addRelationShip: (state, action) => {
			state.relationShip = action.payload
		},
		addDocument: (state, action) => {
			state.identificationDocument = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchPersonalInfoDynamicFields.pending, (state) => {
				state.loading = true
			})
			.addCase(fetchPersonalInfoDynamicFields.fulfilled, (state) => {
				state.loading = false
				state.error = null
			})
			.addCase(
				fetchPersonalInfoDynamicFields.rejected,
				(state, action) => {
					state.loading = false
					state.error = action.payload
				}
			)
	}
})

export const { addTitle, addGender, addStatus, addRelationShip, addDocument } =
	PersonalinfoDynamicFields.actions
export const PersonalinfoDynamicFieldsReducer =
	PersonalinfoDynamicFields.reducer
