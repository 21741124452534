import { gql } from '@apollo/client'
export const VERIFY_EMAIL_MUTATION = gql`
	mutation VerifyEmail($input: verifyEmailInput!) {
		verifyMail(input: $input) {
			status
			data
			message
			errors
		}
	}
`
