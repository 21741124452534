import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchRegisterSettings } from './store/RegisterSettings'
import { fetchLoginSettings } from './store/LoginSettings'
import { fetchContactUsSettings } from './store/ContactusSettings'
import { fetchFileUploadingSettings } from './store/FileUploadSettings'
import { fetchMagicLinkSettings } from './store/MagicLinkSettings'
import { fetchEmailServerSettings } from './store/EmailServerSettings'
import { fetchPersonalInfoDynamicFields } from './store/PersonalInfoDynamicFields'
import { fetchBusinessInfoDynamicFields } from './store/BusinessInfoDynamicFields'
import { getAllTableLayouts } from './store/GetTableLayouts'
import './index.css'
import './assets/css/Colors/index.css'
import './assets/css/Typography/index.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/themes/tailwind-light/theme.css'
import 'primeicons/primeicons.css'
import { Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import Register from './pages/Register'
import ProtectedRoute from './utils/ProtectedRoute'
import Forgot from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import MagicLink from './pages/MagicLink'
import SendMagicLink from './pages/SendMagicLink'
import Policy from './pages/Policy'
import EmailVerified from './pages/EmailVerified'
import Contact from './pages/Contact'
const App = () => {
	const authenticated = useSelector((state) => state.Auth.isLoggedIn)
	const dispatch = useDispatch()
	useEffect(() => {
		if (authenticated) {
			dispatch(fetchRegisterSettings())
			dispatch(fetchLoginSettings())
			dispatch(fetchContactUsSettings())
			dispatch(fetchFileUploadingSettings())
			dispatch(fetchMagicLinkSettings())
			dispatch(fetchEmailServerSettings())
			dispatch(fetchPersonalInfoDynamicFields())
			dispatch(fetchBusinessInfoDynamicFields())
			dispatch(getAllTableLayouts())
		}
	}, [authenticated])
	return (
		<>
			<Helmet>
				<title>
					{
						'TRM - Next Generation Blockchain Intelligence | Monitor, Investigate, and Mitigate Crypto Fraud and Financial Crime'
					}
				</title>
			</Helmet>
			<Routes>
				<Route
					path="*"
					element={authenticated ? <Dashboard /> : <Login />}
				/>
				<Route path="/sendmagiclink" element={<SendMagicLink />} />
				<Route path="/register" element={<Register />} />
				<Route path="/sendmagiclink" element={<SendMagicLink />} />
				<Route path="/register" element={<Register />} />
				<Route path="/forgot-password" element={<Forgot />} />
				<Route path="/policy" element={<Policy />} />
				<Route path="/contact-us" element={<Contact />} />
				<Route path="/verify" element={<EmailVerified />} />
				<Route path="/reset-password" element={<ResetPassword />} />
				<Route path="/magiclink" element={<MagicLink />} />
				<Route
					path="/*"
					element={
						<ProtectedRoute>
							{' '}
							<Dashboard />{' '}
						</ProtectedRoute>
					}
				></Route>
			</Routes>
		</>
	)
}

export default App
