import React from 'react'
import Verified from '../../../assets/img/check.png'
import Button from '../../../components/ui/Buttons/Button'
import { VERIFY_OTHEREMAIL_MUTATION } from '../../../utils/GraphQl/VerifyOtherEmails'
import { useDispatch, useSelector } from 'react-redux'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import { addAlerts } from '../../../store/AlertSlice'
import PageAlerts from '../../PageAlerts'
const BusinessInfo = () => {
	const businessinfo = useSelector(
		(state) => state.Auth?.userdata?.user?.user_data?.business_information
	)
	const authToken = useSelector((state) => state.Auth.token)
	const dispatch = useDispatch()
	const [verify] = useMutation(VERIFY_OTHEREMAIL_MUTATION)
	const handleVerification = async () => {
		try {
			const response = await verify({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						mail_type: 'work_email',
						email: user.work_email_id
					}
				}
			})
			let data = await response.data
			if (data.createMailVerificationLink.status === 200) {
				dispatch(
					addAlerts({
						pageId: 'businessinfoform',
						type: 'success',
						message: data.createMailVerificationLink.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
			} else if (data.createMailVerificationLink.status === 429) {
				dispatch(
					addAlerts({
						pageId: 'businessinfoform',
						type: 'danger',
						message: 'please login agin',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.createMailVerificationLink.status === 401) {
				dispatch(
					addAlerts({
						pageId: 'businessinfoform',
						type: 'danger',
						message: 'please login agin',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.createMailVerificationLink.status === 500) {
				dispatch(
					addAlerts({
						pageId: 'businessinfoform',
						type: 'danger',
						message: data.createMailVerificationLink.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
			}
		} catch (error) {
			dispatch(
				addAlerts({
					pageId: 'businessinfoform',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const user = {
		employee_id: businessinfo?.employee_id,
		grade: businessinfo?.grade,
		designation: businessinfo?.designation,
		role: businessinfo?.role,
		date_of_joining: businessinfo?.date_of_joining,
		clearence: businessinfo?.clearence,
		reporting_manager_administrative:
			businessinfo?.reporting_manager_administrative,
		reporting_manager_functional:
			businessinfo?.reporting_manager_functional,
		ou1_group: businessinfo?.ou1_group,
		ou2_company: businessinfo?.ou2_company,
		ou3_department: businessinfo?.ou3_department,
		work_email_id: businessinfo?.work_email_id,
		work_phone_number: businessinfo?.work_phone_number?.Number,
		work_address: businessinfo?.work_address,
		work_address_city: businessinfo?.work_address_city,
		work_address_pin_code: businessinfo?.work_address_pin_code,
		work_address_state: businessinfo?.work_address_state,
		work_address_country: businessinfo?.work_address_country?.country,
		business_address: businessinfo?.business_address,
		business_address_city: businessinfo?.business_address_city,
		business_address_pin_code: businessinfo?.business_address_pin_code,
		business_address_state: businessinfo?.business_address_state,
		business_address_country:
			businessinfo?.business_address_country?.country
	}
	return (
		<>
			<PageAlerts pageId={'businessinfoform'} />
			<div>
				<div className="bg-[#f7f7f7] px-[15px] py-[10px]">
					<div className="multipleinputs grid grid-cols-12">
						<div className="col-span-12 sm:col-span-3  md:col-span-2 lg:w-full">
							<div className="form-group">
								<label className="label">Employee ID</label>
								<div className="text-display">
									<p>{user.employee_id}</p>
								</div>
							</div>
						</div>
						<div className="col-span-12 sm:col-span-3  md:col-span-2 lg:w-full">
							<div className="form-group">
								<label className="label">Grade</label>
								<div className="text-display">
									<p>{user.grade}</p>
								</div>
							</div>
						</div>
						<div className=" col-span-12 sm:col-span-6  md:col-span-4">
							<div className="form-group">
								<label className="label">Designation</label>
								<div className="text-display">
									<p>{user.designation}</p>
								</div>
							</div>
						</div>

						<div className="col-span-12 sm:col-span-6  md:col-span-4">
							<div className="form-group">
								<label className="label">Role</label>
								<div className="text-display">
									<p>{user.role}</p>
								</div>
							</div>
						</div>

						<div className="col-span-12 sm:col-span-3  md:col-span-2 lg:w-full">
							<div className="form-group">
								<label className="label">Date of Joining</label>
								<div className="text-display">
									<p>{user.date_of_joining}</p>
								</div>
							</div>
						</div>
						<div className="col-span-12 sm:col-span-3  md:col-span-2 lg:w-full">
							<div className="form-group">
								<label className="label">Clearence</label>
								<div className="text-display">
									<p>{user.clearence}</p>
								</div>
							</div>
						</div>
						<div className="col-span-12 sm:col-span-6  md:col-span-4">
							<div className="form-group">
								<label className="label">
									Reporting Manager (Administrative)
								</label>
								<div className="text-display">
									<p>
										{user.reporting_manager_administrative}
									</p>
								</div>
							</div>
						</div>

						<div className="col-span-12 sm:col-span-6  md:col-span-4">
							<div className="form-group">
								<label className="label">
									Reporting Manager (Functional)
								</label>
								<div className="text-display">
									<p>{user.reporting_manager_functional}</p>
								</div>
							</div>
						</div>

						<div className="col-span-12 sm:col-span-4  md:col-span-4">
							<div className="form-group">
								<label className="label">OU1 (Group)</label>
								<div className="text-display">
									<p>{user.ou1_group}</p>
								</div>
							</div>
						</div>
						<div className="col-span-12 sm:col-span-4  md:col-span-4">
							<div className="form-group">
								<label className="label">OU2 (Company)</label>
								<div className="text-display">
									<p>{user.ou2_company}</p>
								</div>
							</div>
						</div>
						<div className="col-span-12 sm:col-span-4  md:col-span-4">
							<div className="form-group">
								<label className="label">
									OU3 (Department)
								</label>
								<div className="text-display">
									<p>{user.ou3_department}</p>
								</div>
							</div>
						</div>

						<div className="col-span-12 sm:col-span-6  md:col-span-6">
							<div className="form-group">
								<label className="label">Work eMail ID</label>
								<div className="text-display">
									<p>{user.work_email_id}</p>
									{businessinfo?.work_email_verified ? (
										<div className="verfied">
											<img src={Verified} alt="" />
											<span>Verified</span>
										</div>
									) : (
										<>
											{user.work_email_id && (
												<Button
													onClick={handleVerification}
													className={
														'btn btn-danger btn-large !static'
													}
												>
													verify now
												</Button>
											)}
										</>
									)}
								</div>
							</div>
						</div>
						<div className="col-span-12 sm:col-span-6  md:col-span-6">
							<div className="form-group">
								<label className="label">
									Work Phone Number
								</label>
								<div className="text-display">
									<p>{user.work_phone_number}</p>
									{/* <div className="verfied">
										<img src={Verified} alt="" />
										<span>Verified</span>
									</div> */}
								</div>
							</div>
						</div>
						<div className="col-span-12 sm:col-span-6  md:col-span-6">
							<div className="form-group">
								<label className="label">Work Address</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{user.work_address}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-span-12 sm:col-span-6  md:col-span-6">
							<div className="form-group">
								<label className="label">
									Business Address
								</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{user.business_address}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-span-12 sm:col-span-4  md:col-span-3">
							<div className="form-group">
								<label className="label">City</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{user.work_address_city}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-span-12 sm:col-span-4  md:col-span-3">
							<div className="form-group">
								<label className="label">Pin Code</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{user.work_address_pin_code}</p>
									</div>
								</div>
							</div>
						</div>

						<div className="col-span-12 sm:col-span-4  md:col-span-3">
							<div className="form-group">
								<label className="label">City</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{user.business_address_city}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-span-12 sm:col-span-4  md:col-span-3">
							<div className="form-group">
								<label className="label">Pin Code</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{user.business_address_pin_code}</p>
									</div>
								</div>
							</div>
						</div>

						<div className="col-span-12 sm:col-span-4  md:col-span-3">
							<div className="form-group">
								<label className="label">State</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{user.work_address_state}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-span-12 sm:col-span-4  md:col-span-3">
							<div className="form-group">
								<label className="label">Country</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{user.work_address_country}</p>
									</div>
								</div>
							</div>
						</div>

						<div className="col-span-12 sm:col-span-4  md:col-span-3">
							<div className="form-group">
								<label className="label">State</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{user.business_address_state}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-span-12 sm:col-span-4  md:col-span-3">
							<div className="form-group">
								<label className="label">Country</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{user.business_address_country}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default BusinessInfo
