import React, { useState } from 'react'
import Modal from '../../../components/ui/modals'
import TextBox from '../../../components/ui/TextBox'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../../components/ui/Buttons/Icon-button'
const AddBoardMembers = ({ setShowModal, type, rowData, setSelectedData }) => {
	const [overLay, setOverLay] = React.useState('')
	const [showPromptModal, setShowPromptModal] = useState(true)
	const [scrollable, setScrollable] = React.useState(true)
	const [ChairmanData, setChairmanData] = useState(
		type === 'edit_chairman' || type === 'edit_member'
			? rowData[0]
			: {
					full_name: '',
					designation: '',
					email: '',
					phone: ''
				}
	)

	const handleModalClose = () => {
		setShowModal(false)
		setScrollable(false)
		setShowPromptModal(false)
		setOverLay('false')
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		if (type == 'add_chairman') {
			let newChairmanData = {
				...ChairmanData
			}
			setSelectedData((prev) => {
				const baseSection = prev[0]?.data?.corporate_structure || {}
				const existingChairmanPersons = Array.isArray(
					baseSection?.board?.chairman
				)
					? baseSection?.board?.chairman
					: []
				const updatedChairmans = [
					...existingChairmanPersons,
					{
						id: existingChairmanPersons.length + 730,
						...newChairmanData
					}
				]
				const updatedFirstElement = {
					...prev[0],
					data: {
						...prev[0]?.data,
						corporate_structure: {
							...baseSection,
							board: {
								...baseSection.board,
								chairman: updatedChairmans
							}
						}
					}
				}
				return [updatedFirstElement, ...prev.slice(1)]
			})
		} else if (type == 'edit_chairman') {
			setSelectedData((prev) => {
				const newData = [...prev]
				if (newData[0]?.data?.corporate_structure?.board?.chairman) {
					let index =
						newData[0]?.data?.corporate_structure?.board?.chairman.findIndex(
							(company) => company.id == rowData[0].id
						)
					if (index !== -1) {
						const updatedCompany = {
							...newData[0]?.data?.corporate_structure?.board
								?.chairman[index],
							...ChairmanData
						}
						newData[0].data.corporate_structure.board.chairman[
							index
						] = updatedCompany
					}
				}
				return newData
			})
		} else if (type == 'add_member') {
			let newMember = {
				...ChairmanData
			}
			setSelectedData((prev) => {
				const baseSection = prev[0]?.data?.corporate_structure || {}
				const existingChairmanPersons = Array.isArray(
					baseSection?.board?.members
				)
					? baseSection?.board?.members
					: []
				const updatedMembers = [
					...existingChairmanPersons,
					{ id: existingChairmanPersons.length + 40, ...newMember }
				]
				const updatedFirstElement = {
					...prev[0],
					data: {
						...prev[0]?.data,
						corporate_structure: {
							...baseSection,
							board: {
								...baseSection.board,
								members: updatedMembers
							}
						}
					}
				}
				return [updatedFirstElement, ...prev.slice(1)]
			})
		} else if (type == 'edit_member') {
			setSelectedData((prev) => {
				const newData = [...prev]
				if (newData[0]?.data?.corporate_structure?.board?.members) {
					let index =
						newData[0]?.data?.corporate_structure?.board?.members.findIndex(
							(company) => company.id == rowData[0].id
						)
					if (index !== -1) {
						const updatedCompany = {
							...newData[0]?.data?.corporate_structure?.board
								?.members[index],
							...ChairmanData
						}
						newData[0].data.corporate_structure.board.members[
							index
						] = updatedCompany
					}
				}
				return newData
			})
		}
		handleModalClose()
	}

	const HANDLEChairmanData = (field, value) => {
		setChairmanData((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}
	const FORM = (
		<div className="w-[350px]">
			<TextBox
				name={'full_name'}
				id={'full_name'}
				value={ChairmanData?.full_name}
				labelName={'Full Name'}
				onChange={(data) => HANDLEChairmanData('full_name', data)}
			/>
			<TextBox
				name={'email'}
				id={'email'}
				value={ChairmanData?.email}
				labelName={'Email'}
				onChange={(data) => HANDLEChairmanData('email', data)}
			/>

			<TextBox
				name={'designation'}
				id={'designation'}
				value={ChairmanData?.designation}
				labelName={'Designation'}
				onChange={(data) => HANDLEChairmanData('designation', data)}
			/>
			<TextBox
				name={'phone'}
				id={'phone'}
				value={ChairmanData?.phone}
				labelName={'Phone'}
				onChange={(data) => HANDLEChairmanData('phone', data)}
			/>
		</div>
	)
	const footer = (
		<>
			<div className="modal-footer flex  items-center justify-between border-t  ">
				<h5 className="invisible font-normal">{'T'}</h5>
				<div className="flex items-center justify-end gap-[3px]">
					<IconButton
						// disabled={!ChairmanData.response}
						Icon={faSave}
						className="btn-success btn-large flex gap-1.5"
						type="button"
						onClick={handleSubmit}
						children="Use"
					/>
				</div>
			</div>
		</>
	)
	return (
		<div>
			<Modal
				modalSize={'lg'}
				title={
					type === 'add_chairman'
						? `Add New Chairman`
						: type == 'edit_chairman'
							? 'Edit Selected Chairman'
							: type == 'edit_member'
								? 'Edit Selected Member'
								: 'Add New Maember'
				}
				onClose={handleModalClose}
				body={FORM}
				overLay={overLay}
				showModal={showPromptModal}
				position="!top-[36%] !left-[30%] !z-[99999]"
				className="z-[9999]"
				scrollable={scrollable}
				Footer={footer}
			/>
		</div>
	)
}

export default AddBoardMembers
