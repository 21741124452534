import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import parse from 'html-react-parser'
const DrawerBody = (props) => {
	const location = useLocation()
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location.pathname])
	return (
		<div
			className="drawer-body z-50 px-4 py-4  "
			style={
				props.scrollable
					? { height: 'calc(100% - 92px)', overflowY: 'scroll' }
					: null
			}
		>
			{typeof props.body === 'string' ? parse(props.body) : props.body}
		</div>
	)
}

export default React.memo(DrawerBody)
