const SearchBar = ({ value, onChange, className, labelName }) => {
	return (
		<div className={`form-group ${className}`}>
			{labelName && <label className="label">{labelName}</label>}
			<div className="input-outer">
				<input
					className="form-control"
					type="search"
					key="search-bar"
					value={value}
					placeholder={'Search'}
					onChange={(e) => onChange(e.target.value)}
				/>
			</div>
		</div>
	)
}

export default SearchBar
