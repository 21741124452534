import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
const ProtectedRoute = ({ children }) => {
	const FORCEUPDATE = useSelector(
		(state) => state.Auth?.userdata?.forceUpdate
	)
	const isUserProfilePath = useLocation().pathname === '/userprofile'
	if (FORCEUPDATE && !isUserProfilePath) {
		return <Navigate to="/userprofile" />
	} else {
		return children
	}
}

export default ProtectedRoute
