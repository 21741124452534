import { useState, useEffect } from 'react'

function useDateRangeRestrictions() {
	const [minDate, setMinDate] = useState(null)
	const [maxDate, setMaxDate] = useState(null)

	// useEffect(() => {
	//     const today = new Date();
	//     const currentMonth = today.getMonth();
	//     const currentYear = today.getFullYear();

	//     // Calculate previous month and year
	//     const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
	//     const prevYear = currentMonth === 0 ? currentYear - 1 : currentYear;

	//     // Calculate next month and year
	//     const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
	//     const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;

	//     // Set minimum date to the start of the previous month
	//     const newMinDate = new Date(prevYear, prevMonth, 1);

	//     // Set maximum date to the end of the next month
	//     const newMaxDate = new Date(nextYear, nextMonth + 1, 0);

	//     // Update state
	//     setMinDate(newMinDate);
	//     setMaxDate(newMaxDate);

	// }, []);

	useEffect(() => {
		let today = new Date()
		let month = today.getMonth()
		let year = today.getFullYear()
		let prevMonth = month === 0 ? 11 : month
		let prevYear = prevMonth === 11 ? year - 1 : year
		let nextMonth = month === 11 ? 0 : month + 1
		let nextYear = nextMonth === 0 ? year + 1 : year

		let mindate = new Date()

		mindate.setMonth(prevMonth)
		mindate.setFullYear(prevYear)

		let maxdate = new Date()

		maxdate.setMonth(nextMonth)
		maxdate.setFullYear(nextYear)
		setMinDate(mindate)
		setMaxDate(maxdate)
	}, [])

	return { minDate, maxDate }
}

export default useDateRangeRestrictions
