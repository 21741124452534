import React, { useLayoutEffect, useState } from 'react'
import AuthLayout from '../../Layouts/AuthLayout'
import FormBody from '../../components/Common/FormBody'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Icon from '../../components/ui/icon'
import { useMutation } from '@apollo/client'
import { VERIFY_EMAIL_MUTATION } from '../../utils/GraphQl/VerifyRegisteredEmail'
import PageAlerts from '../PageAlerts'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import {
	updatePersonalEmail,
	updateWorkEmail
} from '../../store/AuthSlice/AuthSlice'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import { useDispatch, useSelector } from 'react-redux'
const EmailVerified = () => {
	const [verifyUser] = useMutation(VERIFY_EMAIL_MUTATION)
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [showVerified, setShowVerified] = useState(false)
	// Extract the token from the URL using URLSearchParams
	const token = new URLSearchParams(location.search).get('token')
	//    const token='iIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo4NywidXNlcm5hbWUiOiJJUUJBTCBGQVJPT1EiLCJlbWFpbCI6ImFrYXNoZmFyb29xMjIyQGdtYWlsLmNvbSIsImlhdCI6MTcwNzgzMDAwMCwiZXhwIjoxNzA3ODMzNjAwfQ.WFJIlGvZvvT99awtlwZbGIRurdYgCcSt6iGOJIxLBLM'
	const alertsForPage = useSelector((state) => state.Alerts['EmailVerified'])
	const ALERTS = Array.isArray(alertsForPage) ? [...alertsForPage] : []
	useLayoutEffect(() => {
		verifyEmail()
	}, [])
	const verifyEmail = async () => {
		try {
			const response = await verifyUser({
				variables: {
					input: {
						token: token
					}
				}
			})
			let data = await response.data
			// console.log('response from api')
			// console.log(data)
			if (
				data.verifyMail.status === 200 &&
				data.verifyMail.data?.verification_type === 'personal_email'
			) {
				// console.log('personal email; verified')
				dispatch(
					addAlerts({
						pageId: 'EmailVerified',
						type: 'success',
						message: data.verifyMail.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setShowVerified(true)
				setTimeout(() => {
					dispatch(
						updatePersonalEmail(data.verifyMail.data.user_data)
					)
					dispatch(removeAlerts({ pageId: 'EmailVerified' }))
					navigate('/userprofile')
				}, 3000)
			} else if (
				data.verifyMail.status === 200 &&
				data.verifyMail.data?.verification_type === 'work_email'
			) {
				// console.log('work email; verified')
				dispatch(
					addAlerts({
						pageId: 'EmailVerified',
						type: 'success',
						message: data.verifyMail.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setShowVerified(true)
				setTimeout(() => {
					dispatch(updateWorkEmail(data.verifyMail.data.user_data))
					dispatch(removeAlerts({ pageId: 'EmailVerified' }))
					navigate('/userprofile')
				}, 3000)
			} else if (data.verifyMail.status === 200) {
				// console.log('register email verified')
				dispatch(
					addAlerts({
						pageId: 'EmailVerified',
						type: 'success',
						message: data.verifyMail.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setShowVerified(true)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'EmailVerified' }))
					navigate('/')
				}, 3000)
				// login logic will goes here
				//   dispatch(loginUser(user))
				//   navigate('/dashboard')
			} else if (data.verifyMail.status !== 200) {
				dispatch(
					addAlerts({
						pageId: 'EmailVerified',
						type: 'danger',
						message: data.verifyMail.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				// console.log('something went wrong')
				return
			}
		} catch (error) {
			console.error('Login failed', error)
			// dispatch(
			// 	addAlerts({
			// 		pageId: 'EmailVerified',
			// 		type: 'danger',
			// 		message: "Something went wrong try again ",
			// 		icon: faCheck,
			// 		autoclose: 3000
			// 	})
			// )
		}
	}
	return (
		<>
			<PageAlerts pageId={'EmailVerified'} />
			{showVerified ? (
				<AuthLayout>
					<FormBody>
						<div className="flex h-full w-full items-center justify-center">
							<div>
								<Icon
									name="line-email-verifaction"
									width={'100%'}
									height={'200px'}
									className={'icon-fill-primary300'}
									viewBox={'0 0 24 24'}
								/>
							</div>
						</div>

						<div className="flex flex-col items-center justify-center ">
							<div>
								<h1>Congratulations !</h1>
							</div>
							<div className="mb-4 max-w-[334px]">
								<p>
									You have successfully confirmed your Email
									and now you are ready to access TRM
								</p>
							</div>
							{/* <div>
								<Link to="/" className="btn-primary btn-large">
									Go To Login
								</Link>
							</div> */}
						</div>
					</FormBody>
				</AuthLayout>
			) : (
				<div className="flex h-screen w-full items-center justify-center">
					<h2>Verification Under Process</h2>
				</div>
			)}
		</>
	)
}

export default EmailVerified
