import { useState, useEffect } from 'react'
import DataTableWrapper from '../../../components/ui/DataTables'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import React from 'react'
import { faClose, faEdit } from '@fortawesome/free-solid-svg-icons'
import AddCommittee from '../AddCommittee'
import EditCommittieMembers from '../EditCommitteMembers'
const CommitteeMembers = ({ originalData, setSelectedParentsData }) => {
	const [committeMembers, setcommitteMembers] = useState([])
	const [selectedData, setSelectedData] = useState([])
	const [showCommittie, setShowCommittie] = useState(false)
	const [isEdit, setIsEdit] = useState(false)
	const dopdownOptions = ['All']
	useEffect(() => {
		setcommitteMembers((prev) => [])
		const allChairmains = []
		if (Array.isArray(originalData) && originalData.length > 0) {
			originalData?.forEach((item) => {
				item?.data?.corporate_structure?.committee?.forEach((data) => {
					data?.members?.forEach((obj) => {
						allChairmains.push(obj)
					})
				})
			})
		}
		setcommitteMembers((prev) => {
			const filteredPrev = prev.filter(
				(prevObj) =>
					!allChairmains.some((newObj) => newObj.id === prevObj.id)
			)
			return [...filteredPrev, ...allChairmains]
		})
	}, [originalData])

	const handleFilter = (rowData) => {
		setSelectedParentsData((prev) => {
			if (!prev.length) return prev
			const committees = prev[0]?.data?.corporate_structure?.committee

			if (!committees) {
				return prev
			}
			const updatedCommittees = committees.map((committee) => {
				const filteredMembers = committee?.members?.filter(
					(member) => member.id !== rowData.id
				)

				return {
					...committee,
					members: filteredMembers
				}
			})

			const updatedFirstElement = {
				...prev[0],
				data: {
					...prev[0]?.data,
					corporate_structure: {
						...prev[0]?.data?.corporate_structure,
						committee: updatedCommittees
					}
				}
			}
			const newState = [updatedFirstElement, ...prev.slice(1)]
			return newState
		})

		setcommitteMembers((prevcommitteMembers) =>
			prevcommitteMembers.filter((member) => member.id !== rowData.id)
		)
	}
	const ActionBodyTemplate = (rowData) => {
		return (
			<div className="flex justify-between">
				<div className="">
					<IconButton
						type="button"
						className={
							'relative top-0.5 flex h-5 w-6 items-center justify-center rounded-sm border bg-transparent text-sm text-grey-200 hover:bg-transparent hover:text-grey-500'
						}
						onClick={(e) => {
							e.preventDefault()
							setSelectedData([{ ...rowData }])
							setIsEdit(true)
						}}
						children=""
						Icon={faEdit}
						size={'sm'}
					/>
				</div>
				<div>
					<IconButton
						type="button"
						className={
							'relative top-0.5 flex h-5 w-6 items-center justify-center rounded-sm border bg-transparent text-sm text-red-500 hover:bg-transparent hover:text-red-700'
						}
						onClick={(e) => {
							e.preventDefault()
							handleFilter(rowData)
						}}
						children=""
						Icon={faClose}
						size={'lg'}
					/>
				</div>
			</div>
		)
	}

	let COLUMNS = [
		{
			sortable: true,
			header: 'Full Name ',
			field: 'full_name',
			style: { width: '20%' },
			headerStyle: { width: '20%' }
		},

		{
			sortable: true,
			header: 'Email',
			field: 'email',
			style: { width: 'auto' },
			headerStyle: { width: 'auto' }
		},
		{
			sortable: true,
			header: 'Designation',
			field: 'designation',
			style: { width: '25%' },
			headerStyle: { width: '25%' }
		},
		{
			sortable: true,
			header: 'Phone',
			field: 'phone',
			style: { width: '15%' },
			headerStyle: { width: '15%' }
		},

		{
			header: ' Action',
			style: { width: '70px' },
			body: ActionBodyTemplate,
			headerStyle: { width: '70px' }
		}
	]

	const HandleData = (data) => {
		setSelectedData(data)
	}

	const HeaderButtons = ({ exportCSV }) => (
		<>
			{/* <Button
								onClick={()=>setShowCommittie(true)}
								children={'Add Member'}
								className='btn-large btn-primary w-fit'
								/> */}
		</>
	)

	return (
		<div>
			<div className="h5 border-b pb-1"> Committee Members</div>

			{isEdit && (
				<EditCommittieMembers
					selectedData={originalData}
					type="edit_member"
					setSelectedData={setSelectedParentsData}
					setShowModal={setIsEdit}
					rowData={selectedData}
				/>
			)}
			{showCommittie && (
				<AddCommittee
					type={'add'}
					setSelectedData={setSelectedParentsData}
					setShowModal={setShowCommittie}
				/>
			)}
			<DataTableWrapper
				showHeader={false}
				paginator={false}
				products={committeMembers}
				HeaderButtons={HeaderButtons}
				options={dopdownOptions}
				columns={COLUMNS}
				onDataSelection={HandleData}
				selectedData={selectedData}
			/>
		</div>
	)
}
export default CommitteeMembers
