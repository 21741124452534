import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
const ImageEditor = ({ labelName, onImageCrop, id, image }) => {
	const [selectedImage, setSelectedImage] = useState(null)
	const [cropData, setCropData] = useState(null)
	const [imageUrl, setImageUrl] = useState(null)
	const [croppedFile, setCroppedFile] = useState(null)
	const [imageLoaded, setImageLoaded] = useState(false)
	const cropperRef = useRef(null)
	useEffect(() => {
		if (image && !imageLoaded) {
			const reader = new FileReader()
			reader.onloadend = () => {
				setSelectedImage(reader.result)
				setImageLoaded(true)
			}
			reader.readAsDataURL(image)
		}
	}, [image, imageLoaded])
	const handleInputImageChange = (e) => {
		const file = e.target.files[0]
		if (file) {
			const reader = new FileReader()
			reader.onloadend = () => {
				setSelectedImage(reader.result)
			}
			reader.readAsDataURL(file)
		}
	}

	const onCrop = () => {
		const cropper = cropperRef.current?.cropper
		if (cropper) {
			const canvas = cropper.getCroppedCanvas()
			setCropData(canvas.toDataURL())
			canvas.toBlob((blob) => {
				setCroppedFile(
					new File([blob], 'cropped-image.png', { type: 'image/png' })
				)
			}, 'image/png')
		}
	}

	const cropHandler = (e) => {
		e.preventDefault()
		setImageUrl(cropData)
		onImageCrop(croppedFile)
	}

	useEffect(() => {
		if (cropData) {
			onImageCrop(croppedFile)
		}
	}, [cropData, onImageCrop, croppedFile])

	return (
		<>
			<div className="form-group">
				{labelName && <label className="label">{labelName}</label>}
				<div className="input-outer">
					<div className="flex flex-wrap gap-3">
						<div className="flex h-[248px] w-[200px] flex-col items-center justify-between">
							<div className="rounded-2 h-[200px] w-[200px] bg-[#f8f8f3]">
								{imageUrl && (
									<img
										src={imageUrl}
										alt="img"
										width={'100%'}
										height={'200px'}
										style={{
											maxHeight: '200px',
											minHeight: '200px'
										}}
									/>
								)}
							</div>
							<div className="w-full">
								<label
									htmlFor={id}
									style={{ cursor: 'pointer' }}
									className="btn btn-large btn-primary w-full"
								>
									Select Image
								</label>
								<input
									type="file"
									id={id}
									onChange={handleInputImageChange}
									style={{ display: 'none' }}
								/>
							</div>
						</div>
						<div className="flex h-[248px] w-[200px] flex-col items-center justify-between gap-2">
							<div className="h-[200px] w-[200px] bg-[#f8f8f3]">
								<Cropper
									ref={cropperRef}
									style={{ height: '100%', width: '100%' }}
									initialAspectRatio={1}
									src={selectedImage}
									viewMode={1}
									minCropBoxHeight={5}
									minCropBoxWidth={5}
									background={false}
									responsive={true}
									autoCropArea={1}
									checkOrientation={false}
									guides={true}
									crop={onCrop}
								/>
							</div>
							<div className="w-full">
								<button
									className="btn btn-large btn-primary w-full"
									onClick={(e) => cropHandler(e)}
								>
									Crop Image
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

ImageEditor.propTypes = {
	labelName: PropTypes.string,
	onImageCrop: PropTypes.func.isRequired
}

export default React.memo(ImageEditor)
