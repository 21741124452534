import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import client from '../../utils/GraphQl/apolloClient'
import { GET_MULTIPLE_CATEGORY_LISTS } from '../../utils/GraphQl/CatalogueMutations'
import { GET_MULTIPLE_SETTINGS } from '../../utils/GraphQl/SettingsMutation/index.js'
export const fetchBusinessInfoDynamicFields = createAsyncThunk(
	'BusinessInfoDynamic/fetchBusinessInfoDynamicFields',
	async (_, { getState, dispatch, rejectWithValue }) => {
		try {
			const authToken = getState().Auth.token
			const { data: settingsData } = await client.query({
				query: GET_MULTIPLE_SETTINGS,
				variables: {
					keys: [
						'appsetting_user_profile_identification_ou3_group_id',
						'appsetting_user_profile_identification_ou2_group_id',
						'appsetting_user_profile_identification_ou1_group_id',
						'appsetting_user_profile_identification_reporting_manager_functional_id',
						'appsetting_user_profile_identification_reporting_manager_admin_id',
						'appsetting_user_profile_identification_clearence_id',
						'appsetting_user_profile_identification_grade_id'
					]
				},
				context: { headers: { authorization: authToken } },
				fetchPolicy: 'network-only'
			})

			const multipleSettings = settingsData?.getMultipleSettings
			if (multipleSettings.status !== 200) {
				return rejectWithValue('Failed to fetch settings')
			}

			const ids = []
			let gradeId,
				clearenceId,
				reoprtingAdminID,
				reoprtingManagerID,
				ou1Id,
				ou2Id,
				ou3Id

			multipleSettings.data.forEach((item) => {
				if (
					item.key ===
					'appsetting_user_profile_identification_grade_id'
				)
					gradeId = Number(item.value)
				if (
					item.key ===
					'appsetting_user_profile_identification_clearence_id'
				)
					clearenceId = Number(item.value)
				if (
					item.key ===
					'appsetting_user_profile_identification_reporting_manager_admin_id'
				)
					reoprtingAdminID = Number(item.value)
				if (
					item.key ===
					'appsetting_user_profile_identification_reporting_manager_functional_id'
				)
					reoprtingManagerID = Number(item.value)
				if (
					item.key ===
					'appsetting_user_profile_identification_ou1_group_id'
				)
					ou1Id = Number(item.value)
				if (
					item.key ===
					'appsetting_user_profile_identification_ou2_group_id'
				)
					ou2Id = Number(item.value)
				if (
					item.key ===
					'appsetting_user_profile_identification_ou3_group_id'
				)
					ou3Id = Number(item.value)

				ids.push(Number(item.value))
			})

			const { data: categoryData } = await client.query({
				query: GET_MULTIPLE_CATEGORY_LISTS,
				variables: { ids },
				context: { headers: { authorization: authToken } },
				fetchPolicy: 'network-only'
			})

			const getMultipleCategoryLists =
				categoryData?.getMultipleCategoryLists
			if (getMultipleCategoryLists.status !== 200) {
				return rejectWithValue('Failed to fetch category lists')
			}
			const categoryListData = getMultipleCategoryLists.data
			categoryListData.forEach((element) => {
				if (element.id === gradeId)
					dispatch(addGrade(element?.options_data))
				if (element.id === clearenceId)
					dispatch(addClearence(element?.options_data))
				if (element.id === reoprtingAdminID)
					dispatch(addReportingAdmin(element?.options_data))
				if (element.id === reoprtingManagerID)
					dispatch(addReportingManager(element?.options_data))
				if (element.id === ou1Id)
					dispatch(addOu1(element?.options_data))
				if (element.id === ou2Id)
					dispatch(addOu2(element?.options_data))
				if (element.id === ou3Id)
					dispatch(addOu3(element?.options_data))
			})

			return categoryListData
		} catch (error) {
			return rejectWithValue(
				'Failed to fetch personal info dynamic fields'
			)
		}
	}
)

const InitialState = {
	grade: [],
	clearence: [],
	reporting_manager_admin: [],
	reporting_manager_functional: [],
	ou1: [],
	ou2: [],
	ou3: [],
	loading: false,
	error: null
}

const BusinessinfoDynamicFields = createSlice({
	name: 'businessInfoDynamic',
	initialState: InitialState,
	reducers: {
		addGrade: (state, action) => {
			state.grade = action.payload
		},
		addClearence: (state, action) => {
			state.clearence = action.payload
		},
		addReportingAdmin: (state, action) => {
			state.reporting_manager_admin = action.payload
		},
		addReportingManager: (state, action) => {
			state.reporting_manager_functional = action.payload
		},
		addOu1: (state, action) => {
			state.ou1 = action.payload
		},
		addOu2: (state, action) => {
			state.ou2 = action.payload
		},
		addOu3: (state, action) => {
			state.ou3 = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchBusinessInfoDynamicFields.pending, (state) => {
				state.loading = true
			})
			.addCase(fetchBusinessInfoDynamicFields.fulfilled, (state) => {
				state.loading = false
				state.error = null
			})
			.addCase(
				fetchBusinessInfoDynamicFields.rejected,
				(state, action) => {
					state.loading = false
					state.error = action.payload
				}
			)
	}
})

export const {
	addGrade,
	addClearence,
	addReportingAdmin,
	addReportingManager,
	addOu1,
	addOu2,
	addOu3
} = BusinessinfoDynamicFields.actions
export const BusinessinfoDynamicFieldsReducer =
	BusinessinfoDynamicFields.reducer
