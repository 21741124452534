import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import TextBox from '../../../components/ui/TextBox'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import SimpleDropDown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import { useMutation } from '@apollo/client'
import Button from '../../../components/ui/Buttons/Button'
import FalseAttachments from '../../../components/ui/FalseAttachments'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { ADD_ADDITIONAL_FIELDS } from '../../../utils/GraphQl/AdditionalFields'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const AddAdditionalFields = ({
	setDrawerstate,
	SetFetchAgian,
	setPageId,
	setLoader,
	scopeSelectorData
}) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const [AddProfileFields] = useMutation(ADD_ADDITIONAL_FIELDS)
	const dispatch = useDispatch()
	const [key, setKeys] = useState({
		key: '',
		value: ''
	})
	const [ArrayofObjects, setArrayofObjects] = useState([])
	const [Formdata, setFormdata] = useState({
		field_type: '',
		field_label: '',
		field_variable: '',
		data_type: '',
		field_description: ''
	})
	const handleSubmit = async () => {
		setPageId('add-additional-fields')
		try {
			setLoader(true)
			const response = await AddProfileFields({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						data_type: Formdata?.data_type,
						field_name: Formdata?.field_variable,
						field_description: Formdata?.field_description,
						field_type: Formdata?.field_type?.value,
						label: Formdata?.field_label,
						scope: Formdata?.scope.value,
						options: ArrayofObjects
						// name: Formdata?.field_variable,
						// label: Formdata?.field_label,
						// fieldtype: Formdata?.field_type,
						// description: Formdata?.field_description,
						// options: ArrayofObjects
					}
				}
			})
			let data = await response.data
			console.log('data =>', data)

			if (data.createAdditionalField.status === 400) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'add-additional-fields',
						type: 'danger',
						message: data.createAdditionalField.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.createAdditionalField.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'add-additional-fields',
						type: 'danger',
						message: 'Session Expired  please Login again ',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.createAdditionalField.status === 201) {
				setLoader(false)
				SetFetchAgian((prev) => !prev)
				dispatch(
					addAlerts({
						pageId: 'add-additional-fields',
						type: 'success',
						message: 'Field Created successsfully',
						icon: faCheck,
						autoclose: 3000
					})
				)
			} else if (data.createAdditionalField.status === 403) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'add-additional-fields',
						type: 'danger',
						message: data.createAdditionalField.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'add-additional-fields' }))
				}, 3100)
			} else if (data.createAdditionalField.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'add-additional-fields',
						type: 'danger',
						message: data.createAdditionalField.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'add-additional-fields' }))
				}, 3100)
			}
			setTimeout(() => {
				setShowDrawer(false)
				setScrollable(false)
				setScrollableFooter(false)
				setSize('md')
				setOverlay('')
				setBody(null)
				setDrawerstate(false)
				dispatch(removeAlerts({ pageId: 'setting' }))
			}, 3000)
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'add-additional-fields',
					type: 'danger',
					message: 'error',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const HandleFormData = (key, value) => {
		setFormdata((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}
	const HandleKeyValue = (key, value) => {
		setKeys((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}

	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setDrawerstate(false)
	}
	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)

	useEffect(() => {
		const addValue = (e) => {
			e.preventDefault()
			if (!key.key) {
				dispatch(
					addAlerts({
						pageId: 'add-additional-fields',
						type: 'danger',
						message: 'Please enter key ',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!key.value) {
				dispatch(
					addAlerts({
						pageId: 'add-additional-fields',
						type: 'danger',
						message: 'Please enter value',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				setArrayofObjects((prev) => [
					...prev,
					{ key: key.key, value: key.value }
				])
				setKeys({
					key: '',
					value: ''
				})
			}
		}
		const handleAttachmentRemove = (object) => {
			console.log('object => ', object)

			const FilteredValues = ArrayofObjects.filter(
				(obj) => obj.key !== object.key
			)
			setArrayofObjects(FilteredValues)
		}
		const FORM = (
			<>
				<form
					encType="multipart/form-data"
					className=" flex w-[350px] flex-col justify-start"
				>
					{scopeSelectorData ? (
						<>
							{scopeSelectorData?.field_type ===
								'simple_dropdown' && (
								<SimpleDropDown
									label={'Scope'}
									options={
										scopeSelectorData.options_data
											? scopeSelectorData.options_data
											: []
									}
									value={Formdata.scope?.label}
									onChange={(data) =>
										HandleFormData('scope', data)
									}
								/>
							)}
						</>
					) : null}
					<SimpleDropDown
						label="Field Type "
						options={[
							{
								value: 'elastic-textbox',
								label: 'Elastic Textbox'
							},
							{ value: 'textbox', label: 'Textbox' },
							{ value: 'date', label: 'Date' },
							{ value: 'radio', label: 'Radio' },
							{ value: 'drop-down', label: 'Dropdown' },
							{ value: 'checkbox', label: 'Checkbox' },
							{
								value: 'file-attachment',
								label: 'File Attachment'
							},
							{
								value: 'multi-select-drop-down',
								label: 'Multiselect Dropdown'
							}
						]}
						value={Formdata.field_type?.label}
						onChange={(data) => HandleFormData('field_type', data)}
					/>
					{Formdata.field_type.value === 'drop-down' && (
						<>
							<div className="flex w-full gap-1.5">
								<TextBox
									id={'key'}
									className="flex-1"
									labelName="Key"
									value={key.key}
									onChange={(data) =>
										HandleKeyValue('key', data)
									}
								/>
								<TextBox
									id={'value'}
									className="flex-1"
									labelName="Value"
									value={key.value}
									onChange={(data) =>
										HandleKeyValue('value', data)
									}
								/>
								<Button
									onClick={addValue}
									children="Add"
									className="btn btn-primary btn-large !mb-[10px] !mt-[31px]"
								/>
							</div>

							{ArrayofObjects.length > 0 &&
								ArrayofObjects.map((obj) => {
									return (
										<FalseAttachments
											filename={obj.key}
											onFileRemove={() =>
												handleAttachmentRemove(obj)
											}
										/>
									)
								})}
						</>
					)}
					{Formdata.field_type.value === 'multi-select-drop-down' && (
						<>
							<div className="flex w-full gap-1.5">
								<TextBox
									id={'key'}
									className="flex-1"
									labelName="Key"
									value={key.key}
									onChange={(data) =>
										HandleKeyValue('key', data)
									}
								/>
								<TextBox
									id={'value'}
									className="flex-1"
									labelName="Value"
									value={key.value}
									onChange={(data) =>
										HandleKeyValue('value', data)
									}
								/>
								<Button
									onClick={addValue}
									children="Add"
									className="btn btn-primary btn-large !mb-[10px] !mt-[31px]"
								/>
							</div>

							{ArrayofObjects.length > 0 &&
								ArrayofObjects.map((obj) => {
									return (
										<FalseAttachments
											filename={obj.key}
											onFileRemove={() =>
												handleAttachmentRemove(obj)
											}
										/>
									)
								})}
						</>
					)}
					{Formdata.field_type.value === 'checkbox' && (
						<>
							<div className="flex w-full gap-1.5">
								<TextBox
									id={'key_next'}
									className="flex-1"
									labelName="label"
									value={key.key}
									onChange={(data) =>
										HandleKeyValue('key', data)
									}
								/>
								<TextBox
									id={'value_next'}
									className="flex-1"
									labelName="Value"
									value={key.value}
									onChange={(data) =>
										HandleKeyValue('value', data)
									}
								/>
								<Button
									onClick={addValue}
									children="Add"
									className="btn btn-primary btn-large !mb-[10px] !mt-[31px]"
								/>
							</div>

							{ArrayofObjects.length > 0 &&
								ArrayofObjects.map((obj) => {
									return (
										<FalseAttachments
											filename={obj.key}
											onFileRemove={() =>
												handleAttachmentRemove(obj)
											}
										/>
									)
								})}
						</>
					)}
					{Formdata.field_type.value === 'radio' && (
						<>
							<>
								<div className="flex w-full gap-1.5">
									<TextBox
										id={'name'}
										className="flex-1"
										labelName="Name"
										value={key.key}
										onChange={(data) =>
											HandleKeyValue('key', data)
										}
									/>
									<TextBox
										id={'name_value'}
										className="flex-1"
										labelName="Value"
										value={key.value}
										onChange={(data) =>
											HandleKeyValue('value', data)
										}
									/>
									<Button
										onClick={addValue}
										children="Add"
										className="btn btn-primary btn-large !mb-[10px] !mt-[31px]"
									/>
								</div>

								{ArrayofObjects.length > 0 &&
									ArrayofObjects.map((obj) => {
										return (
											<FalseAttachments
												filename={obj.key}
												onFileRemove={() =>
													handleAttachmentRemove(obj)
												}
											/>
										)
									})}
							</>
						</>
					)}

					<TextBox
						id={'label'}
						value={Formdata.field_label}
						type={'text'}
						labelName={'Field Label '}
						onChange={(data) => HandleFormData('field_label', data)}
					/>

					<TextBox
						id={'label_value'}
						value={Formdata.field_variable}
						labelName={'Field Variable Name'}
						onChange={(data) =>
							HandleFormData('field_variable', data)
						}
					/>
					<TextBox
						id={'description'}
						key={'Field Description'}
						value={Formdata.field_description}
						labelName={'Field Description'}
						onChange={(data) =>
							HandleFormData('field_description', data)
						}
					/>
					<TextBox
						id={'datatype'}
						value={Formdata.data_type}
						key={'Data Type'}
						labelName={'Data Type'}
						onChange={(data) => HandleFormData('data_type', data)}
					/>
				</form>
			</>
		)
		setBody(FORM)
	}, [showDrawer, Formdata, key, ArrayofObjects])
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "> </h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={handleSubmit}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	return (
		<>
			<Drawer
				label="Add Additional Fields"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default AddAdditionalFields
