import React, { useEffect, useState } from 'react'
import DataTableWrapper from '../../components/ui/DataTables'
import { classNames } from 'primereact/utils'
import Button from '../../components/ui/Buttons/Button'
import { useSelector, useDispatch } from 'react-redux'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import { gql, useApolloClient, useMutation } from '@apollo/client'
import {
	faCheck,
	faEdit,
	faPlus,
	faTrash,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import PageAlerts from '../PageAlerts'
import IconButton from '../../components/ui/Buttons/Icon-button'
import AddCatalogue from './AddCatalogue'
import EditCatalogue from './EditCatalogue'
import {
	DELETE_CATALOGUE,
	GET_CATALOGUE_LIST,
	GET_MULTIPLE_CATEGORY_LISTS
} from '../../utils/GraphQl/CatalogueMutations'
import ProgressBar from '../ProgressBar'
import TableLayout from './TableLayout'
let COLUMNS = [
	{
		selectionMode: 'multiple',
		isHidden: false,
		position: 'left',
		field: 'checkbox',
		headerStyle: { width: '2%' },
		style: { width: '2%' },
		className: 'chk-dt chk-primary',
		id: 'primary'
	},
	{
		sortable: true,
		isHidden: false,
		position: 'center',
		style: { width: '14%', minWidth: '14%' },
		headerStyle: { width: '14%', minWidth: '14%' },
		header: 'Id',
		field: 'id'
	},
	{
		sortable: true,
		isHidden: false,
		position: 'center',
		style: { width: '14%', minWidth: '14%' },
		headerStyle: { width: '14%', minWidth: '14%' },
		header: 'Category',
		field: 'catalogue_category'
	},
	{
		sortable: true,
		isHidden: false,
		position: 'center',
		style: { width: '14%', minWidth: '14%' },
		headerStyle: { width: '14%', minWidth: '14%' },
		header: 'Risk heading',
		field: 'risk_heading'
	},
	{
		sortable: true,
		isHidden: false,
		position: 'center',
		style: { width: '14%', minWidth: '14%' },
		headerStyle: { width: '14%', minWidth: '14%' },
		header: 'Risk Description',
		field: 'risk_description'
	}
]
const RiskCatalogue = () => {
	const TABLE_LAYOUTS = useSelector(
		(state) => state.tableLayouts?.riskCatalogueLayout
	)
	const authToken = useSelector((state) => state.Auth.token)
	const [columns, setColumns] = useState(COLUMNS)
	const [isLayout, setIsLayout] = useState(false)
	const [DataTableData, setDataTableData] = useState([])
	const [isAdd, setIsAdd] = useState(false)
	const [isEdit, setIsEdit] = useState(false)
	const [selectedData, setSelectedData] = useState([])
	const dispatch = useDispatch()
	const [loader, setLoader] = useState(false)
	const client = useApolloClient()
	const dopdownOptions = ['All']
	const [delete_catalogue] = useMutation(DELETE_CATALOGUE)
	const [categoryId, setCategoryId] = useState(null)
	const [tagsId, setTagsId] = useState(null)
	const [categorySelectorData, setCategorySelectorData] = useState(null)
	const [tagSelectorData, setTagSelectorData] = useState(null)
	const [categoryListIds, setCategoryListIds] = useState([])

	useEffect(() => {
		if (TABLE_LAYOUTS.length > 0) {
			TABLE_LAYOUTS.forEach((data) => {
				if (data.table_name === 'table_risk_catalogue') {
					setColumns(data.structure)
					// COLUMNS=data.structure
				}
			})
		}
	}, [TABLE_LAYOUTS])

	useEffect(() => {
		const handleScrollPosition = () => {
			if (isEdit || isAdd || isLayout) {
				const scrollPosition = window.pageYOffset
				document.body.classList.add('scroll-disabled')
				document.body.style.top = `-${scrollPosition}px`
			} else {
				document.body.classList.remove('scroll-disabled')
				const scrollPosition = parseInt(
					document.body.style.top || '0',
					10
				)
				document.body.style.top = ''
				window.scrollTo(0, -scrollPosition)
			}
		}

		setTimeout(() => {
			document.body.classList.remove('scroll-disabled')
		}, 300)

		handleScrollPosition()
	}, [isEdit, isAdd, isLayout])

	const fetchCatalogue = async () => {
		try {
			setLoader(true)
			const { data } = await client.query({
				query: GET_CATALOGUE_LIST,
				variables: {
					keys: [
						'appsetting_risk_catalogue_category_id',
						'appsetting_risk_catalogue_tags_id'
					],
					pagination: {
						offset: 0,
						limit: 100000
					}
				},
				context: {
					headers: {
						authorization: authToken
					}
				}
				// fetchPolicy: 'network-only'
			})
			let multipleSettings = data?.getMultipleSettings
			let listCatalogues = data?.listCatalogues
			if (!listCatalogues || !multipleSettings) {
				dispatch(
					addAlerts({
						pageId: 'delete_catalogue',
						type: 'danger',
						message: 'No Data Found',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				throw new Error('No data received')
			}

			if (multipleSettings.status === 200) {
				const ids = []
				multipleSettings.data.forEach(async (item) => {
					if (item.key === 'appsetting_risk_catalogue_category_id')
						setCategoryId(Number(item.value))
					if (item.key === 'appsetting_risk_catalogue_tags_id')
						setTagsId(Number(item.value))
					ids.push(Number(item.value))
				})
				setCategoryListIds(ids)
			}
			if (
				listCatalogues.status === 401 ||
				multipleSettings.status === 401
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'delete_catalogue',
						type: 'danger',
						message: 'Session expired, please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'delete_catalogue' }))
				}, 3100)
			} else if (
				listCatalogues.status === 204 ||
				multipleSettings.status === 204
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'delete_catalogue',
						type: 'danger',
						message: 'No Data Found',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (listCatalogues.status === 200) {
				setLoader(false)
				if (listCatalogues.status === 200) {
					let fetchedData = listCatalogues?.data?.items.map(
						(item) => ({
							id: item.id,
							catalogue_category: item.catalogue_category,
							// catalogue_category: categorySelectorData.options_data.filter(option => option.value === item.catalogue_category ? option.label : item.catalogue_category),
							risk_description: item.risk_description,
							risk_heading: item.risk_heading,
							tags: Array.isArray(item.tags)
								? item?.tags?.join(',')
								: item?.tags
						})
					)
					setDataTableData(fetchedData)
				}
			} else if (
				listCatalogues.status === 403 ||
				multipleSettings.status === 403
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'delete_catalogue',
						type: 'danger',
						message:
							listCatalogues.errors[0] ||
							multipleSettings.errros[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'delete_catalogue' }))
				}, 3100)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'delete_catalogue',
					type: 'danger',
					message: 'Something went wrong, please try again.',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	useEffect(() => {
		fetchCatalogue()
	}, [])

	const fetchCategoryLists = async () => {
		try {
			if (categoryListIds.length > 0) {
				const { data } = await client.query({
					query: GET_MULTIPLE_CATEGORY_LISTS,
					variables: {
						ids: categoryListIds
					},
					context: {
						headers: {
							authorization: authToken
						}
					},
					fetchPolicy: 'network-only'
				})
				let categoryLists = await data?.getMultipleCategoryLists
				if (categoryLists.status === 403) {
					setLoader(false)
					dispatch(
						addAlerts({
							pageId: 'delete_catalogue',
							type: 'danger',
							message: categoryLists.errors[0],
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
					setTimeout(() => {
						dispatch(removeAlerts({ pageId: 'delete_catalogue' }))
					}, 3100)
				} else if (categoryLists.status === 401) {
					setLoader(false)
					dispatch(
						addAlerts({
							pageId: 'delete_catalogue',
							type: 'danger',
							message: 'Session expired, please login again',
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
					setTimeout(() => {
						dispatch(removeAlerts({ pageId: 'delete_catalogue' }))
					}, 3100)
				} else if (categoryLists.status === 200) {
					const categoryListData = await categoryLists.data
					await categoryListData.forEach((element) => {
						if (element.id === categoryId)
							setCategorySelectorData(element)
						if (element.id === tagsId) setTagSelectorData(element)
					})
				}
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'delete_catalogue',
					type: 'danger',
					message: 'Something went wrong, please try again.',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	useEffect(() => {
		fetchCategoryLists()
	}, [categoryListIds])

	useEffect(() => {
		let data = DataTableData.map((d) => {
			categorySelectorData?.options_data.forEach((element) => {
				if (d['catalogue_category'] === element.value)
					d['catalogue_category'] = element.label
			})
			return d
		})
		setDataTableData(data)
	}, [categorySelectorData])

	const template1 = {
		layout: 'CustomPaginator PrevPageLink PageLinks NextPageLink',
		CustomPaginator: (options) => {
			return (
				<span style={{ marginRight: 'auto' }}>
					Showing {options.first + 1} to{' '}
					{options.first + options.rows} of {options.totalRecords}{' '}
					entries
				</span>
			)
		},
		PrevPageLink: (options) => {
			options.className = classNames(options.className).replace(
				'p-disabled',
				''
			)
			return (
				<Button
					type={'button'}
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={options.onClick}
					// disabled={options.disabled}
					children={'Previous'}
				/>
			)
		},
		NextPageLink: (options) => {
			return (
				<Button
					type="button"
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={(event) => {
						// if (!stopfetching) {
						// 	fetchAgain()
						// 	setLimit((prev) => prev + 10)
						// }
						options.onClick(event)
					}}
					// disabled={options.disabled}
					children={'Next'}
				/>
			)
		},

		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page &&
					options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, {
					'p-disabled': true
				})

				return (
					<span className={className} style={{ userSelect: 'none' }}>
						...
					</span>
				)
			}
			const isCurrentPage = options.page === options.currentPage
			const linkClassName = classNames(options.className, {
				p: isCurrentPage
			})
			const linkStyle = isCurrentPage
				? {
						backgroundColor: '#7252d3',
						borderRadius: '0',
						color: '#fff'
					}
				: {
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: '#007bff'
					}
			return (
				<Button
					type="button"
					className={linkClassName}
					style={linkStyle}
					onClick={(event) => {
						// if (!stopfetching) {
						// 	fetchAgain()
						// 	setLimit((prev) => prev + 10)
						// }
						options.onClick(event)
					}}
					children={`${options.page + 1}`}
				/>
			)
		}
	}
	const paginatorLeft = (options) => {
		return (
			<span style={{ marginRight: 'auto' }}>
				Showing {options.first + 1} to{' '}
				{Math.min(options.first + options.rows, options.totalRecords)}{' '}
				entries from {options.totalRecords}
			</span>
		)
	}
	const HandleData = (data) => {
		setSelectedData(data)
	}
	const HandleEdit = () => {
		if (selectedData.length > 1) {
			dispatch(
				addAlerts({
					pageId: 'delete_cataloge',
					type: 'danger',
					message: 'Please Select only one row',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else {
			setIsEdit(true)
		}
	}
	const deleteCatalogue = async () => {
		let ID = selectedData.map((value) => String(value.id))
		try {
			setLoader(true)
			const response = await delete_catalogue({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					catalogueId: ID
				}
			})
			setLoader(false)
			let data = await response?.data
			let deleteCatalogue = data?.deleteCatalogue
			if (
				deleteCatalogue.status === 401 ||
				deleteCatalogue.status === 400
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'delete_cataloge',
						type: 'danger',
						message: deleteCatalogue.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (
				deleteCatalogue.status !== 200 &&
				deleteCatalogue?.errors?.length > 0
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'delete_cataloge',
						type: 'danger',
						message: deleteCatalogue.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (deleteCatalogue.status === 200) {
				setLoader(false)
				setDataTableData((prev) => {
					let filtered = prev.filter(
						(item) => item.id !== selectedData[0].id
					)
					setSelectedData([])
					return filtered
				})
				setSelectedData([])
				dispatch(
					addAlerts({
						pageId: 'delete_cataloge',
						type: 'success',
						message: deleteCatalogue.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
			} else if (deleteCatalogue.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'delete_cataloge',
						type: 'danger',
						message: 'something went wrong',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'delete_cataloge',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const HeaderButtons = ({ exportCSV }) => (
		<>
			<div className="flex flex-wrap items-center justify-start  gap-[3px]">
				<IconButton
					onClick={() => setIsLayout((prev) => !prev)}
					Icon={faPlus}
					size="sm"
					className="btn btn-large btn-primary flex gap-1.5"
				>
					Layout
				</IconButton>{' '}
				<IconButton
					onClick={() => setIsAdd(true)}
					Icon={faCheck}
					size="sm"
					className="btn btn-large btn-primary flex gap-1.5"
				>
					Add
				</IconButton>{' '}
				<IconButton
					disabled={selectedData.length !== 1}
					onClick={HandleEdit}
					Icon={faEdit}
					size={'sm'}
					className="btn btn-large btn-edit flex gap-1.5"
				>
					Edit
				</IconButton>
				<IconButton
					disabled={selectedData.length === 0}
					onClick={deleteCatalogue}
					Icon={faTrash}
					size={'sm'}
					className="btn btn-large btn-danger flex gap-1.5"
				>
					Delete
				</IconButton>
			</div>{' '}
		</>
	)
	return (
		<>
			{isLayout && (
				<TableLayout
					setDrawerstate={setIsLayout}
					columns={columns}
					originalColumns={COLUMNS}
					setColumns={setColumns}
				/>
			)}

			{isAdd && (
				<AddCatalogue
					setDrawerstate={setIsAdd}
					setDataTableData={setDataTableData}
					categorySelectorData={categorySelectorData}
					tagSelectorData={tagSelectorData}
					fetchCategoryLists={fetchCategoryLists}
				/>
			)}
			{isEdit && (
				<EditCatalogue
					DataTableData={DataTableData}
					setDrawerstate={setIsEdit}
					setDataTableData={setDataTableData}
					setSelectedData={setSelectedData}
					selectedData={selectedData}
					categorySelectorData={categorySelectorData}
					tagSelectorData={tagSelectorData}
					fetchCategoryLists={fetchCategoryLists}
				/>
			)}
			{loader && <ProgressBar />}
			<PageAlerts pageId="delete_cataloge" />
			<DataTableWrapper
				products={DataTableData}
				HeaderButtons={HeaderButtons}
				options={dopdownOptions}
				columns={columns}
				onDataSelection={HandleData}
				template={template1}
				paginatorLeft={paginatorLeft}
				selectedData={selectedData}
			/>
		</>
	)
}

export default RiskCatalogue
