import React, { useState, useEffect, useCallback } from 'react'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import Drawer from '../../../components/ui/drawers'
import PageAlerts from '../../PageAlerts'
import ProgressBar from '../../ProgressBar'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useDraggableInPortal } from './useDraggableInPortal'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import { useDispatch } from 'react-redux'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'

const DraggableItem = React.memo(({ product, index, renderDraggable }) => (
	<Draggable
		key={product.id}
		draggableId={product.id.toString()}
		index={index}
	>
		{renderDraggable((provided, snapshot) => (
			<div
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				className={`draggable-item ${snapshot.isDragging ? 'is-dragging' : ''}`}
			>
				<p>{product.id}</p>
				<p>{product.risk_heading}</p>
			</div>
		))}
	</Draggable>
))

const ArrangeOrder = ({ PRODUCTS, setDrawerstate, setOrigoinalData }) => {
	const [products, setProducts] = useState(PRODUCTS)
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [loader, setLoader] = useState(false)
	const [size, setSize] = useState('30%')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [SaveData, setSaveData] = useState(false)
	const renderDraggable = useDraggableInPortal()
	const dispatch = useDispatch()
	const HandleSubmit = () => {
		setProducts((prev) => {
			return prev.map((item, idx) => {
				return {
					...item,
					position: idx
				}
			})
		})
		setSaveData(true)
		localStorage.setItem('products', JSON.stringify(products))
		dispatch(
			addAlerts({
				pageId: 'arrange_order',
				type: 'success',
				message: 'Order Changed successfully',
				icon: faCheck,
				autoclose: 3000
			})
		)
		setTimeout(() => {
			handleDrawerClose()
		}, 3000)
	}
	useEffect(() => {
		localStorage.setItem('products', JSON.stringify(products))
		setOrigoinalData(products)
	}, [SaveData])

	const dragEnded = useCallback(
		(result) => {
			const { source, destination } = result
			if (!destination) return
			const updatedProducts = [...products]
			const [removed] = updatedProducts.splice(source.index, 1)
			updatedProducts.splice(destination.index, 0, removed)
			setProducts(() => updatedProducts)
		},
		[products]
	)

	const handleDrawerClose = () => {
		dispatch(removeAlerts({ pageId: 'arrange_order' }))
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setDrawerstate(false)
	}

	const drawerFooterBody = (
		<>
			<h5></h5>
			<div className="flex  gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>
				<IconButton
					onClick={HandleSubmit}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])
	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}
	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			{loader && <ProgressBar />}
			<PageAlerts pageId={'arrange_order'} />
			<Drawer
				label="Rearrange The Order"
				size={size}
				body={
					<div className="col-sm-12 col-md-5">
						<DragDropContext onDragEnd={dragEnded}>
							<Droppable droppableId="products">
								{(provided, snapshot) => (
									<div
										className={`droppable-container select-none ${snapshot.isDraggingOver ? 'is-dragging-over' : ''}`}
										ref={provided.innerRef}
										{...provided.droppableProps}
									>
										{products.map((product, idx) => (
											<DraggableItem
												key={product.id}
												product={product}
												index={idx}
												renderDraggable={
													renderDraggable
												}
											/>
										))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</div>
				}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}

export default ArrangeOrder
