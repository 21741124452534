import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import PageAlerts from '../../PageAlerts'
import TextBox from '../../../components/ui/TextBox'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import { CREATE_USER_MUTATION } from '../../../utils/GraphQl/CreateUserMutation'
import ProgressBar from '../../ProgressBar'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const AddUser = ({ setDrawerstate, fetchUsers }) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [loader, setLoader] = useState(false)
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const dispatch = useDispatch()
	const [CreateUser] = useMutation(CREATE_USER_MUTATION)
	const [Formdata, setFormdata] = useState({
		email: '',
		fullname: ''
	})
	const HANDLEFORMDATA = (field, value) => {
		setFormdata((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}
	const handleCreateUser = async () => {
		try {
			setLoader(true)
			const response = await CreateUser({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						email: Formdata.email,
						fullname: Formdata.fullname
					}
				}
			})
			let data = await response.data
			let createUser = data.createUser
			if (createUser.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'create_user',
						type: 'danger',
						message: ' Session expired please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (createUser.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'create_user',
						type: 'danger',
						message: createUser.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (
				createUser.status !== 201 &&
				createUser.errors.length > 0
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'create_user',
						type: 'danger',
						message: createUser.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (createUser.status === 201) {
				fetchUsers()
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'create_user',
						type: 'success',
						message: createUser.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					setShowDrawer(false)
					setScrollable(false)
					setScrollableFooter(false)
					setSize('md')
					setOverlay('')
					setBody(null)
					dispatch(removeAlerts({ pageId: 'create_user' }))
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'create_user',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	const FORM = (
		<form
			encType="multipart/form-data"
			className=" flex w-[350px] flex-col justify-start"
		>
			<>
				<div className="flex w-[350px] flex-col justify-start">
					<TextBox
						name={'full_name'}
						id={'full_name'}
						value={Formdata.fullname}
						labelName={'FullName'}
						onChange={(data) => HANDLEFORMDATA('fullname', data)}
					/>
					<TextBox
						name={'Email'}
						id={'Email'}
						value={Formdata.email}
						labelName={'Email'}
						onChange={(data) => HANDLEFORMDATA('email', data)}
					/>
				</div>
			</>
		</form>
	)
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setDrawerstate(false)
	}
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])

	useEffect(() => {
		setBody(FORM)
	}, [showDrawer, Formdata])

	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "> </h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={() => handleCreateUser()}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') {
			handleDrawerClose()
		}
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			{loader && <ProgressBar />}
			<PageAlerts pageId={'create_user'} />
			<Drawer
				label="Add User"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default AddUser
