import React from 'react'
import '../../../../assets/css/Buttons/index.css'
import '../../../../assets/css/DataTables/index.css'
const Button = ({
	className = 'btn-primary ',
	onClick,
	children,
	type,
	style,
	disabled,
	btnsize = 'btn-large',
	positionClass = ''
}) => {
	const disabledClass =
		'!cursor-not-allowed btn flex gap-1.5 ' + btnsize + ' ' + positionClass

	return (
		<button
			className={
				disabled ? disabledClass : className + ' ' + positionClass
			}
			type={type}
			onClick={onClick}
			style={style}
			disabled={disabled}
		>
			{children}
		</button>
	)
}

Button.defaultProps = {
	className: 'primary-300',
	type: 'button',
	disabled: false
}

export default Button
