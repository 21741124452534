import React, { useState } from 'react'
import AuthLayout from '../../Layouts/AuthLayout'
import FormHeader from '../../components/Common/FormHeader'
import Formfooter from '../../components/Common/FormFooter'
import FormBody from '../../components/Common/FormBody'
import PageAlerts from '../PageAlerts'
import Button from '../../components/ui/Buttons/Button'
import TextBox from '../../components/ui/TextBox'
import Icon from '../../components/ui/icon'
import { useMutation } from '@apollo/client'
import { MAGICLINK_MUTATION } from '../../utils/GraphQl/MagicLinkMutation'
import { useDispatch } from 'react-redux'
import { addAlerts } from '../../store/AlertSlice'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'

const SendMagicLink = () => {
	const [email, setEmail] = useState('')
	const [sendMagicLink] = useMutation(MAGICLINK_MUTATION)
	const dispatch = useDispatch()
	const [loader, setLoader] = useState(false)
	const HandleChange = (data) => {
		setEmail(data)
	}
	const VerifyUser = async () => {
		try {
			setLoader(true)
			const response = await sendMagicLink({
				variables: {
					input: {
						email: email
					}
				}
			})
			let data = await response.data
			if (data.sendMagicLink.errors?.length > 0) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'magiclink',
						type: 'danger',
						message: data.sendMagicLink.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.sendMagicLink.status === 400) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'magiclink',
						type: 'danger',
						message: data.sendMagicLink.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.sendMagicLink.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'magiclink',
						type: 'danger',
						message: data.sendMagicLink.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.sendMagicLink.status === 200) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'magiclink',
						type: 'success',
						message: data.sendMagicLink.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'magiclink',
					type: 'danger',
					message: 'Something went wrong.',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault()
		if (!email) {
			dispatch(
				addAlerts({
					pageId: 'magiclink',
					type: 'danger',
					message: 'Please insert your email address.',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else {
			const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
			let isValid = EMAIL_REGEXP.test(email)
			if (!isValid) {
				dispatch(
					addAlerts({
						pageId: 'magiclink',
						type: 'danger',
						message: 'Please insert a valid email address.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				VerifyUser()
			}
		}
	}

	return (
		<>
			<PageAlerts pageId={'magiclink'} />
			<AuthLayout>
				<FormHeader
					title="Send Magic Link"
					message="Welcome! Please submit to Sign-in"
				/>
				<FormBody>
					<TextBox
						id={'email_adress'}
						name={'email_address'}
						onChange={HandleChange}
						labelName="Email Address"
						type={'text'}
						className={'mb-4'}
					/>
					<div className="mb-3">
						<p className="small-text ">
							By clicking <strong>Submit</strong> below, you agree
							to our Terms and Conditions and Privacy Policy.
						</p>
					</div>

					{loader ? (
						<Button
							className="btn-large btn-primary btn-all flex  w-full cursor-not-allowed gap-2"
							isIconBtn={true}
							onClick={() => console.log('')}
						>
							<span className="waiting-loader"> </span> Please
							Wait
						</Button>
					) : (
						<Button
							className="btn-large btn-primary btn-all flex w-full gap-2 p-1"
							isIconBtn={true}
							onClick={handleSubmit}
						>
							<Icon
								name="line-lock"
								width={'16px'}
								height={'16px'}
								className={'icon-fill-white'}
								viewBox={'0 0 24 24'}
							/>{' '}
							Submit
						</Button>
					)}
				</FormBody>
				<Formfooter
					message1="Don't have an account"
					linktext1="Create an Account"
					link1="register"
					message2="Need assistance or have questions"
					linktext2="Contact Support"
					link2="contact-us"
				/>
			</AuthLayout>
		</>
	)
}

export default SendMagicLink
