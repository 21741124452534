import React, { useEffect } from 'react'
import DataTableWrapper from '../../components/ui/DataTables'
import { useState } from 'react'
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import { classNames } from 'primereact/utils'
import Button from '../../components/ui/Buttons/Button'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '../../components/ui/Buttons/Icon-button'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import { logoutUser } from '../../store/AuthSlice/AuthSlice'
import ProgressBar from '../ProgressBar'
import { DELETE_BASE } from '../../utils/GraphQl/KnowledgeBase'
import Card from '../../components/ui/Card'
import {
	faPlus,
	faPen,
	faTrash,
	faFileArrowDown,
	faFileExport,
	faTriangleExclamation,
	faCheck
} from '@fortawesome/free-solid-svg-icons'
import PageAlerts from '../PageAlerts'
import Add from './Add'
import Edit from './Edit'
import Import from './Import'
import TableLayout from './TableLayout'
const GET_BASES = gql`
	query GetAllBases($pagination: PaginationInput) {
		getAllBases(pagination: $pagination) {
			status
			message
			errors
			data
		}
	}
`
const GET_LISTS = gql`
	query GetCategoryList($listId: Int) {
		getCategoryList(list_id: $listId) {
			status
			message
			data
			errors
		}
	}
`

const BusinessObjectives = () => {
	let COLUMNS = [
		{
			selectionMode: 'multiple',
			isHidden: false,
			position: 'left',
			field: 'checkbox',
			headerStyle: { width: '2%' },
			style: { width: '2%' },
			className: 'chk-dt chk-primary',
			id: 'primary'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'left',
			style: { width: '3%', minWidth: '3%' },
			headerStyle: { width: '3%', minWidth: '3%' },
			header: 'Id',
			field: 'id'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'left',
			style: { width: '3%', minWidth: '3%' },
			headerStyle: { width: '3%', minWidth: '3%' },
			header: 'Category',
			field: 'category',
			className: 'column-Category'
		},
		{
			sortable: true,
			isHidden: false,
			position: 'left',
			style: { width: '13%', minWidth: '13%' },
			headerStyle: { width: '13%', minWidth: '13%' },
			header: 'Heading',
			field: 'heading',
			className: 'column-Heading'
		},

		{
			sortable: true,
			isHidden: false,
			position: 'left',
			style: { width: '81%', minWidth: '81%' },
			headerStyle: { width: '81%', minWidth: '81%' },
			header: 'Detailed Description',
			field: 'description',
			className: 'column-DetailedDescription'
		}
	]
	const userdata = useSelector((state) => state.Auth)
	const TABLE_LAYOUTS = useSelector(
		(state) => state.tableLayouts?.businessObjectiveLayout
	)
	const authToken = userdata.token
	const [Components, setComponents] = useState([])
	const [SelectedData, setSelectedData] = useState([])
	const [limit, setLimit] = useState(60)
	const [stopfetching, setStopFetching] = useState(false)
	const [loader, setLoader] = useState(true)
	const [showAddList, setShowAddList] = useState(false)
	const [showEditList, setShowEditList] = useState(false)
	const [showImportList, setShowImportList] = useState(false)
	const [RecordsCount, setRecodrsCount] = useState()
	const [DeleteBases] = useMutation(DELETE_BASE)
	const client = useApolloClient()
	const dispatch = useDispatch()
	const dopdownOptions = ['All', 'Category', 'Heading']
	const [fetchedFields, setFetchedFields] = useState({})
	const [columns, setColumns] = useState([...COLUMNS])
	const [isLayout, setIsLayout] = useState(false)
	useEffect(() => {
		if (TABLE_LAYOUTS.length > 0) {
			TABLE_LAYOUTS.forEach((data) => {
				if (data.table_name == 'table_business_objective') {
					setColumns(data.structure)
					COLUMNS = data.structure
				}
			})
		}
	}, [TABLE_LAYOUTS])
	useEffect(() => {
		const handleScrollPosition = () => {
			if (showImportList || showEditList || showAddList || isLayout) {
				const scrollPosition = window.pageYOffset
				document.body.classList.add('scroll-disabled')
				document.body.style.top = `-${scrollPosition}px`
			} else {
				document.body.classList.remove('scroll-disabled')
				const scrollPosition = parseInt(
					document.body.style.top || '0',
					10
				)
				document.body.style.top = ''
				window.scrollTo(0, -scrollPosition)
			}
		}

		setTimeout(() => {
			document.body.classList.remove('scroll-disabled')
		}, 300)

		handleScrollPosition()
	}, [showImportList, showEditList, showAddList, isLayout])
	const fetchAgain = async () => {
		try {
			const { data } = await client.query({
				query: GET_BASES,
				variables: {
					pagination: {
						offset: limit,
						limit: 10
					}
				},
				context: {
					headers: {
						authorization: authToken
					}
				}
			})

			let bases = data?.getAllBases
			if (bases.status === 401) {
				dispatch(
					addAlerts({
						pageId: 'DeleteBase',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'DeleteBase' }))
				}, 3100)
			} else if (bases.status === 204) {
				setStopFetching(true)
			} else if (bases.status === 200) {
				setLoader(false)
				let FinalData = bases.data.items
				// console.log('Fetching again =>', FinalData)
				FinalData.forEach((item) => {
					if (
						!Components.find(
							(existingItem) => existingItem.id === item.id
						)
					) {
						setComponents((prev) => [
							...prev,
							{
								...item,
								// categories:item.category,
								category: item?.category?.name
							}
						])
					}
				})
			} else if (bases.status === 403) {
				dispatch(
					addAlerts({
						pageId: 'DeleteBase',
						type: 'danger',
						message: bases.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'DeleteBase' }))
				}, 3100)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'DeleteBase',
					type: 'danger',
					message: 'Something went wrong pleasse try again.',
					icon: faCheck,
					autoclose: 3000
				})
			)
		}
	}

	const HandleDeleteBase = async (BASE_ID) => {
		try {
			setLoader(true)
			const response = await DeleteBases({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					baseId: BASE_ID
				}
			})
			let DeleteBase = await response.data.deleteBase
			// let Bases = data?.createBase
			if (DeleteBase.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'DeleteBase',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'DeleteBase' }))
				}, 3000)
			} else if (
				DeleteBase.status !== 200 &&
				DeleteBase.errors.length > 0
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'DeleteBase',
						type: 'danger',
						message: DeleteBase.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (DeleteBase.status === 200) {
				setSelectedData([])
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'DeleteBase',
						type: 'success',
						message: DeleteBase.message,
						icon: faCheck,
						autoclose: 3000
					})
				)

				const filteredComponents = Components.filter(
					(item) => !BASE_ID.includes(item.id)
				)
				setComponents(filteredComponents)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'DeleteBase' }))
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'DeleteBase',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoader(true)
				const { data } = await client.query({
					query: GET_BASES,
					variables: {
						pagination: {
							offset: 0,
							limit: limit
						}
					},
					context: {
						headers: {
							authorization: authToken
						}
					}
					// fetchPolicy: 'network-only'
				})

				let bases = data?.getAllBases
				if (bases.status === 401) {
					setLoader(false)
					dispatch(
						addAlerts({
							pageId: 'DeleteBase',
							type: 'danger',
							message: 'Session expired, please login again',
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
					setTimeout(() => {
						dispatch(logoutUser())
						dispatch(removeAlerts({ pageId: 'DeleteBase' }))
					}, 3100)
				}
				if (bases.status === 403) {
					setLoader(false)
					dispatch(
						addAlerts({
							pageId: 'DeleteBase',
							type: 'danger',
							message: bases.message,
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
				} else if (bases.status === 204) {
					setLoader(false)
					dispatch(
						addAlerts({
							pageId: 'DeleteBase',
							type: 'danger',
							message: 'No data found',
							icon: faTriangleExclamation,
							autoclose: 3000
						})
					)
				} else if (bases.status === 200) {
					setLoader(false)
					let FinalData = bases.data.items
					setRecodrsCount(bases.data.totalCount)
					FinalData.forEach((item) => {
						if (
							!Components.find(
								(existingItem) => existingItem.id === item.id
							)
						) {
							setComponents((prev) => [
								...prev,
								{
									...item
								}
							])
						}
					})
				}
			} catch (error) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'DeleteBase',
						type: 'danger',
						message: 'Something went wrong, please try again.',
						icon: faCheck,
						autoclose: 3000
					})
				)
			}
		}

		fetchData()
	}, [])

	const { data, loading, error } = useQuery(
		gql`
			query MapSettings {
				FieldValue: getSetting(
					key: "appsetting_knowledgebase_category_id"
				) {
					data
				}
			}
		`,
		{
			context: {
				headers: {
					authorization: authToken
				}
			}
		}
	)
	useEffect(() => {
		if (loading) {
			setLoader(true)
		} else if (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'contact_settings',
					type: 'danger',
					message: 'Failed to fetch settings.',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (!loading && !error && data) {
			setLoader(false)
			const { FieldValue } = data
			let val = Number(FieldValue?.data?.value)
			fetchFields(val)
		}
	}, [data, loading, error])

	const fetchFields = async (val) => {
		try {
			setLoader(true)
			const { data, loading, error } = await client.query({
				query: GET_LISTS,
				variables: {
					listId: val
				},
				context: {
					headers: {
						authorization: authToken
					}
				}
			})
			let bases = data?.getCategoryList
			if (bases.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'list-category',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'list-category' }))
				}, 3100)
			} else if (bases.status === 204) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'list-category',
						type: 'danger',
						message: 'No data found',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (bases.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'list-category',
						type: 'danger',
						message: bases.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (bases.status === 200) {
				setLoader(false)
				let FinalData = bases.data
				setFetchedFields(FinalData)
			} else if (bases.status === 403) {
				dispatch(
					addAlerts({
						pageId: 'DeleteBase',
						type: 'danger',
						message: bases.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'DeleteBase' }))
				}, 3100)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'list-category',
					type: 'danger',
					message: 'Something went wrong pleasse try again.',
					icon: faCheck,
					autoclose: 3000
				})
			)
		}
	}

	const template1 = {
		layout: 'CustomPaginator PrevPageLink PageLinks NextPageLink',
		CustomPaginator: (options) => {
			return (
				<span style={{ marginRight: 'auto' }}>
					Showing {options.first + 1} to{' '}
					{options.first + options.rows} of {options.totalRecords}{' '}
					entries
				</span>
			)
		},
		PrevPageLink: (options) => {
			options.className = classNames(options.className).replace(
				'p-disabled',
				''
			)
			return (
				<Button
					type={'button'}
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={options.onClick}
					// disabled={options.disabled}
					children={'Previous'}
				/>
			)
		},
		NextPageLink: (options) => {
			return (
				<Button
					type="button"
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={(event) => {
						if (!stopfetching) {
							fetchAgain()
							setLimit((prev) => prev + 10)
						}
						options.onClick(event)
					}}
					// disabled={options.disabled}
					children={'Next'}
				/>
			)
		},
		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page &&
					options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, {
					'p-disabled': true
				})

				return (
					<span className={className} style={{ userSelect: 'none' }}>
						...
					</span>
				)
			}
			const isCurrentPage = options.page === options.currentPage
			const linkClassName = classNames(options.className, {
				p: isCurrentPage
			})
			const linkStyle = isCurrentPage
				? {
						backgroundColor: '#7252d3',
						borderRadius: '0',
						color: '#fff'
					}
				: {
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: '#007bff'
					}
			return (
				<Button
					type="button"
					className={linkClassName}
					style={linkStyle}
					onClick={(event) => {
						if (!stopfetching) {
							fetchAgain()
							setLimit((prev) => prev + 10)
						}
						options.onClick(event)
					}}
					children={`${options.page + 1}`}
				/>
			)
		}
	}
	const paginatorLeft = (options) => {
		return (
			<span style={{ marginRight: 'auto' }}>
				Showing {options.first + 1} to{' '}
				{Math.min(options.first + options.rows, options.totalRecords)}{' '}
				entries from {RecordsCount}
			</span>
		)
	}
	const HandleAddFields = () => {
		setShowAddList((prev) => !prev)
	}
	const HandleEditFields = () => {
		if (SelectedData.length < 1) {
			dispatch(
				addAlerts({
					pageId: 'DeleteBase',
					type: 'danger',
					message: 'Please Select Field ',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (SelectedData.length > 1) {
			dispatch(
				addAlerts({
					pageId: 'DeleteBase',
					type: 'danger',
					message: 'Please Select only one Field ',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else {
			setShowEditList((prev) => !prev)
		}
	}
	const HandleImportFields = () => {
		setShowImportList((prev) => !prev)
	}
	const exportDataAsJson = () => {
		if (SelectedData.length < 1) {
			dispatch(
				addAlerts({
					pageId: 'list-category',
					type: 'danger',
					message: 'Please slect fields to download',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (SelectedData.length > 0) {
			const jsonData = JSON.stringify(SelectedData)
			const blob = new Blob([jsonData], { type: 'application/json' })
			const url = URL.createObjectURL(blob)
			const link = document.createElement('a')
			link.href = url
			link.download = 'exported_data.json'
			link.click()
			URL.revokeObjectURL(url)
		}
	}
	const HandleDelete = () => {
		if (SelectedData.length < 1) {
			dispatch(
				addAlerts({
					pageId: 'DeleteBase',
					type: 'danger',
					message: 'Please Select Field ',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (SelectedData.length >= 1) {
			let base_ids = []
			SelectedData.forEach((item) => base_ids.push(item.id))
			HandleDeleteBase(base_ids)
		}
	}
	const HeaderButtons = ({ exportCSV }) => (
		<>
			<div className="flex flex-wrap items-center justify-start  gap-[3px]">
				<IconButton
					onClick={() => setIsLayout((prev) => !prev)}
					Icon={faPlus}
					size="sm"
					className="btn btn-large btn-primary flex gap-1.5"
				>
					Layout
				</IconButton>
				<IconButton
					onClick={HandleAddFields}
					Icon={faPlus}
					size="sm"
					className="btn btn-large btn-primary flex gap-1.5"
				>
					Add
				</IconButton>
				<IconButton
					disabled={SelectedData.length === 0}
					onClick={HandleEditFields}
					Icon={faPen}
					size={'sm'}
					className="btn btn-large btn-edit flex gap-1.5"
					dis
				>
					Edit
				</IconButton>
				<IconButton
					Icon={faTrash}
					disabled={SelectedData.length === 0}
					size={'sm'}
					onClick={HandleDelete}
					className="btn btn-large btn-danger flex gap-1.5"
				>
					Delete
				</IconButton>

				<IconButton
					disabled={SelectedData.length === 0}
					Icon={faFileExport}
					size={'sm'}
					className="btn btn-large btn-primary flex gap-1.5"
					onClick={exportDataAsJson}
				>
					Export
				</IconButton>

				<IconButton
					Icon={faFileArrowDown}
					onClick={HandleImportFields}
					size={'sm'}
					className="btn btn-large btn-primary flex gap-1.5"
				>
					Import
				</IconButton>
			</div>{' '}
		</>
	)
	const HandleData = (data) => {
		setSelectedData(data)
	}
	return (
		<Card>
			{loader && <ProgressBar />}
			{isLayout && (
				<TableLayout
					setDrawerstate={setIsLayout}
					columns={columns}
					originalColumns={COLUMNS}
					setColumns={setColumns}
				/>
			)}
			{showAddList && (
				<Add
					setIsAddList={setShowAddList}
					setState={setComponents}
					fetchedFields={fetchedFields}
				/>
			)}
			{showEditList && (
				<Edit
					setIsEditList={setShowEditList}
					baseData={SelectedData}
					setBOSelectedData={setSelectedData}
					ExistingData={Components}
					fetchedFields={fetchedFields}
				/>
			)}
			{showImportList && (
				<Import
					setIsImportList={setShowImportList}
					setState={setComponents}
					ExistingData={Components}
				/>
			)}
			<PageAlerts pageId={'DeleteBase'} />
			<DataTableWrapper
				products={Components}
				HeaderButtons={HeaderButtons}
				options={dopdownOptions}
				columns={columns}
				onDataSelection={HandleData}
				template={template1}
				paginatorLeft={paginatorLeft}
				selectedData={SelectedData}
			/>
		</Card>
	)
}
export default BusinessObjectives
