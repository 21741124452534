import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { UPDATE_MULTIPLE_SETTINGS } from '../../utils/GraphQl/SettingsMutation'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import { logoutUser } from '../../store/AuthSlice/AuthSlice'
import TextBox from '../../components/ui/TextBox'
import GlobalDrawer from '../GlobalDrawer'
import PageAlerts from '../PageAlerts'
import { removePage } from '../../store/SettingsSlice'
import {
	faCheck,
	faTriangleExclamation,
	faBook,
	faUniversalAccess
} from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../components/ui/Buttons/Icon-button'
import useGlobalKeyPress from '../../hooks/globalKeyPress'
import { fetchFileUploadingSettings } from '../../store/FileUploadSettings'
const FileUploadSettings = () => {
	const [showDrawer, setShowDrawer] = useState(true)
	const [body, setBody] = useState(null)
	const [loader, setLoader] = useState(false)
	const authToken = useSelector((state) => state.Auth.token)
	const dispatch = useDispatch()
	const [FormData, setFormData] = useState({
		allowedfiles: '',
		foldername: ''
	})
	const [updateSettings] = useMutation(UPDATE_MULTIPLE_SETTINGS)
	const handleFileSettings = async () => {
		try {
			setLoader(true)
			const response = await updateSettings({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						appsetting_additional_field_upload_folder: {
							value: FormData.foldername
						},
						appsetting_additional_field_allowed_file_types: {
							value: FormData.allowedfiles
						}
					}
				}
			})
			let data = await response.data
			if (data.updateMultipleSettings.status === 400) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'file-upload-settings',
						type: 'danger',
						message: 'Not found',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateMultipleSettings.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'file-upload-settings',
						type: 'danger',
						message: data.updateMultipleSettings.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateMultipleSettings.status === 403) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'file-upload-settings',
						type: 'danger',
						message: data.updateMultipleSettings.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateMultipleSettings.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'file-upload-settings',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'file-upload-settings' }))
				}, 3000)
			} else if (data.updateMultipleSettings.status === 200) {
				setLoader(false)
				dispatch(fetchFileUploadingSettings())
				dispatch(
					addAlerts({
						pageId: 'file-upload-settings',
						type: 'success',
						message: 'Data updated successsfully',
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					handleDrawerClose()
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'file-upload-settings',
					type: 'danger',
					message: 'error',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const { data, loading, error } = useSelector(
		(state) => state.fileUploadingSettings
	)
	const settingData = data
	useEffect(() => {
		if (loading) {
			// setLoader(true)
		} else if (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'file-upload-settings',
					type: 'danger',
					message: 'Failed to fetch  settings.',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (settingData?.AllowedFiles?.status === 403) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'file-upload-settings',
					type: 'danger',
					message: settingData?.AllowedFiles?.errors[0],
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (!loading && !error && data) {
			if (settingData) {
				setLoader(false)
				const { AllowedFiles, FolderName } = settingData
				let AllowedFiless = AllowedFiles?.data?.value
				let FolderNames = FolderName?.data?.value
				setFormData((prev) => {
					return {
						...prev,
						allowedfiles: AllowedFiless || '',
						foldername: FolderNames || ''
					}
				})
			}
		}
	}, [data])

	const HanldeChange = (fields, val) => {
		setFormData((prev) => {
			return {
				...prev,
				[fields]: val
			}
		})
	}
	const FORM = (
		<>
			<PageAlerts pageId={'file-upload-settings'} />
			<form className="w-[350px]">
				<TextBox
					id={'files'}
					name={'files'}
					labelName={'Allowed Files'}
					value={FormData.allowedfiles}
					onChange={(val) => HanldeChange('allowedfiles', val)}
				/>
				<TextBox
					id={'folder_name'}
					name={'folder_name '}
					labelName={'Folder Name'}
					value={FormData.foldername}
					onChange={(val) => HanldeChange('foldername', val)}
				/>
			</form>
		</>
	)
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])
	useEffect(() => {
		setBody(FORM)
	}, [showDrawer, FormData])
	const handleDrawerClose = () => {
		dispatch(removeAlerts({ pageId: 'file-upload-settings' }))
		dispatch(removePage())
	}
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal ">{''}</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={() => handleFileSettings()}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') {
			handleDrawerClose()
		}
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			<GlobalDrawer
				label={'File Upload Settings'}
				onClose={handleDrawerClose}
				body={body}
				scrollable={true}
				overlay={''}
				size={'md'}
				showDrawer={true}
				drawerFooterBody={drawerFooterBody}
				loader={loader}
				scrollableFooter={false}
				drawerPosition={'right'}
				pageId={'file-upload-settings'}
			/>
		</>
	)
}
export default FileUploadSettings
