import React, { useEffect, useState } from 'react'
import ModalSm from './ModalSm'
import ModalXs from './ModalXs'
import ModalMd from './ModalMd'
import ModalLg from './ModalLg'
import ModalXl from './ModalXl'
import ModalFull from './ModalFull'
import ModalLarge from './ModalLarge'
import ModalAuto from './ModalAuto'
export default function Modal({ ...props }) {
	const [openModal, setOpenModal] = useState(false)
	const [extraClass, setExtraClass] = useState(false)
	useEffect(() => {
		if (props.showModal === true) {
			setOpenModal(props.showModal)
			setTimeout(() => {
				setExtraClass(true)
			}, 200)
		} else {
			setExtraClass(false)
			setTimeout(() => {
				setOpenModal(props.showModal)
			}, 500)
		}
		return () => {
			setExtraClass(false)
		}
	}, [props.showModal])
	props.extraClass = extraClass
	return openModal ? (
		<>
			{props.modalSize === 'xs' ? <ModalXs {...props} /> : null}
			{props.modalSize === 'sm' ? <ModalSm {...props} /> : null}
			{props.modalSize === 'md' ? <ModalMd {...props} /> : null}
			{props.modalSize === 'lg' ? <ModalLg {...props} /> : null}
			{props.modalSize === 'xl' ? <ModalXl {...props} /> : null}
			{props.modalSize === '3xl' ? <ModalLarge {...props} /> : null}
			{props.modalSize === 'full' ? <ModalFull {...props} /> : null}
			{props.modalSize === 'auto' ? <ModalAuto {...props} /> : null}
			{props.overLay === 'custom' ? (
				<>
					<div
						className={`fixed inset-0 z-[999] ${props.className}`}
						style={{
							backdropFilter: `${extraClass ? 'blur(3px)' : 'blur(0px)'}`,
							backgroundColor: `${extraClass ? 'rgba(0,0,0,0.55)' : 'rgba(0,0,0,0)'}`,
							transitionProperty:
								'background-color, backdrop-filter',
							transitionDuration: '0.2s',
							transitionTimingFunction: 'linear'
						}}
						onClick={() => props.onClose()}
					></div>
				</>
			) : (
				<>
					<div
						className={
							'fixed inset-0 z-[999] bg-black ' +
							(extraClass ? 'opacity-50' : 'opacity-0')
						}
						style={{
							transitionProperty: 'opacity',
							transitionDuration: '0.2s',
							transitionTimingFunction: 'linear'
						}}
						onClick={() => props.onClose()}
					></div>
				</>
			)}
		</>
	) : null
}
