import React, { useState, useEffect } from 'react'
import Modal from '../../../components/ui/modals'
import TextBox from '../../../components/ui/TextBox'
import ElasticTextBox from '../../../components/ui/Elastic-textbox'
import Attachment from '../../../components/ui/Attachments'
import KeyValueDropDown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { useSelector } from 'react-redux'
import FalseAttachment from '../../../components/ui/FalseAttachments'
import { useMutation } from '@apollo/client'
import { FILE_UPLOADING_CONTEXT } from '../../../utils/GraphQl/OrganizationContext'
const Purpose = [
	{ label: 'Genrel Office Use', value: 'general office use' },
	{
		label: 'Data Center Facility',
		value: 'Data Center Facility'
	},
	{
		label: 'Manufacturing Facility',
		value: 'Manufacturing Facility'
	},
	{
		label: 'Healthcare Facility',
		value: 'Healthcare Facility'
	},
	{ label: 'Storage Facility', value: 'Storage Facility' },
	{ label: 'Others', value: 'others' }
]
const RegesteredAddressDocument = [
	{ label: 'Ejari Certificate', value: 'Ejari Certificate' },
	{
		label: 'Tenancy Contract or Lease Agreement',
		value: 'Trade License Application'
	},
	{ label: 'NOC', value: 'No objection certificate' },
	{ label: 'Utility Bill', value: 'Utility Bill' }
]
const AddExtraLocations = ({
	setShowModal,
	type,
	rowData,
	setSelectedData
}) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [overLay, setOverLay] = React.useState('')
	const [showPromptModal, setShowPromptModal] = useState(true)
	const [scrollable, setScrollable] = React.useState(true)
	const [FILES, setFILES] = useState([])
	const [upload_file] = useMutation(FILE_UPLOADING_CONTEXT)
	const [loader, setLoader] = useState(false)
	const [Formdata, setFormdata] = useState(
		type === 'edit'
			? rowData[0]
			: {
					address_line_1: '',
					address_line_2: '',
					floor_number: '',
					latitude: '',
					longitutude: '',
					registered_address_country: '',
					registered_address_pin: '',
					registered_address_city: '',
					registered_address_state: '',
					registerd_address_doctype: '',
					purpose: '',
					extra_locations_supporting_document: ''
				}
	)
	const handleSelectedDocument = async (file) => {
		setFILES([...file])
		let variable = {
			folderName: 'extra-locations-supporting-document',
			oldFile: Formdata.extra_locations_supporting_document,
			file: file[0]
		}

		try {
			const response = await upload_file({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: variable
			})
			setLoader(false)
			let data = await response?.data
			let uploadFile = data?.uploadFile
			if (uploadFile.status === 200) {
				let value = uploadFile.data
				HANDLEFORMDATA('extra_locations_supporting_document', value)
			} else if (
				uploadFile.status !== 200 &&
				uploadFile.errors.length > 0
			) {
				return
			}
		} catch (err) {
			console.log('err')
		}
	}
	useEffect(() => {
		if (type === 'edit') {
			if (rowData) {
				let value = rowData[0]?.purpose
				if (value) {
					let index = Purpose.findIndex(
						(item) => item.value === value
					)
					HANDLEFORMDATA('purpose', Purpose[index])
				}
			}
			if (rowData) {
				let value = rowData[0]?.registered_address_document
				if (value) {
					let index = RegesteredAddressDocument.findIndex(
						(item) => item.value === value
					)
					HANDLEFORMDATA(
						'registerd_address_doctype',
						RegesteredAddressDocument[index]
					)
				}
			}
		}
	}, [])

	const handleRemoveDocument = (file) => {
		HANDLEFORMDATA('extra_locations_supporting_document', '')
		let filteredarray = FILES.filter((item) => item.id !== file.id)
		setFILES(filteredarray)
	}
	const handleModalClose = () => {
		setShowModal(false)
		setScrollable(false)
		setShowPromptModal(false)
		setOverLay('false')
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		if (type == 'add') {
			let newFormData = {
				...Formdata,
				purpose: Formdata?.purpose?.value ?? Formdata?.purpose,
				registered_address_doctype:
					Formdata?.registered_address_doctype?.value ??
					Formdata?.registered_address_doctype
			}
			setSelectedData((prev) => {
				const baseSection = prev[0]?.data?.organization_overview || {}
				const existingExtraLocations = Array.isArray(
					baseSection?.extra_locations
				)
					? baseSection?.extra_locations
					: []
				const updatedExtraLocations = [
					...existingExtraLocations,
					{ id: existingExtraLocations.length + 1000, ...newFormData }
				]
				const updatedFirstElement = {
					...prev[0],
					data: {
						...prev[0].data,
						organization_overview: {
							...baseSection,
							extra_locations: updatedExtraLocations
						}
					}
				}
				return [updatedFirstElement, ...prev.slice(1)]
			})
		} else if (type == 'edit') {
			setSelectedData((prev) => {
				const newData = [...prev]
				if (newData[0]?.data?.organization_overview?.extra_locations) {
					let index =
						newData[0]?.data?.organization_overview?.extra_locations?.findIndex(
							(location) => location.id == rowData[0].id
						)
					if (index !== -1) {
						const updatedLocation = {
							...newData[0]?.data?.organization_overview
								?.extra_locations[index],
							...Formdata,
							purpose:
								Formdata?.purpose?.value ?? Formdata?.purpose,
							registered_address_doctype:
								Formdata?.registered_address_doctype?.value ??
								Formdata?.registered_address_doctype
						}
						newData[0].data.organization_overview.extra_locations[
							index
						] = updatedLocation
					}
				}
				return newData
			})
		}
		handleModalClose()
	}

	const HANDLEFORMDATA = (field, value) => {
		setFormdata((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}
	const FORM = (
		<div className="w-[350px]">
			<ElasticTextBox
				name={'address_line_1'}
				id={'address_line_1'}
				value={Formdata?.address_line_1}
				labelName={'Addresss Line 1'}
				onChange={(data) => HANDLEFORMDATA('address_line_1', data)}
			/>
			<ElasticTextBox
				name={'address_line_2'}
				id={'address_line_2'}
				value={Formdata?.address_line_2}
				labelName={'Addresss Line 2'}
				onChange={(data) => HANDLEFORMDATA('address_line_2', data)}
			/>
			<TextBox
				name={'floor_number'}
				id={'floor_nnumber'}
				value={Formdata?.floor_number}
				labelName={'Floor Number'}
				onChange={(data) => HANDLEFORMDATA('floor_number', data)}
			/>

			<TextBox
				name={'latitude'}
				id={'latitude'}
				value={Formdata?.latitude}
				labelName={'Latitude'}
				onChange={(data) => HANDLEFORMDATA('latitude', data)}
			/>
			<TextBox
				name={'longitutude'}
				id={'longitutude'}
				value={Formdata?.longitutude}
				labelName={'Longitutude'}
				onChange={(data) => HANDLEFORMDATA('longitutude', data)}
			/>
			<TextBox
				name={'registered_address_city'}
				id={'registered_address_city'}
				value={Formdata?.registered_address_city}
				labelName={'City'}
				onChange={(data) =>
					HANDLEFORMDATA('registered_address_city', data)
				}
			/>
			<TextBox
				name={'registered_address_state'}
				id={'registered_address_state'}
				value={Formdata?.registered_address_state}
				labelName={'State'}
				onChange={(data) =>
					HANDLEFORMDATA('registered_address_state', data)
				}
			/>
			<TextBox
				name={'registered_address_country'}
				id={'registered_address_country'}
				value={Formdata?.registered_address_country}
				labelName={'Country'}
				onChange={(data) =>
					HANDLEFORMDATA('registered_address_country', data)
				}
			/>
			<TextBox
				name={'registered_address_pin'}
				id={'registered_address_pin'}
				value={Formdata?.registered_address_pin}
				labelName={'Pin'}
				onChange={(data) =>
					HANDLEFORMDATA('registered_address_pin', data)
				}
			/>
			<KeyValueDropDown
				options={Purpose}
				label="Purpose"
				value={Formdata?.purpose?.label}
				onChange={(data) => HANDLEFORMDATA('purpose', data)}
			/>
			<Attachment
				labelName={'Supporting Document'}
				id="extra_locations_doc"
				onSelect={(file) => handleSelectedDocument(file)}
				onRemove={(file) => handleRemoveDocument(file)}
				files={FILES}
			/>
			{Formdata?.extra_locations_supporting_document &&
				FILES.length == 0 && (
					<FalseAttachment
						filename={
							Formdata?.extra_locations_supporting_document.split(
								'/'
							)[2]
						}
						onFileRemove={() =>
							HANDLEFORMDATA(
								'extra_locations_supporting_document',
								''
							)
						}
					/>
				)}

			<KeyValueDropDown
				options={RegesteredAddressDocument}
				label="Document Type"
				value={Formdata?.registerd_address_doctype?.label}
				onChange={(data) =>
					HANDLEFORMDATA('registerd_address_doctype', data)
				}
			/>
		</div>
	)
	const footer = (
		<>
			<div className="modal-footer flex  items-center justify-between border-t  ">
				<h5 className="invisible font-normal">{'T'}</h5>
				<div className="flex items-center justify-end gap-[3px]">
					<IconButton
						// disabled={!Formdata.response}
						Icon={faSave}
						className="btn-success btn-large flex gap-1.5"
						type="button"
						onClick={handleSubmit}
						children="Use"
					/>
				</div>
			</div>
		</>
	)

	return (
		<div>
			<Modal
				modalSize={'lg'}
				title={
					type === 'add'
						? `Add Extra Locations`
						: 'Edit Extra Locations'
				}
				onClose={handleModalClose}
				body={FORM}
				overLay={overLay}
				showModal={showPromptModal}
				position="!top-[36%] !left-[30%] !z-[99999]"
				className="z-[9999]"
				scrollable={scrollable}
				Footer={footer}
			/>
		</div>
	)
}

export default AddExtraLocations
