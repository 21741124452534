import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
const FalseAttachments = ({ filename, onFileRemove }) => {
	return (
		<div className="-mt-1.5 flex items-center justify-between border border-solid border-[#eee] bg-[#f5f5f5]">
			<div className="px-2 py-0.5">{filename}</div>
			<div
				className="flex h-[28PX] w-[28px] cursor-pointer items-center justify-center bg-[#dc3545] text-[#fff]"
				onClick={() => onFileRemove()}
			>
				<FontAwesomeIcon icon={faTimes} />
			</div>
		</div>
	)
}
FalseAttachments.propTypes = {
	filename: PropTypes.string.isRequired,
	onFileRemove: PropTypes.func.isRequired
}

export default FalseAttachments
