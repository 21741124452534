import { useState, useEffect } from 'react'
import DataTableWrapper from '../../../components/ui/DataTables'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import React from 'react'
import Button from '../../../components/ui/Buttons/Button'
import { faClose, faEdit } from '@fortawesome/free-solid-svg-icons'
import AddCommittee from '../AddCommittee'
const Committee = ({ originalData, setSelectedParentsData }) => {
	const [Committees, setCommittees] = useState([])
	const [selectedData, setSelectedData] = useState([])
	const [showEditCommittees, setShowEditCommittees] = useState(false)
	const [showCommittie, setShowCommittie] = useState(false)
	const dopdownOptions = ['All']
	useEffect(() => {
		const allCommittees = []
		if (Array.isArray(originalData)) {
			if (originalData.length > 0) {
				originalData?.forEach((item) => {
					item?.data?.corporate_structure?.committee?.forEach(
						(obj) => {
							if (
								!allCommittees.some(
									(person) => person.id === obj.id
								)
							) {
								allCommittees.push(obj)
							}
						}
					)
				})
				setCommittees((prev) => {
					const filteredPrev = prev.filter(
						(prevObj) =>
							!allCommittees.some(
								(newObj) => newObj.id === prevObj.id
							)
					)
					return [...filteredPrev, ...allCommittees]
				})
			}
		}
	}, [originalData])
	const handleFilter = (rowData) => {
		setSelectedParentsData((prev) => {
			const updatedParentsData = prev.map((parent) => {
				if (parent?.data?.corporate_structure?.committee) {
					const filteredCommittee =
						parent.data.corporate_structure.committee.filter(
							(member) => member.id !== rowData.id
						)
					return {
						...parent,
						data: {
							...parent?.data,
							corporate_structure: {
								...parent.corporate_structure,
								committee: filteredCommittee
							}
						}
					}
				}
				return parent
			})

			return updatedParentsData
		})

		setCommittees((prevCommittees) =>
			prevCommittees.filter((member) => member.id !== rowData.id)
		)
	}

	const ActionBodyTemplate = (rowData) => {
		return (
			<div className="flex justify-between">
				<div className="">
					<IconButton
						type="button"
						className={
							'relative top-0.5 flex h-5 w-6 items-center justify-center rounded-sm border bg-transparent text-sm text-grey-200 hover:bg-transparent hover:text-grey-500'
						}
						onClick={(e) => {
							e.preventDefault()
							setSelectedData([{ ...rowData }])
							setShowEditCommittees(true)
						}}
						children=""
						Icon={faEdit}
						size={'sm'}
					/>
				</div>
				<div>
					<IconButton
						type="button"
						className={
							'relative top-0.5 flex h-5 w-6 items-center justify-center rounded-sm border bg-transparent text-sm text-red-500 hover:bg-transparent hover:text-red-700'
						}
						onClick={(e) => {
							e.preventDefault()
							handleFilter(rowData)
						}}
						children=""
						Icon={faClose}
						size={'lg'}
					/>
				</div>
			</div>
		)
	}

	let COLUMNS = [
		{
			sortable: true,
			header: 'Committee Name ',
			field: 'committee_name',
			style: { width: '20%' },
			headerStyle: { width: '20%' }
		},

		{
			sortable: true,
			header: 'Committee Overview',
			field: 'committee_overview',
			style: { width: 'auto' },
			headerStyle: { width: 'auto' }
		},
		{
			sortable: true,
			header: 'Document Type',
			field: 'committee_doctype',
			style: { width: '25%' },
			headerStyle: { width: '25%' }
		},
		// {
		// 	sortable: true,
		// 	header: 'Attachment',
		// 	field: 'attachment',
		// 	style: { width: '15%' },
		// 	headerStyle: { width: '15%' }
		// },

		{
			header: ' Action',
			style: { width: '70px' },
			body: ActionBodyTemplate,
			headerStyle: { width: '70px' }
		}
	]

	const HandleData = (data) => {
		setSelectedData(data)
	}

	const HeaderButtons = ({ exportCSV }) => (
		<>
			<Button
				onClick={() => setShowCommittie(true)}
				children={'Add committie'}
				className="btn-large btn-primary w-fit"
			/>
		</>
	)

	return (
		<div>
			<div className="h5 border-b pb-1">Committees</div>
			{showEditCommittees && (
				<AddCommittee
					selectedData={originalData}
					type="edit"
					setSelectedData={setSelectedParentsData}
					setShowModal={setShowEditCommittees}
					rowData={selectedData}
				/>
			)}
			{showCommittie && (
				<AddCommittee
					type={'add'}
					setSelectedData={setSelectedParentsData}
					setShowModal={setShowCommittie}
				/>
			)}
			<DataTableWrapper
				showHeader={true}
				paginator={false}
				products={Committees}
				HeaderButtons={HeaderButtons}
				options={dopdownOptions}
				columns={COLUMNS}
				onDataSelection={HandleData}
				selectedData={selectedData}
			/>
		</div>
	)
}
export default Committee
