import React, { useRef, useEffect, useState, forwardRef } from 'react'
import PropTypes from 'prop-types'
import autosize from 'autosize'
import '../../../assets/css/ElasticTextBox/index.css'
const ElasticTextBox = forwardRef(
	(
		{
			value,
			id,
			labelName,
			placeHolder,
			className,
			onChange,
			error,
			success,
			disable,
			readOnly,
			minLength,
			maxLength,
			autoFocus,
			required,
			onBlur,
			onFocus,
			pattern,
			autoComplete,
			...props
		},
		ref
	) => {
		const [cursor, setCursor] = useState(null)
		const textareaRef = useRef(null)

		useEffect(() => {
			if (textareaRef.current) {
				autosize(textareaRef.current)
			}
		}, [])

		useEffect(() => {
			const input = textareaRef.current
			if (input) {
				input.setSelectionRange(cursor, cursor)
			}
		}, [cursor, value])

		const handleInput = (event) => {
			onChange(event.target.value)
			setCursor(event.target.selectionStart)
		}

		useEffect(() => {
			if (textareaRef.current) {
				textareaRef.current.value = value
				autosize.update(textareaRef.current)
			}
		}, [value])

		useEffect(() => {
			if (ref) {
				ref.current = textareaRef.current
			}
		}, [ref])

		return (
			<div className="form-group">
				{labelName && (
					<label htmlFor={id} className="label">
						{labelName}
					</label>
				)}
				<div className="input-outer">
					<textarea
						spellCheck="false"
						id={id}
						value={value}
						onInput={handleInput}
						type="text"
						className={`form-control ${className}`}
						placeholder={placeHolder}
						ref={textareaRef}
						disabled={disable}
						readOnly={readOnly}
						minLength={minLength}
						maxLength={maxLength}
						autoFocus={autoFocus}
						required={required}
						onBlur={onBlur}
						onFocus={onFocus}
						pattern={pattern}
						autoComplete={autoComplete}
						{...props}
					/>
					{error && <p className="danger300">{error}</p>}
					{success && <p className="success300">{success}</p>}
				</div>
			</div>
		)
	}
)
ElasticTextBox.propTypes = {
	// value: PropTypes.string,
	id: PropTypes.string.isRequired,
	labelName: PropTypes.string,
	placeHolder: PropTypes.string,
	className: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	error: PropTypes.string,
	success: PropTypes.string,
	disable: PropTypes.bool,
	readOnly: PropTypes.bool,
	minLength: PropTypes.number,
	maxLength: PropTypes.number,
	autoFocus: PropTypes.bool,
	required: PropTypes.bool,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	pattern: PropTypes.instanceOf(RegExp),
	autoComplete: PropTypes.string
}

export default React.memo(ElasticTextBox)
