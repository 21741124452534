import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import PageAlerts from '../../PageAlerts'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import { IMPORT_USER_MUTATION } from '../../../utils/GraphQl/ImportUserMutation'
import ProgressBar from '../../ProgressBar'
import Checkbox from '../../../components/ui/Form/Checkbox'
import Attachments from '../../../components/ui/Attachments'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const ImportUser = ({ setDrawerstate, fetchData }) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [checked, setChecked] = useState(false)
	const [overlay, setOverlay] = useState('')
	const [loader, setLoader] = useState(false)
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const dispatch = useDispatch()
	const [importUsers] = useMutation(IMPORT_USER_MUTATION)
	const [Files, setFiles] = useState([])
	const handleImportUsers = async () => {
		try {
			setLoader(true)
			const response = await importUsers({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					file: Files[0],
					skipHeader: checked
				}
			})
			let data = await response.data
			let importusers = data.importUsers
			if (importusers.status === 400) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'importusers',
						type: 'danger',
						message: importusers.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (importusers.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'importusers',
						type: 'danger',
						message: ' Session expired please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (importusers.status === 500) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'importusers',
						type: 'danger',
						message: importusers.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (
				importusers.status !== 201 &&
				importusers.errors.length > 0
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'importusers',
						type: 'danger',
						message: importusers.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'importusers',
						type: 'success',
						message: importusers.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				fetchData()
				setTimeout(() => {
					setShowDrawer(false)
					setScrollable(false)
					setScrollableFooter(false)
					setSize('md')
					setOverlay('')
					setBody(null)
					dispatch(removeAlerts({ pageId: 'importusers' }))
				}, 3000)
			}
		} catch (error) {
			console.error('Failed', error)
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'importusers',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const HandleSelectedFile = (file) => {
		if (file[0]?.name?.endsWith('.csv')) {
			setFiles([file[0]])
		} else {
			dispatch(
				addAlerts({
					pageId: 'importusers',
					type: 'danger',
					message: 'Please upload a valid CSV file',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const RemoveFIle = (files) => {
		let FIlteredFiles = Files.filter((file) => file.name !== files.name)
		setFiles(FIlteredFiles)
	}
	const FORM = (
		<form
			encType="multipart/form-data"
			className=" flex w-[350px] flex-col justify-start"
		>
			<>
				<div className="flex w-[350px] flex-col justify-start ">
					<div className="form-group">
						<label className="label">
							Check box for skipHeader
						</label>
						<Checkbox
							id="01"
							label={!checked ? 'False' : 'True'}
							checked={checked}
							onChange={() => setChecked((prev) => !prev)}
							className="check-square"
							iconClass="simple-checkmark"
						/>
					</div>
					<Attachments
						accept={'.csv'}
						id="import_user"
						labelName={'Select File'}
						files={Files}
						onSelect={HandleSelectedFile}
						onRemove={RemoveFIle}
					/>
				</div>
			</>
		</form>
	)
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setDrawerstate(false)
	}
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])

	useEffect(() => {
		setBody(FORM)
	}, [showDrawer, checked, Files])

	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal ">{''}</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={handleImportUsers}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') {
			handleDrawerClose()
		}
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			{loader && <ProgressBar />}
			<PageAlerts pageId={'importusers'} />
			<Drawer
				label="Import User"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default ImportUser
