import React from 'react'
import { useState } from 'react'
import AuthLayout from '../../Layouts/AuthLayout'
import FormHeader from '../../components/Common/FormHeader'
import FormBody from '../../components/Common/FormBody'
import FormFooter from '../../components/Common/FormFooter'
import TextBox from '../../components/ui/TextBox'
import Button from '../../components/ui/Buttons/Button'
import Icon from '../../components/ui/icon'
import { useMutation } from '@apollo/client'
import { FORGOTPASSWORD_MUTATION } from '../../utils/GraphQl/ForgotPasswordMutation'
import { useDispatch } from 'react-redux'
import { addAlerts } from '../../store/AlertSlice'
import PageAlerts from '../PageAlerts'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'

const ForgotPassword = () => {
	const [forgotpassword] = useMutation(FORGOTPASSWORD_MUTATION)
	const [email, setEmail] = useState(null)
	const [loader, setLoader] = useState(false)
	const dispatch = useDispatch()
	const HandleChange = (data) => {
		setEmail(data)
	}
	const handleForgotPassword = async () => {
		try {
			setLoader(true)
			const response = await forgotpassword({
				variables: {
					input: {
						email: email
					}
				}
			})
			let rawdata = await response.data
			if (rawdata.forgetPassword.errors?.length > 0) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'forgotpassword',
						type: 'danger',
						message: rawdata.forgetPassword.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (rawdata.forgetPassword.status === 200) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'forgotpassword',
						type: 'success',
						message: rawdata.forgetPassword.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
			} else if (rawdata.forgetPassword.status !== 200) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'forgotpassword',
						type: 'danger',
						message: rawdata.forgetPassword.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
			}
		} catch (error) {
			console.error('forgot passord request failed', error)
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'forgotpassword',
					type: 'danger',
					message: 'Error',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault()
		if (!email) {
			dispatch(
				addAlerts({
					pageId: 'forgotpassword',
					type: 'danger',
					message: 'Please enter your email address.',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
			let isValid = emailRegex.test(email)
			if (!isValid) {
				dispatch(
					addAlerts({
						pageId: 'forgotpassword',
						type: 'danger',
						message: 'Please enter valid email address.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				handleForgotPassword()
			}
		}
	}

	return (
		<>
			<PageAlerts pageId={'forgotpassword'} />
			<AuthLayout>
				<FormHeader
					title="Forget Password"
					message="Welcome! Please use below form to reset your account."
				/>
				<FormBody>
					<TextBox
						id={'email_address'}
						name={'email_address'}
						labelName={'Email Address'}
						type={'email'}
						className={'mb-4'}
						onChange={HandleChange}
					/>

					<div className="mb-4">
						<p className="small-text text-[#6e7985] ">
							By clicking <strong>Reset</strong> below, you agree
							to our Terms and Conditions and Privacy Policy.
						</p>
					</div>

					{loader ? (
						<Button
							className="btn-large btn-primary btn-all flex  w-full cursor-not-allowed gap-2"
							isIconBtn={true}
							onClick={() => console.log('')}
						>
							<span className="waiting-loader"> </span> Please
							Wait
						</Button>
					) : (
						<Button
							className="btn-large btn-primary btn-all flex w-full gap-2 p-1"
							isIconBtn={true}
							onClick={handleSubmit}
						>
							<Icon
								name="line-lock"
								width={'16px'}
								height={'16px'}
								className={'icon-fill-white'}
								viewBox={'0 0 24 24'}
							/>
							Forgot Password
						</Button>
					)}
				</FormBody>
				<FormFooter
					message1="Need assistance or have questions"
					linktext1="Contact Support"
					link1="contact-us"
					message2="Don't have an account"
					linktext2="Create an Account"
					link2="register"
				/>
			</AuthLayout>
		</>
	)
}
export default ForgotPassword
