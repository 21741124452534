import React, { useState, useEffect, useCallback } from 'react'
import DataTableWrapper from '../../components/ui/DataTables'
import { classNames } from 'primereact/utils'
import Button from '../../components/ui/Buttons/Button'
import { useSelector } from 'react-redux'
import IconButton from '../../components/ui/Buttons/Icon-button'
import { CSVLink } from 'react-csv'
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons'
import PageAlerts from '../PageAlerts'
import Card from '../../components/ui/Card'
import ActionTableLayout from './ActionTableLayout'
import NotificationTableLayout from './NotificationTableLayout'
import ProgressBar from '../ProgressBar'
import { setLoader } from '../../store/NotificatinSlice'
import { useDispatch } from 'react-redux'

const NotificationList = () => {
	let ACTION_COLUMNS = [
		{
			selectionMode: 'multiple',
			isHidden: false,
			position: 'left',
			field: 'checkbox',
			headerStyle: { width: '2%' },
			style: { width: '2%' },
			className: 'chk-dt chk-primary',
			id: 'primary'
		},
		{
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },

			header: 'Id',
			field: 'id'
		},
		{
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },

			header: 'User Id',
			field: 'user_id'
		},

		{
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },

			header: 'Messages',
			field: 'message'
		},
		{
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'Action Name',
			field: 'action_name',
			body: (rowData) => {
				let actionName = rowData.action.split('_')
				return actionName.join(' ')
			}
		},
		{
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'Created At',
			field: 'createdat'
		},
		{
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'Action',
			field: 'actionn',
			body: (rowData) => {
				if (rowData?.custom_fields?.type === 'url') {
					return (
						<Button
							className="btn-large btn-danger"
							onClick={() => handleAction(rowData)}
						>
							Action
						</Button>
					)
				} else {
					return null
				}
			}
		}
	]
	let NOTIFICATION_COLUMNS = [
		{
			selectionMode: 'multiple',
			isHidden: false,
			position: 'left',
			field: 'checkbox',
			headerStyle: { width: '2%' },
			style: { width: '2%' },
			className: 'chk-dt chk-primary',
			id: 'primary'
		},
		{
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'Id',
			field: 'id'
		},
		{
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'User Id',
			field: 'user_id'
		},

		{
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'Messages',
			field: 'message'
		},
		{
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'Action Name',
			field: 'action_name',
			body: (rowData) => {
				let actionName = rowData.action.split('_')
				return actionName.join(' ')
			}
		},
		{
			isHidden: false,
			position: 'center',
			style: { width: '14%', minWidth: '14%' },
			headerStyle: { width: '14%', minWidth: '14%' },
			header: 'Created At',
			field: 'createdat'
		}
	]
	const ACTION_LAYOUT = useSelector(
		(state) => state.tableLayouts?.notificationActionLayout
	)
	const NOTIFCATION_LAYOUT = useSelector(
		(state) => state.tableLayouts?.notificationLayout
	)

	const [notificationsContent, setNotificationsContent] = useState([])
	const [actionsContent, setActionsContent] = useState([])
	const Data = useSelector((state) => state.Notifications)
	const [selectedNotificationData, setselectedNotificationData] = useState([])
	const [actionNotificationData, setActionNotificationData] = useState([])
	const [Notifications, setNotifications] = useState([])
	const [Actions, setActions] = useState([])
	const [loader, setLoader] = useState(true)
	const [exportNotifications, setExportNotifications] = useState([])
	const [exportActions, setExportActions] = useState([])
	const [isActionTableLayout, SetIsActionTableLayout] = useState(false)
	const [isNotificationListLayout, setIsNotificationListLayout] =
		useState(false)
	const [actionCoumns, setActionColumns] = useState(ACTION_COLUMNS)
	const [notificationColumns, setNotificationColumns] =
		useState(NOTIFICATION_COLUMNS)
	useEffect(() => {
		if (ACTION_LAYOUT.length > 0) {
			ACTION_LAYOUT.forEach((data) => {
				if (data.table_name === 'table_notification_actions') {
					let NewDAta = data.structure
					if (NewDAta) {
						let DATA = NewDAta.map((item) => {
							if (item.header == 'Action') {
								return {
									...item,
									body: (rowData) => {
										if (
											rowData?.custom_fields?.type ===
											'url'
										) {
											return (
												<Button
													className="btn-large btn-danger"
													onClick={() =>
														handleAction(rowData)
													}
												>
													Action
												</Button>
											)
										} else {
											return null
										}
									}
								}
							} else if (item.header == 'Action Name') {
								return {
									...item,
									body: (rowData) => {
										let actionName =
											rowData.action.split('_')
										return actionName.join(' ')
									}
								}
							} else {
								return { ...item }
							}
						})
						setActionColumns(DATA)
						ACTION_COLUMNS = DATA
					}
				}
			})
		}
	}, [ACTION_LAYOUT])
	useEffect(() => {
		if (NOTIFCATION_LAYOUT.length > 0) {
			NOTIFCATION_LAYOUT.forEach((data) => {
				if (data.table_name === 'table_notifications') {
					let NewDAta = data?.structure
					if (NewDAta) {
						let DATA = NewDAta.map((item) => {
							if (item.header == 'Action Name') {
								return {
									...item,
									body: (rowData) => {
										let actionName =
											rowData.action.split('_')
										return actionName.join(' ')
									}
								}
							} else {
								return { ...item }
							}
						})
						setNotificationColumns(DATA)
						NOTIFICATION_COLUMNS = DATA
					}
				}
			})
		}
	}, [NOTIFCATION_LAYOUT])
	const HandleNotifications = (data) => {
		setselectedNotificationData(data)
		setNotifications(data)
		setExportNotifications((prev) =>
			prev.filter((prevUser) =>
				data.some(
					(selectedNotificationData) =>
						selectedNotificationData.user_id === prevUser.user_id
				)
			)
		)
	}

	const HandleActions = (data) => {
		setActionNotificationData(data)
		setActions(data)
		setExportActions((prev) =>
			prev.filter((prevUser) =>
				data.some(
					(actionNotificationData) =>
						actionNotificationData.user_id === prevUser.user_id
				)
			)
		)
	}

	const dopdownOptions = ['All']

	const handleAction = (data) => {
		let url = data.custom_fields.data
		console.log(url)
		window.open(url, '_blank')
	}
	const formateDate = useCallback((date) => {
		const originalDate = new Date(date)
		const options = {
			year: 'numeric',
			month: 'short',
			day: '2-digit',
			hour: 'numeric',
			minute: 'numeric',
			hour12: true
		}

		const formattedTime = originalDate.toLocaleTimeString('en-US', options)
		const finalFormattedString = `${formattedTime} `
		return finalFormattedString
	}, [])
	useEffect(() => {
		if (Data.Notifications.length > 0) {
			Data.Notifications.map((item) => {
				setNotificationsContent((prev) => {
					return [
						...prev,
						{
							...item,
							id: Number(item.id),
							createdat: formateDate(item.created_at)
						}
					]
				})
			})
		}
		if (Data.Actions.length > 0) {
			Data.Actions.map((item) => {
				setActionsContent((prev) => {
					return [
						...prev,
						{
							...item,
							id: Number(item.id),
							createdat: formateDate(item.created_at)
						}
					]
				})
			})
		}
		setTimeout(() => {
			setLoader(false)
		}, 500)
	}, [Data, formateDate])

	const notificationHeaders = [
		{ label: 'User ID', key: 'user_id' },
		{ label: 'Message', key: 'message' },
		{ label: 'Action', key: 'action' }
	]
	const ActionHeaders = [
		{ label: 'User ID', key: 'user_id' },
		{ label: 'Message', key: 'message' },
		{ label: 'Action', key: 'action' }
	]

	useEffect(() => {
		Notifications.forEach((user) => {
			setExportNotifications((prev) => {
				if (
					Notifications.some(
						(selectedUser) => selectedUser.id === user.id
					)
				) {
					return [
						...prev,
						{
							user_id: user.id,
							message: user.message,
							action: user.action
						}
					]
				}
				return prev
			})
		})
		Actions.forEach((user) => {
			setExportActions((prev) => {
				if (
					Actions.some((selectedUser) => selectedUser.id === user.id)
				) {
					return [
						...prev,
						{
							user_id: user.id,
							message: user.message,
							action: user.action
						}
					]
				}
				return prev
			})
		})
	}, [Notifications, Actions])

	const template1 = {
		layout: 'CustomPaginator PrevPageLink PageLinks NextPageLink',
		CustomPaginator: (options) => {
			return (
				<span style={{ marginRight: 'auto' }}>
					Showing {options.first + 1} to{' '}
					{options.first + options.rows} of {options.totalRecords}{' '}
					entries
				</span>
			)
		},
		PrevPageLink: (options) => {
			options.className = classNames(options.className).replace(
				'p-disabled',
				''
			)
			return (
				<Button
					type={'button'}
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={options.onClick}
					// disabled={options.disabled}
					children={'Previous'}
				/>
			)
		},
		NextPageLink: (options) => {
			return (
				<Button
					type="button"
					className={`${options.className} p-3`}
					style={{
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: options.disabled ? '#6c757d' : '#007bff'
					}}
					onClick={(event) => {
						// if (!stopfetching) {
						// 	fetchAgain()
						// 	setLimit((prev) => prev + 10)
						// }
						options.onClick(event)
					}}
					// disabled={options.disabled}
					children={'Next'}
				/>
			)
		},

		PageLinks: (options) => {
			if (
				(options.view.startPage === options.page &&
					options.view.startPage !== 0) ||
				(options.view.endPage === options.page &&
					options.page + 1 !== options.totalPages)
			) {
				const className = classNames(options.className, {
					'p-disabled': true
				})

				return (
					<span className={className} style={{ userSelect: 'none' }}>
						...
					</span>
				)
			}
			const isCurrentPage = options.page === options.currentPage
			const linkClassName = classNames(options.className, {
				p: isCurrentPage
			})
			const linkStyle = isCurrentPage
				? {
						backgroundColor: '#7252d3',
						borderRadius: '0',
						color: '#fff'
					}
				: {
						borderRadius: 0,
						border: '1px solid #dee2e6',
						color: '#007bff'
					}
			return (
				<Button
					type="button"
					className={linkClassName}
					style={linkStyle}
					onClick={(event) => {
						// if (!stopfetching) {
						// 	fetchAgain()
						// 	setLimit((prev) => prev + 10)
						// }
						options.onClick(event)
					}}
					children={`${options.page + 1}`}
				/>
			)
		}
	}
	const paginatorLeft = (options) => {
		return (
			<span style={{ marginRight: 'auto' }}>
				Showing {options.first + 1} to{' '}
				{Math.min(options.first + options.rows, options.totalRecords)}{' '}
				entries from {options.totalRecords}
			</span>
		)
	}

	const HeaderButtonNotifications = ({ exportCSV }) => (
		<div className="flex flex-wrap items-center justify-start  gap-[3px]">
			<IconButton
				Icon={faPlus}
				size={'sm'}
				className="btn btn-large btn-primary flex gap-1.5"
				onClick={() => setIsNotificationListLayout(true)}
			>
				Layout
			</IconButton>
			<CSVLink data={exportNotifications} headers={notificationHeaders}>
				<IconButton
					Icon={faFileExport}
					disabled={!selectedNotificationData.length > 0}
					size={'sm'}
					className="btn btn-large btn-primary flex gap-1.5"
					onClick={() => console.log(exportNotifications)}
				>
					Export Notifications
				</IconButton>
			</CSVLink>
		</div>
	)

	const HeaderButtonsActions = ({ exportCSV }) => (
		<div className="flex flex-wrap items-center justify-start  gap-[3px]">
			<IconButton
				Icon={faPlus}
				size={'sm'}
				className="btn btn-large btn-primary flex gap-1.5"
				onClick={() => SetIsActionTableLayout(true)}
			>
				Layout
			</IconButton>
			<CSVLink data={exportActions} headers={ActionHeaders}>
				<IconButton
					disabled={!actionNotificationData.length > 0}
					Icon={faFileExport}
					size={'sm'}
					className="btn btn-large btn-primary flex gap-1.5"
					onClick={() => console.log('')}
				>
					Export Actions
				</IconButton>
			</CSVLink>
		</div>
	)

	useEffect(() => {
		const handleScrollPosition = () => {
			if (isActionTableLayout || isNotificationListLayout) {
				const scrollPosition = window.pageYOffset
				document.body.classList.add('scroll-disabled')
				document.body.style.top = `-${scrollPosition}px`
			} else {
				document.body.classList.remove('scroll-disabled')
				const scrollPosition = parseInt(
					document.body.style.top || '0',
					10
				)
				document.body.style.top = ''
				window.scrollTo(0, -scrollPosition)
			}
		}

		setTimeout(() => {
			document.body.classList.remove('scroll-disabled')
		}, 300)

		handleScrollPosition()
	}, [isActionTableLayout, isNotificationListLayout])
	return (
		<>
			{Data.loader && <ProgressBar />}
			{loader && <ProgressBar />}
			<PageAlerts pageId={'additionalfieldsadded'} />
			{isActionTableLayout && (
				<ActionTableLayout
					setDrawerstate={SetIsActionTableLayout}
					columns={actionCoumns}
					originalColumns={ACTION_COLUMNS}
					setColumns={setActionColumns}
				/>
			)}

			{isNotificationListLayout && (
				<NotificationTableLayout
					setDrawerstate={setIsNotificationListLayout}
					columns={notificationColumns}
					originalColumns={NOTIFICATION_COLUMNS}
					setColumns={setNotificationColumns}
				/>
			)}

			<Card className="mb-3">
				<DataTableWrapper
					products={actionsContent}
					HeaderButtons={HeaderButtonsActions}
					options={dopdownOptions}
					columns={actionCoumns}
					onDataSelection={HandleActions}
					template={template1}
					paginatorLeft={paginatorLeft}
					selectedData={actionNotificationData}
					// bodyTemplate={actionBodyTemplate}
				/>
			</Card>
			<Card>
				<DataTableWrapper
					products={notificationsContent}
					HeaderButtons={HeaderButtonNotifications}
					options={dopdownOptions}
					columns={notificationColumns}
					onDataSelection={HandleNotifications}
					template={template1}
					paginatorLeft={paginatorLeft}
					selectedData={selectedNotificationData}
					// bodyTemplate={actionBodyTemplate}
				/>
			</Card>
		</>
	)
}

export default NotificationList
