import { gql } from '@apollo/client'

export const GET_SETTINGS_QUERY = gql`
	query GetSetting($key: String) {
		getSetting(key: $key) {
			status
			message
			data
			errors
		}
	}
`

export const UPDATE_SEETINGS = gql`
	mutation Mutation($key: String!, $input: EditSettingsInput!) {
		updateSettings(key: $key, input: $input) {
			status
			message
			data
			errors
		}
	}
`

export const UPDATE_MULTIPLE_SETTINGS = gql`
	mutation UpdateMultipleSettings($input: JSON) {
		updateMultipleSettings(input: $input) {
			status
			message
			data
			errors
		}
	}
`
export const GET_MULTIPLE_SETTINGS = gql`
	query GetMultipleSettings($keys: [String]) {
		getMultipleSettings(keys: $keys) {
			status
			message
			data
			errors
		}
	}
`
