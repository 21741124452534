import React from 'react'
import '../../../assets/css/Card/index.css'
const Card = ({ children, className }) => {
	return (
		<div className={`card card-one ${className}`}>
			<div className="card-body">{children}</div>
		</div>
	)
}

export default Card
