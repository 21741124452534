import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	Templates: []
}
const templateSlice = createSlice({
	name: 'templateSlice',
	initialState,
	reducers: {
		FetchedTemplates: (state, action) => {
			const fetchedData = action.payload
			//   console.log('fetched Data',fetchedData);
			fetchedData.forEach((Template) => {
				state.Templates = state.Templates.map((prevTem) =>
					prevTem.id !== Template.id
						? prevTem
						: { ...prevTem, ...Template }
				)
				const existingTemplate = state.Templates.find(
					(t) => t.id === Template.id
				)

				if (!existingTemplate) {
					state.Templates.push({
						...Template,
						id: Template.id,
						type: Template.is_html === true ? 'HTML' : 'TEXT'
					})
				}
			})
		},
		AddSingleTemplate: (state, action) => {
			let data = action.payload
			// console.log('single template',data)
			state.Templates.push({
				...data,
				id: data.id,
				type: data.is_html === true ? 'HTML' : 'TEXT'
			})
		},
		DeleteTemplate: (state, action) => {
			let template = action.payload
			//   console.log('template to be delated ',template)
			state.Templates = state.Templates.filter(
				(temp) => temp.id !== template.id
			)
		}
	}
})
export const { FetchedTemplates, AddSingleTemplate, DeleteTemplate } =
	templateSlice.actions

export const templateSliceReducer = templateSlice.reducer
