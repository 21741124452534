import React, { useState, useEffect } from 'react'
import Modal from '../../../components/ui/modals'
import TextBox from '../../../components/ui/TextBox'
import Attachment from '../../../components/ui/Attachments'
import KeyValueDropDown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import FalseAttachment from '../../../components/ui/FalseAttachments'
import { useMutation } from '@apollo/client'
import { FILE_UPLOADING_CONTEXT } from '../../../utils/GraphQl/OrganizationContext'
import { useSelector } from 'react-redux'
const committee_filetype = [
	{
		label: 'Committee Bylaws',
		value: 'Committee Bylaws'
	},
	{
		label: 'Terms of Reference ',
		value: 'Terms of Reference '
	},
	{ label: 'Committee Charter', value: 'Committee Charter' }
]
const AddCommittie = ({ setShowModal, setSelectedData, type, rowData }) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [overLay, setOverLay] = React.useState('')
	const [showPromptModal, setShowPromptModal] = useState(true)
	const [scrollable, setScrollable] = React.useState(true)
	const [committee_attachment, setcommittee_attachment] = useState([])
	const [members, setMembers] = useState([])
	const [chairman, setChairman] = useState([])
	const [upload_file] = useMutation(FILE_UPLOADING_CONTEXT)
	const [CommitteeData, setCommitteeData] = useState(
		type === 'edit'
			? rowData[0]
			: {
					committee_name: '',
					committee_overview: '',
					committee_attachment: '',
					committee_doctype: '',
					committee_supporting_document: ''
				}
	)
	useEffect(() => {
		if (type === 'edit') {
			if (rowData) {
				let value = rowData[0]?.committee_doctype
				if (value) {
					let index = committee_filetype.findIndex(
						(item) => item.value === value
					)
					handleCommitteeData(
						'committee_doctype',
						committee_filetype[index]
					)
				}
			}
		}
	}, [])
	const addMember = () => {
		setMembers([
			...members,
			{ full_name: '', email: '', designation: '', phone: '' }
		])
	}
	const addChairman = () => {
		setChairman([
			...chairman,
			{ full_name: '', email: '', designation: '', phone: '' }
		])
	}

	const handleModalClose = () => {
		setShowModal(false)
		setScrollable(false)
		setShowPromptModal(false)
		setOverLay('false')
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		if (type == 'add') {
			let newCommitteeData = {
				...CommitteeData,
				committee_doctype:
					CommitteeData?.committee_doctype?.value ??
					CommitteeData?.committee_doctype,
				members: members,
				chairmans: chairman
			}
			setSelectedData((prev) => {
				const baseCommittee = prev[0]?.data?.corporate_structure || {}
				const existingCommittee = Array.isArray(baseCommittee.committee)
					? baseCommittee.committee
					: []
				const updatedCommittee = [
					...existingCommittee,
					{ id: existingCommittee.length + 1010, ...newCommitteeData }
				]
				const updatedFirstElement = {
					...prev[0],
					data: {
						...prev[0].data,
						corporate_structure: {
							...baseCommittee,
							committee: updatedCommittee
						}
					}
				}
				return [updatedFirstElement, ...prev.slice(1)]
			})
		} else if (type == 'edit') {
			setSelectedData((prev) => {
				const newData = JSON.parse(JSON.stringify(prev))
				if (newData[0]?.data?.corporate_structure?.committee) {
					let index =
						newData[0]?.data?.corporate_structure?.committee?.findIndex(
							(committee) => committee.id === rowData[0].id
						)
					if (index !== -1) {
						const committeeMembers =
							newData[0].data.corporate_structure.committee[index]
								.members || []
						const committeeChairmans =
							newData[0].data.corporate_structure.committee[index]
								.chairmans || []

						const updatedCommittee = {
							...newData[0].data.corporate_structure.committee[
								index
							],
							...CommitteeData,
							members: [...committeeMembers, ...members],
							chairmans: [...committeeChairmans, ...chairman],
							committee_doctype:
								CommitteeData?.committee_doctype?.value ??
								CommitteeData?.committee_doctype
						}
						newData[0].data.corporate_structure.committee[index] =
							updatedCommittee
					}
				}
				return newData
			})
		}
		handleModalClose()
	}
	const handleSelectedDocument = async (file) => {
		setcommittee_attachment([...file])
		let variable = {
			folderName: 'committee_supporting_document',
			oldFile: CommitteeData?.committee_supporting_document,
			file: file[0]
		}
		try {
			const response = await upload_file({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: variable
			})
			let data = await response?.data
			let uploadFile = data?.uploadFile
			if (uploadFile.status === 200) {
				let value = uploadFile.data
				handleCommitteeData('committee_supporting_document', value)
			} else if (
				uploadFile.status !== 200 &&
				uploadFile.errors.length > 0
			) {
				return
			}
		} catch (err) {
			console.log('err')
		}
	}

	const handleRemoveDocument = (file) => {
		handleCommitteeData('committee_supporting_document', '')
		setcommittee_attachment([])
	}
	const handleCommitteeData = (key, value) => {
		setCommitteeData((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}
	const updateMember = (index, key, value) => {
		const updatedMembers = members.map((member, idx) => {
			if (idx === index) {
				return { ...member, id: idx + 1000, [key]: value }
			}
			return member
		})
		setMembers(updatedMembers)
	}
	const updateChairmain = (index, key, value) => {
		const updatedMembers = chairman.map((member, idx) => {
			if (idx === index) {
				return { ...member, id: idx + 10000, [key]: value }
			}
			return member
		})
		setChairman(updatedMembers)
	}
	const FORM = (
		<div className="w-[350px]">
			<TextBox
				name={'committee_name'}
				id={'committee_name'}
				value={CommitteeData.committee_name}
				labelName={'Committee Name'}
				onChange={(data) => handleCommitteeData('committee_name', data)}
			/>
			<TextBox
				name={'committee_overview'}
				id={'committee_overview'}
				value={CommitteeData.committee_overview}
				labelName={'Committee Overview'}
				onChange={(data) =>
					handleCommitteeData('committee_overview', data)
				}
			/>

			<Attachment
				labelName={'Supporting Document'}
				id="committe_supporting_doc"
				onSelect={(file) => handleSelectedDocument(file)}
				onRemove={(file) => handleRemoveDocument(file)}
				files={committee_attachment}
			/>
			{CommitteeData?.committee_supporting_document &&
				committee_attachment.length == 0 && (
					<FalseAttachment
						filename={
							CommitteeData?.committee_supporting_document.split(
								'/'
							)[2]
						}
						onFileRemove={() =>
							handleCommitteeData(
								'committee_supporting_document',
								''
							)
						}
					/>
				)}
			<KeyValueDropDown
				options={committee_filetype}
				label="Document Type"
				value={CommitteeData?.committee_doctype?.value}
				onChange={(data) =>
					handleCommitteeData('committee_doctype', data)
				}
			/>

			{members.map((member, index) => (
				<div key={index}>
					<TextBox
						name={`full_name_${index}`}
						id={`full_name_${index}`}
						value={member.full_name}
						labelName="Full Name"
						onChange={(data) =>
							updateMember(index, 'full_name', data)
						}
					/>
					<TextBox
						name={`email_${index}`}
						id={`email_${index}`}
						value={member.email}
						labelName="Email"
						onChange={(data) => updateMember(index, 'email', data)}
					/>
					<TextBox
						name={`designation_${index}`}
						id={`designation_${index}`}
						value={member.designation}
						labelName="Designation"
						onChange={(e) => updateMember(index, 'designation', e)}
					/>
					<TextBox
						name={`phone_${index}`}
						id={`phone_${index}`}
						value={member.phone}
						labelName="Phone"
						onChange={(e) => updateMember(index, 'phone', e)}
					/>
				</div>
			))}
			{chairman.map((member, index) => (
				<div key={index}>
					<TextBox
						name={`full_name_${index}`}
						id={`full_name_${index}`}
						value={member.full_name}
						labelName="Full Name"
						onChange={(data) =>
							updateChairmain(index, 'full_name', data)
						}
					/>
					<TextBox
						name={`email_${index}`}
						id={`email_${index}`}
						value={member.email}
						labelName="Email"
						onChange={(data) =>
							updateChairmain(index, 'email', data)
						}
					/>
					<TextBox
						name={`designation_${index}`}
						id={`designation_${index}`}
						value={member.designation}
						labelName="Designation"
						onChange={(e) =>
							updateChairmain(index, 'designation', e)
						}
					/>
					<TextBox
						name={`phone_${index}`}
						id={`phone_${index}`}
						value={member.phone}
						labelName="Phone"
						onChange={(e) => updateChairmain(index, 'phone', e)}
					/>
				</div>
			))}
		</div>
	)
	const footer = (
		<>
			<div className="modal-footer flex  items-center justify-between border-t  ">
				<h5 className="invisible font-normal">{'T'}</h5>
				<div className="flex items-center justify-end gap-[3px]">
					<IconButton
						Icon={faSave}
						className="btn-success btn-large flex gap-1.5"
						type="button"
						onClick={(e) => {
							e.preventDefault()
							addMember()
						}}
						children="Add Member"
					/>
					<IconButton
						Icon={faSave}
						className="btn-success btn-large flex gap-1.5"
						type="button"
						onClick={(e) => {
							e.preventDefault()
							addChairman()
						}}
						children="Add Chairman"
					/>
					<IconButton
						Icon={faSave}
						className="btn-success btn-large flex gap-1.5"
						type="button"
						onClick={handleSubmit}
						children="Save"
					/>
				</div>
			</div>
		</>
	)

	return (
		<div>
			<Modal
				modalSize={'lg'}
				title={`Add Committee `}
				onClose={handleModalClose}
				body={FORM}
				overLay={overLay}
				showModal={showPromptModal}
				position="!top-[36%] !left-[30%] !z-[99999]"
				className="z-[9999]"
				scrollable={scrollable}
				Footer={footer}
			/>
		</div>
	)
}

export default AddCommittie
