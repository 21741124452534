import React from 'react'

const AlertWrapper = ({ children }) => {
	return (
		<div className="alert-wrapper" data-position="top">
			{children}{' '}
		</div>
	)
}
export default AlertWrapper
