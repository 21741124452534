import { gql } from '@apollo/client'
export const DELETE_USER_MUTATION = gql`
	mutation ($user_id: JSON) {
		deleteUser(user_id: $user_id) {
			errors
			status
			message
		}
	}
`
