import { gql } from '@apollo/client'
export const VERIFY_OTHEREMAIL_MUTATION = gql`
	mutation CreateMailVerificationLink($input: verifyUserEmailsInput!) {
		createMailVerificationLink(input: $input) {
			status
			message
			errors
		}
	}
`
