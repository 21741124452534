import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { store } from './store'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import client from './utils/GraphQl/apolloClient'
import App from './App'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<ApolloProvider client={client}>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</ApolloProvider>
)
