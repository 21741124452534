import React from 'react'
import IconButton from '../Buttons/Icon-button'
import { faXmark, faSave, faClose } from '@fortawesome/free-solid-svg-icons'
import classes from '../../../assets/css/Modals/index.module.css'
export default function ModalXs({ ...props }) {
	return (
		<>
			<div
				className={`${classes.modalXsContainer} ${props.extraClass ? 'opacity-100' : 'opacity-0'}`}
			>
				<div className={`${classes.modalXsContent}`}>
					<div className="px-4 pt-1">
						<div className="modal-header ">
							<div className={classes.modalHeader}>
								<h5 className="">{props.title}</h5>
								<IconButton
									type="button"
									className={classes.modalHeaderBtn}
									onClick={() => props.onClose()}
									children=""
									Icon={faClose}
									size={'lg'}
								/>
							</div>
						</div>
					</div>
					<div className="modal-body p-4">
						<div className="relative flex-auto p-0">
							{props.body}
						</div>
					</div>
					<div className="border-t  px-4 py-2 ">
						<div className={classes.modalFooter}>
							{/* <h5 className='font-normal'>Additional Text</h5> */}
							<div className={classes.modalFooterBtns}>
								<IconButton
									Icon={faXmark}
									className="btn-default btn-large flex gap-1.5"
									type="button"
									onClick={() => props.onClose()}
									children="Close"
								/>
								<IconButton
									Icon={faSave}
									className="btn-success btn-large flex gap-1.5"
									type="button"
									onClick={() => props.onClose()}
									children="Save Changes"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
