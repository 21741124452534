import React from 'react'
import SidebarToggle from './SidebarToggle'
import Notifications from './Notifications'
import Profile from './Profile'
const Header = () => {
	return (
		<div className="header-main right-0 top-0  z-50  flex h-[72px] items-center border-b-[#f3f5f9] bg-white px-6 ">
			<SidebarToggle />
			<Notifications />
			<Profile />
		</div>
	)
}

export default React.memo(Header)
