import React, { useState, useRef, useEffect, useCallback } from 'react'
// import Icon from "../../assets/icons";
import { Link } from 'react-router-dom'
import Icon from '../ui/icon'
import Badges from '../ui/Badges'
import { useDispatch } from 'react-redux'
import { AddNotifications, setLoader } from '../../store/NotificatinSlice'
import { useSelector } from 'react-redux'
import { gql, useApolloClient } from '@apollo/client'
import { addAlerts, removeAlerts } from '../../store/AlertSlice'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { logoutUser } from '../../store/AuthSlice/AuthSlice'
import PageAlerts from '../../pages/PageAlerts'
import ProgressBar from '../../pages/ProgressBar'
const GET_NOTIFICATIONS = gql`
	query GetAllTemplates($pagination: PaginationInput) {
		getNotification(pagination: $pagination) {
			data {
				notificationsCount
				actionsCount
				notifications
				actions
			}
			status
			message
			errors
		}
	}
`
const Notifications = () => {
	const NOTIFICATIONS = useSelector(
		(state) => state.Notifications.Notifications
	)
	const ACTIONS = useSelector((state) => state.Notifications.Actions)
	const authToken = useSelector((state) => state.Auth.token)
	const [showNotifications, setShowNotifications] = useState(false)
	const dispatch = useDispatch()
	const client = useApolloClient()
	const FETCH_NOTIFICATIONS = async () => {
		dispatch(setLoader(true))
		try {
			const { data } = await client.query({
				query: GET_NOTIFICATIONS,
				variables: {
					pagination: {
						offset: 0,
						limit: 100000
					}
				},
				context: {
					headers: {
						authorization: authToken
					}
				}
			})
			let notification = data.getNotification
			dispatch(setLoader(false))
			if (notification.status === 401) {
				dispatch(
					addAlerts({
						pageId: 'fetchnotification',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'fetchnotification' }))
				}, 3000)
			} else if (notification.status === 204) {
			} else if (notification.status === 200) {
				let DATA = notification.data
				dispatch(AddNotifications(DATA))
			} else if (notification.status === 403) {
				dispatch(
					addAlerts({
						pageId: 'fetchnotification',
						type: 'danger',
						message: notification.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					dispatch(removeAlerts({ pageId: 'fetchnotification' }))
				}, 3100)
			}
		} catch (error) {
			dispatch(setLoader(false))
			dispatch(
				addAlerts({
					pageId: 'fetchnotification',
					type: 'danger',
					message: 'Something went wrong pleasse try again.',
					icon: faCheck,
					autoclose: 3000
				})
			)
		}
	}
	useEffect(() => {
		FETCH_NOTIFICATIONS()
	}, [])
	const closeref = useRef()

	const HandleShowNotifications = () => {
		setShowNotifications((prev) => !prev)
	}
	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (closeref.current && !closeref.current.contains(event.target)) {
				setShowNotifications(false)
			}
		}
		document.addEventListener('mousedown', handleOutsideClick)
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick)
		}
	}, [])

	const formateDate = useCallback((date) => {
		const originalDate = new Date(date)
		const options = {
			month: 'short',
			day: '2-digit',
			hour: 'numeric',
			minute: 'numeric',
			hour12: true
		}

		const formattedDate = originalDate.toLocaleDateString('en-US', options)
		const formattedTime = originalDate.toLocaleTimeString('en-US', options)
		const finalFormattedString = `${formattedDate} `
		return finalFormattedString
	}, [])

	return (
		<>
			<PageAlerts pageId={'fetchnotification'} />
			<div
				className="dropdown dropdown-notification ms-xl-4 relative ms-3 cursor-pointer"
				ref={closeref}
			>
				<div
					href=""
					className="relative"
					data-bs-toggle="dropdown"
					data-bs-auto-close="outside"
					onClick={() => setShowNotifications((prev) => !prev)}
				>
					<Badges
						text={ACTIONS.length}
						className="badge-important badge-tiny max-h-5"
						positionclassName="absolute -top-[3px] left-2"
					/>
					<Icon
						name="line-notification-list"
						width="24px"
						height="24px"
						className="icon-fill-primary300"
					/>
				</div>
				{showNotifications && (
					<div className="dropdown-menu dropdown-menu-end !right-[-8px] !top-0 !z-auto  !bg-[#fff]">
						<div className="dropdown-menu-header">
							<h6 className="dropdown-menu-title">
								Notifications
							</h6>
						</div>
						<ul className="list-group ">
							{Notifications &&
								NOTIFICATIONS.map((notification, index) => {
									return (
										index < 5 && (
											<li
												className="list-group-item"
												key={index}
											>
												<div className="list-group-body">
													<span
														className={`${notification.action_status ? 'badge-success' : 'badge-danger'} px-[7.15px] pb-1 pt-[2.5px] capitalize`}
													>
														{notification.action ===
														'update_profile'
															? 'Update profile'
															: notification.action}
													</span>
													<p className="!text-[13px]">
														{notification.message}
													</p>
													<span>
														{formateDate(
															notification.updated_at
														)}
													</span>
												</div>
											</li>
										)
									)
								})}
						</ul>
						<div className="dropdown-menu-footer">
							<Link
								to={{
									pathname: '/notificationslist',
									state: NOTIFICATIONS
								}}
								onClick={HandleShowNotifications}
							>
								Show all Notifications
							</Link>
						</div>
					</div>
				)}
			</div>
		</>
	)
}
export default React.memo(Notifications)
