import { gql } from '@apollo/client'

export const LIST_MAPPING_MUTATION = gql`
	mutation ($category_id: Int) {
		mapKnowledgeBaseList(category_id: $category_id) {
			status
			message
			errors
			data
		}
	}
`
