import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { gql } from '@apollo/client'
import client from '../../utils/GraphQl/apolloClient'
const key = 'appsetting_user_profile_additional_fields'
export const fetchUserProfileAdditionalFieldsSettings = createAsyncThunk(
	'userProfileAdditionalFieldsSettings/fetchUserProfileAdditionalFieldsSettings',
	async (_, { getState }) => {
		try {
			const authToken = getState().Auth.token
			const query = gql`
				query GetSetting($key: String) {
					getSetting(key: $key) {
						status
						message
						errors
						data
					}
				}
			`
			const { data } = await client.query({
				query,
				context: { headers: { authorization: authToken } },
				variables: {
					key: key
				},
				fetchPolicy: 'network-only'
			})
			return data
		} catch (error) {
			throw new Error('Failed to fetch Contact-Us settings')
		}
	}
)

const userProfileAdditionalFieldsSettings = createSlice({
	name: 'userProfileAdditionalFieldsSettings',
	initialState: {
		data: null,
		loading: false,
		error: null
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(
				fetchUserProfileAdditionalFieldsSettings.pending,
				(state) => {
					state.loading = true
				}
			)
			.addCase(
				fetchUserProfileAdditionalFieldsSettings.fulfilled,
				(state, action) => {
					state.loading = false
					state.data = action.payload
					state.error = null
				}
			)
			.addCase(
				fetchUserProfileAdditionalFieldsSettings.rejected,
				(state, action) => {
					state.loading = false
					state.error = action.error.message
				}
			)
	}
})

export default userProfileAdditionalFieldsSettings.reducer
