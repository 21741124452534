import React, { useState } from 'react'
import Verified from '../../../assets/img/check.png'
import Button from '../../../components/ui/Buttons/Button'
import { VERIFY_OTHEREMAIL_MUTATION } from '../../../utils/GraphQl/VerifyOtherEmails'
import { useDispatch, useSelector } from 'react-redux'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import { addAlerts } from '../../../store/AlertSlice'
import PageAlerts from '../../PageAlerts'
const PersonalInfo = () => {
	const personalinfo = useSelector(
		(state) => state.Auth?.userdata?.user?.user_data?.personal_information
	)
	const authToken = useSelector((state) => state.Auth.token)
	const dispatch = useDispatch()
	const userdata = {
		title: personalinfo?.title || '',
		full_name: personalinfo?.fullname || '',
		dob: personalinfo?.dob || '',
		age: personalinfo?.age || '',
		status: personalinfo?.status || '',
		gender: personalinfo?.gender || '',
		personal_email: personalinfo?.personal_email_id || '',
		personal_mobile_number:
			personalinfo?.personal_mobile_number?.Number || '',
		citizenship: personalinfo?.citizenship?.country || '',
		residency: personalinfo?.residency?.country || '',
		dual_citizenship: personalinfo?.dual_citizenship || '',
		second_citizenship: personalinfo?.second_citizenship?.country || '',
		identification_document: personalinfo?.identification_document || '',
		id_number: personalinfo?.id_number || '',
		expiry_date: personalinfo?.expiry_date || '',
		issue_location: personalinfo?.issue_location?.country || '',
		home_address: personalinfo?.home_address || '',
		permenant_address: personalinfo?.permenant_address || '',
		city1: personalinfo?.home_address_city || '',
		pin_code1: personalinfo?.home_address_pin_code || '',
		state1: personalinfo?.home_address_state || '',
		country1: personalinfo?.home_address_country?.country || '',
		city2: personalinfo?.permanent_address_city || '',
		pin_code2: personalinfo?.permanent_address_pin_code || '',
		state2: personalinfo?.permanent_address_state || '',
		country2: personalinfo?.permanent_address_country?.country || '',
		relationship: personalinfo?.relationship || '',
		emergency_contact_name: personalinfo?.emergency_contact_name || '',
		emergency_contact_number:
			personalinfo?.emergency_contact_number?.Number || '',
		emergency_contact_email: personalinfo?.emergency_contact_email || '',
		profile_photo1: personalinfo?.profile_photo,
		security_clearance: personalinfo?.security_clearance
	}

	const [verify] = useMutation(VERIFY_OTHEREMAIL_MUTATION)
	const [verifying, setverifying] = useState(false)

	const handleVerification = async (e) => {
		try {
			setverifying(true)
			const response = await verify({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: {
						mail_type: 'personal_email',
						email: userdata.personal_email
					}
				}
			})
			let data = await response.data
			if (data.createMailVerificationLink.status === 200) {
				dispatch(
					addAlerts({
						pageId: 'personalinfoform',
						type: 'success',
						message: data.createMailVerificationLink.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setverifying(false)
			} else if (data.createMailVerificationLink.status === 429) {
				dispatch(
					addAlerts({
						pageId: 'personalinfoform',
						type: 'danger',
						message: 'too many requsets',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setverifying(false)
			} else if (data.createMailVerificationLink.status === 401) {
				dispatch(
					addAlerts({
						pageId: 'personalinfoform',
						type: 'danger',
						message: 'please login agin',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setverifying(false)
			} else if (data.createMailVerificationLink.status === 400) {
				dispatch(
					addAlerts({
						pageId: 'personalinfoform',
						type: 'danger',
						message: 'Bad request for email verification',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
				setverifying(false)
			} else if (data.createMailVerificationLink.status === 500) {
				dispatch(
					addAlerts({
						pageId: 'personalinfoform',
						type: 'danger',
						message: data.createMailVerificationLink.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setverifying(false)
			}
		} catch (error) {
			dispatch(
				addAlerts({
					pageId: 'personalinfoform',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	return (
		<>
			<PageAlerts pageId={'personalinfoform'} />
			<div>
				<div className="bg-[#f7f7f7] px-[15px] py-[10px]">
					<div className="multipleinputs grid grid-cols-2  md:grid-cols-4 ">
						<div className="col-span-4 sm:col-span-1 md:col-span-1 md:w-3/4 lg:w-full">
							<div className="form-group">
								<label className="label">Title</label>
								<div className="text-display">
									<p>{userdata.title} </p>
								</div>
							</div>
						</div>

						<div className=" col-span-4 sm:col-span-3 md:col-span-3 md:w-1/2 lg:w-full">
							<div className="form-group">
								<label className="label">Full Name</label>
								<div className="text-display">
									<p>{userdata.full_name}</p>
								</div>
							</div>
						</div>
						<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
							<div className="form-group">
								<label className="label">Date of Birth</label>
								<div className="text-display">
									<p>
										{userdata.dob.day} {userdata.dob.month}{' '}
										{userdata.dob.year}
									</p>
								</div>
							</div>
						</div>

						<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
							<div className="form-group">
								<label className="label">Age</label>
								<div className="text-display">
									<p>{userdata.age}</p>
								</div>
							</div>
						</div>

						<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
							<div className="form-group">
								<label className="label">Status</label>
								<div className="text-display">
									<p>{userdata.status}</p>
								</div>
							</div>
						</div>

						<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
							<div className="form-group">
								<label className="label">Gender</label>
								<div className="text-display">
									<p>{userdata.gender} </p>
								</div>
							</div>
						</div>
						<div className="col-span-4 sm:col-span-2  md:col-span-2 lg:w-full">
							<div className="form-group">
								<label className="label">
									Personal eMail ID
								</label>
								<div className="text-display">
									<p>{userdata.personal_email}</p>
									{personalinfo?.personal_email_verified ? (
										<div className="verfied">
											<img src={Verified} alt="" />
											<span>Verified</span>
										</div>
									) : (
										<>
											{userdata.personal_email && (
												<>
													{verifying ? (
														<Button
															onClick={
																handleVerification
															}
															className={`btn btn-danger btn-large p-disabled !static`}
														>
															<span className="waiting-loader">
																{' '}
															</span>
															<span className="ml-1">
																Verifying...
															</span>
														</Button>
													) : (
														<Button
															onClick={
																handleVerification
															}
															className={`btn btn-danger btn-large !static`}
														>
															verify now
														</Button>
													)}
												</>
											)}
										</>
									)}
								</div>
							</div>
						</div>
						<div className="col-span-4 sm:col-span-2  md:col-span-2 lg:w-full">
							<div className="form-group">
								<label className="label">
									Personal Mobile Number
								</label>
								<div className="text-display">
									<p>{userdata.personal_mobile_number}</p>
									{/* <div className="verfied">
										<img src={Verified} alt="" />
										<span>Verified</span>
									</div> */}
								</div>
							</div>
						</div>

						<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
							<div className="form-group">
								<label className="label">Citizenship</label>
								<div className="text-display">
									<p>{userdata.citizenship}</p>
								</div>
							</div>
						</div>

						<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
							<div className="form-group">
								<label className="label">Residency</label>
								<div className="text-display">
									<p>{userdata.residency}</p>
								</div>
							</div>
						</div>

						<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
							<div className="form-group">
								<label className="label">
									Dual Citizenship
								</label>
								<div className="text-display">
									<p>{userdata.dual_citizenship}</p>
								</div>
							</div>
						</div>

						<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
							<div className="form-group">
								<label className="label">
									Second Citizenship
								</label>
								<div className="text-display">
									<p>{userdata.second_citizenship}</p>
								</div>
							</div>
						</div>

						<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
							<div className="form-group">
								<label className="label">
									Identification Document
								</label>
								<div className="text-display">
									<p>{userdata.identification_document}</p>
								</div>
							</div>
						</div>

						<div className="col-span-4 sm:col-span-2   md:col-span-2 lg:col-span-1 lg:w-full">
							<div className="form-group">
								<label className="label">ID Number</label>
								<div className="text-display">
									<p>{userdata.id_number}</p>
									{/* <div className="verfied">
										<img src={Verified} alt="" />
										<span>Verified</span>
									</div> */}
								</div>
							</div>
						</div>

						<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
							<div className="form-group">
								<label className="label">Expiry Date</label>
								<div className="text-display">
									<p>
										{userdata.expiry_date.day}{' '}
										{userdata.expiry_date.month}{' '}
										{userdata.expiry_date.year}
									</p>
								</div>
							</div>
						</div>

						<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
							<div className="form-group">
								<label className="label">Issue Location</label>
								<div className="text-display">
									<p>{userdata.issue_location}</p>
								</div>
							</div>
						</div>
						<div className="col-span-4 sm:col-span-2 md:col-span-2 lg:w-full">
							<div className="form-group">
								<label className="label">Home Address</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{userdata.home_address}</p>
									</div>
								</div>
							</div>
						</div>

						<div className="col-span-4 sm:col-span-2 md:col-span-2 lg:w-full">
							<div className="form-group">
								<label className="label">
									Permenant Address
								</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{userdata.permenant_address}</p>
									</div>
								</div>
							</div>
						</div>

						<div className=" col-span-4 sm:col-span-2 md:col-span-1  lg:w-full">
							<div className="form-group">
								<label className="label">City</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{userdata.city1}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-span-4 sm:col-span-2 md:col-span-1  lg:w-full">
							<div className="form-group">
								<label className="label">Pin Code</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{userdata.pin_code1}</p>
									</div>
								</div>
							</div>
						</div>

						<div className="col-span-4 sm:col-span-2 md:col-span-1 lg:w-full">
							<div className="form-group">
								<label className="label">City</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{userdata.city2}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-span-4 sm:col-span-2 md:col-span-1 lg:w-full">
							<div className="form-group">
								<label className="label">Pin Code</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{userdata.pin_code2}</p>
									</div>
								</div>
							</div>
						</div>

						<div className="col-span-4 sm:col-span-2 md:col-span-1  lg:w-full">
							<div className="form-group">
								<label className="label">State</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{userdata.state1}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-span-4 sm:col-span-2 md:col-span-1  lg:w-full">
							<div className="form-group">
								<label className="label">Country</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{userdata.country1}</p>
									</div>
								</div>
							</div>
						</div>

						<div className="col-span-4 sm:col-span-2 md:col-span-1  lg:w-full">
							<div className="form-group">
								<label className="label">State</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{userdata.state2}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-span-4 sm:col-span-2 md:col-span-1 lg:w-full">
							<div className="form-group">
								<label className="label">Country</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{userdata.country2}</p>
									</div>
								</div>
							</div>
						</div>

						<div className="col-span-4 sm:col-span-2 md:col-span-1 ">
							<div className="form-group">
								<label className="label">
									Emergency Contact Name
								</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{userdata.emergency_contact_name}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-span-4 sm:col-span-2 md:col-span-1 ">
							<div className="form-group">
								<label className="label">Relationship</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{userdata.relationship}</p>
									</div>
								</div>
							</div>
						</div>
						{/* 
					<div className="col-span-4 sm:col-span-1  md:col-span-1 lg:w-full">
						<div className="form-group">
							<label className="label">Profile Photo</label>
							<div className="text-display">
								<img src={userdata.profile_photo1} alt="Profile Photo" />
							</div>
						</div>
					</div> */}
						<div className="col-span-4 sm:col-span-2 md:col-span-1 ">
							<div className="form-group">
								<label className="label">
									Emergency Contact Number
								</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>
											{userdata.emergency_contact_number}
										</p>
									</div>
								</div>
							</div>
						</div>
						{/* <div className="col-span-4 sm:col-span-2 md:col-span-1 ">
							<div className="form-group">
								<label className="label">
									Security Clearance
								</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>{userdata.security_clearance}</p>
									</div>
								</div>
							</div>
						</div> */}
						<div className="col-span-4 sm:col-span-2 md:col-span-1 ">
							<div className="form-group">
								<label className="label">
									Emergency Contact Email
								</label>
								<div className="text-display">
									<div className="text-display-budge">
										<p>
											{userdata.emergency_contact_email}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default PersonalInfo
