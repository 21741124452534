import React, { useState, useEffect } from 'react'
import Button from '../../../components/ui/Buttons/Button'
import Icon from '../../../components/ui/icon'
import PersonalInfoDrawer from '../PersonalInfoDrawer'
import BusinessinfoDrawer from '../BusinessInfoDrawer'
import AdditionalInfoDrawer from '../AdditionalInfoDrawer'
import SocialInfoDrawer from '../SocialInfoDrawer'
const FormMiddleButtons = ({ heading, onEditProfile, setdrawer, users }) => {
	const [show, setShow] = useState(false)
	const [drawerType, setDrawerType] = useState(setdrawer)
	const HandleDrawerShowClick = () => {
		setShow((prev) => !prev)
	}
	useEffect(() => {
		const handleScrollPosition = () => {
			if (show) {
				const scrollPosition = window.pageYOffset
				document.body.classList.add('scroll-disabled')
				document.body.style.top = `-${scrollPosition}px`
			} else {
				document.body.classList.remove('scroll-disabled')
				const scrollPosition = parseInt(
					document.body.style.top || '0',
					10
				)
				document.body.style.top = ''
				window.scrollTo(0, -scrollPosition)
			}
		}

		setTimeout(() => {
			document.body.classList.remove('scroll-disabled')
		}, 300)

		handleScrollPosition()
	}, [show])

	return (
		<>
			{' '}
			{drawerType === 'personalinfoedit' && (
				<PersonalInfoDrawer show={show} setShow={setShow} />
			)}
			{drawerType === 'socialinfoedit' && (
				<SocialInfoDrawer show={show} setShow={setShow} />
			)}
			{drawerType === 'busnessinfoedit' && (
				<BusinessinfoDrawer
					show={show}
					setShow={setShow}
					users={users}
				/>
			)}
			{drawerType === 'additionalinfoedit' && (
				<AdditionalInfoDrawer show={show} setShow={setShow} />
			)}
			<div className="mb-6 flex flex-wrap items-center justify-between">
				<h5 className="">{heading} </h5>
				<div className="flex flex-wrap gap-1 ">
					<Button
						className="btn-large btn-primary  flex gap-1 px-3"
						isIconBtn={true}
					>
						<Icon
							name="line-download-file"
							width={'16px'}
							height={'15px'}
							className="icon-fill-white"
							viewBox={'0 0 23 23'}
						/>
						Download
					</Button>
					<Button
						className="btn-large btn-primary  flex gap-1 px-3"
						onClick={HandleDrawerShowClick}
						isIconBtn={true}
					>
						<Icon
							name="line-edit-file"
							width={'16px'}
							height={'15px'}
							className="icon-fill-white"
							viewBox={'0 0 23 23'}
						/>
						Edit Profile
					</Button>
				</div>
			</div>
		</>
	)
}
export default React.memo(FormMiddleButtons)
