import React, { useEffect, useState } from 'react'
import Icon from '../ui/icon'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addPage } from '../../store/SettingsSlice'
const SidebarBody = ({ DATA, onClick }) => {
	const [showLinks, setShowLinks] = useState({})
	const FORCEUPDATE = useSelector(
		(state) => state.Auth?.userdata?.forceUpdate
	)
	const [showParents, setShowParents] = useState({})
	const dispatch = useDispatch()
	const navigate = useNavigate()
	useEffect(() => {
		DATA.map((items) => {
			setShowParents((prev) => {
				return {
					...prev,
					[items.id]: true
				}
			})
			items.children.map((items) => {
				if (items.id) {
					setShowLinks((prev) => {
						return {
							...prev,
							[items.id]: false
						}
					})
				}
			})
		})
	}, [])
	const toggleParents = (parent) => {
		let id = parent.id
		setShowParents((prev) => {
			return {
				...prev,
				[id]: !prev[id]
			}
		})
	}
	const HandleChildClick = (link) => {
		if (!FORCEUPDATE) {
			let data = []
			data.push(link)
			dispatch(addPage(data))
		}
	}
	const Headings =
		' relative  text-[14px] font-inter-ui grey300 nav-link px-5 py-2 flex justify-start gap-3 items-center hover:bg-contrast-lower hover:cursor-pointer'
	const ListClass =
		'block px-5 py-1.5 grey300 text-[13px] relative indent-[30.5px] before:absolute before:top-[50%] before:left-[26px] before:w-[5px] before:h-[1.5px] before:bg-[#9ea7b1] before:transition-all before:hover:transition-all  before:hover:duration-250 hover:transition-all hover:before:w-[16px] before:hover:left-[20.5px] hover:!text-[#757575]'
	const HandleClick = (title) => {
		setShowLinks((prev) => ({
			...prev,
			[title]: !prev[title]
		}))
	}
	const settingsArray = [
		'/registersettings',
		'/contact-settings',
		'/loginsettings',
		'/fileupload',
		'/magiclinksettings',
		'/emailserver',
		'/additional-fields',
		'/emailtemplates',
		'/listpage',
		'/list-mapping'
	]
	const RESULT = (
		<>
			{DATA.map((parent) => {
				return (
					<div
						className="nav-group show border-b border-b-[#f3f5f9] "
						key={parent.id}
					>
						{parent.Heading && (
							<span
								onClick={() => toggleParents(parent)}
								className="grey300 side-arrow-down relative block  px-5 py-3 text-[11px] uppercase tracking-[.7px] hover:cursor-pointer hover:bg-contrast-lower"
							>
								{parent.Heading}
							</span>
						)}
						<ul
							className={`nav nav-sidebar  ${showParents[parent.id] ? 'open' : ''}`}
						>
							{parent.children.map((child) => (
								<div
									key={child.title}
									className="nav-item grey300 w-full"
								>
									<li
										className={Headings}
										onClick={() => {
											navigate(child.link)
											HandleClick(child.id)
										}}
									>
										<Icon
											name={child.icon}
											width={'20px'}
											height={'20px'}
											className="icon-fill-grey200"
											viewBox={'0 0 25 25'}
											fillColor="grey"
										/>
										<span
											className={`${child.data.length > 0 ? 'sidebar-arrow-right' : ''} !grey3000`}
										>
											<span>{child.title}</span>
										</span>
									</li>
									<div
										className={`${showLinks[child.id] ? 'expand block' : 'sidebar-menu-hide'}`}
									>
										{showLinks[child.id] &&
											child.data.map((lists) => {
												return (
													<li
														key={lists.name}
														className="nav-item"
													>
														<nav className="nav nav-sub">
															{!settingsArray.includes(
																lists.link
															) ? (
																<Link
																	to={
																		lists.link
																	}
																	className={
																		ListClass
																	}
																>
																	{lists.name}
																</Link>
															) : (
																<Link
																	onClick={() =>
																		HandleChildClick(
																			lists.link
																		)
																	}
																	className={
																		ListClass
																	}
																>
																	{lists.name}
																</Link>
															)}
														</nav>
													</li>
												)
											})}
									</div>
								</div>
							))}
						</ul>
					</div>
				)
			})}
		</>
	)
	return (
		<div id="sidebarMenu" className="sidebar-body relative h-auto ">
			<div className="w-[240px]">{RESULT}</div>
		</div>
	)
}
export default React.memo(SidebarBody)
