import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { gql } from '@apollo/client'
import client from '../../utils/GraphQl/apolloClient'
import { GET_ALL_TABLE_LAYOUTS } from '../../utils/GraphQl/TableLayout'
export const getAllTableLayouts = createAsyncThunk(
	'table_layouts/getAllTableLayouts',
	async (_, { getState }) => {
		try {
			const authToken = getState().Auth.token
			const query = gql`
				${GET_ALL_TABLE_LAYOUTS}
			`
			const { data } = await client.query({
				query,
				context: { headers: { authorization: authToken } },
				fetchPolicy: 'network-only'
			})
			return data
		} catch (error) {
			throw new Error('Failed to fetch Login settings')
		}
	}
)

const tableLayoutSlice = createSlice({
	name: 'tableLayoutSlice',
	initialState: {
		userListLayout: [],
		businessObjectiveLayout: [],
		additionalFieldsLayout: [],
		emailTemplateLayout: [],
		listPageLayout: [],
		organizationContextLayout: [],
		riskCatalogueLayout: [],
		notificationActionLayout: [],
		notificationLayout: [],
		loading: false,
		error: null
	},
	reducers: {
		updateUserListLayout: (state, action) => {
			state.userListLayout = [action.payload]
		},
		updateBusinessObjectiveLayout: (state, action) => {
			state.businessObjectiveLayout = [action.payload]
		},
		updateadditionalFieldsLayout: (state, action) => {
			state.additionalFieldsLayout = [action.payload]
		},
		updateEmailTemplateLayout: (state, action) => {
			state.emailTemplateLayout = [action.payload]
		},
		updatelistPageLayoutLayout: (state, action) => {
			state.listPageLayout = [action.payload]
		},
		updateOrganizationContextLayout: (state, action) => {
			state.organizationContextLayout = [action.payload]
		},
		updateRiskCatalogueLayout: (state, action) => {
			state.riskCatalogueLayout = [action.payload]
		},
		updateNotificationActionLayout: (state, action) => {
			state.notificationActionLayout = [action.payload]
		},
		updateNotificationLayout: (state, action) => {
			state.notificationLayout = [action.payload]
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllTableLayouts.pending, (state) => {
				state.loading = true
			})
			.addCase(getAllTableLayouts.fulfilled, (state, action) => {
				state.loading = false
				let data = action.payload?.getAllTableStructures?.data
				if (Array.isArray(data)) {
					data?.forEach((element) => {
						if (element.table_name === 'table_user_list') {
							state.userListLayout = [element]
						}
						if (
							element.table_name ===
							'table_additional_fields_list'
						) {
							state.additionalFieldsLayout = [element]
						}
						if (element.table_name === 'table_business_objective') {
							state.businessObjectiveLayout = [element]
						}
						if (element.table_name === 'table_email_template') {
							state.emailTemplateLayout = [element]
						}
						if (element.table_name === 'table_list_page') {
							state.listPageLayout = [element]
						}
						if (
							element.table_name === 'table_organization_context'
						) {
							state.organizationContextLayout = [element]
						}
						if (element.table_name === 'table_risk_catalogue') {
							state.riskCatalogueLayout = [element]
						}
						if (
							element.table_name === 'table_notification_actions'
						) {
							state.notificationActionLayout = [element]
						}
						if (element.table_name === 'table_notifications') {
							state.notificationLayout = [element]
						}
					})
				}

				// state.layouts =data;
				state.error = null
			})
			.addCase(getAllTableLayouts.rejected, (state, action) => {
				state.loading = false
				state.error = action.error.message
			})
	}
})
export const {
	updateadditionalFieldsLayout,
	updateBusinessObjectiveLayout,
	updateUserListLayout,
	updateEmailTemplateLayout,
	updatelistPageLayoutLayout,
	updateOrganizationContextLayout,
	updateRiskCatalogueLayout,
	updateNotificationLayout,
	updateNotificationActionLayout
} = tableLayoutSlice.actions

export default tableLayoutSlice.reducer
