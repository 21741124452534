import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import PageAlerts from '../../PageAlerts'
import ElasticTextbox from '../../../components/ui/Elastic-textbox'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import Simpledropdown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import MultiSelectdropdown from '../../../components/Dropdowns/MultiSelectdropdown'
import { faBook, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { UPDATE_CATALOGUE } from '../../../utils/GraphQl/CatalogueMutations'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import {
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import ProgressBar from '../../ProgressBar'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'

const EditCatalogue = ({
	setDrawerstate,
	setSelectedData,
	setDataTableData,
	selectedData,
	categorySelectorData,
	tagSelectorData,
	fetchCategoryLists
}) => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [loader, setLoader] = useState(false)
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const dispatch = useDispatch()
	const [update_catalogue] = useMutation(UPDATE_CATALOGUE)
	const [lists, setLists] = useState([])
	const [items, setItems] = useState(tagSelectorData.options_data)
	const CATEGORY = categorySelectorData.options_data

	const [Formdata, setFormdata] = useState({
		category: selectedData[0]?.category || '',
		risk_heading: selectedData[0]?.risk_heading || '',
		risk_description: selectedData[0]?.risk_description || ''
	})
	useEffect(() => {
		let category = selectedData[0].catalogue_category
		let idx = CATEGORY.findIndex((item) => item.label === category)
		HANDLEFORMDATA('category', CATEGORY[idx])
		let tags = selectedData[0]?.tags?.split(',')
		items.forEach((item) => {
			if (tags.includes(item.value)) {
				setLists((prev) => [...prev, item])
			}
		})
		let FilteredItems = items.filter((item) => !tags.includes(item.value))
		setItems(FilteredItems)
	}, [])
	const HANDLEFORMDATA = (field, value) => {
		setFormdata((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}
	const add = (data) => {
		setLists([...lists, data])
		setItems(items.filter((item) => item.label !== data.label))
	}
	const removeItem = (item) => {
		setLists((prevLists) =>
			prevLists.filter((listItem) => listItem.label !== item.label)
		)
		setItems([item, ...items])
	}

	const handleSubmit = async () => {
		let TAGS = []
		if (lists.length !== 0) {
			TAGS = lists.map((item) => item.value)
		}

		try {
			setLoader(true)
			const response = await update_catalogue({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					catalogueId: Number(selectedData[0].id),
					input: {
						tags: TAGS,
						catalogue_category: Formdata.category?.value,
						risk_description: Formdata.risk_description,
						risk_heading: Formdata.risk_heading,
						catalogue_description: ''
					}
				}
			})
			setLoader(false)
			let data = await response?.data
			let updateCatalogue = data?.updateCatalogue
			if (updateCatalogue.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'edit_catalogue',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (updateCatalogue.status !== 201) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'edit_catalogue',
						type: 'danger',
						message:
							updateCatalogue.errors.map((err) => err) ??
							updateCatalogue.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (
				updateCatalogue.status !== 201 &&
				updateCatalogue.message
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'edit_catalogue',
						type: 'danger',
						message: updateCatalogue.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (updateCatalogue.status === 201) {
				setLoader(false)
				let updated = updateCatalogue?.data
				let category = await categorySelectorData.options_data.find(
					(item) => item.value === updated[0]?.catalogue_category
				)?.label
				let newObject = {
					id: selectedData[0]?.id,
					catalogue_category: category,
					// catalogue_category: updated[0]?.catalogue_category,
					risk_description: updated[0]?.risk_description,
					risk_heading: updated[0]?.risk_heading,
					tags: updated[0]?.tags.join(',')
				}
				setSelectedData([{ ...newObject }])
				setDataTableData((prev) => {
					let DATA = [...prev]
					let index = DATA.findIndex(
						(item) => item.id === selectedData[0].id
					)
					if (index !== -1) {
						DATA[index] = newObject
					}
					return DATA
				})
				dispatch(
					addAlerts({
						pageId: 'edit_catalogue',
						type: 'success',
						message: updateCatalogue.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					handleDrawerClose()
				}, 3000)
			}
		} catch (error) {
			console.log('error ', error)
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'edit_catalogue',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	const FORM = (
		<form
			encType="multipart/form-data"
			className=" flex w-[350px] flex-col justify-start"
		>
			<>
				<div className="flex w-[350px] flex-col justify-start pr-1">
					{categorySelectorData ? (
						<>
							{categorySelectorData?.field_type ===
								'simple_dropdown' && (
								<Simpledropdown
									label={'Category'}
									options={CATEGORY}
									value={Formdata.category?.label}
									onChange={(data) =>
										HANDLEFORMDATA('category', data)
									}
								/>
							)}
						</>
					) : null}
					{/* <Simpledropdown
						label="Category"
						options={CATEGORY}
						onChange={(data) => HANDLEFORMDATA('category', data)}
						value={Formdata.category?.label}
					/> */}
					<ElasticTextbox
						name="risk_heading"
						id="risk_heading"
						value={Formdata.risk_heading}
						labelName={'Risk Heading'}
						onChange={(data) =>
							HANDLEFORMDATA('risk_heading', data)
						}
					/>
					<ElasticTextbox
						name="risk_description"
						id="risk_description"
						value={Formdata.risk_description}
						labelName={'Risk Description'}
						onChange={(data) =>
							HANDLEFORMDATA('risk_description', data)
						}
					/>
					{tagSelectorData ? (
						<>
							{tagSelectorData?.field_type ===
								'multiselect_dropdown' && (
								<MultiSelectdropdown
									label="Tags"
									remainingItems={items}
									selectedItems={lists}
									onItemAdd={(item) => add(item)}
									onItemRemove={(item) => removeItem(item)}
								/>
							)}
						</>
					) : null}
					{/* <MultiSelectdropdown
						label="Tags"
						remainingItems={items}
						selectedItems={lists}
						onItemAdd={(item) => add(item)}
						onItemRemove={(item) => removeItem(item)}
					/> */}
				</div>
			</>
		</form>
	)
	const handleDrawerClose = () => {
		dispatch(removeAlerts({ pageId: 'edit_catalogue' }))
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setDrawerstate(false)
	}
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])

	useEffect(() => {
		fetchCategoryLists()
	}, [])

	useEffect(() => {
		setBody(FORM)
	}, [
		showDrawer,
		Formdata,
		items,
		lists,
		categorySelectorData,
		tagSelectorData
	])

	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal "> </h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={handleSubmit}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)
	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)
	return (
		<>
			{loader && <ProgressBar />}
			<PageAlerts pageId={'edit_catalogue'} />
			<Drawer
				label="Edit Catalogue"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}

export default EditCatalogue
