import React, { useState, useEffect, useLayoutEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import TextBox from '../../../components/ui/TextBox'
import Simpledropdown from '../../../components/Dropdowns/SimpledropdownOptionKey'
import SimpleDropDown from '../../../components/Dropdowns/Simpledropdown'
import DatePicker from '../../../components/ui/Datepicker'
import ElasticTextBox from '../../../components/ui/Elastic-textbox'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import CountrySelector from '../../../components/ui/Countryselctor'
import PhoneSelector from '../../../components/ui/PhoneSelector'
import {
	faBook,
	faUniversalAccess,
	faCheck,
	faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import { UPDATE_PROFILE_MUTATION } from '../../../utils/GraphQl/UpdateProfileMutation'
import { updateBiusinessInfo } from '../../../store/AuthSlice/AuthSlice'
import { useDispatch, useSelector } from 'react-redux'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import ProgressBar from '../../ProgressBar'
import PageAlerts from '../../PageAlerts'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const BusinessinfoDrawer = ({ show, setShow, users }) => {
	const USER = useSelector((state) => state.Auth)
	const BUSINESS_INFO_DYNAMIC_FIELDS = useSelector(
		(state) => state.businessInfoDynamicFields
	)
	const [loader, setLoader] = useState(false)
	const businessinfo = USER?.userdata?.user?.user_data?.business_information
	const [showDrawer, setShowDrawer] = useState(false)
	const [overlay, setOverlay] = useState('')
	const [size, setSize] = useState('md')
	const [scrollable, setScrollable] = useState(false)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [body, setBody] = useState(null)
	const [clearenceOptions, setClearenceOptions] = useState([])
	const [gradeOptions, setGradeOptions] = useState([])
	const [reportingAdminOptions, setReportingAdminOptions] = useState([])
	const [reportingManagerOptions, setReportingManagerOptions] = useState([])
	const [ou1Options, setOu1Options] = useState([])
	const [ou2Options, setOu2Options] = useState([])
	const [ou3Options, setOu3Options] = useState([])
	const [currentDate, setCurrentDate] = useState(null)
	const [workCountry, setWorkCountry] = useState(
		businessinfo?.work_address_country || null
	)
	const [businessCountry, setBusinessCountry] = useState(
		businessinfo?.business_address_country || null
	)
	const dispatch = useDispatch()
	const [Formdata, setFormdata] = useState({
		eid: businessinfo?.employee_id || '',
		grade: businessinfo?.grade || '',
		designation: businessinfo?.designation || '',
		role: businessinfo?.role || '',
		clearence: businessinfo?.clearence || '',
		rma: businessinfo?.reporting_manager_administrative || '',
		rmf: businessinfo?.reporting_manager_functional || '',
		ou1: businessinfo?.ou1_group || '',
		ou2: businessinfo?.ou2_company || '',
		ou3: businessinfo?.ou3_department || '',
		workemailid: businessinfo?.work_email_id || '',
		workaddress: businessinfo?.work_address || '',
		businessaddress: businessinfo?.business_address || '',
		city1: businessinfo?.work_address_city || '',
		city2: businessinfo?.business_address_city || '',
		pincode1: businessinfo?.work_address_pin_code || '',
		pincode2: businessinfo?.business_address_pin_code || '',
		state1: businessinfo?.work_address_state || '',
		state2: businessinfo?.business_address_state || ''
	})

	const [workPhoneNumber, setworkPhoneNumber] = useState({
		countryCode: businessinfo?.work_phone_number?.countryCode || '+91',
		countryName: businessinfo?.work_phone_number?.countryName || 'india',
		countryFlag: businessinfo?.work_phone_number?.countryFlag || 'in',
		Number: businessinfo?.work_phone_number?.Number || ''
	})
	const UpdateFields = (obj) => {
		setworkPhoneNumber({
			...workPhoneNumber,
			countryCode: obj.countryCode,
			countryName: obj.countryName,
			countryFlag: obj.countryFlag
		})
	}
	const setuserNumber = (obj) => {
		setworkPhoneNumber({
			...workPhoneNumber,
			Number: obj.Number
		})
	}
	const HANDLEFORMDATA = (field, value) => {
		setFormdata((prevdata) => {
			return {
				...prevdata,
				[field]: value
			}
		})
	}
	useEffect(() => {
		const updatedFormdata = { ...Formdata }

		if (BUSINESS_INFO_DYNAMIC_FIELDS.grade) {
			let gradeIndex = BUSINESS_INFO_DYNAMIC_FIELDS.grade.findIndex(
				(item) => item.value === businessinfo?.grade
			)
			if (gradeIndex !== -1) {
				updatedFormdata.grade =
					BUSINESS_INFO_DYNAMIC_FIELDS.grade[gradeIndex]
			}
			setGradeOptions(BUSINESS_INFO_DYNAMIC_FIELDS.grade)
		}

		if (BUSINESS_INFO_DYNAMIC_FIELDS.clearence) {
			let clearenceIdx = BUSINESS_INFO_DYNAMIC_FIELDS.clearence.findIndex(
				(item) => item.value === businessinfo?.clearence
			)
			if (clearenceIdx !== -1) {
				updatedFormdata.clearence =
					BUSINESS_INFO_DYNAMIC_FIELDS.clearence[clearenceIdx]
			}
			setClearenceOptions(BUSINESS_INFO_DYNAMIC_FIELDS.clearence)
		}
		if (BUSINESS_INFO_DYNAMIC_FIELDS.ou1) {
			let ou1Idx = BUSINESS_INFO_DYNAMIC_FIELDS.ou1.findIndex(
				(item) => item.value === businessinfo?.ou1_group
			)
			if (ou1Idx !== -1) {
				updatedFormdata.ou1 = BUSINESS_INFO_DYNAMIC_FIELDS.ou1[ou1Idx]
			}
			setOu1Options(BUSINESS_INFO_DYNAMIC_FIELDS.ou1)
		}

		if (BUSINESS_INFO_DYNAMIC_FIELDS.ou2) {
			let ou2Idx = BUSINESS_INFO_DYNAMIC_FIELDS.ou2.findIndex(
				(item) => item.value === businessinfo?.ou2_company
			)
			if (ou2Idx !== -1) {
				updatedFormdata.ou2 = BUSINESS_INFO_DYNAMIC_FIELDS.ou2[ou2Idx]
			}
			setOu2Options(BUSINESS_INFO_DYNAMIC_FIELDS.ou2)
		}

		if (BUSINESS_INFO_DYNAMIC_FIELDS.ou3) {
			let ou3Idx = BUSINESS_INFO_DYNAMIC_FIELDS.ou3.findIndex(
				(item) => item.value === businessinfo?.ou3_department
			)
			if (ou3Idx !== -1) {
				updatedFormdata.ou3 = BUSINESS_INFO_DYNAMIC_FIELDS.ou3[ou3Idx]
			}
			setOu3Options(BUSINESS_INFO_DYNAMIC_FIELDS.ou3)
		}

		setFormdata(updatedFormdata)
	}, [BUSINESS_INFO_DYNAMIC_FIELDS, businessinfo])

	const HandleDateChange = (date) => {
		setCurrentDate(date)
	}
	useLayoutEffect(() => {
		setBody(FORM)
	}, [show, workPhoneNumber])
	const [update] = useMutation(UPDATE_PROFILE_MUTATION)
	const handleLogin = async () => {
		try {
			setLoader(true)

			const response = await update({
				context: {
					headers: {
						authorization: USER?.token
					}
				},
				variables: {
					input: {
						user_id: USER?.userdata?.user?.user_id,
						data: {
							employee_id: Formdata.eid,
							grade: Formdata.grade?.value,
							designation: Formdata.designation,
							role: Formdata.role,
							date_of_joining: currentDate,
							clearence: Formdata.clearence?.value,
							reporting_manager_administrative:
								Formdata.rma?.value,
							reporting_manager_functional: Formdata.rmf?.value,
							ou1_group: Formdata.ou1?.value,
							ou2_company: Formdata.ou2?.value,
							ou3_department: Formdata.ou3?.value,
							work_email_id: Formdata.workemailid,
							work_phone_number: workPhoneNumber,
							work_address: Formdata.workaddress,
							work_address_city: Formdata.city1,
							work_address_pin_code: Formdata.pincode1,
							work_address_state: Formdata.state1,
							work_address_country: workCountry,
							business_address: Formdata.businessaddress,
							business_address_city: Formdata.city2,
							business_address_pin_code: Formdata.pincode2,
							business_address_state: Formdata.state2,
							business_address_country: businessCountry
						}
					}
				}
			})
			let data = await response.data
			// console.log('response from api')
			// console.log('business info =>',data)
			let updateprofile = data.updateProfile
			// console.log(updateprofile)
			if (updateprofile.status === 401) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'updatebusinessinfo',
						type: 'danger',
						message: 'Please login again.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (
				updateprofile.status !== 200 &&
				updateprofile.errors.length > 0
			) {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'updatebusinessinfo',
						type: 'danger',
						message: updateprofile.errors.map((err) => err),
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				setLoader(false)
				dispatch(
					addAlerts({
						pageId: 'updatebusinessinfo',
						type: 'success',
						message: updateprofile.message,
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					// setShowDrawer(false)
					setShowDrawer(false)
					setScrollable(false)
					setScrollableFooter(false)
					setSize('md')
					setOverlay('')
					setBody(null)
					setShow(false)
					dispatch(updateBiusinessInfo(data.updateProfile.data))
					dispatch(removeAlerts({ pageId: 'updatebusinessinfo' }))
					// navigate('/')
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'updatebusinessinfo',
					type: 'danger',
					message: 'something went wrong',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}

	const FORM = (
		<form
			encType="multipart/form-data"
			className="flex w-[350px] flex-col justify-start"
		>
			<>
				<div className="flex w-[350px] flex-col justify-start pr-1">
					<TextBox
						id={'eid'}
						name={'Eid'}
						value={Formdata.eid}
						labelName={'Employee ID'}
						type={'text'}
						onChange={(data) => HANDLEFORMDATA('eid', data)}
					/>
					<Simpledropdown
						options={gradeOptions}
						label="Grade"
						value={Formdata?.grade?.label}
						onChange={(data) => HANDLEFORMDATA('grade', data)}
					/>
					<TextBox
						name={'designation'}
						id={'designation'}
						value={Formdata.designation}
						labelName={'Designation'}
						type={'text'}
						onChange={(data) => HANDLEFORMDATA('designation', data)}
					/>

					<TextBox
						name={'role'}
						id={'role'}
						value={Formdata.role}
						labelName={'Role'}
						type={'text'}
						onChange={(data) => HANDLEFORMDATA('role', data)}
					/>

					<DatePicker
						labelName={'Date of joining'}
						onDateChange={(date) => HandleDateChange(date)}
						currentDate={currentDate}
					/>
					<Simpledropdown
						options={clearenceOptions}
						label="Clearence"
						value={Formdata?.clearence?.label}
						onChange={(data) => HANDLEFORMDATA('clearence', data)}
					/>
					<SimpleDropDown
						options={users}
						label="Reporting Manager (Administrative)"
						value={Formdata?.rma}
						onChange={(data) => HANDLEFORMDATA('rma', data)}
					/>
					<SimpleDropDown
						options={users}
						label="Reporting Manager (Functional)"
						value={Formdata?.rmf}
						onChange={(data) => HANDLEFORMDATA('rmf', data)}
					/>
					<Simpledropdown
						options={ou1Options}
						label="OU1 (Group)"
						value={Formdata?.ou1?.label}
						onChange={(data) => HANDLEFORMDATA('ou1', data)}
					/>

					<Simpledropdown
						options={ou2Options}
						label="OU2 (Group)"
						value={Formdata?.ou2?.label}
						onChange={(data) => HANDLEFORMDATA('ou2', data)}
					/>

					<Simpledropdown
						options={ou3Options}
						label="OU3 (Group)"
						value={Formdata?.ou3?.label}
						onChange={(data) => HANDLEFORMDATA('ou3', data)}
					/>

					<TextBox
						name={'workemail_id'}
						id={'workemail_id'}
						value={Formdata.workemailid}
						labelName={'Work eMail ID'}
						type={'text'}
						onChange={(data) => HANDLEFORMDATA('workemailid', data)}
					/>
					<PhoneSelector
						labelName={'Work Phone Number'}
						currentCountry={workPhoneNumber}
						onCountrySelect={(obj) => UpdateFields(obj)}
						onUSerInputChange={(obj) => setuserNumber(obj)}
					/>

					<ElasticTextBox
						id="work_address"
						value={Formdata.workaddress}
						onChange={(data) => HANDLEFORMDATA('workaddress', data)}
						labelName={'Work Address'}
					/>
					<ElasticTextBox
						id="business_address"
						value={Formdata.businessaddress}
						onChange={(data) =>
							HANDLEFORMDATA('businessaddress', data)
						}
						labelName={'Business Address'}
					/>
					<TextBox
						name={'city1'}
						id={'city1'}
						value={Formdata.city1}
						labelName={'Work City'}
						type={'text'}
						onChange={(data) => HANDLEFORMDATA('city1', data)}
					/>
					<TextBox
						name={'pincode_1'}
						id={'pincode_1'}
						value={Formdata.pincode1}
						labelName={'Work Pincode'}
						type={'text'}
						onChange={(data) => HANDLEFORMDATA('pincode1', data)}
					/>
					<TextBox
						name={'work_state'}
						id={'work_state'}
						value={Formdata.state1}
						labelName={'Work State'}
						type={'text'}
						onChange={(data) => HANDLEFORMDATA('state1', data)}
					/>
					<CountrySelector
						defaultCountry={workCountry}
						label="Work Country"
						onChange={(data) => setWorkCountry(data)}
					/>
					<TextBox
						name={'business_city2'}
						id={'business_city2'}
						value={Formdata.city2}
						labelName={'Business City'}
						type={'text'}
						onChange={(data) => HANDLEFORMDATA('city2', data)}
					/>
					<TextBox
						id={'pincode_2'}
						value={Formdata.pincode2}
						labelName={'Business Pincode'}
						type={'text'}
						onChange={(data) => HANDLEFORMDATA('pincode2', data)}
					/>
					<TextBox
						id={'state_2'}
						value={Formdata.state2}
						labelName={'Business State'}
						type={'text'}
						onChange={(data) => HANDLEFORMDATA('state2', data)}
					/>
					<CountrySelector
						defaultCountry={businessCountry}
						label="Business Country"
						onChange={(data) => setBusinessCountry(data)}
					/>
				</div>
			</>
		</form>
	)

	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setShow(false)
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)

	useEffect(() => {
		setShowDrawer(show)
		setScrollable(show)
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', show)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [show])

	useEffect(() => {
		setBody(FORM)
	}, [
		show,
		workPhoneNumber,
		Formdata,
		BUSINESS_INFO_DYNAMIC_FIELDS,
		businessinfo,
		gradeOptions,
		clearenceOptions,
		reportingAdminOptions,
		reportingManagerOptions,
		ou1Options,
		ou2Options,
		ou3Options
	])
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal ">{''}</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={() => handleLogin()}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)
	return (
		<>
			<PageAlerts pageId={'updatebusinessinfo'} />
			{loader && <ProgressBar />}

			<Drawer
				label="Business Info"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}

export default React.memo(BusinessinfoDrawer)
