import React, { useEffect, useState } from 'react'
import TextBox from '../../components/ui/TextBox'
import SimpleDropDown from '../../components/Dropdowns/Simpledropdown'
import { UPDATE_EMAIL_SERVER_QUERY } from '../../utils/GraphQl/EmailServer'
import { useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { addAlerts } from '../../store/AlertSlice'
import GlobalDrawer from '../GlobalDrawer'
import { removePage } from '../../store/SettingsSlice'
import {
	faCheck,
	faTriangleExclamation,
	faBook,
	faUniversalAccess
} from '@fortawesome/free-solid-svg-icons'
import IconButton from '../../components/ui/Buttons/Icon-button'
import { logoutUser } from '../../store/AuthSlice/AuthSlice'
import { removeAlerts } from '../../store/AlertSlice'
import { fetchEmailServerSettings } from '../../store/EmailServerSettings'
import useGlobalKeyPress from '../../hooks/globalKeyPress'
const EmailServer = () => {
	const authToken = useSelector((state) => state.Auth.token)
	const [showDrawer, setShowDrawer] = useState(true)
	const [body, setBody] = useState(null)
	const [loader, setLoader] = useState(false)
	const dispatch = useDispatch()
	const [updateEmailSettings] = useMutation(UPDATE_EMAIL_SERVER_QUERY)
	const [serverData, setServerData] = useState({})
	const { data, loading, error } = useSelector(
		(state) => state.emailServerSettings
	)
	useEffect(() => {
		if (loading) {
			// setLoader(true)
		} else if (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'emailserver',
					type: 'danger',
					message: 'Failed to fetch  settings.',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (data?.getMultipleSettings?.status === 403) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'emailserver',
					type: 'danger',
					message: data.authentication.errors[0],
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		} else if (data?.getMultipleSettings?.status === 200) {
			let SettingData = data.getMultipleSettings.data
			setLoader(false)
			SettingData.forEach((element) => {
				setServerData((prev) => {
					return {
						...prev,
						[element.key]: element.value
					}
				})
			})
		}
	}, [data, loading, error])
	const HandleData = (key, value) => {
		setServerData({
			...serverData,
			[key]: value
		})
	}
	const handleServerSettings = async () => {
		try {
			setLoader(true)
			const response = await updateEmailSettings({
				context: {
					headers: {
						authorization: authToken
					}
				},
				variables: {
					input: { ...serverData }
				}
			})
			let data = await response.data
			if (data.updateMultipleSettings.status === 403) {
				setLoader(false)

				dispatch(
					addAlerts({
						pageId: 'emailserver',
						type: 'danger',
						message: ' you are not allowed',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateMultipleSettings.status === 500) {
				setLoader(false)

				dispatch(
					addAlerts({
						pageId: 'emailserver',
						type: 'danger',
						message: data.updateMultipleSettings.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (
				data.updateMultipleSettings.status !== 200 &&
				data.updateMultipleSettings.errors.length > 0
			) {
				setLoader(false)

				dispatch(
					addAlerts({
						pageId: 'emailserver',
						type: 'danger',
						message: data.updateMultipleSettings.errors[0],
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
			if (
				data.updateMultipleSettings.status !== 200 &&
				data.updateMultipleSettings.errors.length == 0
			) {
				setLoader(false)

				dispatch(
					addAlerts({
						pageId: 'emailserver',
						type: 'danger',
						message: data.updateMultipleSettings.message,
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (data.updateMultipleSettings.status === 401) {
				setLoader(false)

				dispatch(
					addAlerts({
						pageId: 'emailserver',
						type: 'danger',
						message: 'Session expiered please login again',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)

				setTimeout(() => {
					dispatch(logoutUser())
					dispatch(removeAlerts({ pageId: 'emailserver' }))
				}, 3000)
			} else if (data.updateMultipleSettings.status === 200) {
				setLoader(false)
				dispatch(fetchEmailServerSettings())
				dispatch(
					addAlerts({
						pageId: 'emailserver',
						type: 'success',
						message: 'Data updated successsfully',
						icon: faCheck,
						autoclose: 3000
					})
				)
				setTimeout(() => {
					handleDrawerClose()
				}, 3000)
			}
		} catch (error) {
			setLoader(false)
			dispatch(
				addAlerts({
					pageId: 'updateEmailSettings',
					type: 'danger',
					message: 'error',
					icon: faTriangleExclamation,
					autoclose: 3000
				})
			)
		}
	}
	const HandleSubmit = (e) => {
		e.preventDefault()
		const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		let isSenderEmail = EMAIL_REGEXP.test(
			serverData.senderemail.value ?? serverData.senderemail
		)
		let isReplyEmail = EMAIL_REGEXP.test(
			serverData.replyemail.value ?? serverData.replyemail
		)

		if (
			!serverData.hostname ||
			!serverData.portnumber ||
			!serverData.authentication ||
			!serverData.encryption ||
			!serverData.username ||
			!serverData.password ||
			!isSenderEmail ||
			!isReplyEmail
		) {
			if (!serverData.hostname) {
				dispatch(
					addAlerts({
						pageId: 'emailserver',
						type: 'danger',
						message: 'Please enter host name.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!serverData.portnumber) {
				dispatch(
					addAlerts({
						pageId: 'emailserver',
						type: 'danger',
						message: 'Please enter port number.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!serverData.authentication) {
				dispatch(
					addAlerts({
						pageId: 'emailserver',
						type: 'danger',
						message: 'Please select authentication mode.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!serverData.encryption) {
				dispatch(
					addAlerts({
						pageId: 'emailserver',
						type: 'danger',
						message: 'Please select encryption type.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!serverData.username) {
				dispatch(
					addAlerts({
						pageId: 'emailserver',
						type: 'danger',
						message: 'Please enter user name.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!serverData.password) {
				dispatch(
					addAlerts({
						pageId: 'emailserver',
						type: 'danger',
						message: 'Please enter your password.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!serverData.senderemail) {
				dispatch(
					addAlerts({
						pageId: 'emailserver',
						type: 'danger',
						message: 'Please enter your email address.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!serverData.replyemail) {
				dispatch(
					addAlerts({
						pageId: 'emailserver',
						type: 'danger',
						message: 'Please enter a reply email address.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else if (!isReplyEmail) {
				dispatch(
					addAlerts({
						pageId: 'emailserver',
						type: 'danger',
						message: 'Please enter a valid reply email address.',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			} else {
				dispatch(
					addAlerts({
						pageId: 'emailserver',
						type: 'danger',
						message:
							'Something Went Wrong Please try aftre some time .',
						icon: faTriangleExclamation,
						autoclose: 3000
					})
				)
			}
		} else {
			handleServerSettings()
		}
	}
	const FORM = (
		<>
			<div className=" rounded-sm">
				<div className="w-[350px] ">
					<TextBox
						name={'host_name'}
						id="host-name"
						type={'text'}
						value={serverData?.appsetting_smtp_hostname || ''}
						labelName={'Host Name'}
						onChange={(data) =>
							HandleData('appsetting_smtp_hostname', data)
						}
					/>

					<SimpleDropDown
						id="port-num"
						label="Number"
						value={serverData?.appsetting_smtp_port || ''}
						options={['587', '465']}
						onChange={(data) =>
							HandleData('appsetting_smtp_port', data)
						}
					/>

					<SimpleDropDown
						id="authentication"
						value={serverData?.appsetting_smtp_authentication || ''}
						label="Authentication"
						options={['yes', 'no']}
						onChange={(data) =>
							HandleData('appsetting_smtp_authentication', data)
						}
					/>

					<SimpleDropDown
						id="encryption"
						value={
							serverData?.appsetting_smtp_encryption_check || ''
						}
						label="Encryption Type"
						options={['TLS', 'SSL']}
						onChange={(data) =>
							HandleData('appsetting_smtp_encryption_check', data)
						}
					/>

					<TextBox
						name={'user_name'}
						id="user-name"
						type={'text'}
						value={serverData?.appsetting_smtp_username || ''}
						labelName={'User Name'}
						onChange={(data) =>
							HandleData('appsetting_smtp_username', data)
						}
					/>
					<TextBox
						id="password"
						type={'password'}
						value={serverData?.appsetting_smtp_password || ''}
						labelName={'Password'}
						onChange={(data) =>
							HandleData('appsetting_smtp_password', data)
						}
					/>
					<TextBox
						name={'user_email'}
						id="user-email"
						type={'email'}
						value={serverData?.appsetting_smtp_email || ''}
						labelName={'Email'}
						onChange={(data) =>
							HandleData('appsetting_smtp_email', data)
						}
					/>
					<TextBox
						id="sender-email"
						type={'text'}
						value={serverData?.appsetting_smtp_sender_email || ''}
						labelName={'Sender Email Address'}
						onChange={(data) =>
							HandleData('appsetting_smtp_sender_email', data)
						}
					/>
					<TextBox
						id="reply-email"
						type={'text'}
						value={serverData?.appsetting_smtp_reply_to_email || ''}
						labelName={'Reply To Email Address'}
						onChange={(data) =>
							HandleData('appsetting_smtp_reply_to_email', data)
						}
					/>
				</div>
			</div>
		</>
	)
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])
	useEffect(() => {
		setBody(FORM)
	}, [showDrawer, serverData])
	const handleDrawerClose = () => {
		dispatch(removeAlerts({ pageId: 'emailserver' }))
		dispatch(removePage())
	}
	const drawerFooterBody = (
		<>
			{' '}
			<h5 className="font-normal ">{''}</h5>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>
				<IconButton
					onClick={() => handleServerSettings()}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)

	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)

	return (
		<>
			<GlobalDrawer
				label={'Email Server Settings'}
				onClose={handleDrawerClose}
				body={body}
				scrollable={true}
				overlay={''}
				size={'md'}
				showDrawer={true}
				drawerFooterBody={drawerFooterBody}
				loader={loader}
				scrollableFooter={false}
				drawerPosition={'right'}
				pageId={'emailserver'}
			/>
		</>
	)
}

export default EmailServer
