import { ApolloClient, InMemoryCache } from '@apollo/client'
// import { createUploadLink } from  'apollo-upload-client'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { API_BASE_URL } from '../../constants'

const httpLink = createUploadLink({
	uri: API_BASE_URL + '/graphql'
})

const client = new ApolloClient({
	link: httpLink,
	// cache: new InMemoryCache()
	cache: new InMemoryCache({ addTypename: false })
})

export default client
