import React, { useState, useEffect } from 'react'
import Drawer from '../../../components/ui/drawers'
import PageAlerts from '../../PageAlerts'
import ProgressBar from '../../ProgressBar'
import TextBox from '../../../components/ui/TextBox'
import IconButton from '../../../components/ui/Buttons/Icon-button'
import {
	faBook,
	faEdit,
	faPlus,
	faUniversalAccess
} from '@fortawesome/free-solid-svg-icons'
import ElasticTextBox from '../../../components/ui/Elastic-textbox'
import { addAlerts, removeAlerts } from '../../../store/AlertSlice'
import { useDispatch } from 'react-redux'
import TreatmentPlan from '../TreatmentPlan'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import ElasticTextbox from '../../../components/ui/Elastic-textbox'
import Button from '../../../components/ui/Buttons/Button'
import PromptModal from '../PromptModal'
import useGlobalKeyPress from '../../../hooks/globalKeyPress'
const EditColumns = ({
	setDrawerstate,
	selectedData,
	PRODUCTS,
	setSelectedData
}) => {
	const [showDrawer, setShowDrawer] = useState(true)
	const [overlay, setOverlay] = useState('')
	const [loader, setLoader] = useState(false)
	const [size, setSize] = useState('60%')
	const [scrollable, setScrollable] = useState(true)
	const [scrollableFooter, setScrollableFooter] = useState(false)
	const [showPromptModal, setShowPromptModal] = useState(false)
	const [body, setBody] = useState(null)
	const [showAddTreatmentplan, setShowAddTreatmentPlan] = useState(false)
	const [showEditTreatmentPlan, setShowEditTreatmentPlan] = useState(false)
	const dispatch = useDispatch()
	const [current_position, setCurrentPosition] = useState(
		(selectedData[0]?.position + 1).toString() ?? ''
	)
	const [EditColumn, setEditColumn] = useState({
		...selectedData[0],
		risk_heading: selectedData[0]?.risk_heading || '',
		risk_description: selectedData[0]?.risk_description || '',
		risk_function: selectedData[0]?.risk_function || '',
		risk_owner: selectedData[0]?.risk_owner || '',
		expand_data: [
			{
				label: selectedData[0]?.expand_data[0]?.label,
				data: selectedData[0]?.expand_data[0]?.data
			},
			{
				label: selectedData[0]?.expand_data[1]?.label,
				data: selectedData[0]?.expand_data[1]?.data
			},
			{
				label: selectedData[0]?.expand_data[2]?.label,
				data: selectedData[0]?.expand_data[2]?.data
			},
			{
				label: selectedData[0]?.expand_data[3]?.label,
				data: selectedData[0]?.expand_data[3]?.data
			}
		]
	})
	const HANDLEEditColumn = (key, value) => {
		setEditColumn((prev) => {
			return {
				...prev,
				[key]: value
			}
		})
	}
	const handleKeyChange = (index, key, value) => {
		setEditColumn((prev) => {
			const updatedInternalData = [...prev.expand_data]
			updatedInternalData[index][key] = value
			return {
				...prev,
				expand_data: updatedInternalData
			}
		})
	}
	const FORM = (
		<>
			<form
				encType="multipart/form-data"
				className=" flex  flex-col justify-start"
			>
				<>
					<div className="flex  w-full flex-col justify-start ">
						<div className="flex w-full gap-2">
							<div className="relative w-1/2">
								<TextBox
									id={'risk_heading'}
									key={'risk_heading'}
									name="risk_heading"
									value={EditColumn.risk_heading}
									labelName={'Risk Heading'}
									onChange={(data) =>
										HANDLEEditColumn('risk_heading', data)
									}
								/>
								<Button
									children={'Ai'}
									onClick={(e) => {
										e.preventDefault()
										setShowPromptModal('risk_heading')
									}}
									className="btn-tiny btn-primary !absolute left-24 top-1"
								/>
							</div>
							<div className="w-1/2">
								<TextBox
									id={'risk_owner'}
									key={'risk_owner'}
									name={'risk_owner'}
									value={EditColumn.risk_owner}
									labelName={'Risk Owner'}
									onChange={(data) =>
										HANDLEEditColumn('risk_owner', data)
									}
								/>
							</div>
						</div>

						<div className="flex w-full gap-2">
							<div className="relative w-1/2">
								<ElasticTextBox
									id="risk_description"
									key={'risk_description'}
									labelName="Risk Description"
									value={EditColumn.risk_description}
									onChange={(data) =>
										HANDLEEditColumn(
											'risk_description',
											data
										)
									}
								/>
								<Button
									children={'Ai'}
									onClick={(e) => {
										e.preventDefault()
										setShowPromptModal('risk_description')
									}}
									className="btn-tiny btn-primary !absolute left-28 top-1"
								/>
							</div>
							<div className="w-1/2">
								<ElasticTextBox
									id="risk_function"
									key={'risk_function'}
									value={EditColumn.risk_function}
									onChange={(data) =>
										HANDLEEditColumn('risk_function', data)
									}
									labelName="Risk Function"
								/>
							</div>
						</div>
						<div className="flex w-full gap-2">
							<div className="w-1/2">
								<TextBox
									type={'number'}
									id={'current_position'}
									key={'current_position'}
									name="current_position"
									labelName={'Current Position'}
									value={current_position}
									onChange={(data) =>
										setCurrentPosition(data)
									}
								/>
							</div>
						</div>
						<div className="h5 border-b pb-1">Expanded data </div>
						{EditColumn.expand_data.map((item, index) => (
							<div
								className="flex w-full gap-2"
								key={index + 'label'}
							>
								<div className="w-1/2">
									<ElasticTextbox
										id={item.label}
										value={item.label}
										labelName={'Label'}
										onChange={(data) =>
											handleKeyChange(
												index,
												'label',
												data
											)
										}
									/>
								</div>

								<div className="w-1/2">
									<ElasticTextBox
										id={item.data}
										value={item.data}
										labelName={'Data'}
										onChange={(data) =>
											handleKeyChange(index, 'data', data)
										}
									/>
								</div>
							</div>
						))}
					</div>
				</>
				<div>
					<TreatmentPlan
						setTreatmentPlanData={setSelectedData}
						TreatmentPlanData={selectedData}
						products={PRODUCTS}
						setShowAddTreatmentPlan={setShowAddTreatmentPlan}
						showAddTreatmentplan={showAddTreatmentplan}
						setShowEditTreatmentPlan={setShowEditTreatmentPlan}
						showEditTreatmentPlan={showEditTreatmentPlan}
					/>
					{showPromptModal !== false && (
						<PromptModal
							setShowModal={setShowPromptModal}
							modalType={showPromptModal}
							HANDLEEditColumn={HANDLEEditColumn}
						/>
					)}
				</div>
			</form>
		</>
	)
	const handleDrawerClose = () => {
		setShowDrawer(false)
		setScrollable(false)
		setScrollableFooter(false)
		setSize('md')
		setOverlay('')
		setBody(null)
		setDrawerstate(false)
	}
	useEffect(() => {
		setTimeout(() => {
			document.body.classList.toggle('no-scroll', showDrawer)
		}, 300)
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [showDrawer])
	useEffect(() => {
		setBody(FORM)
	}, [
		showDrawer,
		EditColumn,
		showAddTreatmentplan,
		showEditTreatmentPlan,
		showPromptModal,
		current_position
	])
	const HandleSubmit = () => {
		let newData = { ...EditColumn }
		let index = PRODUCTS.findIndex((item) => item.id === selectedData[0].id)
		if (index !== -1) {
			PRODUCTS[index] = newData
			let previousIndex = selectedData[0]?.position
			let newIndex = Number(current_position) - 1
			if (newIndex >= PRODUCTS.length || newIndex < 0) {
				newIndex = PRODUCTS.length - 1
			}
			if (previousIndex !== newIndex) {
				let swapIndex = PRODUCTS.findIndex(
					(item) => item.position === newIndex
				)
				if (swapIndex !== -1) {
					;[PRODUCTS[previousIndex], PRODUCTS[newIndex]] = [
						PRODUCTS[newIndex],
						PRODUCTS[previousIndex]
					]
					PRODUCTS[previousIndex].position = previousIndex
					PRODUCTS[newIndex].position = newIndex
				}
			}
		}
		localStorage.setItem('products', JSON.stringify(PRODUCTS))
		setSelectedData([{ ...newData }])
		dispatch(
			addAlerts({
				pageId: 'riskmanagement_editcolumns',
				type: 'success',
				message: 'Data updated successfully',
				icon: faCheck,
				autoclose: 3000
			})
		)
		setTimeout(() => {
			dispatch(removeAlerts({ pageId: 'riskmanagement_editcolumns' }))
			handleDrawerClose()
		}, 3100)
	}
	const drawerFooterBody = (
		<>
			{' '}
			{/* <h5 className="font-normal "> </h5> */}
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={() => setShowAddTreatmentPlan(true)}
					Icon={faPlus}
					size={'sm'}
					className="btn btn-large btn-primary flex gap-[6px]"
				>
					Add Fields
				</IconButton>
				<IconButton
					onClick={() => setShowEditTreatmentPlan(true)}
					Icon={faEdit}
					size={'sm'}
					className="btn btn-large btn-primary flex gap-[6px]"
				>
					Edit Fields
				</IconButton>
			</div>
			<div className="flex flex-wrap-reverse gap-[3px]">
				<IconButton
					onClick={handleDrawerClose}
					Icon={faBook}
					size={'sm'}
					className="btn btn-large btn-complete flex gap-[6px]"
				>
					Cancel
				</IconButton>

				<IconButton
					onClick={HandleSubmit}
					Icon={faUniversalAccess}
					size={'sm'}
					className="btn btn-success btn-large flex gap-[6px]"
				>
					Submit
				</IconButton>
			</div>
		</>
	)
	const handleKeyPress = (event) => {
		if (event.key === 'Escape') handleDrawerClose()
	}

	useGlobalKeyPress(handleKeyPress)
	return (
		<>
			{loader && <ProgressBar />}
			<PageAlerts pageId={'riskmanagement_editcolumns'} />

			<Drawer
				label="Edit Columns"
				size={size}
				body={body}
				drawerFooterBody={drawerFooterBody}
				scrollable={scrollable}
				onClose={handleDrawerClose}
				overlay={overlay}
				scrollableFooter={scrollableFooter}
				drawerPosition={'right'}
				showDrawer={showDrawer}
			/>
		</>
	)
}
export default EditColumns
